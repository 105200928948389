import React from 'react'
import { Route } from 'react-router-dom'
import { PasswordRequest } from '../../layout/unauthorized/auth/content/password-request'
import { PasswordResult } from '../../layout/unauthorized/auth/content/password-result'
import { PasswordReset } from '../../layout/unauthorized/auth/content/password-reset'

//these components are now under the /auth route. remove this file when the emails are updated to the new address on the back end
export function LayoutPasswordRecover() {
  return (
    <React.Fragment>
      <Route path="/public/password-recover/request" component={PasswordRequest} />
      <Route path="/public/password-recover/result/:email" component={PasswordResult} />
      <Route path="/public/password-recover/reset/:resetToken" component={PasswordReset} />
    </React.Fragment>
  )
}
