import React from 'react'
import { Route } from 'react-router-dom'
import { LoginSetting } from './content/login-settings'

export const LayoutLoginSetting = () => {
  return (
    <React.Fragment>
      <Route path="/org/login-setting" component={LoginSetting} />
    </React.Fragment>
  )
}
