import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ButtonPrimary } from '../../../../../../../../shared/legacy/button'
import { Card } from '../../../../../../../../shared/card/card'

export const StorageChunkDetails = forwardRef(({useFileApiForm, storageKey, onDelete}, ref) => {

    StorageChunkDetails.propTypes = {
        useFileApiForm: PropTypes.func.isRequired
    }

    const [fileFormActions, fileFormState] = useFileApiForm()
    
    useImperativeHandle(ref, () => ({
        reload
    }))
    
    useEffect(() => {
        if (storageKey) {
            let path = `details?storage-key=${storageKey}`
            fileFormActions.get(path)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storageKey])

    const fileFound = useMemo(() => fileFormState.form.found, [fileFormState.form])

    const reload = async () => {
        let path = `details?storage-key=${storageKey}`
        try { await fileFormActions.get(path)
        } catch { fileFormActions.init() }
    }

    const handleDelete = async () => {    
        let path = `file?storage-key=${storageKey}`
        await fileFormActions.delete(path)
        await reload()
        onDelete && onDelete()
    }

    return (
    <React.Fragment>
        {fileFound &&
        <Card title='File Details'>
            <table className="table table-responsive-sm">
                <tbody>
                    <tr>
                        <td>Key</td>
                        <td>
                            {fileFormState.form.storageKey}
                        </td>
                    </tr>
                    <tr>
                        <td>Content Length</td>
                        <td>
                            {fileFormState.form.contentLength}
                        </td>
                    </tr>
                    <tr>
                        <td>Content Type</td>
                        <td>
                            {fileFormState.form.contentType}
                        </td>
                    </tr>
                    <tr>
                        <td>Last Modified</td>
                        <td>
                            {fileFormState.form.lastModified}
                        </td>
                    </tr>
                    <tr>
                        <td />
                        <td>
                            <ButtonPrimary text="delete" onClick={handleDelete} />
                        </td>
                    </tr>
                </tbody>
            </table>
            </Card>}
    </React.Fragment>
    )
})
