import React, { useContext, useEffect, useReducer } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

const BreadcrumbContent = React.createContext()

export const useBreadcrumbContext = () => useContext(BreadcrumbContent)

export const useRouteStack = () => {
  const history = useHistory()

  const breadcrumbContext = useBreadcrumbContext()

  const backRoute = level => {
    const item = breadcrumbContext.stack[breadcrumbContext.stack.length - level - 1]

    if (item) {
      history.replace(item.url)
    } else {
      history.goBack()
    }
  }

  const getStackLevel = level => {
    return breadcrumbContext.stack[breadcrumbContext.stack.length - level - 1]
  }

  const replaceStackLevel = (level, append) => {
    const item = breadcrumbContext.stack[breadcrumbContext.stack.length - level - 1]
    if (item) {
      history.replace(`${item.url}/${append}`)
    }
  }

  return { backRoute, getStackLevel, replaceStackLevel }
}

const useBreadcrumbReducer = () => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'add':
        return [...state, action.payload]
      case 'update':
        let index = state.findIndex(i => i.url === action.payload.url)
        if (index !== -1) {
          let newState = [...state]
          newState[index] = action.payload
          return newState
        } else {
          return state
        }
      case 'remove':
        return state.filter(i => i.url !== action.payload)
      default:
        return state
    }
  }, [])

  const add = (url, text, Icon, block) => dispatch({ type: 'add', payload: { url, text, Icon, block } })
  const update = (url, text, Icon, block) => dispatch({ type: 'update', payload: { url, text, Icon, block } })
  const remove = url => dispatch({ type: 'remove', payload: url })

  return [state, add, update, remove]
}

export const BreadcrumbProvider = ({ children }) => {
  const [stack, add, update, remove] = useBreadcrumbReducer()

  return (
    <BreadcrumbContent.Provider value={{ stack, add, update, remove }}>
      {children}
    </BreadcrumbContent.Provider>
  )
}

export const BreadcrumbView = ({ children, Icon, text, block, onMatch, onPath }) => {
  const { url, isExact } = useRouteMatch()
  const location = useLocation()

  const breadcrumbContext = useBreadcrumbContext()

  useEffect(
    () => {
      breadcrumbContext.add(url, text, Icon, block)
      return () => breadcrumbContext.remove(url)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  )

  useEffect(
    () => {
      breadcrumbContext.update(url, text, Icon, block)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [text, Icon, block]
  )

  useEffect(
    () => {
      if (isExact) {
        onMatch && onMatch()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isExact]
  )

  useEffect(
    () => {
      onPath && location.pathname.includes(url) && onPath()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  )

  return (
    <React.Fragment>
      {isExact &&
        <React.Fragment>
          {children}
        </React.Fragment>}
    </React.Fragment>
  )
}
