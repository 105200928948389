import React, { useEffect, useState } from 'react'
import { useApiListOrganization } from '../../../api-new/state/content'
import { useLoad } from '../../hooks/load'
import { FormSelectObjFilter } from '../form-select-obj-filter'

export const FormSelectOrgaization = ({ lable, name, organizationID, required, small, onChange }) => {
  const apiListOrganization = useApiListOrganization()

  const [selectedItem, updateSelectedItem] = useState()

  useLoad(() => apiListOrganization.get(), [])

  useEffect(
    () => {
      let org = apiListOrganization.items.find(i => i.id === organizationID)
      if (org) {
        updateSelectedItem(org)
      }
    },
    [apiListOrganization.items, organizationID]
  )

  const handleSelect = value => updateSelectedItem(value)

  const handleChange = value => onChange && onChange(value)

  return (
    <FormSelectObjFilter
      label={lable}
      name={name}
      items={apiListOrganization.items}
      selectedItem={selectedItem}
      itemName="name"
      itemValue="id"
      required={required}
      small={small}
      valueAsNumber
      onSelect={handleSelect}
      onChange={handleChange}
    />
  )
}
