import { useMemo } from 'react'

export const useButtonCount = (selectedItems, readonlyItems) => {
  return useMemo(
    () => {
      let selectedIDs = selectedItems ? selectedItems.map(i => i.id) : []
      let readonlyIDs = readonlyItems ? readonlyItems.map(i => i.id) : []

      let allIDs = [...selectedIDs, ...readonlyIDs]
      let uniqueIDs = [...new Set(allIDs)]

      return uniqueIDs.length
    },
    [selectedItems, readonlyItems]
  )
}
