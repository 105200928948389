import React, { useEffect, useMemo } from 'react'
import { FormatLabel } from '../../../../../../shared/form/format-label'
import { InputNew } from './input-new'
import { Question } from './question'
import { emulateTab } from 'emulate-tab'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from '../../../../../../shared/form/form'

export const QuestionListContext = React.createContext()

export const QuestionList = ({ label, name, readOnly, showCorrectChoice, onChange }) => {
  const { watch, setValue } = useFormContext()

  let formDisabled = useFormDisable()

  let state = watch(name)

  const questions = useMemo(() => (state ? JSON.parse(state) : []), [state])

  const changeState = value => setValue(name, value, { shouldDirty: true })

  useEffect(
    () => {
      onChange && onChange(questions)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questions]
  )

  const handleAddQuestion = value => {
    questions.push({ text: value, choices: [] })
    const meta = JSON.stringify(questions)
    changeState(meta)
    setTimeout(() => emulateTab.backwards(), 100)
  }

  const handleUpdateQuestion = (questionIndex, question) => {
    questions[questionIndex] = question
    const meta = JSON.stringify(questions)
    changeState(meta)
  }

  const handleDeleteQuestion = questionIndex => {
    if (questions[questionIndex].id) {
      questions[questionIndex].delete = true
    } else {
      questions.splice(questionIndex, 1)
    }

    const meta = JSON.stringify(questions)
    changeState(meta)
  }

  const handleDropQuestion = (dragIndex, dropIndex) => {
    questions.forEach(i => (i.modified = true))
    let hold = questions[dragIndex]
    questions.splice(dragIndex, 1)
    questions.splice(dropIndex, 0, hold)
    const meta = JSON.stringify(questions)
    changeState(meta)
  }

  const handleAddChoice = (questionIndex, value) => {
    if (questions[questionIndex].choices) {
      questions[questionIndex].choices.push({ text: value, correct: false })
    } else {
      questions[questionIndex].choices = [{ text: value, correct: false }]
    }

    const meta = JSON.stringify(questions)
    changeState(meta)
  }

  const handleUpdateChoice = (questionIndex, choiceIndex, choice) => {
    questions[questionIndex].choices[choiceIndex] = choice
    const meta = JSON.stringify(questions)
    changeState(meta)
  }

  const handleDeleteChoice = (questionIndex, choiceIndex) => {
    let question = questions[questionIndex]
    let choice = questions[questionIndex].choices[choiceIndex]

    if (question.correctChoiceID !== undefined && question.correctChoiceID === choice.id) {
      question.correctChoiceID = null
    }

    if (questions[questionIndex].choices[choiceIndex].id) {
      questions[questionIndex].choices[choiceIndex].delete = true
    } else {
      questions[questionIndex].choices.splice(choiceIndex, 1)
    }

    const meta = JSON.stringify(questions)
    changeState(meta)
  }

  const handleCorrectChoice = (questionIndex, choiceIndex) => {
    for (let choice of questions[questionIndex].choices) {
      choice.correct = false
    }
    questions[questionIndex].choices[choiceIndex].correct = true
    const meta = JSON.stringify(questions)
    changeState(meta)
  }

  const handleDropChoice = (questionIndex, dragIndex, dropIndex) => {
    let choices = questions[questionIndex].choices
    choices.forEach(i => (i.modified = true))
    let hold = choices[dragIndex]
    choices.splice(dragIndex, 1)
    choices.splice(dropIndex, 0, hold)
    const meta = JSON.stringify(questions)
    changeState(meta)
  }

  return (
    <React.Fragment>
      <FormatLabel label={label}>
        <QuestionListContext.Provider
          value={{
            questionIndex: 1,
            choiceIndex: 1,
            disabled: formDisabled,
            formName: name,
            showCorrectChoice: showCorrectChoice,
            handleAddQuestion: handleAddQuestion,
            handleUpdateQuestion: handleUpdateQuestion,
            handleDeleteQuestion: handleDeleteQuestion,
            handleDropQuestion: handleDropQuestion,
            handleAddChoice: handleAddChoice,
            handleUpdateChoice: handleUpdateChoice,
            handleDeleteChoice: handleDeleteChoice,
            handleCorrectChoice: handleCorrectChoice,
            handleDropChoice: handleDropChoice
          }}>
          {questions.map((question, i) =>
            <React.Fragment key={i}>
              {!question.delete &&
                <div className="py-2">
                  <Question index={i} question={question} readOnly={readOnly} />
                </div>}
            </React.Fragment>
          )}
          <div className="py-2">
            <InputNew pos={questions.length} placeholder="new question" readOnly={readOnly} onEnter={handleAddQuestion} />
          </div>
        </QuestionListContext.Provider>
      </FormatLabel>
    </React.Fragment>
  )
}

export const indexChar = value => String.fromCharCode(value + 65)
