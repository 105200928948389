import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Column, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import { useApiListRoleOrg } from '../../../../../api-new/state/content'

export const RoleOrgListUser = () => {
  const { roleOrg } = useParams()

  const apiListRoleOrg = useApiListRoleOrg()

  const title = useMemo(() => `Role ${roleOrg}`, [roleOrg])

  const handleReady = async () => await apiListRoleOrg.get(`${roleOrg}/role`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text={roleOrg}>
        <CardFullHeightScrollY title={title}>
          <TableBuilder apiList={apiListRoleOrg} onReady={handleReady}>
            <Column field="roleName" sortable filter />
            <Column field="organizationName" sortable filter />
            <Column field="organizationType" header="type" sortable filter />
            <Column field="email" sortable filter />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
