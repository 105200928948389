import React, { useEffect, useMemo } from 'react'
import { useGetUniqueRowValues } from './hooks'
import { useAddUserContext } from '../context'
import { useImportContext } from './context'
import { Card } from '../../../../../../../shared/card/card'

export const StepRole = () => {
  const addUserContext = useAddUserContext()
  const importContext = useImportContext()

  const uniqueRoleNames = useGetUniqueRowValues(importContext.rows, importContext.indexes.role)

  const handleUpdate = (index, roleName, role) => {
    let newValues = { ...importContext.roleValues }

    if (role) newValues[index] = { roleName, role }
    else delete newValues[index]

    importContext.updateRoleValues(newValues)
  }

  useEffect(
    () => {
      if (Object.keys(importContext.roleValues).length) {
        return
      }

      let values = {}

      uniqueRoleNames.forEach((roleName, index) => {
        let roleOptions = addUserContext.allRoles.filter(role => role.role.toLowerCase().includes(roleName))

        if (roleOptions.length === 1) {
          let role = roleOptions[0]
          values[index] = { roleName, role }
        }
      })

      importContext.updateRoleValues(values)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addUserContext.allRoles, uniqueRoleNames]
  )

  return (
    <React.Fragment>
      <Card title="map roles">
        {uniqueRoleNames.map((roleName, i) =>
          <div key={i} className="m-2">
            <SelectRole index={i} label={roleName} roleName={roleName} onUpdate={handleUpdate} />
          </div>
        )}
      </Card>
    </React.Fragment>
  )
}

const SelectRole = ({ index, label, roleName, onUpdate }) => {
  const addUserContext = useAddUserContext()
  const importContext = useImportContext()

  const displayRoles = useMemo(() => addUserContext.allRoles.filter(role => role.role.toLowerCase().includes(roleName)), [
    addUserContext.allRoles,
    roleName
  ])

  const value = useMemo(
    () => {
      let item = importContext.roleValues[index]
      if (item) {
        return item.role.id
      }
      return -1
    },
    [index, importContext.roleValues]
  )

  useEffect(
    () => {
      let warning = value === -1 && displayRoles.length !== 0
      importContext.roleWarnings[index] = warning
      importContext.updateRoleWarnings({ ...importContext.roleWarnings })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  const showWarning = useMemo(() => !!importContext.roleWarnings[index], [importContext.roleWarnings, index])

  const handleChange = e => {
    let id = parseInt(e.target.value)
    let role = addUserContext.allRoles.find(i => i.id === id)
    onUpdate && onUpdate(index, roleName, role)
  }

  return (
    <div>
      <label className="form-label">
        {label}
      </label>
      <select className={`form-control ${showWarning && 'border-danger'}`} value={value} onChange={handleChange}>
        <option value={-1}>select</option>
        {displayRoles.map((role, i) =>
          <option key={i} value={role.id}>
            {role.role}
          </option>
        )}
      </select>
    </div>
  )
}
