import React, { useContext, useEffect, useMemo } from 'react'
import { Choice } from './choice'
import { Input } from './input'
import { InputNew } from './input-new'
import { QuestionListContext } from './question-list'
import { useFormContext } from 'react-hook-form'
import { IconClickDelete } from '../../../../../../shared/icon/icon-click'

export const Question = ({ index, question, readOnly }) => {
  const { setError, clearErrors } = useFormContext()

  let questionListContext = useContext(QuestionListContext)

  const choices = useMemo(() => question.choices || [], [question])
  const activeChoices = useMemo(() => choices.filter(i => !i.delete), [choices])

  const errorChoice = useMemo(() => !question.delete && activeChoices.length < 2, [question, activeChoices])
  const errorCorrect = useMemo(() => questionListContext.showCorrectChoice && !activeChoices.some(i => i.correct), [
    activeChoices,
    questionListContext.showCorrectChoice
  ])

  useEffect(
    () => {
      if (errorChoice || errorCorrect) setError(questionListContext.formName, { message: 'question error' })
      else clearErrors(questionListContext.formName)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorChoice, errorCorrect]
  )

  const handleChange = value => {
    question.text = value
    question.modified = true
    questionListContext.handleUpdateQuestion(index, question)
  }

  const handleDelete = () => questionListContext.handleDeleteQuestion(index)
  const handleDrop = dragIndex => questionListContext.handleDropQuestion(dragIndex, index)
  const handleAddChoice = value => questionListContext.handleAddChoice(index, value)

  const Actions = () =>
    <React.Fragment>
      <IconClickDelete onClick={handleDelete} />
    </React.Fragment>

  questionListContext.choiceIndex = 0

  return (
    <React.Fragment>
      <Input
        ddGroup="question"
        index={index}
        pos={questionListContext.questionIndex++}
        value={question.text}
        draggable
        limit={500}
        readOnly={questionListContext.disabled || readOnly}
        onChange={handleChange}
        onDrop={handleDrop}
        Actions={Actions}
      />
      <div className="ps-4">
        {choices.map((choice, i) =>
          <React.Fragment key={i}>
            {!choice.delete && <Choice index={i} questionIndex={index} choice={choice} readOnly={readOnly} />}
          </React.Fragment>
        )}
        {activeChoices.length < 5 &&
          <InputNew
            pos={question.choices && question.choices.length}
            placeholder="new choice"
            readOnly={readOnly}
            onEnter={handleAddChoice}
          />}
      </div>

      {errorChoice &&
        <div className="px-4">
          <small className="text-danger">2 choices required</small>
        </div>}
      {errorCorrect &&
        <div className="px-4">
          <small className="text-danger">correct choice required</small>
        </div>}
    </React.Fragment>
  )
}
