import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormRequired } from './hooks/input-hooks'
import { useLoad } from '../hooks/load'
import { FilterList } from '../component/filter-list'

export const FormSelectObjFilter = ({
  className,
  label,
  name,
  subtext,
  items,
  selectedItem,
  itemName,
  itemValue,
  selectText,
  required,
  small,
  disabled,
  valueAsNumber,
  onChange,
  onSelect,
  DisplayItemSelected,
  DisplayItemList
}) => {
  const { watch, setValue } = useFormContext()

  let formDisabled = useFormDisable()

  let watchValue = watch(name)

  const selectedValue = useMemo(() => (selectedItem ? selectedItem[itemValue] : null), [selectedItem, itemValue])

  useLoad(
    () => {
      let value = valueAsNumber ? parseInt(selectedValue) : selectedValue
      setValue(name, value, { shouldDirty: true })
    },
    [selectedValue]
  )

  useLoad(() => onChange && onChange(watchValue), [watchValue])

  useFormRequired(name, required, disabled)

  return (
    <FormatLabel className={className} name={name} label={label} subtext={subtext} hasValue={!!watchValue} required={required}>
      <FilterList
        items={items}
        itemName={itemName}
        selectedItem={selectedItem}
        selectText={selectText}
        disabled={disabled || formDisabled}
        required={required}
        small={small}
        onSelect={onSelect}
        DisplayItemSelected={DisplayItemSelected}
        DisplayItemList={DisplayItemList}
      />
    </FormatLabel>
  )
}
