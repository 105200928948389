import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { useApiFormAsset } from '../../../../../../api-new/state/content'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { NodeAssetEdit } from './node-asset-edit'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { NodeAssetDelete } from './node-asset-delete'
import { IconList } from '../../../../../../shared/icon/icon'

export const NodeAssetDetails = ({ parentURL }) => {
  const { url } = useRouteMatch()
  const { assetID } = useParamsInt()

  const apiFormAsset = useApiFormAsset()

  const handlePath = async () => apiFormAsset.get(`${assetID}`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text={apiFormAsset.form.name} onPath={handlePath} block />

      <Route path={`${url}/edit`}>
        <NodeAssetEdit parentUrl={url} assetID={assetID} apiForm={apiFormAsset} />
      </Route>

      <Route path={`${url}/delete`}>
        <NodeAssetDelete parentURL={parentURL} assetID={assetID} />
      </Route>
    </React.Fragment>
  )
}
