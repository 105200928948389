import React, { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { ButtonPrimary } from '../legacy/button'
// import { AuthContext } from '../../context/auth.context'
import Modal from 'react-bootstrap/Modal'
import { useLogout } from '../hooks/logout'

export const Timeout = () => {
  const logout = useLogout()

  const [show, updateShow] = useState(false)

  const onIdle = () => {
    pause()
    logout()
    // updateShow(true)
  }

  const { pause } = useIdleTimer({
    onIdle,
    timeout: parseInt(process.env.REACT_APP_TIMEOUT),
    throttle: 1000
  })

  const handleClick = () => {
    window.location.replace(`/auth/email?redirect=${window.location.pathname}`)
  }

  return (
    <React.Fragment>
      <ModalTimeout show={show} onClick={handleClick} />
    </React.Fragment>
  )
}

const ModalTimeout = ({ show, onClick }) => {
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Notice</Modal.Title>
      </Modal.Header>
      <Modal.Body>You have been logged out due to inactivity.</Modal.Body>
      <Modal.Footer>
        <ButtonPrimary text="Ok" onClick={onClick} />
      </Modal.Footer>
    </Modal>
  )
}
