import Papa from 'papaparse'

export const exportCsv = (data, filename) => {
  let value = Papa.unparse(data)
  let blob = new Blob([value], { type: 'text/csv' })
  let a = document.createElement('a')

  a.download = filename
  a.href = window.URL.createObjectURL(blob)
  a.click()
}
