import React from 'react'
import { Route } from 'react-router-dom'
import { ProviderAdd } from './content/provider-add'
import { ProviderAuthView } from './content/provider-auth-view'
import { ProviderAuthList } from './content/provider-auth-list'
import { ProviderEdit } from './content/provider-edit'
import { ProviderList } from './content/provider-list'
import { ProviderOrganization } from './content/provider-organization'
import { ProviderOrganizationEdit } from './content/provider-organization-edit'
import { ProviderRecycle } from './content/provider-recycle'
import { Delete } from '../../../../shared/page/page-delete'
import { Purge } from '../../../../shared/page/page-purge'
import { useApiFormProvider } from '../../../../api-new/state/auth'
import { LogEventSys } from '../../../../shared/page/log-event/log-event-sys'

export const LayoutProvider = () => {
  return (
    <React.Fragment>
      <Route path="/admin/provider/list" component={ProviderList} />
      <Route path="/admin/provider/list/edit/:providerID" component={ProviderEdit} />
      <Route path="/admin/provider/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormProvider} titleParam="providerName" successPath="/admin/provider/list" />
      </Route>

      <Route path="/admin/provider/list/edit/:providerID/organization/:providerID" component={ProviderOrganization} />
      <Route
        path="/admin/provider/list/edit/:providerID/organization/:providerID/edit/:providerID/:organizationID"
        component={ProviderOrganizationEdit}
      />

      <Route path="/admin/provider/list/add" component={ProviderAdd} />
      <Route path="/admin/provider/list/recycle" component={ProviderRecycle} />

      <Route path="/admin/provider/list/recycle/purge/:purgeID">
        <Purge useApiForm={useApiFormProvider} titleParam="providerName" successPath="/admin/provider/list/recycle" />
      </Route>

      <Route path="/admin/provider/list/edit/:providerID/auth/:providerID" component={ProviderAuthList} />
      <Route path="/admin/provider/list/edit/:providerID/auth/:providerID/view/:authProviderID" component={ProviderAuthView} />

      <Route path="/admin/provider/list/activity-all">
        <LogEventSys subject="provider" />
      </Route>
      <Route path="/admin/provider/list/activity-id/:id">
        <LogEventSys subject="provider" />
      </Route>
    </React.Fragment>
  )
}
