import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { IconEdit } from '../../../../../../shared/icon/icon'
import { useApiFormNodeLibrary } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { Card } from '../../../../../../shared/card/card'
import { useDataContext } from '../../data-context'
import { NodeLibraryMenuFull } from '../common/node-library-menu'
import { ThumnailAsset } from '../../common/thumbnail/thumbnail-asset'
import { NodeTags } from '../../common/shared/node-tags'

export const NodeLibraryEdit = ({ parentURL, nodeID }) => {
  const apiFormNodeLibrary = useApiFormNodeLibrary()

  const dataContext = useDataContext()

  useLoad(
    async () => {
      apiFormNodeLibrary.get(nodeID)
    },
    [nodeID]
  )

  const handleSubmit = async data => {
    let node = await apiFormNodeLibrary.put(data.id, data)
    dataContext.nodeUpdate(node)
  }

  const HeaderMenu = () => NodeLibraryMenuFull(parentURL)

  return (
    <React.Fragment>
      <BreadcrumbView text="edit" Icon={IconEdit}>
        <CardFullHeightScrollY title="edit" HeaderMenu={HeaderMenu}>
          <Card title="asset">
            <Form state={apiFormNodeLibrary.form} onSubmit={handleSubmit}>
              <FormText label="name" name="name" />
              <FormSubmit text="update" />
            </Form>
          </Card>

          <NodeTags apiForm={apiFormNodeLibrary} />

          <ThumnailAsset nodeID={nodeID} />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
