import { useMemo } from 'react'
import { DashboardHeader, Widget } from '../../../../../../shared/chart/widget'
import { ChartBar } from '../../../../../../shared/chart/chart'
import { IconSession } from '../../../../../../shared/icon/icon'
import { useLoad } from '../../../../../../shared/hooks/load'
import { getPlatformColors } from '../../../../../../shared/chart/chart-color'
import moment from 'moment'
import { useApiListGraphGeneralOrg } from '../../../../../../api-new/state/analytics'

export const DashboardAnalyticSession = () => {
  const apiListGraphGeneralOrg = useApiListGraphGeneralOrg()

  useLoad(() => apiListGraphGeneralOrg.get('duration'), [])

  const chartData = useMemo(
    () => {
      if (!apiListGraphGeneralOrg.items.length) {
        return { labels: [], data: undefined, average: 0 }
      }

      let sumDuration = apiListGraphGeneralOrg.items.reduce((acc, val) => {
        return acc.add(moment.duration(val.duration))
      }, moment.duration())

      let avgMS = sumDuration._milliseconds / apiListGraphGeneralOrg.items.length
      let average = moment.utc(avgMS).format('HH:mm:ss')

      let sorted = apiListGraphGeneralOrg.items.sort((a, b) => a.os.localeCompare(b.os))
      const labels = sorted.map(i => i.os)
      const data = sorted.map(i => {
        let time = moment(i.duration, 'HH:mm:ss')
        return parseFloat(`${time.minutes()}.${time.seconds()}`)
      })

      const colors = getPlatformColors(labels)

      const dataSet = {
        data: data,
        borderColor: colors,
        backgroundColor: colors,
        borderWidth: 1
      }

      return { labels: labels, data: dataSet, average: average }
    },
    [apiListGraphGeneralOrg.items]
  )

  return (
    <Widget
      header={<DashboardHeader Icon={IconSession} text="AVERAGE SESSION" value={chartData.average} />}
      body={<ChartBar labels={chartData.labels} data={chartData.data} displayX displayY />}
    />
  )
}
