import React from 'react'
import { Confirm } from '../../../../shared/component/confirm'
import { BreadcrumbView, useRouteStack } from '../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../shared/hooks/location'
import { useManageAssessmentForm } from '../../../../api/state'
import { IconClose } from '../../../../shared/icon/icon'

export const AssessmentManageClear = () => {
  const { assessmentID } = useParamsInt()

  const { backRoute } = useRouteStack()

  const [manageAssessmentFormActions, manageAssessmentFormState] = useManageAssessmentForm()

  const handleMatch = async () => await manageAssessmentFormActions.get(assessmentID)

  const handleSubmit = async data => {
    await manageAssessmentFormActions.delete(`${data.id}/clear`)
    backRoute(2)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconClose} text="clear" onMatch={handleMatch}>
        <Confirm title="clear results" confirmValue="clear" state={manageAssessmentFormState} onSubmit={handleSubmit} />
      </BreadcrumbView>
    </React.Fragment>
  )
}
