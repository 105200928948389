import React from 'react'

export const Button = ({ Icon, isActive, onClick }) => {
  return (
    <React.Fragment>
      <div className={`m-2 ${isActive && 'text-info'}`} onClick={onClick}>
        {Icon ? <Icon /> : <span>button</span>}
      </div>
    </React.Fragment>
  )
}

export const ButtonBar = ({ children }) => {
  return (
    <div className="border-left border-right d-flex flex-wrap">
      {children}
    </div>
  )
}

export const ButtonSpacer = () => {
  return <div className="border-left" />
}

export const ButtonClick = ({ Icon, onClick }) => {
  return <Button Icon={Icon} onClick={onClick} />
}
