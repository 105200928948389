import React, { useMemo } from 'react'
import { IconSelectListAdd, IconSelectListReadonly, IconSelectListRemove } from '../../../../../../../shared/icon/icon'

export const SelectListItem = ({ selectedItems, readonlyItems, item, fnListFormat, onAdd, onRemove }) => {
  const isSelected = useMemo(() => !!selectedItems.find(selectedItem => selectedItem.id === item.id), [item, selectedItems])
  const isReadonly = useMemo(() => !!readonlyItems.find(readonlyItem => readonlyItem.id === item.id), [item, readonlyItems])

  const handleClick = () => {
    if (isReadonly) {
      return
    }

    if (isSelected) onRemove(item)
    else onAdd(item)
  }

  return (
    <div className="d-flex" style={{ cursor: 'pointer' }} onClick={handleClick}>
      <div className={`border rounded p-1 m-1 ${(isReadonly || isSelected) && 'bg-primary text-white'}`}>
        {isReadonly ? <IconSelectListReadonly /> : isSelected ? <IconSelectListAdd /> : <IconSelectListRemove />}
      </div>
      <div className="border-bottom p-1 m-1 flex-fill">
        {fnListFormat(item)}
      </div>
    </div>
  )
}
