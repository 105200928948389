import React, { useCallback, useMemo, useState } from 'react'
import { MouseClickAway } from '../legacy/dropdown-search/mouse-click-away'
import { IconClose } from '../icon/icon'

export const FilterList = ({
  items,
  selectedItem,
  DisplayItemSelected,
  DisplayItemList,
  itemName,
  selectText,
  disabled,
  small,
  fnFilter,
  fnSort,
  fnSelectedItem,
  onSelect
}) => {
  const [showFilter, updateShowFilter] = useState(false)
  const [filterValue, updateFilterValue] = useState('')

  const filteredItems = useFilter(fnFilter, filterValue, items, itemName)
  const sortItems = useSort(fnSort, filteredItems)

  const _selectText = useMemo(() => selectText || 'Select', [selectText])

  const handleFilterChange = e => updateFilterValue(e.target.value)

  const _fnSelectedItem = useCallback(
    (item, selectedItem) => {
      if (fnSelectedItem) return fnSelectedItem(item, selectedItem)
      else return selectedItem && item.id === selectedItem.id
    },
    [fnSelectedItem]
  )

  const handleSelect = item => {
    updateShowFilter(false)
    onSelect && onSelect(item)
  }

  const handleClear = () => {
    updateShowFilter(false)
    onSelect && onSelect(null)
  }

  const handleFilterShow = () => {
    if (disabled) {
      return
    }
    updateShowFilter(true)
  }

  const handleFilterClear = () => {
    if (filterValue) {
      updateFilterValue('')
    } else {
      updateShowFilter(false)
    }
  }

  const handleMouseClickAway = () => updateShowFilter(false)

  const ItemSelected = useMemo(
    () => {
      if (DisplayItemSelected) return DisplayItemSelected
      else return DefaultItem
    },
    [DisplayItemSelected]
  )

  const ItemList = useMemo(
    () => {
      if (DisplayItemList) return DisplayItemList
      else return DefaultItem
    },
    [DisplayItemList]
  )

  return (
    <React.Fragment>
      <MouseClickAway onClick={handleMouseClickAway}>
        {!showFilter &&
          <div>
            <div className="input-group">
              <div className={`form-control ${disabled && 'bg-light'}`} onClick={handleFilterShow}>
                <div className="no-wrap text-truncate">
                  {selectedItem
                    ? <ItemSelected item={selectedItem} itemName={itemName} />
                    : <div>
                        {_selectText}
                      </div>}
                </div>
              </div>
            </div>
          </div>}

        {showFilter &&
          <div style={{ cursor: 'pointer' }}>
            <div className="dropdown">
              <div className={`input-group ${small && 'input-group-sm'}`}>
                <input className="form-control" value={filterValue} placeholder="filter" autoFocus onChange={handleFilterChange} />
                <span className="input-group-text" style={{ cursor: 'pointer' }} onClick={handleFilterClear}>
                  <IconClose />
                  {filterValue ? <span>Clear</span> : <span>Close</span>}
                </span>
              </div>

              <div
                className={`dropdown-menu ${showFilter && 'show'}`}
                style={{ maxHeight: '50vh', maxWidth: '50vw', overflow: 'auto', direction: 'ltr' }}>
                {sortItems &&
                  <React.Fragment>
                    <div className="dropdown-item" onClick={handleClear}>
                      {_selectText}
                    </div>
                    {sortItems.length
                      ? sortItems.map((item, i) =>
                          <div
                            key={i}
                            className={`dropdown-item ${_fnSelectedItem(item, selectedItem) && 'bg-primary text-light'}`}
                            onClick={() => handleSelect(item)}>
                            <ItemList item={item} itemName={itemName} />
                          </div>
                        )
                      : <div className="dropdown-item">No Results</div>}
                  </React.Fragment>}
              </div>
            </div>
          </div>}
      </MouseClickAway>
    </React.Fragment>
  )
}

const DefaultItem = ({ item, itemName }) =>
  <div>
    {item[itemName]}
  </div>

const useFilter = (fnFilter, filterValue, items, itemName) => {
  return useMemo(
    () => {
      if (!filterValue || filterValue.length <= 2) {
        return items
      }

      if (fnFilter) {
        return fnFilter(items, filterValue)
      }

      const lowerValue = filterValue.toLowerCase()
      return items.filter(item => item[itemName].toLowerCase().includes(lowerValue))
    },
    [fnFilter, filterValue, items, itemName]
  )
}

const useSort = (fnSort, items) => {
  return useMemo(
    () => {
      if (!fnSort) {
        return items
      }

      return fnSort(items)
    },
    [fnSort, items]
  )
}
