import { useMemo } from 'react'

export const useGetUniqueRowValues = (rows, index) => {
  let result = useMemo(
    () => {
      if (index === undefined || index === -1) {
        return []
      }

      let items = rows.reduce((acc, row) => {
        let item = row[index]
        if (item) {
          return [...acc, ...item.toLowerCase().split(',')]
        }
        return acc
      }, [])

      return [...new Set([...items])]
    },
    [rows, index]
  )

  return result
}
