import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import Papa from 'papaparse'
import moment from 'moment'

export const Export = forwardRef(({ name, type, fileType }, ref) => {
  const fileName = useMemo(
    () => {
      let today = new Date()
      let date = moment(today).format('YYYY-MM-DD')

      const _name = name ? name.toLowerCase().replace(' ', '_') : ''
      return `${_name}-${type}_${date}.csv`
    },
    [name, type]
  )

  useImperativeHandle(ref, () => ({
    export: data => {
      let textData = Papa.unparse(data)
      let blob = new Blob([textData], { type: fileType })
      let a = document.createElement('a')
      a.download = fileName
      a.href = window.URL.createObjectURL(blob)
      a.click()
    }
  }))

  return <React.Fragment />
})
