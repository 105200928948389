import React, { useMemo } from 'react'
import { useApiListHeatmapSessionQuery } from '../../../../../../api-new/state/heatmap'
import { CourseHeader, Footer, FooterButton, Widget } from '../../../../../../shared/chart/widget'
import { IconHeatmap } from '../../../../../../shared/icon/icon'
import { ChartBar } from '../../../../../../shared/chart/chart'
import { chartColorMain } from '../../../../../../shared/chart/chart-color'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom'
import { useLoad } from '../../../../../../shared/hooks/load'

export const CourseHeatmap = ({ nodeID }) => {
  const { url } = useRouteMatch()

  const apiListHeatmapSessionQuery = useApiListHeatmapSessionQuery()

  useLoad(
    async () => {
      await apiListHeatmapSessionQuery.get(`metadata.nodeid=$eq:${nodeID}/course`)
    },
    [nodeID]
  )

  const chartData = useMemo(
    () => {
      if (!apiListHeatmapSessionQuery.items.length) {
        return { labels: [], data: undefined, value: 0 }
      }

      let value = apiListHeatmapSessionQuery.items.reduce((a, i) => a + i.count, 0)

      const labels = apiListHeatmapSessionQuery.items.map(i => i.date)
      const data = apiListHeatmapSessionQuery.items.map(i => i.count)

      const dataSet = {
        data: data,
        borderColor: chartColorMain,
        backgroundColor: chartColorMain,
        pointBackgroundColor: chartColorMain,
        pointBorderColor: chartColorMain,
        pointRadius: 1,
        borderWidth: 1,
        tension: 1.0,
        fill: true
      }

      return { labels: labels, data: dataSet, value: value }
    },
    [apiListHeatmapSessionQuery.items]
  )

  return (
    <Widget
      header={<CourseHeader Icon={IconHeatmap} text="Heatmaps" value={chartData.value} />}
      body={<ChartBar labels={chartData.labels} data={chartData.data} displayX displayY />}
      footer={
        <React.Fragment>
          <Footer>
            <FooterButton text="Reports" to={`${url}/heatmap/session/${nodeID}`} />
          </Footer>
        </React.Fragment>
      }
    />
  )
}
