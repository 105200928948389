import React from 'react'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { IconLegal } from '../../shared/icon/icon'
import { Cookie } from '../../shared/legacy/legal/cookie'

export const CookiePage = () => {
  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconLegal} text="cookie policy">
        <Cookie />
      </BreadcrumbView>
    </React.Fragment>
  )
}
