import React from 'react'
import { useAnalyticContext } from '../context/provider-analytic'
import { TableEdit } from '../../../../shared/component/table-edit'
import { Form, FormSubmitCancel, FormText, FormTextArea } from '../../../../shared/form/form'
import { useApiListAnalyticParentType } from '../../../../api-new/state/analytics'

export const AnalyticParentType = () => {
  const apiListAnalyticParentType = useApiListAnalyticParentType()

  const analyticContext = useAnalyticContext()

  const handleLoad = async () => {
    await apiListAnalyticParentType.get()
    analyticContext.update('parent-type')
  }

  const confirmDelete = data => {
    if (data.analyticParentCount) return 'This parent type has child analytic parent, are you sure you want to delete?'
    else return null
  }

  return (
    <TableEdit
      title="analytic parent type"
      columns={[{ header: 'name', value: 'name', filter: true }, { header: 'description', value: 'description', filter: true }]}
      sortBy="name"
      FormAdd={FormEdit}
      FormEdit={FormEdit}
      apiList={apiListAnalyticParentType}
      onLoad={handleLoad}
      onAdd
      onEdit
      onDelete
      onRestore
      onPurge
      fnConfirmDelete={confirmDelete}
    />
  )
}

const FormEdit = ({ row, onSubmit, onClose }) => {
  return (
    <Form state={row} onSubmit={onSubmit}>
      <div className="row">
        <FormText className="col-12" label="name" name="name" required small />
        <FormTextArea className="col-12" label="description" name="description" small />
        <FormSubmitCancel small onCancel={onClose} />
      </div>
    </Form>
  )
}
