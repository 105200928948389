import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

export const Popup = ({ selector, title, children }) => {
  const popup = (
    <Popover style={{ backgroundColor: 'transparent', border: 'none' }}>
      {title &&
        <Popover.Header as="h3">
          {title}
        </Popover.Header>}
      <Popover.Body>
        {children}
      </Popover.Body>
    </Popover>
  )

  return (
    <React.Fragment>
      <OverlayTrigger rootClose placement="top" trigger="click" overlay={popup}>
        <div>
          {selector}
        </div>
      </OverlayTrigger>
    </React.Fragment>
  )
}
