import React, { useMemo } from 'react'
import { IconFile, IconGroup, IconMove, IconUser } from '../../../../../../shared/icon/icon'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuItem,
  MenuItemHeader,
  MenuRecycle
} from '../../../../../../shared/menu/menu'
import { useRoleOrgNode } from '../../../../../../role/hook/role-org'
import { useLoginSysAdmin } from '../../../../../../role/hook/login-sys-admin'

export const NodeCategoryMenuFull = (url, parentID, showCategoryAdd) => {
  const roleOrgNode = useRoleOrgNode()
  const loginSysAdmin = useLoginSysAdmin()

  const hasNode = useMemo(() => !!parentID, [parentID])

  return (
    <React.Fragment>
      <Menu>
        <MenuItemHeader text="sys admin" show={loginSysAdmin} />
        <MenuEdit text="sys edit" to={`${url}/edit-sys`} show={loginSysAdmin} pin={false} />
        <MenuItem Icon={IconFile} text="assets" to={`${url}/edit-sys/assets`} show={loginSysAdmin} />
        <MenuAdd Icon={IconFile} text="add node" to={`${url}/add-type`} show={loginSysAdmin} pin={false} />

        <MenuItemHeader text="org" show={loginSysAdmin} />
        {showCategoryAdd && <MenuAdd text="add category" to={`${url}/add`} show={roleOrgNode.accessCreate} pin={false} />}
        {hasNode && <MenuEdit to={`${url}/edit`} show={roleOrgNode.accessUpdate} pin={false} />}
        {hasNode && <MenuItem text="move" Icon={IconMove} to={`${url}/move`} show={roleOrgNode.accessUpdate} />}
        {hasNode && <MenuDelete to={`${url}/delete`} show={roleOrgNode.accessDelete} />}

        {hasNode && <MenuItem text="user permissions" Icon={IconUser} to={`${url}/user-permission`} show />}
        {hasNode && <MenuItem text="group permissions" Icon={IconGroup} to={`${url}/group-permission`} show />}

        <MenuRecycle to={`${url}/recycle`} show={roleOrgNode.accessDelete} />
        <MenuActivityLog to={`${url}/activity`} show />
      </Menu>
    </React.Fragment>
  )
}

export const NodeCategoryMenuPartial = url => {
  const roleOrgNode = useRoleOrgNode()
  const loginSysAdmin = useLoginSysAdmin()

  return (
    <Menu>
      <MenuItemHeader text="sys admin" show={loginSysAdmin} />
      <MenuEdit text="sys edit" to={`${url}/edit-sys`} show={loginSysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${url}/edit-sys/assets`} show={loginSysAdmin} />

      <MenuItemHeader text="org" show={loginSysAdmin} />
      <MenuEdit to={`${url}/edit`} show={roleOrgNode.accessUpdate} pin={false} />
      <MenuItem text="move" Icon={IconMove} to={`${url}/move`} show={roleOrgNode.accessUpdate} />
      <MenuDelete to={`${url}/delete`} show={roleOrgNode.accessDelete} />
    </Menu>
  )
}
