import { useMemo, useState } from 'react'
import { useApiListNodeUser, useApiListUserLogin } from '../../api-new/state/content'
import { ModalDialog } from '../component/modal-dialog'
import { IconMinus, IconPlus } from '../icon/icon'
import { TextFilter } from './text-filter'

export const PermissionNodeUserForNodeModal = ({ target, nodeID, onClose, onClosed }) => {
  const [filter, updateFilter] = useState('')

  const apiListUserLogin = useApiListUserLogin()
  const apiListNodeUser = useApiListNodeUser()

  const filteredItems = useMemo(
    () => apiListUserLogin.items.filter(i => i.email.includes(filter) || i.firstName.includes(filter) || i.lastName.includes(filter)),
    [apiListUserLogin.items, filter]
  )

  const handleOpen = () => {
    updateFilter('')
    apiListUserLogin.get()
    apiListNodeUser.get(`${nodeID}/node`)
  }

  const handleClose = () => onClose && onClose()

  const handleAdd = async userID => {
    await apiListNodeUser.post({ nodeID: nodeID, userID: userID })
    await apiListNodeUser.get(`${nodeID}/node`)
  }

  const handleRemove = async userID => {
    await apiListNodeUser.delete(`${nodeID}/${userID}`)
    await apiListNodeUser.get(`${nodeID}/node`)
  }

  return (
    <ModalDialog target={target} header="add user" fullScreen onOpen={handleOpen} onClose={onClosed}>
      <div className="d-flex flex-column h-100">
        <div>
          <TextFilter value={filter} onChange={updateFilter} />
        </div>
        <div className="flex-fill overflow-auto p-2">
          {filteredItems.map((userLogin, i) =>
            <AddUserLoginItem
              key={i}
              userLogin={userLogin}
              selectedNodeUsers={apiListNodeUser.items}
              onAdd={handleAdd}
              onRemove={handleRemove}
            />
          )}
        </div>
        <div>
          <button onClick={handleClose}>close</button>
        </div>
      </div>
    </ModalDialog>
  )
}

const AddUserLoginItem = ({ userLogin, selectedNodeUsers, onAdd, onRemove }) => {
  const isSelected = useMemo(
    () => {
      return selectedNodeUsers.findIndex(selectedNodeUser => selectedNodeUser.userID === userLogin.id) !== -1
    },
    [selectedNodeUsers, userLogin]
  )

  const handleClick = () => {
    if (isSelected) {
      onRemove && onRemove(userLogin.id)
    } else {
      onAdd && onAdd(userLogin.id)
    }
  }

  return (
    <div className="p-1 border-bottom" style={{ cursor: 'pointer' }} onClick={handleClick}>
      <span className="px-1">
        {isSelected ? <IconMinus /> : <IconPlus />}
      </span>
      <span>
        {userLogin.email} ({userLogin.firstName} {userLogin.lastName})
      </span>
    </div>
  )
}
