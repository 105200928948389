import React, { useMemo } from 'react'
import { Route, useHistory, useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconTemplate } from '../../../../../../shared/icon/icon'
import { NodeTemplateEdit } from './node-template-edit'
import { NodeTemplateDelete } from './node-template-delete'
import { useDataContext } from '../../data-context'
import { LogEventOrgSubjectID } from '../../../../../../shared/page/log-event/log-event-org'
import { LoginSysAdmin } from '../../../../../../role/components/login-sys-admin'
import { NodeTemplateEditSys } from './node-template-edit-sys'
import { NodeTemplateCourse } from './node-template-course'
import { NodeTemplateMove } from './node-template-move'
import { NodeTemplateCopy } from './node-template-copy'
import { PermissionNode } from '../../common/permission/permission-node'

export const NodeTemplateDetails = ({ parentURL }) => {
  const { url } = useRouteMatch()
  const { nodeID } = useParamsInt()

  const history = useHistory()

  const dataContext = useDataContext()

  const itemCurrent = useMemo(() => dataContext.nodes.find(i => i.id === nodeID) || {}, [dataContext.nodes, nodeID])

  const handleMatch = () => history.replace(`${parentURL}/template/${nodeID}/edit`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTemplate} text={itemCurrent.name} onMatch={handleMatch} />

      <PermissionNode url={url} nodeID={nodeID} />

      <Route path={`${url}/edit`}>
        <NodeTemplateEdit parentURL={url} nodeID={nodeID} />
      </Route>

      <LoginSysAdmin>
        <Route path={`${url}/edit-sys`}>
          <NodeTemplateEditSys parentURL={url} nodeID={nodeID} />
        </Route>
      </LoginSysAdmin>

      <Route path={`${url}/move`}>
        <NodeTemplateMove parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/copy`}>
        <NodeTemplateCopy parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/generate`}>
        <NodeTemplateCourse parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/delete`}>
        <NodeTemplateDelete grandParentURL={parentURL} parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/activity`}>
        <LogEventOrgSubjectID subject="node" subjectID={nodeID} />
      </Route>
    </React.Fragment>
  )
}
