import React, { useMemo } from 'react'
import { chartBlueBackground, chartBlueLine, chartBluePoint } from './backgrounds'
import { ChartLine } from '../../../../../../../shared/chart/chart'
import moment from 'moment'

const dateFormat = item => {
  return moment(item).format('MMMM Do')
}

export function GraphSession({ sessionCount }) {
  const labels = useMemo(() => sessionCount.map(item => dateFormat(item.date)), [sessionCount])
  const deviceData = useMemo(() => sessionCount.map(item => item.count), [sessionCount])

  const data = {
    label: 'Daily Sessions',
    data: deviceData,
    backgroundColor: chartBlueBackground,
    borderColor: chartBlueLine,
    pointBackgroundColor: chartBluePoint,
    pointBorderColor: chartBluePoint,
    fill: true,
    tension: 0.3
  }

  return (
    <div className="h-100">
      <ChartLine title="Daily Sessions (last 14 days)" labels={labels} data={data} displayY />
    </div>
  )
}
