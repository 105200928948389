import React, { useState } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormPassword, FormPasswordValidate, FormSubmit } from '../../../../../shared/form/form'
import { Card } from '../../../../../shared/card/card'
import { useApiFormPasswordUpdate } from '../../../../../api-new/state/auth'

export function PasswordUpdate() {
  const apiFormPasswordUpdate = useApiFormPasswordUpdate()

  const [error, updateError] = useState()

  const handleSubmit = async data => {
    try {
      await apiFormPasswordUpdate.post(data)
      apiFormPasswordUpdate.init()
      updateError(null)
    } catch (error) {
      updateError(error)
    }
  }

  return (
    <React.Fragment>
      <BreadcrumbView text="update password">
        <Card title="update password">
          {error &&
            <div className="alert alert-danger" role="alert">
              {error}
            </div>}

          <Form state={apiFormPasswordUpdate.form} submitting={apiFormPasswordUpdate.submitting} onSubmit={handleSubmit}>
            <FormPassword label="current password" name="oldPassword" required />
            <FormPasswordValidate label="new password" name="newPassword" required />
            <FormSubmit text="update password" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
