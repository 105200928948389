import { useApiListState } from '../../../../../../api/api-list/action'
import { useApiFormState } from '../../../../../../api/api-form/action'
import { useApiGetState } from '../../../../../../api/api-get/action'

export const useCourseDetailForm = () => {
  return useApiFormState(process.env.REACT_APP_API_ANALYTICS, 'api/v1/report/course-details')
}

export const useCourseSessionCountList = () => {
  return useApiListState(process.env.REACT_APP_API_ANALYTICS, 'api/v1/report/course-session-count')
}

export const useCourseDeviceList = () => {
  return useApiListState(process.env.REACT_APP_API_ANALYTICS, 'api/v1/report/course-device')
}

export const useCourseUserList = () => {
  return useApiListState(process.env.REACT_APP_API_ANALYTICS, 'api/v1/report/course-users')
}

export const useCourseStepList = () => {
  return useApiListState(process.env.REACT_APP_API_ANALYTICS, 'api/v1/report/course-steps')
}

export const useCourseSessionGet = () => {
  return useApiGetState(process.env.REACT_APP_API_ANALYTICS, 'api/v1/report/course-session')
}
