import React, { useRef } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../../shared/icon/icon'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuItem,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../../shared/table-builder/table-builder'
import { useApiListAppStore } from '../../../../../../api-new/state/config'
import { useRoleSysApp } from '../../../../../../role/hook/role-sys'

export const AppStoreList = () => {
  const { appID } = useParamsInt()

  const apiListAppStore = useApiListAppStore()

  const roleSysApp = useRoleSysApp()

  const handleReady = () => apiListAppStore.get(`${appID}/app`)

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" to={`/admin/app/edit/${appID}/store/add`} show={roleSysApp.accessCreate} />
      <MenuRecycle to={`/admin/app/edit/${appID}/store/recycle`} show={roleSysApp.accessDelete} />

      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuActivityLog to={`/admin/app/edit/${appID}/store/activity-all`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="stores">
        <CardFullHeightScrollY ref={tableRef} title="stores" HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListAppStore} onReady={handleReady}>
            <Column field="name" sortable filter />
            <Column field="key" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/admin/app/edit/${appID}/store/edit/${row.id}`} show={roleSysApp.accessUpdate} />
                  <MenuItem Icon={IconList} text="versions" to={`/admin/app/edit/${appID}/store/edit/${row.id}/version`} show pin />
                  <MenuDelete to={`/admin/app/edit/${appID}/store/edit/${row.id}/delete`} show={roleSysApp.accessDelete} />
                  <MenuActivityLog to={`/admin/app/edit/${appID}/store/activity-id/${row.id}`} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
