import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconAnalytic } from '../../../../../shared/icon/icon'
import { AnalyticProvider } from '../../../../../view/common/analytic/context/provider-analytic'
import { AnalyticParentType } from '../../../../../view/common/analytic/controls/analytic-parent-type'
import { AnalyticParent } from '../../../../../view/common/analytic/controls/analytic-parent'
import { AnalyticType } from '../../../../../view/common/analytic/controls/analytic-type'

export const AnalyticSetup = () => {
  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAnalytic} text="ANALYTIC SETUP">
        <AnalyticProvider>
          <AnalyticParentType />
          <AnalyticParent />
        </AnalyticProvider>
        <AnalyticType />
      </BreadcrumbView>
    </React.Fragment>
  )
}
