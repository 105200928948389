import React from 'react'
import { Form, FormPassword, FormSubmit } from '../../../../../../shared/form/form'
import { Card } from '../../../../../../shared/card/card'

export const LoginPassword = ({ state, onSubmit }) => {
  return (
    <React.Fragment>
      <Card title="password">
        <Form state={state.form} submitting={state.submitting} onSubmit={onSubmit}>
          <FormPassword label="password" name="password" />
          <FormSubmit text="update password" />
        </Form>
      </Card>
    </React.Fragment>
  )
}
