import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../../shared/icon/icon'
import { useApiFormGroup } from '../../../../../api-new/state/content'
import { Form, FormSubmitCancel, FormText } from '../../../../../shared/form/form'
import { Card } from '../../../../../shared/card/card'
import { useRoleOrgGroup } from '../../../../../role/hook/role-org'

export function GroupAdd() {
  const history = useHistory()

  const apiFormGroup = useApiFormGroup()

  const roleOrgGroup = useRoleOrgGroup()

  const handleSubmit = async data => {
    let group = await apiFormGroup.post(data)
    if (roleOrgGroup.accessUpdate) history.push(`/org/group/list/edit/${group.id}`)
    else history.push(`/org/group/list`)
  }

  const handleCancel = () => history.push('/org/group/list')

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add">
        <Card title="add group">
          <Form submitting={apiFormGroup.submitting} onSubmit={handleSubmit}>
            <FormText label="name" name="name" required autoFocus />
            <FormSubmitCancel text="add group" onCancel={handleCancel} />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
