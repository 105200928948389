import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form, FormEmail, FormSubmitCancel } from '../../../../shared/form/form'
import { PositionCenter, TitleMain } from './component'
import { useApiFormAuthPasswordRecover } from '../../../../api-new/state/auth'
import { useLoad } from '../../../../shared/hooks/load'

export const PasswordRequest = () => {
  const history = useHistory()

  const apiFormAuthPasswordRecover = useApiFormAuthPasswordRecover()

  useLoad(() => apiFormAuthPasswordRecover.init(), [])

  const handleSubmit = async data => {
    await apiFormAuthPasswordRecover.get(`${data.email}`)
    history.push(`/auth/password-recover/result/${data.email}`)
  }

  const handleCancel = () => history.push('/auth/email')

  return (
    <PositionCenter>
      <TitleMain value="recover password" />
      <Form onSubmit={handleSubmit}>
        <FormEmail name="email" placeholder="email" required autoFocus />
        <FormSubmitCancel text="recover password" onCancel={handleCancel} />
      </Form>
    </PositionCenter>
  )
}
