import React, { useMemo, useState } from 'react'
import { useApiListNodeCategory } from '../../../api-new/state/content'
import { useLoad } from '../../hooks/load'
import { getMap } from '../../../layout/authorized/org/node/common/shared/node-path'
import { FormSelectObjFilter } from '../form-select-obj-filter'
import { IconBreadcrumbSeperator, IconCategory } from '../../icon/icon'

const DisplayItemList = ({ item }) => {
  return (
    <React.Fragment>
      {item['path'].map((item, i) =>
        <React.Fragment key={i}>
          {i > 0 && <IconBreadcrumbSeperator size="xs" />}
          <IconCategory />
          <span>
            {item}
          </span>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export const FormSelectCategory = ({ lable, name, organizationID, includeRoot, required, small, onChange }) => {
  const apiListNodeCategory = useApiListNodeCategory()

  const [selectedItem, updateSelectedItem] = useState()

  useLoad(
    () => {
      debugger
      if (organizationID) apiListNodeCategory.get(`${organizationID}/organization`)
      else apiListNodeCategory.init()
      updateSelectedItem(null)
    },
    [organizationID]
  )

  const handleSelect = value => updateSelectedItem(value)

  const handleChange = value => {
    onChange && onChange(value)
  }

  const categoryItems = useMemo(
    () => {
      let items = buildCategoryPath(apiListNodeCategory.items)

      if (includeRoot) return [{ id: -1, name: 'root', path: ['root'] }, ...items]
      else return items
    },
    [apiListNodeCategory.items, includeRoot]
  )

  return (
    <FormSelectObjFilter
      label={lable}
      name={name}
      items={categoryItems}
      selectedItem={selectedItem}
      itemName="name"
      itemValue="id"
      required={required}
      small={small}
      valueAsNumber
      onSelect={handleSelect}
      onChange={handleChange}
      DisplayItemSelected={DisplayItemList}
      DisplayItemList={DisplayItemList}
    />
  )
}

const buildCategoryPath = categories => {
  const map = getMap(categories)

  const ancestorPath = nodeID => {
    if (!map[nodeID]) {
      return []
    }

    let category = map[nodeID]

    if (category.parentID) {
      let path = ancestorPath(category.parentID)
      return [...path, category.name]
    } else {
      return [category.name]
    }
  }

  let result = []

  categories.forEach(category => {
    const path = ancestorPath(category.id)

    let item = {
      id: category.id,
      path: path,
      name: path.join(' ')
    }

    result.push(item)
  })

  return result
}
