import React, { useEffect, useMemo, useState } from 'react'
import { useProviderOrganizationForm } from '../../../../../api/state'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormHiddenText, FormHiddenNumber, FormSubmitBack } from '../../../../../shared/form/form'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Card } from '../../../../../shared/card/card'
import { IconDelete, IconEdit, IconPlus } from '../../../../../shared/icon/icon'

const KeyValue = ({ value, onUpdate }) => {
  let items = useMemo(
    () => {
      if (!value) {
        return []
      }

      return JSON.parse(value)
    },
    [value]
  )

  const handleAdd = () => {
    items.push({ key: '', value: '' })
    update(items)
  }

  const handleDelete = index => {
    items.splice(index, 1)
    update(items)
  }

  const handleChangeKey = (index, key) => {
    items[index].key = key
    update(items)
  }

  const handleChangeValue = (index, value) => {
    items[index].value = value
    update(items)
  }

  const update = items => {
    let json = JSON.stringify(items)
    onUpdate && onUpdate(json)
  }

  return (
    <React.Fragment>
      <table className="table table-borderless">
        <thead>
          <tr className="border-bottom">
            <th>Key</th>
            <th>Value</th>
            <th>
              <IconPlus onClick={handleAdd} />
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, i) =>
            <tr key={i}>
              <td>
                <input className="form-control" value={item.key} placeholder="key" onChange={e => handleChangeKey(i, e.target.value)} />
              </td>
              <td>
                <input
                  className="form-control"
                  value={item.value}
                  placeholder="value"
                  onChange={e => handleChangeValue(i, e.target.value)}
                />
              </td>
              <td>
                <IconDelete onClick={() => handleDelete(i)} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  )
}

export const ProviderOrganizationEdit = () => {
  const { providerID, organizationID } = useParamsInt()

  const [providerOrganizationFormActions, providerOrganizationFormState] = useProviderOrganizationForm()

  const [matchData, updateMatchData] = useState(undefined)

  const title = useMemo(() => (providerOrganizationFormState.form ? `edit ${providerOrganizationFormState.form.organizationName}` : ''), [
    providerOrganizationFormState.form
  ])

  useEffect(
    () => {
      updateMatchData(providerOrganizationFormState.form.matchData)
    },
    [updateMatchData, providerOrganizationFormState.form]
  )

  const handleMatch = () => providerOrganizationFormActions.get(`struct/${providerID}/${organizationID}`)

  const handleSubmit = async data => {
    await providerOrganizationFormActions.putArgs({ path: `${providerID}/${organizationID}`, data: data })
  }

  const handleUpdate = data => updateMatchData(data)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={title} onMatch={handleMatch}>
        <Card title={title}>
          <Form state={providerOrganizationFormState.form} onSubmit={handleSubmit}>
            <FormHiddenNumber name="providerID" value={providerID} />
            <FormHiddenNumber name="organizationID" value={organizationID} />
            <FormHiddenText name="matchData" value={matchData} shouldDirty />
            <KeyValue value={matchData} onUpdate={handleUpdate} />
            <FormSubmitBack text="update organization" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
