import React from 'react'
import { CardHeader } from './components/card-header'

export const CardFullHeight = ({ children, title, subtitle, HeaderMenu, HeaderComponent }) => {
  return (
    <React.Fragment>
      <div className="card overflow-hidden h-100">
        <div className="card-body d-flex flex-column h-100">
          <div className="card-title">
            <CardHeader title={title} subtitle={subtitle} HeaderMenu={HeaderMenu} HeaderComponent={HeaderComponent} />
          </div>
          <div className="card-text flex-fill" style={{ minHeight: 0 }}>
            {children}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
