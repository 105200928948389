import { useApiListGroup } from '../../../api-new/state/content'
import { useLoad } from '../../hooks/load'
import { FormSelectObj } from '../form-select-obj'

export const FormSelectGroupID = ({ label, name, required }) => {
  const apiListGroup = useApiListGroup()

  useLoad(() => apiListGroup.get(), [])

  return <FormSelectObj label={label} name={name} itemName="name" itemValue="id" state={apiListGroup} valueAsNumber required={required} />
}
