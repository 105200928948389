import { useMemo } from 'react'
import { useRouteMatch } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { NodeCategoryMenuPartial } from './common/node-category-menu'
import { GridItem } from '../common/shared/grid-item'
import { IconCategory } from '../../../../../shared/icon/icon'

export const NodeCategoryGridItem = ({ node }) => {
  const { url } = useRouteMatch()

  const to = useMemo(() => `${url}/node/${node.id}`, [url, node])

  const FooterMenu = () => NodeCategoryMenuPartial(`${url}/node/${node.id}`)

  return <GridItem nodeID={node.id} Icon={IconCategory} FooterMenu={FooterMenu} title={node.name} to={to} />
}
