import { useContext, useReducer } from 'react'
import { getReducer, getInitialState } from './reducer'
import { getAsync, getBlobAsync } from '../../shared/api.service'
import { getEndpoint, handleException } from '../common/common'
import { AuthContext } from '../../context/auth.context'

export const types = {
  INIT: 'INIT',
  ERROR: 'ERROR',
  GET_SUCCESS: 'GET_SUCCESS'
}

const init = () => dispatch => {
  dispatch({ type: types.INIT })
}

const actionGet = (server, uri, args) => dispatch => authContext => {
  return new Promise(async (resolve, reject) => {
    try {
      if (authContext) {
        await authContext.actions.checkExpRefresh()
      }

      let endpoint = getEndpoint(uri, args)

      dispatch({ type: types.SUBMIT })

      getAsync(server, endpoint)
        .then(({ data }) => {
          dispatch({ type: types.GET_SUCCESS, payload: data })
          resolve(data)
        })
        .catch(error => {
          dispatch({ type: types.ERROR, payload: error || '' })
          reject(error)
        })
    } catch (error) {
      handleException(error)
      reject(error)
    }
  })
}

const actionBlob = (server, uri, args) => dispatch => authContext => {
  return new Promise(async (resolve, reject) => {
    try {
      if (authContext) {
        await authContext.actions.checkExpRefresh()
      }

      let endpoint = getEndpoint(uri, args)

      dispatch({ type: types.SUBMIT })

      getBlobAsync(server, endpoint)
        .then(({ data }) => {
          dispatch({ type: types.GET_SUCCESS, payload: data })
          resolve(data)
        })
        .catch(error => {
          dispatch({ type: types.ERROR, payload: error || '' })
          reject(error)
        })
    } catch (error) {
      handleException(error)
      reject(error)
    }
  })
}

export const useApiGetState = (server, uri) => {
  const [state, dispatch] = useReducer(getReducer, getInitialState)

  const authContext = useContext(AuthContext)

  const actions = {
    init: () => init()(dispatch),

    get: (path, toast) => actionGet(server, uri, { path, toast })(dispatch)(authContext),
    getArgs: args => actionGet(server, uri, args)(dispatch)(authContext),

    blob: (path, toast) => actionBlob(server, uri, { path, toast })(dispatch)(authContext),
    blobArgs: args => actionBlob(server, uri, args)(dispatch)(authContext)
  }

  return [actions, state]
}
