import React from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Menu, MenuAdd, MenuDelete, MenuEdit } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, ColumnYesNo, ColumnLink, ColumnMenu, ColumnFilter } from '../../../../../shared/table-builder/table-builder'
import { useApiListRoleOrgPermission } from '../../../../../api-new/state/content'
import { IconList } from '../../../../../shared/icon/icon'

export const RoleOrgPermissionListIdentifier = () => {
  const { identifier } = useParams()

  const apiListRoleOrgPermission = useApiListRoleOrgPermission()

  const handleReady = () => apiListRoleOrgPermission.get(`${identifier}/identifier`)

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add role permission" to={'/root/role-org-permission/list/add'} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text={identifier}>
        <CardFullHeightScrollY title={`role permission ${identifier}`} HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListRoleOrgPermission} onReady={handleReady}>
            <ColumnFilter field="identifier" header="identifier" />

            <ColumnLink field="roleName" header="role" to={row => `/root/role-org-permission/list/role/${row.roleName}`} sortable filter />

            <ColumnYesNo field="accessCreate" header="create" />
            <ColumnYesNo field="accessRead" header="read" />
            <ColumnYesNo field="accessUpdate" header="update" />
            <ColumnYesNo field="accessDelete" header="delete" />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/root/role-org-permission/list/edit/${row.id}`} show />
                  <MenuDelete to={`/root/role-org-permission/list/edit/${row.id}/delete`} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
