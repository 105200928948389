import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { PageLayout } from './page/layout'
import { AuthProvider } from './context/auth.context'
import { BreadcrumbProvider } from './context/breadcrumb.context'
import { PassaveProvider } from './context/passave.context'
import { RouteExternal } from './view-external/route'
import { RoleProvider } from './role/role.context'
import { PageProvider } from './context/page.context'

import './scss/app.scss'

function App() {
  return (
    <div id="app" className="App">
      <Router>
        <PageProvider>
          <BreadcrumbProvider>
            <AuthProvider>
              <RoleProvider>
                <PassaveProvider>
                  <Switch>
                    <Route path="/external" component={RouteExternal} />
                    <PageLayout />
                  </Switch>
                </PassaveProvider>
              </RoleProvider>
            </AuthProvider>
          </BreadcrumbProvider>
        </PageProvider>
      </Router>
    </div>
  )
}

export default App
