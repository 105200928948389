import React, { useMemo } from 'react'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Route, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'

import { IconCategory, IconContent } from '../../../../../shared/icon/icon'
import { useDataContext } from '../data-context'
import { FilterInput, useFilterList } from '../common/filter/filter-input'

import { isDatatypeNodeLibraryCategory, isDatatypeNodeLibrary } from '../common/shared/metadata'
import { CardFullHeight } from '../../../../../shared/card/card-full-height'
import { NodeLibraryGridItem } from './node-library-grid-item'

import { NodeLibraryAdd } from './pages/node-library-add'
import { NodeLibraryDetails } from './pages/node-library-details'
import { NodeLibraryFilterList } from './node-library-filter-list'
import { NodeCategory } from '../node-category/node-category'
import { pathNodeLibraryMap } from '../common/shared/path-node-library'
import {
  useApiFormNodeLibraryCategory,
  useApiListNodeDecendantLibrary,
  useApiListNodeLibraryCategory
} from '../../../../../api-new/state/content'
import { NodeCategoryGridItem } from '../node-category/node-category-grid-item'
import { NodeCategoryMenuFull } from '../node-category/common/node-category-menu'

export const NodeLibraryGrid = () => {
  return (
    <React.Fragment>
      <div className="container-fluid p-0 h-100">
        <NodeLibraryGridBranch />
      </div>
    </React.Fragment>
  )
}

const NodeLibraryGridBranch = ({ grandParentURL }) => {
  const { url } = useRouteMatch()
  const { parentID } = useParamsInt()

  const dataContext = useDataContext()

  const itemCurrent = useMemo(() => dataContext.library.find(i => i.id === parentID), [dataContext.library, parentID])
  const children = useMemo(() => dataContext.library.filter(i => i.parentID === (parentID || null)), [dataContext.library, parentID])
  const libraryCategory = useMemo(() => children.filter(item => isDatatypeNodeLibraryCategory(item)), [children])
  const library = useMemo(() => children.filter(item => isDatatypeNodeLibrary(item)), [children])

  const title = useMemo(
    () => {
      if (parentID) return itemCurrent ? itemCurrent.name : ''
      else return 'Asset Library'
    },
    [parentID, itemCurrent]
  )

  const Icon = useMemo(
    () => {
      if (parentID) return IconCategory
      else return IconContent
    },
    [parentID]
  )

  const HeaderMenu = () => NodeCategoryMenuFull(url, parentID)

  const [filter, updateFilter, showFilter, selectFilter] = useFilterList()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={Icon} text={title}>
        <CardFullHeight title={title} HeaderMenu={HeaderMenu}>
          <div className="d-flex flex-column h-100">
            <div className="m-2">
              <FilterInput value={filter} onChange={updateFilter} />
            </div>
            <div className="flex-fill" style={{ minHeight: 0 }}>
              <div className="overflow-scroll h-100">
                {showFilter
                  ? <div>
                      <NodeLibraryFilterList nodes={dataContext.library} filter={filter} onSelect={selectFilter} />
                    </div>
                  : <div className="container-fluid">
                      <div className="row g-2">
                        {libraryCategory
                          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                          .map((node, i) => <NodeCategoryGridItem key={i} node={node} />)}
                        {library
                          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                          .map((node, i) => <NodeLibraryGridItem key={i} node={node} />)}
                      </div>
                    </div>}
              </div>
            </div>
          </div>
        </CardFullHeight>
      </BreadcrumbView>

      <Route path={`${url}/node/:parentID`}>
        <NodeLibraryGridBranch grandParentURL={url} />
      </Route>

      <Route path={`${url}/library/:nodeID`}>
        <NodeLibraryDetails parentURL={url} />
      </Route>

      <Route path={`${url}/add-type`}>
        <NodeLibraryAdd parentURL={url} parentID={parentID} />
      </Route>

      <NodeCategory
        url={url}
        grandParentURL={grandParentURL}
        nodeID={parentID}
        useApiListCategory={useApiListNodeLibraryCategory}
        useApiFormCategory={useApiFormNodeLibraryCategory}
        useApiListRecycle={useApiListNodeDecendantLibrary}
        pathNodeFn={pathNodeLibraryMap}
      />
    </React.Fragment>
  )
}
