import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormEmail, FormText, FormSubmit } from '../../../../../shared/form/form'
import { Card } from '../../../../../shared/card/card'
import { useApiFormLoginSettings } from '../../../../../api-new/state/auth'

export const LoginSetting = () => {
  const apiFormLoginSettings = useApiFormLoginSettings()

  const handleMatch = () => apiFormLoginSettings.get()

  const handleSubmit = async data => await apiFormLoginSettings.put(data.id, data)

  return (
    <React.Fragment>
      <BreadcrumbView text="settings" onMatch={handleMatch}>
        <Card title="settings">
          <Form state={apiFormLoginSettings.form} submitting={apiFormLoginSettings.submitting} onSubmit={handleSubmit}>
            <FormEmail label="email" name="email" required readOnly />
            <FormText label="first name" name="firstName" required />
            <FormText label="last name" name="lastName" required />
            <FormSubmit text="update" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
