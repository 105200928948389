import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import moment from 'moment'

export const ExportModel = forwardRef(({ name, onExport }, ref) => {
  const [show, updateShow] = useState(false)

  const [filename, updateFilename] = useState(name)

  const [headerValues, updateHeaderValues] = useState([])

  const disabled = useMemo(() => headerValues.every(i => i.isSelected === false), [headerValues])

  useImperativeHandle(ref, () => {
    return {
      show: items => {
        let today = new Date()
        let date = moment(today).format('YYYY-MM-DD')
        name = name ? name.toLowerCase().replaceAll(' ', '_') : ''
        const filename = `${name}_${date}`
        updateFilename(filename)

        let headerValues = items.map(header => {
          return { name: header, isSelected: true }
        })
        updateHeaderValues(headerValues)
        updateShow(true)
      },
      hide: () => {
        updateShow(false)
        updateHeaderValues([])
      }
    }
  })

  const handleChange = (i, e) => {
    let newHeaderValues = [...headerValues]
    newHeaderValues[i].isSelected = e.target.checked
    updateHeaderValues(newHeaderValues)
  }

  const handleClose = () => updateShow(false)

  const handleExport = () => {
    let headers = headerValues.filter(i => i.isSelected).map(i => i.name)
    onExport && onExport(filename, headers)
  }

  const handleFilename = e => updateFilename(e.target.value)

  const handleCheckAll = e => {
    let newHeaderValues = [...headerValues]
    newHeaderValues.forEach(header => (header.isSelected = e.target.checked))
    updateHeaderValues(newHeaderValues)
  }

  const checkAll = useMemo(() => headerValues.every(i => i.isSelected === true), [headerValues])

  return (
    <React.Fragment>
      <div id="modal-confirm" className="modal" tabIndex="-1" style={{ display: `${show ? 'block' : 'none'}` }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-capitalize">Export</h5>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">Filename</label>
                <input className="form-control" placeholder="filename" value={filename} onChange={handleFilename} />
              </div>

              <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <table className="table table-sm table-borderless">
                  <thead>
                    <tr>
                      <th>
                        <div className="form-check">
                          <input id="checkall" className="form-check-input" type="checkbox" checked={checkAll} onChange={handleCheckAll} />
                          <label className="form-check-label" htmlFor="checkall">
                            Check All
                          </label>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {headerValues &&
                      headerValues.map((value, i) =>
                        <tr key={i}>
                          <td>
                            <div className="form-check">
                              <input
                                id={`check-${i}`}
                                className="form-check-input"
                                type="checkbox"
                                checked={value.isSelected}
                                onChange={e => handleChange(i, e)}
                              />
                              <label className="form-check-label" htmlFor={`check-${i}`}>
                                {value.name}
                              </label>
                            </div>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary text-capitalize" onClick={handleClose}>
                Cancel
              </button>

              <button type="button" className="btn btn-primary text-capitalize" onClick={handleExport} disabled={disabled}>
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
})
