import { AdminNodeProvider } from './admin-node-context'
import { GridRoot } from './grid-root'

export const AdminNode = () => {
  return (
    <AdminNodeProvider>
      <GridRoot />
    </AdminNodeProvider>
  )
}
