import { useEffect, useState } from 'react'
import { useApiListAnalyticParent } from '../../../api-new/state/analytics'
import { useNotificationContext } from '../../../context/notification/context'

export const useAnalyticParentCourse = () => {
  const notificationContext = useNotificationContext()

  const apiListAnalyticParent = useApiListAnalyticParent()

  const [parent, updateParent] = useState({})

  useEffect(() => {
    const parentName = 'course'
    apiListAnalyticParent.get().then(parents => {
      const parent = parents.find(i => i.name === parentName)
      if (parent) updateParent(parent)
      else notificationContext.alert.error(`analytic parent: ${parentName} not found`)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return parent
}
