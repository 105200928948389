import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { AnalyticGridTopic } from './grid-topics'
import { AnalyticGridAnalytic } from './grid-analytic'
import { TableAll } from './table-all'
import { TableTopic } from './table-topic'
import { TableAnalytic } from './table-analytic'
import { CourseAnalyticSetup } from './analytic-setup'
import { TimelineTopic } from './timeline/timeline-topic'
import { TimelineAll } from './timeline/timeline-all'
import { TimelineAnalytic } from './timeline/timeline-analytic'

export const AnalyticRoute = () => {
  const { url } = useRouteMatch()

  return (
    <React.Fragment>
      <Route path={`${url}/analytic/:nodeID/setup`} component={CourseAnalyticSetup} />

      <Route path={`${url}/analytic/:nodeID/topic`} component={AnalyticGridTopic} />

      {/* all course topics */}
      <Route path={`${url}/analytic/:nodeID/topic/table`} component={TableAll} />
      <Route path={`${url}/analytic/:nodeID/topic/table/timeline`} component={TimelineAll} />

      {/* single course topic */}
      <Route path={`${url}/analytic/:nodeID/topic/:topicID/analytic`} component={AnalyticGridAnalytic} />
      <Route path={`${url}/analytic/:nodeID/topic/:topicID/analytic/table`} component={TableTopic} />
      <Route path={`${url}/analytic/:nodeID/topic/:topicID/analytic/table/timeline`} component={TimelineTopic} />

      {/* single course analytic */}
      <Route path={`${url}/analytic/:nodeID/topic/:topicID/analytic/:analyticID/table`} component={TableAnalytic} />
      <Route path={`${url}/analytic/:nodeID/topic/:topicID/analytic/:analyticID/table/timeline`} component={TimelineAnalytic} />
    </React.Fragment>
  )
}
