import React, { forwardRef } from 'react'

export const SelectFile = forwardRef(({ onFileSelect }, ref) => {
  const handleChange = e => {
    if (e.target.files && e.target.files.length) {
      onFileSelect && onFileSelect(e.target.files)
      ref.current.value = ''
    }
  }

  return (
    <React.Fragment>
      <input ref={ref} type="file" onChange={handleChange} hidden />
    </React.Fragment>
  )
})
