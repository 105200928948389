export const ROLE_SYS = {
  SYS_DASHBOARD: 'SYS_DASHBOARD',
  SYS_APP: 'SYS_APP',
  SYS_ORGANIZATION: 'SYS_ORGANIZATION',
  SYS_LOGIN: 'SYS_LOGIN',
  SYS_AUTH_STATUS: 'SYS_AUTH_STATUS',
  SYS_PROVIDER: 'SYS_PROVIDER',
  SYS_SENDGRID_EVENT: 'SYS_SENDGRID_EVENT',
  SYS_ERROR_LOG: 'SYS_ERROR_LOG'
}

export const ROLE_ORG = {
  DASHBOARD: 'DASHBOARD',
  NODE: 'NODE',
  // CONTENT: 'CONTENT',
  // TEMPLATE: 'TEMPLATE',
  USER: 'USER',
  GROUP: 'GROUP',
  GROUP_USER: 'GROUP_USER',
  NODE_USER: 'NODE_USER',
  NODE_GROUP: 'NODE_GROUP',
  HEATMAP: 'HEATMAP',
  ANALYTIC: 'ANALYTIC',
  ASSESSMENT_MANAGE: 'ASSESSMENT_MANAGE',
  ASSESSMENT_REPORT: 'ASSESSMENT_REPORT',
  TRADESHOW: 'TRADESHOW',
  DOC: 'DOC',
  EDITOR: 'EDITOR',
  META_QUERY: 'META_QUERY',
  STORAGE_TRANSFER: 'STORAGE_TRANSFER'
}
