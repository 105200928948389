import React, { useContext, useMemo, useState } from 'react'

const Context = React.createContext()

export const useImportContext = () => useContext(Context)

export const ImportContext = ({ children }) => {
  const [headers, updateHeaders] = useState()
  const [rows, updateRows] = useState()
  const [indexes, updateIndexes] = useState({})

  const [roleValues, updateRoleValues] = useState({})
  const [groupValues, updateGroupValues] = useState({})
  const [contentValues, updateContentValues] = useState({})
  const [templateValues, updateTemplateValues] = useState({})
  const [libraryValues, updateLibraryValues] = useState({})

  const [roleWarnings, updateRoleWarnings] = useState({})
  const [groupWarnings, updateGroupWarnings] = useState({})
  const [contentWarnings, updateContentWarnings] = useState({})
  const [templateWarnings, updateTemplateWarnings] = useState({})
  const [libraryWarnings, updateLibraryWarnings] = useState({})

  let steps = useMemo(
    () => {
      let steps = []

      steps.push({ name: 'columns' })

      if (indexes.role) {
        steps.push({ name: 'roles' })
      }

      if (indexes.group) {
        steps.push({ name: 'groups' })
      }

      if (indexes.content) {
        steps.push({ name: 'contents' })
      }

      if (indexes.template) {
        steps.push({ name: 'templates' })
      }

      // if (indexes.library) {
      //   steps.push({ name: 'libraries' })
      // }

      return steps
    },
    [indexes]
  )

  const [currentStep, updateCurrentStep] = useState(0)

  return (
    <Context.Provider
      value={{
        headers,
        updateHeaders,
        rows,
        updateRows,
        indexes,
        updateIndexes,
        roleValues,
        updateRoleValues,
        groupValues,
        updateGroupValues,
        contentValues,
        updateContentValues,
        templateValues,
        updateTemplateValues,
        libraryValues,
        updateLibraryValues,
        roleWarnings,
        updateRoleWarnings,
        groupWarnings,
        updateGroupWarnings,
        contentWarnings,
        updateContentWarnings,
        templateWarnings,
        updateTemplateWarnings,
        libraryWarnings,
        updateLibraryWarnings,
        steps,
        currentStep,
        updateCurrentStep
      }}>
      {children}
    </Context.Provider>
  )
}
