export const TileBody = ({ children }) =>
  <div className="row">
    {children}
  </div>

export const TileColumn11112 = ({ children }) => {
  return (
    <div className="col-12 col-xl-6">
      {children}
    </div>
  )
}

export const TileColumn11111 = ({ children }) => {
  return (
    <div className="col-12">
      {children}
    </div>
  )
}
