import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormText, FormSubmit, FormHiddenNumber, FormatLabel, FormURL, FormHiddenText } from '../../../../../../shared/form/form'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { AppVersionSelect } from '../shared/app-version-select'
import { useSelectValue } from '../shared/hooks'
import { IconEdit, IconList } from '../../../../../../shared/icon/icon'
import { Menu, MenuActivityLog, MenuDelete, MenuItem } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useRoleSysApp } from '../../../../../../role/hook/role-sys'
import { useApiFormAppStore } from '../../../../../../api-new/state/config'

export const AppStoreEdit = () => {
  const { appID, appStoreID } = useParamsInt()

  const apiFormAppStore = useApiFormAppStore()

  const handlePath = () => apiFormAppStore.get(appStoreID)

  const handleSubmit = data => apiFormAppStore.put(data.id, data)

  const [currentVersionID, updateCurrentVersionID] = useSelectValue(apiFormAppStore.form.currentVersionID)

  const [downloadType, updateDownloadType] = useSelectValue(apiFormAppStore.form.downloadType)

  const roleSysApp = useRoleSysApp()

  const HeaderMenu = () =>
    <Menu>
      <MenuItem
        Icon={IconList}
        text="versions"
        to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version`}
        show={roleSysApp.accessCreate}
        pin
      />
      <MenuDelete to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/delete`} show={roleSysApp.accessDelete} />
      <MenuActivityLog to={`/admin/app/edit/${appID}/store/activity-all`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={apiFormAppStore.form.name} onPath={handlePath}>
        <CardFullHeightScrollY title={apiFormAppStore.form.name} HeaderMenu={HeaderMenu}>
          <Form state={apiFormAppStore.form} onSubmit={handleSubmit}>
            <FormHiddenText name="downloadType" value={downloadType} required shouldDirty />
            <FormHiddenNumber name="currentVersionID" value={currentVersionID} shouldDirty />

            <FormText label="key" name="key" readOnly />

            <FormText label="name" name="name" />

            <FormatLabel label="Version">
              <AppVersionSelect appStoreID={appStoreID} currentVersionID={currentVersionID} onSelect={updateCurrentVersionID} />
            </FormatLabel>

            <FormURL label="download proxy URL" name="appStoreProxyURL" readOnly />

            <FormatLabel label="Download Proxy Behavior">
              <SelectDownloadType value={downloadType} onChange={updateDownloadType} />
            </FormatLabel>

            <FormURL label="store url" name="storeURL" hidden={downloadType !== 'store'} />

            <FormSubmit text="save store" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}

const SelectDownloadType = ({ value, onChange }) => {
  const handleChange = e => {
    onChange && onChange(e.target.value)
  }

  return (
    <select className="form-control" value={value} onChange={handleChange}>
      <option value="">Select</option>
      <option value="edxr">Edxr Download Page</option>
      <option value="direct">Direct Download</option>
      <option value="store">Store URL</option>
    </select>
  )
}
