import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Form, FormHiddenText, FormPasswordValidate, FormSubmitCancel } from '../../../../shared/form/form'
import { PositionCenter, TitleMain } from './component'
import { useApiFormAuthPasswordRecover } from '../../../../api-new/state/auth'
import { useLoad } from '../../../../shared/hooks/load'

export const PasswordReset = () => {
  const history = useHistory()
  const { resetToken } = useParams()

  const apiFormAuthPasswordRecover = useApiFormAuthPasswordRecover()

  useLoad(() => apiFormAuthPasswordRecover.init(), [])

  const handleSubmit = async data => {
    await apiFormAuthPasswordRecover.put(`${resetToken}`, data)
    history.push('/auth/email')
  }

  const handleCancel = () => history.push('/auth/email')

  return (
    <PositionCenter>
      <TitleMain value="update password" />
      <Form onSubmit={handleSubmit}>
        <FormHiddenText name="resetToken" value={resetToken} />
        <FormPasswordValidate name="password" required />
        <FormSubmitCancel text="update password" onCancel={handleCancel} />
      </Form>
    </PositionCenter>
  )
}
