import React from 'react'
import { Route, useRouteMatch } from 'react-router'

import { SessionNodeList } from './session-node-list'
import { SeriesList } from './series-list'
import { Display } from './display/display'

export function HeatmapLayoutNode() {
  const { url } = useRouteMatch()

  return (
    <React.Fragment>
      <Route path={`${url}/heatmap/session/:nodeID`} component={SessionNodeList} />
      <Route path={`${url}/heatmap/session/:nodeID/series/:sessionID`} component={SeriesList} />
      <Route path={`${url}/heatmap/session/:nodeID/series/:sessionID/display/:seriesID`} component={Display} />
    </React.Fragment>
  )
}
