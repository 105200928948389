import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { IconCopyright } from '../../shared/icon/icon'

const termsPath = '/public/legal/terms'
const privacyPath = '/public/legal/privacy'
const cookiesPath = '/public/legal/cookie'
const contactPath = '/public/contact-us/request'
const downloadsPath = '/download/links'
const trustPath = 'https://trust.educationxr.com'
const statusPath =
  'https://status.educationxr.com/?_gl=1*jzytkc*_ga*Mzk0MDYyMTM1LjE3MTYyNDQ4MzM.*_ga_TMSPP1YX7Q*MTcxNjI0NDgzMy4xLjEuMTcxNjI0NDg1NC4wLjAuMA..'

const FooterLinkFlatTo = ({ text, to }) => {
  const history = useHistory()

  const handleClick = () => to && history.push(to)

  return (
    <span className="edxr-pointer" onClick={handleClick}>
      {text}
    </span>
  )
}
const FooterLinkFlatUrl = ({ text, url }) => {
  const handleClick = () => url && window.open(url, '_blank', 'noreferrer')

  return (
    <span className="edxr-pointer" onClick={handleClick}>
      {text}
    </span>
  )
}

const FooterLinkListTo = ({ text, to }) => {
  const history = useHistory()

  const handleClick = () => to && history.push(to)

  return (
    <li className="dropdown-item edxr-pointer" onClick={handleClick}>
      {text}
    </li>
  )
}

const FooterLinkListUrl = ({ text, url }) => {
  const handleClick = () => url && window.open(url, '_blank', 'noreferrer')

  return (
    <li className="dropdown-item edxr-pointer" onClick={handleClick}>
      {text}
    </li>
  )
}

const FooterSeperator = () => {
  return <span className="mx-2">|</span>
}

const LinkDropdown = ({ className }) => {
  return (
    <div className={className}>
      <div className="btn-group">
        <div className="dropdown-toggle" data-bs-toggle="dropdown">
          Links
        </div>
        <ul className="dropdown-menu">
          <FooterLinkListTo text="Terms" to={termsPath} />
          <FooterLinkListTo text="Privacy" to={privacyPath} />
          <FooterLinkListTo text="Cookies" to={cookiesPath} />
          <FooterLinkListTo text="Contact" to={contactPath} />
          <FooterLinkListTo text="Downloads" to={downloadsPath} />
          <FooterLinkListUrl text="Trust" url={trustPath} />
          <FooterLinkListUrl text="Status" url={statusPath} />
        </ul>
      </div>
    </div>
  )
}

const LinksBig = ({ className }) => {
  return (
    <div className={className}>
      <div className="text-nowrap">
        <FooterLinkFlatTo text="Terms" to={termsPath} />
        <FooterSeperator />
        <FooterLinkFlatTo text="Privacy" to={privacyPath} />
        <FooterSeperator />
        <FooterLinkFlatTo text="Cookies" to={cookiesPath} />
        <FooterSeperator />
        <FooterLinkFlatTo text="Contact" to={contactPath} />
        <FooterSeperator />
        <FooterLinkFlatTo text="Downloads" to={downloadsPath} />
        <FooterSeperator />
        <FooterLinkFlatUrl text="Trust" url={trustPath} />
        <FooterSeperator />
        <FooterLinkFlatUrl text="Status" url={statusPath} />
      </div>
    </div>
  )
}

const Copyright = () => {
  let copyright = useMemo(() => new Date().getFullYear(), [])
  return (
    <div>
      <IconCopyright />
      Heizenrader LLC {copyright}
    </div>
  )
}

export const Footer = () => {
  return (
    <div className="d-flex border-top p-1">
      <div className="flex-fill mx-2">
        <LinkDropdown className="d-md-none" />
        <LinksBig className="d-none d-md-block" />
      </div>
      <div className="text-end">
        <Copyright />
      </div>
      <div style={{ marginLeft: 60 }} />
    </div>
  )
}
