import { useMemo, useReducer } from 'react'
import { getBlobAsync } from '../shared/api.service'

export const types = {
  INIT: 'INIT',
  ERROR: 'ERROR',
  SUBMIT: 'SUBMIT',
  GET_SUCCESS: 'GET_SUCCESS',
  POST_SUCCESS: 'POST_SUCCESS',
  PUT_SUCCESS: 'PUT_SUCCESS',
  DELETE_SUCCESS: 'DELETE_SUCCESS'
}

export const initialState = {
  blob: undefined,
  loading: false,
  submitting: false,
  error: undefined
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.INIT:
      return { ...initialState }
    case types.ERROR:
      return { ...state, loading: false, submitting: false, error: action.payload }
    case types.SUBMIT:
      return { ...state, loading: false, submitting: true, error: undefined }
    case types.GET_SUCCESS:
      return { ...state, form: action.payload, loading: false, submitting: false, error: undefined }
    default:
      return state
  }
}

export const useApiBlob = (server, endpoint) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const actions = useMemo(() => {
    return {
      get: arg => getAction(server, endpoint, dispatch)(arg)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(
    () => {
      return { ...state, ...actions }
    },
    [state, actions]
  )
}

const getAction = (server, endpoint, dispatch) => async arg => {
  try {
    if (arg) {
      endpoint = `${endpoint}/${arg}`
    }

    dispatch({ type: types.SUBMIT })
    var result = await getBlobAsync(server, endpoint)
    dispatch({ type: types.GET_SUCCESS, payload: result.data })
    return result.data
  } catch (exception) {
    dispatch({ type: types.ERROR, payload: exception.error || '' })
  }
}
