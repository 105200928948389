import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormOnChange, useFormRequired, useFormShouldDirty } from './hooks/input-hooks'

export const FormTextArea = ({
  className,
  label,
  placeholder,
  subtext,
  id,
  name,
  required,
  readOnly,
  defaultValue,
  shouldDirty,
  autoFocus,
  hidden,
  small,
  onChange,
  onEnter
}) => {
  const { register, watch } = useFormContext()

  let formDisabled = useFormDisable()

  let watchValue = watch(name)

  useFormRequired(name, required)
  useFormShouldDirty(name, defaultValue, shouldDirty)
  useFormOnChange(name, onChange)

  const handleKeyUp = e => e.code === 'Enter' && onEnter && onEnter()

  return (
    <React.Fragment>
      <FormatLabel
        className={className}
        name={name}
        label={label}
        subtext={subtext}
        hasValue={!!watchValue}
        required={required}
        hidden={hidden}>
        <textarea
          id={id}
          className={`form-control ${small && 'form-control-sm'}`}
          placeholder={placeholder || label}
          autoFocus={autoFocus}
          type="text"
          {...register(name, { required })}
          disabled={formDisabled}
          readOnly={readOnly}
          onKeyUp={handleKeyUp}
        />
      </FormatLabel>
    </React.Fragment>
  )
}
