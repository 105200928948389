import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormRequired } from './hooks/input-hooks'
import { useLoad } from '../hooks/load'

export const FormSelectObj = ({
  className,
  label,
  subtext,
  name,
  state,
  defaultValue,
  itemName,
  itemValue,
  nullText,
  nullValue,
  required,
  disabled,
  readOnly,
  autoFocus,
  valueAsNumber,
  small,
  onChange
}) => {
  const { watch, setValue } = useFormContext()

  let formDisabled = useFormDisable()

  let watchValue = watch(name)

  useLoad(() => onChange && onChange(watchValue), [watchValue])

  useLoad(() => setTimeout(() => defaultValue && setValue(name, defaultValue), 1), [name, defaultValue])

  const items = useMemo(
    () => {
      return (state && state.items) || []
    },
    [state]
  )

  useFormRequired(name, required, disabled)

  const handleChange = e => {
    let value = e.target.value

    if (valueAsNumber) {
      value = Number.parseInt(value)

      if (isNaN(value)) {
        value = nullValue || null
      }
    }

    setValue(name, value, { shouldDirty: true })
  }

  return (
    <FormatLabel className={className} name={name} label={label} subtext={subtext} hasValue={!!watchValue} required={required}>
      <select
        className={`form-control ${small && 'form-control-sm'}`}
        value={watchValue}
        onChange={handleChange}
        required
        readOnly={readOnly}
        disabled={disabled || formDisabled}
        autoFocus={autoFocus}>
        <option value="">
          {nullText || 'Select'}
        </option>
        {items.map((item, i) =>
          <option key={i} value={item[itemValue]}>
            {item[itemName]}
          </option>
        )}
      </select>
    </FormatLabel>
  )
}
