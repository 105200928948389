import { useHistory, useRouteMatch } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { useLoad } from '../../../../../../shared/hooks/load'
import { useApiListAnalytic } from '../../../../../../api-new/state/analytics'
import { AnalyticCard } from './analytic-card'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useApiFormAnalyticTopic } from '../../../../../../api-new/state/analytics'
import { IconGrid } from '../../../../../../shared/icon/icon'

export const AnalyticGridAnalytic = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { topicID } = useParamsInt()

  const apiFormAnalyticTopic = useApiFormAnalyticTopic()
  const apiListAnalytic = useApiListAnalytic()

  useLoad(
    () => {
      apiFormAnalyticTopic.get(topicID)
      apiListAnalytic.get(`${topicID}/topic`)
    },
    [topicID]
  )

  const handleClickAll = () => history.push(`${url}/table`)
  const handleClick = id => history.push(`${url}/${id}/table`)

  return (
    <BreadcrumbView text={apiFormAnalyticTopic.form.name} Icon={IconGrid}>
      <CardFullHeightScrollY title={apiFormAnalyticTopic.form.name}>
        <div className="container p-2">
          <div className="row g-2">
            <div className="col-4">
              <AnalyticCard
                name="all"
                description={`Course analytics for topic ${apiFormAnalyticTopic.form.name}`}
                onClick={handleClickAll}
              />
            </div>
            {apiListAnalytic.items.map((analytic, i) =>
              <div key={i} className="col-4">
                <AnalyticCard id={analytic.id} name={analytic.name} description={analytic.description} onClick={handleClick} />
              </div>
            )}
          </div>
        </div>
      </CardFullHeightScrollY>
    </BreadcrumbView>
  )
}
