import React, { useCallback } from 'react'
import { SearchList } from './search-list'
import moment from 'moment'
import { IconUser } from '../../icon/icon'

const DisplayItemSelected = ({ selectText, item }) => {
  return (
    <React.Fragment>
      {item &&
        <span>
          <IconUser />
          {moment(item.startOn).format('MMMM DD YYYY - hh:mm:ss a')}
        </span>}
      {!item &&
        <span className="text-capitalize">
          {selectText || 'select'}
        </span>}
    </React.Fragment>
  )
}

const DisplayItemList = ({ item }) => {
  return (
    <React.Fragment>
      {item &&
        <span>
          <IconUser />
          {moment(item.startOn).format('MMMM DD YYYY - hh:mm:ss a')}
        </span>}
    </React.Fragment>
  )
}

const handleFilter = (items, value) => {
  if (!value) {
    return items
  }

  value = value.toLowerCase().trim().split(' ')

  return items.filter(item => value.some(i => item.toLowerCase().includes(i)))
}

export const SearchListSession = ({ dropLeft, selectText, actionText, nullText, items, selectedItem, disabled, onAdd, onSelect }) => {
  const handleSelectedItem = useCallback(
    item => {
      if (item && selectedItem) {
        return item.sessionID === selectedItem.sessionID
      }

      return false
    },
    [selectedItem]
  )

  return (
    <React.Fragment>
      <SearchList
        dropLeft={dropLeft}
        items={items}
        selectedItem={selectedItem}
        DisplayItemSelected={DisplayItemSelected}
        DisplayItemList={DisplayItemList}
        selectText={selectText}
        actionText={actionText}
        nullText={nullText}
        disabled={disabled}
        fnFilter={handleFilter}
        fnSelectedItem={handleSelectedItem}
        onAdd={onAdd}
        onSelect={onSelect}
      />
    </React.Fragment>
  )
}
