import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

export const useFormRequired = (name, required, disabled) => {
  const { watch, setError, clearErrors } = useFormContext()

  let watchValue = watch(name)

  useEffect(
    () => {
      if (required) {
        if (watchValue) clearErrors(name)
        else setError(name, { message: 'required' })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchValue, required, disabled]
  )
}

export const useFormShouldDirty = (name, defaultValue, shouldDirty) => {
  const { setValue } = useFormContext()

  useEffect(
    () => {
      if (defaultValue) {
        setTimeout(() => setValue(name, defaultValue, { shouldDirty }), 1)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, defaultValue, shouldDirty]
  )
}

export const useFormOnChange = (name, onChange) => {
  const { watch } = useFormContext()

  let watchValue = watch(name)

  useEffect(
    () => {
      onChange && onChange(watchValue)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchValue]
  )
}

export const useFormOnItemsChange = (name, items, onChange) => {
  const { watch } = useFormContext()

  let watchValue = watch(name)

  useEffect(
    () => {
      onChange && onChange(watchValue)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchValue, items]
  )
}

export const useFormSetValue = (name, value, shouldDirty) => {
  const { setValue } = useFormContext()

  useEffect(
    () => {
      setValue(name, value, { shouldDirty })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )
}

export const useFormDefaultValue = (name, defaultValue, shouldDirty) => {
  const { setValue } = useFormContext()

  useEffect(
    () => {
      setValue(name, defaultValue, { shouldDirty })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValue]
  )
}
