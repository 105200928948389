import React from 'react'

const Button = ({ className, style, text, icon, Icon, type, disabled, onClick, attrs }) => {
  return (
    <button
      className={`btn ${className} ${disabled && 'disabled'} text-capitalize mx-1`}
      style={style}
      type={type}
      disabled={disabled}
      onClick={onClick}
      {...attrs}>
      {icon && icon}
      {Icon && <Icon />}
      <span>
        {text}
      </span>
    </button>
  )
}

export const ButtonPrimary = ({ style, text, icon, Icon, type, disabled, onClick, attrs }) => {
  return (
    <Button
      className="btn-primary"
      style={style}
      type={type}
      text={text}
      icon={icon}
      Icon={Icon}
      disabled={disabled}
      onClick={onClick}
      attrs={attrs}
    />
  )
}

export const ButtonSecondary = ({ style, text, icon, type, disabled, onClick, attrs }) => {
  return (
    <Button
      className="btn-secondary"
      style={style}
      type={type}
      text={text}
      icon={icon}
      disabled={disabled}
      onClick={onClick}
      attrs={attrs}
    />
  )
}

// export const ButtonPrimarySmall = ({ text, icon, type, disabled, onClick, attrs }) => {
//   return <Button className="btn-primary btn-sm" type={type} text={text} icon={icon} disabled={disabled} onClick={onClick} attrs={attrs} />
// }

// export const ButtonSubmit = ({ style, text, icon, disabled, onClick }) => {
//   return (
//     <Button
//       className={`btn btn-primary ${disabled && 'disabled'}`}
//       style={style}
//       type="submit"
//       text={text}
//       icon={icon}
//       onClick={!disabled ? onClick : undefined}
//     />
//   )
// }

// export const ButtonPrimarySpinner = ({ style, text, disabled, submitting, onClick }) => {
//   const icon = submitting ? <span className="spinner-border spinner-border-sm mr-2" role="status" /> : undefined
//   return (
//     <Button
//       className={`btn btn-primary ${(disabled || submitting) && 'disabled'}`}
//       style={style}
//       type="submit"
//       text={text}
//       icon={icon}
//       onClick={!disabled && !submitting ? onClick : undefined}
//     />
//   )
// }

// export const ButtonPrimaryProgress = ({ style, Icon, text, progress, disabled, submitting, onClick }) => {
//   const progressText = useMemo(() => (!!progress ? `${text} ${progress}%` : text), [text, progress])

//   return (
//     <Button
//       className={`btn btn-primary ${(disabled || submitting) && 'disabled'}`}
//       style={style}
//       type="submit"
//       text={progressText}
//       icon={Icon}
//       onClick={!disabled && !submitting ? onClick : undefined}
//     />
//   )
// }
