import React, { useMemo } from 'react'
import { useAuthContext } from '../../context/auth.context'

export const LoginSysRoot = ({ children }) => {
  const authContext = useAuthContext()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const show = useMemo(() => authContext.state.isSysRoot, [authContext.state])

  return (
    <React.Fragment>
      {show && children}
    </React.Fragment>
  )
}
