import React from 'react'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { Card } from '../../../../../../shared/card/card'

import { IconEdit } from '../../../../../../shared/icon/icon'
import { Menu, MenuPurge } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { RoleSelectUser } from '../../../../../../role/role-select/role-select-user'
import { useApiFormLoginUser } from '../../../../../../api-new/state/content'

export const OrganizationUserEdit = () => {
  const { url } = useRouteMatch()

  const { userID } = useParamsInt()

  const apiFormLoginUser = useApiFormLoginUser()

  const handlePath = () => apiFormLoginUser.get(`${userID}`)

  const handleSubmit = data => apiFormLoginUser.put(`${data.id}`, data)

  const HeaderMenu = () =>
    <Menu>
      <MenuPurge to={`${url}/purge/${userID}`} show pin />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={apiFormLoginUser.form.organizationName} onPath={handlePath}>
        <CardFullHeightScrollY title={apiFormLoginUser.form.organizationName} HeaderMenu={HeaderMenu}>
          <RoleSelectUser userID={userID} />
          <Card title="pin">
            <Form state={apiFormLoginUser.form} onSubmit={handleSubmit}>
              <FormText label="pin" name="pin" />
              <FormSubmit />
            </Form>
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
