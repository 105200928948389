import React, { useEffect } from 'react'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { useContactUsForm } from './state'
import { useHistory } from 'react-router-dom'
import { Form, FormText, FormEmail, FormSelectListObj, FormSubmit, FormTextArea } from '../../shared/form/form'
import { IconContactUs } from '../../shared/icon/icon'
import { ScrollY } from '../../shared/component/scroll'
import { CardFullHeightScrollY } from '../../shared/card/card-full-height-scroll-y'
const topics = [
  { value: 'content', text: 'Request New Content' },
  { value: 'feature', text: 'Request New Feature' },
  { value: 'feedback', text: 'Give Feedback' },
  { value: 'bug', text: 'Report a Bug' },
  { value: 'other', text: 'Other' }
]

export const ContactUsRequest = () => {
  const history = useHistory()

  const [contactUsFormActions, contactUsFormState] = useContactUsForm()

  useEffect(() => {
    contactUsFormActions.init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async data => {
    await contactUsFormActions.postArgs({ data: data, toast: 'form submitted' })
    history.push('/public/contact-us/complete')
  }

  return (
    <ScrollY>
      <BreadcrumbView Icon={IconContactUs} text="contact us">
        <CardFullHeightScrollY title="contact us">
          <Form submitting={contactUsFormState.submitting} onSubmit={handleSubmit}>
            <FormSelectListObj label="topic" name="topic" state={topics} itemValue="value" itemName="text" />
            <FormText label="first name" name="firstName" required />
            <FormText label="last name" name="lastName" required />
            <FormEmail label="email" name="email" required />
            <FormText label="phone number" name="phoneNumber" required />
            <FormTextArea label="description" name="description" required />
            <FormSubmit />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </ScrollY>
  )
}
