import React, { useMemo } from 'react'
import { Card } from '../card/card'
import { Form, FormTextConfirm, FormSubmitBack } from '../form/form'

export const Confirm = ({ title, state, confirmValue, onSubmit }) => {
  const form = useMemo(() => (state ? state.form : {}), [state])

  return (
    <React.Fragment>
      <Card title={title}>
        <Form state={form} onSubmit={onSubmit}>
          <FormTextConfirm
            placeholder={confirmValue}
            subtext={`Type ${confirmValue} to confirm`}
            confirmValue={confirmValue}
            name="test"
            required
            autoFocus
          />
          <FormSubmitBack text="confirm" />
        </Form>
      </Card>
    </React.Fragment>
  )
}
