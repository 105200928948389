import React, { useMemo } from 'react'
import { Progress } from '../../../../../../shared/legacy/progress'

export const NodeCopyProgress = ({ progress }) => {
  const totalLength = useMemo(() => (progress ? progress.totalLength : 0), [progress])
  const totalPosition = useMemo(() => (progress ? progress.totalPosition : 0), [progress])
  const currentLength = useMemo(() => (progress ? progress.currentLength : 0), [progress])
  const currentPosition = useMemo(() => (progress ? progress.currentPosition : 0), [progress])
  const message = useMemo(() => (progress ? progress.message : ''), [progress])

  return (
    <React.Fragment>
      {progress &&
        <div>
          <div className="p-2">
            {message}
          </div>
          <div className="p-2">
            <label className="p-1">Total Progress</label>
            <Progress length={totalLength} current={totalPosition} />
          </div>
          <div className="p-2">
            <label className="p-1">File Progress</label>
            <Progress length={currentLength} current={currentPosition} />
          </div>
        </div>}
    </React.Fragment>
  )
}
