import React, { useContext, useMemo } from 'react'
import { AuthContext } from './auth.context'

export function DisplayOrgCompany({ children }) {
  const authContext = useContext(AuthContext)

  let show = useMemo(() => authContext.state.jwtClaims.organizationType === 'company', [authContext.state.jwtClaims])

  return (
    <React.Fragment>
      {show && children}
    </React.Fragment>
  )
}
