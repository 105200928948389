import React, { useState } from 'react'
import { Terms } from './terms'

const Expand = ({ show, children }) => {
  return (
    <React.Fragment>
      <div className={`collapse ${show ? 'show' : ''}`}>
        {children}
      </div>
    </React.Fragment>
  )
}

const Checkbox = ({ label, state, readOnly, onCheck }) => {
  const handleChange = e => onCheck(e.target.checked)

  return (
    <div className="form-check">
      <input className="form-check-input" id="termsCheckbox" type="checkbox" checked={state} disabled={readOnly} onChange={handleChange} />
      <label className="form-check-label text-capitalize" htmlFor="termsCheckbox">
        {label}
      </label>
    </div>
  )
}

export const TermsAccept = ({ state, readOnly, onCheck }) => {
  const [show, updateShow] = useState(false)
  const handleShow = () => updateShow(!show)

  return (
    <React.Fragment>
      <Expand show={show}>
        <Terms />
      </Expand>

      <div className="d-flex">
        <Checkbox label="accept terms and conditions" state={state} readOnly={readOnly} onCheck={onCheck} />
        <div className="px-2 text-info" style={{ cursor: 'pointer' }} onClick={handleShow}>
          {!show && <span>Show</span>}
          {show && <span>Hide</span>}
        </div>
      </div>
    </React.Fragment>
  )
}
