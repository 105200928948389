import { useApiForm } from '../api-form'
import { useApiList } from '../api-list'
import { useApiListChunk } from '../api-list-chunk'
import { urlArgs } from '../state'

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//DDD
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const useApiListLogEventOrg = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/log-event-org${urlArgs(args)}`)
export const useApiFormLogEventOrg = args => useApiForm(process.env.REACT_APP_API_ANALYTICS, `api/ddd/log-event-org${urlArgs(args)}`)
export const useApiListLogEventSys = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/log-event-sys${urlArgs(args)}`)

export const useApiListChunkReportCustomOrg = args =>
  useApiListChunk(process.env.REACT_APP_API_ANALYTICS, `api/ddd/report-custom-org${urlArgs(args)}`)
export const useApiListReportCustomOrg = args =>
  useApiList(process.env.REACT_APP_API_ANALYTICS, `api/ddd/report-custom-org${urlArgs(args)}`)

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const useApiListAnalyticParentType = args =>
  useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/analytic-parent-type${urlArgs(args)}`)

export const useApiListAnalyticParent = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/analytic-parent${urlArgs(args)}`)

export const useApiListAnalyticTopic = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/analytic-topic${urlArgs(args)}`)

export const useApiFormAnalyticTopic = args => useApiForm(process.env.REACT_APP_API_ANALYTICS, `api/v1/analytic-topic${urlArgs(args)}`)

export const useApiListAnalyticType = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/analytic-type${urlArgs(args)}`)

export const useApiListAnalytic = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/analytic${urlArgs(args)}`)

export const useApiFormAnalytic = args => useApiForm(process.env.REACT_APP_API_ANALYTICS, `api/v1/analytic${urlArgs(args)}`)

export const useApiListAnalyticPeriod = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/analytic-period${urlArgs(args)}`)

export const useApiListAnalyticEvent = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/analytic-event${urlArgs(args)}`)

export const useApiListReportGeneralUser = args =>
  useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/report-general-user${urlArgs(args)}`)

export const useApiListReportGeneralCourse = args =>
  useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/report-general-course${urlArgs(args)}`)

// export const useApiListReportCustomUser = args =>
//   useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/report-custom-user${urlArgs(args)}`)

// export const useApiListReportCustomCourse = args =>
//   useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/report-custom-course${urlArgs(args)}`)

export const useApiListGraphCustomCourse = args =>
  useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/graph-custom-course${urlArgs(args)}`)

export const useApiListGraphGeneralOrg = args => useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/graph-general-org${urlArgs(args)}`)

export const useApiListGraphGeneralCourse = args =>
  useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/graph-general-course${urlArgs(args)}`)

export const useApiListChunkReportGeneralOrg = args =>
  useApiListChunk(process.env.REACT_APP_API_ANALYTICS, `api/v1/report-general-org${urlArgs(args)}`)

export const useApiListReportGeneralOrg = args =>
  useApiList(process.env.REACT_APP_API_ANALYTICS, `api/v1/report-general-org${urlArgs(args)}`)
