import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'

import { useApiFormNodeTemplate, useApiListAsset } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { useDataContext } from '../../data-context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { NodeTemplateMenuFull } from '../common/node-template-menu'
import { IconEdit } from '../../../../../../shared/icon/icon'
import { ThumnailAsset } from '../../common/thumbnail/thumbnail-asset'
// import { NodeTags } from '../../common/shared/node-tags'

export const NodeTemplateEdit = ({ parentURL, nodeID }) => {
  const apiFormTemplate = useApiFormNodeTemplate()
  const apiListAsset = useApiListAsset()

  const dataContext = useDataContext()

  useLoad(
    async () => {
      apiFormTemplate.get(nodeID)
      apiListAsset.get(`${nodeID}/node`)
    },
    [nodeID]
  )

  const title = useMemo(() => apiFormTemplate.form.name, [apiFormTemplate.form])

  const handleSubmit = async data => {
    let node = await apiFormTemplate.put(data.id, data)
    dataContext.nodeUpdate(node)
  }

  const HeaderMenu = () => NodeTemplateMenuFull(parentURL, apiFormTemplate.form)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="edit">
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <Card>
            <Form state={apiFormTemplate.form} onSubmit={handleSubmit}>
              <FormText label="name" name="name" required />
              <FormSubmit text="update" />
            </Form>
          </Card>

          {/* <NodeTags apiForm={apiFormTemplate} /> */}

          <ThumnailAsset nodeID={nodeID} />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
