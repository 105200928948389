import { useApiForm } from '../api-form'
import { useApiList } from '../api-list'
import { urlArgs } from '../state'

export const useApiListResultAssessmentCourse = args =>
  useApiList(process.env.REACT_APP_API_ASSESSMENT, `api/v1/result-assessment-course${urlArgs(args)}`)

export const useApiListResultAssessmentUser = args =>
  useApiList(process.env.REACT_APP_API_ASSESSMENT, `api/v1/result-assessment-user${urlArgs(args)}`)

export const useApiFormResultAssessmentUser = args =>
  useApiForm(process.env.REACT_APP_API_ASSESSMENT, `api/v1/result-assessment-user${urlArgs(args)}`)

export const useApiListResultAssessment = args =>
  useApiList(process.env.REACT_APP_API_ASSESSMENT, `api/v1/result-assessment${urlArgs(args)}`)

export const useApiFormResultAssessment = args =>
  useApiForm(process.env.REACT_APP_API_ASSESSMENT, `api/v1/result-assessment${urlArgs(args)}`)

export const useApiFormAssessment = args => useApiForm(process.env.REACT_APP_API_ASSESSMENT, `api/v1/assessment${urlArgs(args)}`)

export const useApiListManageAssessmentAssignment = args =>
  useApiList(process.env.REACT_APP_API_ASSESSMENT, `api/v1/manage-assessment-assignment${urlArgs(args)}`)

export const useApiListManageAssessment = args =>
  useApiList(process.env.REACT_APP_API_ASSESSMENT, `api/v1/manage-assessment${urlArgs(args)}`)

export const useApiFormManageAssessment = args =>
  useApiForm(process.env.REACT_APP_API_ASSESSMENT, `api/v1/manage-assessment${urlArgs(args)}`)

export const useApiListUserAssessment = args => useApiList(process.env.REACT_APP_API_ASSESSMENT, `api/v1/user-assessment${urlArgs(args)}`)

export const useApiFormUserAssessment = args => useApiForm(process.env.REACT_APP_API_ASSESSMENT, `api/v1/user-assessment${urlArgs(args)}`)
