import React, { useContext } from 'react'
import { Input } from './input'
import { indexChar, QuestionListContext } from './question-list'
import { IconClickCheck, IconClickDelete } from '../../../../../../shared/icon/icon-click'

export const Choice = ({ index, questionIndex, choice, readOnly }) => {
  let questionListContext = useContext(QuestionListContext)

  const handleChange = value => {
    choice.text = value
    choice.modified = true
    questionListContext.handleUpdateChoice(questionIndex, index, choice)
  }

  const handleDelete = () => questionListContext.handleDeleteChoice(questionIndex, index)
  const handleCheck = () => questionListContext.handleCorrectChoice(questionIndex, index)
  const handleDrop = dragID => questionListContext.handleDropChoice(questionIndex, dragID, index)

  const Actions = () =>
    <React.Fragment>
      {questionListContext.showCorrectChoice && <IconClickCheck onClick={handleCheck} />}
      <IconClickDelete onClick={handleDelete} />
    </React.Fragment>

  return (
    <React.Fragment>
      <Input
        ddGroup={`choice-${questionIndex}`}
        index={index}
        pos={indexChar(questionListContext.choiceIndex++)}
        correctChoice={questionListContext.showCorrectChoice ? choice.correct : undefined}
        value={choice.text}
        draggable
        limit={150}
        readOnly={questionListContext.disabled || readOnly}
        onChange={handleChange}
        onDrop={handleDrop}
        Actions={Actions}
      />
    </React.Fragment>
  )
}
