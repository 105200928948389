import React, { useMemo, useRef } from 'react'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { IconTable } from '../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useLoad } from '../../../../../shared/hooks/load'
import { Column, ColumnDateTime, ColumnFilter, ColumnNum, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListReportGeneralCourse } from '../../../../../api-new/state/analytics'
import { Menu, MenuExportCsv, MenuExportExcel } from '../../../../../shared/menu/menu'
import { useApiFormNode } from '../../../../../api-new/state/content'

export const AnalyticCourseGeneral = () => {
  const { nodeID } = useParamsInt()

  const apiFormNode = useApiFormNode()
  const apiListReportGeneralCourse = useApiListReportGeneralCourse()

  useLoad(() => apiFormNode.get(nodeID), [])

  const handleReady = () => apiListReportGeneralCourse.get(`${nodeID}/node`)

  const title = useMemo(() => `Analytics General - ${apiFormNode.form.name}`, [apiFormNode.form])

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTable} text="analytics general">
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListReportGeneralCourse} onReady={handleReady}>
            <Column field="email" sortable />
            <Column field="firstName" sortable />
            <Column field="lastName" sortable />

            <ColumnDateTime field="startOn" sortable />
            <ColumnDateTime field="endOn" sortable />

            <Column field="stepName" header="Step" sortable />
            <ColumnNum field="objectives" sortable />
            <ColumnNum field="objectiveCompleted" sortable />
            <ColumnNum field="attachments" sortable />
            <ColumnNum field="attachmentCompleted" sortable />

            <ColumnFilter field="os" sortable />
            <ColumnFilter field="platform" sortable />
            <Column field="appVersion" sortable />
            <ColumnFilter field="deviceModel" sortable />
            <ColumnFilter field="deviceType" sortable />
            <ColumnFilter field="vrDeviceModel" sortable />
            <ColumnFilter field="vrDeviceName" sortable />
            <Column field="vrDevicePresent" sortable />
            <Column field="vrDeviceTrackingOriginMode" sortable />
            <Column field="vrDeviceTrackingSpaceType" sortable />
            <Column field="vrDeviceRefreshRate" sortable />
            <ColumnFilter field="graphicsCard" sortable />
            <Column field="graphicsCardMemorySize" sortable />
            <ColumnFilter field="graphicsCardVendor" sortable />
            <ColumnFilter field="processor" sortable />
            <Column field="processorCount" sortable />
            <Column field="processorFrequency" sortable />
            <Column field="systemMemorySize" sortable />
            <Column field="isMultiPlayer" sortable />
            <Column field="locationCountry" sortable />
            <Column field="locationRegion" sortable />
            <Column field="locationCity" sortable />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
