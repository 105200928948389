import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormHiddenNumber, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { useApiFormTemplate } from '../../../../../../api-new/state/content'
import { useDataContext } from '../../data-context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { NodeTemplateMenuFull } from '../common/node-template-menu'
import { IconEdit } from '../../../../../../shared/icon/icon'
import { pathNodeTemplateMap } from '../../common/shared/path-node-template'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'

export const NodeTemplateAdd = ({ parentURL, parentID }) => {
  const history = useHistory()

  const apiFormTemplate = useApiFormTemplate()

  const dataContext = useDataContext()

  const handleSubmit = async data => {
    let node = await apiFormTemplate.post(data)

    const moveToPath = ({ map }) => {
      dataContext.unsubscribe(moveToPath)
      let path = pathNodeTemplateMap(map, node.id)
      history.push(path)
    }

    dataContext.subscribe(moveToPath)
    dataContext.nodeInsert(node)
  }

  const HeaderMenu = () => NodeTemplateMenuFull(parentURL, apiFormTemplate.form)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="add">
        <CardFullHeightScrollY title="add template" HeaderMenu={HeaderMenu}>
          <Card>
            <Form state={apiFormTemplate.form} onSubmit={handleSubmit}>
              <FormHiddenNumber name="parentID" value={parentID} />
              <FormText label="name" name="name" required />
              <FormSubmit text="create" />
            </Form>
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
