import React, { useMemo } from 'react'
import { useApiFormAssetThumbnail, useApiListAssetThumbnail } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { ImageThumbnailEdit } from './image-thumbnail/image-thumbnail-edit'
import { useDataContext } from '../../data-context'

export const ThumnailAsset = ({ nodeID }) => {
  const apiListAssetThumbnail = useApiListAssetThumbnail()
  const apiFormAssetThumbnail = useApiFormAssetThumbnail()

  const dataContext = useDataContext()

  useLoad(async () => {
    let thumbnails = await apiListAssetThumbnail.get(`${nodeID}/node`)
    if (thumbnails.length === 0) {
      let thumbnail = await apiListAssetThumbnail.post({ nodeID: nodeID, name: 'thumbnail' })
      dataContext.thumbnailInsert(thumbnail)
    }
  }, nodeID)

  const asset = useMemo(
    () => {
      if (apiListAssetThumbnail.items.length === 0) return null
      else return apiListAssetThumbnail.items[0]
    },
    [apiListAssetThumbnail.items]
  )

  const handleUpdate = async blob => {
    asset.length = blob.size
    await apiFormAssetThumbnail.put(`${asset.id}/custom`)
    await apiFormAssetThumbnail.put(asset.id, asset)
  }

  return (
    <React.Fragment>
      {asset &&
        <React.Fragment>
          <ImageThumbnailEdit asset={asset} onUpdate={handleUpdate} />
        </React.Fragment>}
    </React.Fragment>
  )
}
