import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../../../../context/auth.context'
import { ButtonPrimaryConfirm } from '../../../../../shared/component/button'
import { Card } from '../../../../../shared/card/card'
import { useApiFormLoginPurge } from '../../../../../api-new/state/content'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconDelete } from '../../../../../shared/icon/icon'

export const DeleteAccount = () => {
  const history = useHistory()

  const authContext = useContext(AuthContext)

  const apiFormLoginPurge = useApiFormLoginPurge()

  const handleConfirm = async () => {
    let loginID = authContext.state.jwtClaims.loginID
    await apiFormLoginPurge.delete(loginID)
    authContext.actions.logout()
    history.push('/auth/email')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text="delete account">
        <Card title="delete account">
          <div className="p-2">Warning, this action will delete your account and remove all data. This action cannot be redone.</div>
          <ButtonPrimaryConfirm
            text="Delete Account"
            confirmText="Are you sure you want to delete your account?"
            onConfirm={handleConfirm}
          />
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
