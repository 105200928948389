import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { Menu, MenuPurge, MenuRestore } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { useApiListUserLogin } from '../../../../../api-new/state/content'
import { useRoleOrgUser } from '../../../../../role/hook/role-org'

export function UserRecycle() {
  const apiListUserLogin = useApiListUserLogin()

  const roleOrgUser = useRoleOrgUser()

  const handleReady = () => apiListUserLogin.get('deleted')

  const handleRestore = async data => {
    await apiListUserLogin.put(`${data.id}/restore`, data)
    await apiListUserLogin.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListUserLogin} onReady={handleReady}>
            <Column field="email" sortable filter />
            <Column field="firstName" sortable filter />
            <Column field="lastName" sortable filter />
            <Column field="role" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show={roleOrgUser.accessDelete} />
                  <MenuPurge to={`/org/user/list/recycle/purge/${row.id}`} show={roleOrgUser.accessDelete} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
