import React, { useMemo } from 'react'
import locale from 'react-json-editor-ajrm/locale/en'
import { useFormContext } from 'react-hook-form'
import { useFormRequired } from '../hooks/input-hooks'
import { FormatLabel } from '../format-label'
import JSONInput from 'react-json-editor-ajrm'

export const FormJsonEditorStr = ({ label, name, required }) => {
  const { watch, setValue, setError, clearErrors } = useFormContext()

  const watchValue = watch(name)

  let jsonValue = useMemo(
    () => {
      try {
        return JSON.parse(watchValue)
      } catch {
        return {}
      }
    },
    [watchValue]
  )

  useFormRequired(name, required)

  const handleChange = ({ jsObject, error }) => {
    
    if (error) {
      setError(name, { error: 'Invalid JSON Format' })
      return 
    }

    clearErrors(name)

    try {
      let value = JSON.stringify(jsObject)
      setValue(name, value, { shouldDirty: true })
    } catch {}
  }

  return (
    <React.Fragment>
      <FormatLabel label={label} name={name} hasValue={!!watchValue} required={required}>
        <div className="border rounded p-1">
          <JSONInput
            placeholder={jsonValue}
            locale={locale}
            waitAfterKeyPress={3000}
            width="100%"
            height="100%"
            theme="light_mitsuketa_tribute"
            onBlur={handleChange}
          />
        </div>
      </FormatLabel>
    </React.Fragment>
  )
}
