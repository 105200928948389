import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormText, FormCheckbox, FormSubmit } from '../../../../../shared/form/form'
import { useProviderForm } from '../../../../../api/state'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { IconEdit } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'

export const ProviderEdit = () => {
  const { providerID } = useParamsInt()

  const [providerFormActions, providerFormState] = useProviderForm()

  const title = useMemo(() => `edit ${providerFormState.form.providerName}`, [providerFormState.form])

  const handlePath = async () => await providerFormActions.get(providerID)
  const handleSubmit = async data => await providerFormActions.put(data)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={title} onPath={handlePath}>
        <CardFullHeightScrollY title={title}>
          <Form state={providerFormState.form} onSubmit={handleSubmit}>
            <FormText label="identifier" name="identifier" readOnly />
            <FormText label="provider name" name="providerName" required autoFocus />
            <FormText label="display name" name="displayName" required />
            <FormText label="client id" name="clientID" required />
            <FormText label="client secret" name="clientSecret" required />
            <FormText label="scopes (comma seperated)" name="scopes" required />
            <FormText label="issuer (/.well-known/openid-configuration)" name="issure" required />
            <FormText label="signout url" name="signoutURL" emptyNull />
            <FormText label="token url (facebook)" name="tokenURL" emptyNull />
            <FormText label="replace uri (comma seperated)" name="replaceURI" emptyNull />
            <FormText label="append uri (comma seperated)" name="appendURI" emptyNull />
            <FormText label="icon svg" name="iconSVG" emptyNull />
            <FormText label="url" name="url" emptyNull />
            <FormCheckbox label="is active" name="active" />
            <FormSubmit text="save provider" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
