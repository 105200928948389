import React, { useRef } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { BadgePrimary, BadgeSuccess, BadgeWarning } from '../../../../../shared/component/badge'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuItem,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnDate, ColumnFormat, ColumnMenu, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import moment from 'moment'
import { IconAnalytic, IconAssessment, IconContent, IconGroup, IconUsers } from '../../../../../shared/icon/icon'
import { useApiListUserLogin } from '../../../../../api-new/state/content'
import { useRoleOrgGroupUser, useRoleOrgNodeUser, useRoleOrgUser } from '../../../../../role/hook/role-org'

const BadgeRegistered = ({ userLogin }) => {
  return (
    (userLogin.hasRegistration && (userLogin.registered ? <BadgeSuccess value="registered" /> : <BadgePrimary value="invited" />)) ||
    <span />
  )
}

export const UserLoginList = () => {
  const apiListUserLogin = useApiListUserLogin()

  const roleOrgUser = useRoleOrgUser()
  const roleOrgNodeUser = useRoleOrgNodeUser()
  const roleOrgGroupUser = useRoleOrgGroupUser()

  const handleReady = async () => await apiListUserLogin.get()

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add users" to="/org/user/list/add" show={roleOrgUser.accessCreate} />
      <MenuRecycle to="/org/user/list/recycle" show={roleOrgUser.accessDelete} />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuActivityLog to="/org/user/list/activity-all" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconUsers} text="USERS">
        <CardFullHeightScrollY title="Users" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListUserLogin} onReady={handleReady}>
            <Column field="email" sortable />
            <Column field="firstName" sortable />
            <Column field="lastName" sortable />

            <ColumnDate field="createdAt" sortable filter />
            <ColumnDate field="updatedAt" sortable filter hide />
            <ColumnDate field="invitedOn" sortable filter hide />
            <ColumnDate field="registeredOn" sortable filter hide />
            <ColumnDate field="lastAuth" sortable filter hide />

            <ColumnFormat field="registered" header="Status" selector={row => <BadgeRegistered userLogin={row} />} sortable />
            <ColumnFormat
              field="lastAuth"
              selector={row => (row.lastAuth ? moment(row.lastAuth).fromNow(true) : <BadgeWarning value="never" />)}
              sortable
            />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/org/user/list/edit/${row.id}`} show={roleOrgUser.accessUpdate} />
                  <MenuItem
                    Icon={IconContent}
                    text="contents"
                    to={`/org/user/list/edit/${row.id}/content`}
                    show={roleOrgNodeUser.accessRead}
                  />
                  <MenuItem Icon={IconGroup} text="groups" to={`/org/user/list/edit/${row.id}/group`} show={roleOrgGroupUser.accessRead} />
                  <MenuItem
                    text="analytics custom"
                    Icon={IconAnalytic}
                    to={`/org/user/list/edit/${row.id}/analytic-custom`}
                    show={roleOrgGroupUser.accessRead}
                  />
                  <MenuItem
                    text="analytics general"
                    Icon={IconAnalytic}
                    to={`/org/user/list/edit/${row.id}/analytic-general`}
                    show={roleOrgGroupUser.accessRead}
                  />
                  <MenuItem
                    text="assessments"
                    Icon={IconAssessment}
                    to={`/org/user/list/edit/${row.id}/assessment-reports`}
                    show={roleOrgGroupUser.accessRead}
                  />
                  <MenuDelete to={`/org/user/list/edit/${row.id}/delete`} show={roleOrgUser.accessDelete} />
                  <MenuActivityLog to={`/org/user/list/activity-id/${row.id}`} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
