import React from 'react'
import { ButtonBar, Button } from './button/button'
import { IconCopy } from '../../../../../../../../shared/icon/icon'

export const Result = ({ value }) => {
  const handleClick = () => navigator.clipboard.writeText(value)

  return (
    <div>
      <ButtonBar>
        <Button Icon={IconCopy} onClick={handleClick} />
      </ButtonBar>
      <div className="border-top border-bottom p-3">
        {value}
      </div>
    </div>
  )
}
