import { useReducer } from 'react'
import { getAccessToken } from '../storage/auth'

const initialState = { submitting: false, complete: false, error: false }

export const useApiWebsocket = (server, endpoint) => {
  const [state, dispatch] = useReducer((_, payload) => {
    return payload
  }, initialState)

  const connect = (onOpen, onMessage, onClose, onError) => {
    let ws = null

    const init = async initMessage => {
      return new Promise((resolve, reject) => {
        let accessToken = getAccessToken()

        let path = `${server}/${endpoint}?auth=${accessToken}`

        ws = new WebSocket(path)

        ws.onopen = () => {
          dispatch({ submitting: true, complete: false, error: false })
          onOpen && onOpen()
          resolve()
        }

        ws.onmessage = msg => {
          let event = JSON.parse(msg.data)
          onMessage && onMessage(event, initMessage)
        }

        ws.onclose = () => {
          dispatch({ submitting: false, complete: true, error: false })
          onClose && onClose()
        }

        ws.onerror = error => {
          dispatch({ submitting: false, complete: false, error: true })
          onError && onError(error)
          reject(error)
        }
      })
    }

    const send = data => {
      return new Promise((resolve, reject) => {
        if (ws.readyState === ws.OPEN) {
          let json = JSON.stringify(data)
          ws.send(json)
          resolve()
        } else {
          reject('ws not open')
        }
      })
    }

    const close = () => {
      if (ws) {
        ws.close()
      }
    }

    return [init, send, close]
  }

  const cleanup = () => {
    dispatch(initialState)
  }

  return [connect, state, cleanup]
}
