import React from 'react'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { AppLinks } from '../../shared/legacy/app-links'
import { CardFullHeightScrollY } from '../../shared/card/card-full-height-scroll-y'

export const InviteSuccess = () => {
  return (
    <React.Fragment>
      <BreadcrumbView text="register success">
        <CardFullHeightScrollY title="Register Success">
          <AppLinks />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
