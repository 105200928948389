import React, { useEffect } from 'react'
import { useApiListRoleSysLogin } from '../../api-new/state/content'
import { Card } from '../../shared/card/card'
import { SelectTag } from '../../shared/select-tag/select-tag'

export const RoleSelectLogin = ({ loginID }) => {
  const apiListRoleSysLogin = useApiListRoleSysLogin()

  useEffect(
    () => {
      apiListRoleSysLogin.get(`${loginID}/login`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginID]
  )

  const handleAdd = async item => {
    await apiListRoleSysLogin.post({ roleSysID: item.roleSysID, loginID: loginID })
    await apiListRoleSysLogin.get(`${loginID}/login`)
  }

  const handleRemove = async item => {
    await apiListRoleSysLogin.delete(`${item.roleSysID}/${loginID}`)
    await apiListRoleSysLogin.get(`${loginID}/login`)
  }

  return (
    <React.Fragment>
      <Card title="Login Roles">
        <SelectTag
          selectText="select role"
          data={apiListRoleSysLogin.items}
          fnLabel={item => item.roleName}
          fnActive={item => item.isActive}
          onAdd={handleAdd}
          onRemove={handleRemove}
        />
      </Card>
    </React.Fragment>
  )
}
