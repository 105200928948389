import { useCallback, useEffect, useMemo } from 'react'
import { Transforms, createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withHistory } from 'slate-history'
import { Element } from './element'
import { Leaf } from './leaf'
import { ButtonBar, ButtonSpacer } from './button/button'
import { ButtonTag } from './button/button-tag'
import { ButtonTagValue } from './button/button-tag-value'
import { ButtonBlockAlign } from './button/button-block'
import { ButtonBlockList } from './button/button-block'
import {
  IconBold,
  IconAlignCenter,
  IconAlignLeft,
  IconAlignRight,
  IconIndent,
  IconItalic,
  IconListOL,
  IconListUL,
  IconOutdent,
  IconStrikeThrough,
  IconUnderline
} from '../../../../../../../../shared/icon/icon'

export const Editor = ({ initialValue, onChange }) => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  const initial = [
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ]

  const handleChange = value => onChange && onChange(value)

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  useEffect(
    () => {
      if (initialValue) {
        Transforms.delete(editor, { at: [0] })
        Transforms.insertNodes(editor, initialValue)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValue]
  )

  return (
    <Slate editor={editor} value={initial} onChange={handleChange}>
      <ButtonBar>
        <ButtonTag Icon={IconUnderline} format="underline" attribute={true} />
        <ButtonTag Icon={IconStrikeThrough} format="strikethrough" attribute={true} />
        <ButtonTag Icon={IconBold} format="bold" attribute={true} />
        <ButtonTag Icon={IconItalic} format="italic" attribute={true} />
        <ButtonSpacer />
        <ButtonTagValue Icon={IconOutdent} format="indent" value={-1} />
        <ButtonTagValue Icon={IconIndent} format="indent" value={1} />
        <ButtonSpacer />
        <ButtonBlockAlign Icon={IconAlignLeft} format="left" />
        <ButtonBlockAlign Icon={IconAlignCenter} format="center" />
        <ButtonBlockAlign Icon={IconAlignRight} format="right" />
        <ButtonSpacer />
        <ButtonBlockList Icon={IconListUL} format="list-bulleted" />
        <ButtonBlockList Icon={IconListOL} format="list-numbered" />
      </ButtonBar>
      <div className="border-top border-bottom">
        <Editable renderElement={renderElement} renderLeaf={renderLeaf} placeholder="Enter Text" />
      </div>
    </Slate>
  )
}
