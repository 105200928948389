import React from 'react'
import { Route } from 'react-router-dom'
import { DownloadApp } from '../../view/download-new/download-app'
import { DownloadError } from '../../view/download-new/download-error'
import { DownloadLinks } from '../../view/download-new/download-links'

export const RouteDownload = () => {
  return (
    <React.Fragment>
      <Route path="/download/app/:appStoreKey" component={DownloadApp} />
      <Route path="/download/links" component={DownloadLinks} />
      <Route path="/download/error" component={DownloadError} />
    </React.Fragment>
  )
}
