import React from 'react'
import { FormSelectListObj } from '../../../../../shared/form/form'

const types = { items: [{ value: 'quiz', text: 'quiz' }, { value: 'survey', text: 'survey' }] }

export const AssessmentType = ({ label, name, readOnly, onChange }) => {
  return (
    <React.Fragment>
      <FormSelectListObj
        label={label}
        name={name}
        state={types}
        itemName="text"
        itemValue="value"
        readOnly={readOnly}
        onChange={onChange}
      />
    </React.Fragment>
  )
}
