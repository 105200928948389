import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { useFormRequired, useFormShouldDirty } from './hooks/input-hooks'

export const FormCheckbox = ({ label, name, required, disabled, readOnly, hidden, defaultValue, shouldDirty, displayHide }) => {
  const { watch, setValue } = useFormContext()

  let formDisabled = useFormDisable()

  useFormRequired(name, required)
  useFormShouldDirty(name, defaultValue, shouldDirty)

  let value = watch(name)

  const [state, updateState] = useState(false)

  useEffect(
    () => {
      if (value !== undefined) {
        updateState(value)
      }
    },
    [value]
  )

  const handleState = e => setValue(name, e.target.checked, { shouldDirty: true })

  return (
    <React.Fragment>
      <div className="form-check py-2" style={{ display: displayHide ? 'none' : 'block' }}>
        <input
          className="form-check-input"
          type="checkbox"
          id={`checkbox-${name}`}
          name={name}
          disabled={disabled || formDisabled}
          readOnly={readOnly}
          checked={state}
          onChange={handleState}
        />
        {!hidden &&
          <label className="form-check-label" htmlFor={`checkbox-${name}`}>
            {label}
          </label>}
      </div>
    </React.Fragment>
  )
}
