import { types } from "./action";

export const getInitialState = {
  item: {},
  submitting: false,
  message: undefined,
  error: undefined,
};

export const getReducer = (state = getInitialState, action) => {
  switch (action.type) {
    case types.SUBMIT:
      return { ...state, submitting: true, error: undefined };
    case types.ERROR:
      return { ...state, submitting: false, error: action.payload };
    case types.GET_SUCCESS:
      return { ...state, item: action.payload, submitting: false };
    default:
      return state;
  }
};