import React, { useEffect, useMemo, useState } from 'react'
import { IconLinkEdit } from '../../../../../../shared/icon/icon-link'
import { useRouteMatch } from 'react-router'
import { useGroupUserList } from '../../../../../../api/state'
import { Card } from '../../../../../../shared/card/card'

export default function Component({ userID }) {
  const { url } = useRouteMatch()

  const [groupUserListActions, groupUserListState] = useGroupUserList()

  const [groups, updateGroups] = useState([])

  const groupCount = useMemo(() => groupUserListState.items.length || 'none', [groupUserListState.items])

  useEffect(
    () => {
      groupUserListActions.get(`${userID}/user`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userID]
  )

  useEffect(
    () => {
      let length = groupUserListState.items.length
      updateGroups(groupUserListState.items.map((e, i) => `${e.name}${i === length - 1 ? '' : ', '}`))
    },
    [groupUserListState.items]
  )

  const HeaderComponent = () => <IconLinkEdit to={`${url}/group`} />

  return (
    <React.Fragment>
      <Card title={`Groups - ${groupCount}`} HeaderMenu={HeaderComponent}>
        {groups}
      </Card>
    </React.Fragment>
  )
}
