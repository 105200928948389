import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { useApiFormNodeCourse } from '../../../../../../api-new/state/content'
import { IconEdit } from '../../../../../../shared/icon/icon'
import { useDataContext } from '../../data-context'
import { Route, useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { NodeAssetList } from '../../common/assets/node-asset-list'
import { NodeContentMenuFull } from '../common/node-content-menu'
import { FormJsonEditorStr } from '../../../../../../shared/form/form-control/form-json-editor-str'

export const NodeCourseEditSys = ({ parentURL, nodeID }) => {
  const { url } = useRouteMatch()

  const apiFormNodeCourse = useApiFormNodeCourse()

  const dataContext = useDataContext()

  const handleMatch = () => apiFormNodeCourse.get(nodeID)

  const handleSubmit = async data => {
    let node = await apiFormNodeCourse.put(data.id, data)
    dataContext.nodeUpdate(node)
  }

  const HeaderMenu = () => NodeContentMenuFull(parentURL, apiFormNodeCourse.form)

  return (
    <React.Fragment>
      <BreadcrumbView text="sys admin edit" Icon={IconEdit} onMatch={handleMatch}>
        <CardFullHeightScrollY title="sys edit" HeaderMenu={HeaderMenu}>
          <Card>
            <Form state={apiFormNodeCourse.form} onSubmit={handleSubmit}>
              <FormText label="name" name="name" />
              <FormJsonEditorStr label="metadata" name="metadata" />
              <FormSubmit text="update" />
            </Form>
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/assets`}>
        <NodeAssetList parentURL={url} nodeID={nodeID} />
      </Route>
    </React.Fragment>
  )
}
