import React from 'react'
import { Card } from '../../shared/card/card'
import { useQueryParam } from '../../shared/hooks/location'

export const DownloadError = () => {
  const message = useQueryParam('message')

  return (
    <React.Fragment>
      <Card title="download error">
        {message}
      </Card>
    </React.Fragment>
  )
}
