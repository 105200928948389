import React, { useCallback, useEffect, useMemo } from 'react'
import { IconClose } from '../icon/icon'
import { Modal } from 'bootstrap'
import { useRandomID } from '../hooks/random-id'

export const useModalActionID = () => {
  const id = useRandomID()

  const modalID = useMemo(() => `modal-target-${id}`, [id])

  const show = useCallback(
    () => {
      if (modalID) {
        try {
          Modal.getOrCreateInstance(`#${modalID}`).show()
        } catch (e) {
          console.error(`could not find target ${modalID} when opening modal`, e)
        }
      }
    },
    [modalID]
  )

  const hide = useCallback(
    () => {
      if (modalID) {
        try {
          Modal.getOrCreateInstance(`#${modalID}`).hide()
        } catch (e) {
          console.error(`could not find target ${modalID} when opening modal`, e)
        }
      }
    },
    [modalID]
  )

  return [modalID, show, hide]
}

export const useModalActions = target => {
  return {
    show: () => {
      if (target) {
        try {
          Modal.getOrCreateInstance(`#${target}`).show()
        } catch (e) {
          console.error(`could not find target ${target} when opening modal`, e)
        }
      }
    },
    hide: () => {
      if (target) {
        try {
          Modal.getInstance(`#${target}`).hide()
        } catch (e) {
          console.error(`could not find target ${target} when opening modal`, e)
        }
      }
    }
  }
}

export const ModalLinkShow = ({ className, target, Icon, text }) => {
  const modalActions = useModalActions(target)
  const handleClick = () => modalActions.show()

  return (
    <span className={className} onClick={handleClick}>
      {Icon && <Icon />}
      {text}
    </span>
  )
}

export const ModalDialog = ({ children, target, Icon, header, large, fullScreen, backdropStatic, onOpen, onClose, onShow, onHide }) => {
  const modalActions = useModalActions(target)

  useEffect(
    () => {
      if (target) {
        let ele = document.getElementById(target)

        ele.addEventListener('show.bs.modal', () => {
          onOpen && onOpen()
        })

        ele.addEventListener('hide.bs.modal', () => {
          onClose && onClose()
        })

        ele.addEventListener('shown.bs.modal', () => {
          onShow && onShow()
        })

        ele.addEventListener('hidden.bs.modal', () => {
          onHide && onHide()
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [target]
  )

  let backdrop = useMemo(() => (backdropStatic ? 'static' : 'dynamic'), [backdropStatic])

  const handleClose = () => modalActions.hide()

  return (
    <div id={target} className="modal fade" tabIndex="-1" data-bs-backdrop={backdrop}>
      <div className={`modal-dialog ${large && 'modal-lg'} ${fullScreen && 'modal-fullscreen'}`}>
        <div className="modal-content">
          <div className="modal-header bg-primary text-light">
            <div className="modal-title fs-5 fw-bold w-100">
              <div className="d-flex">
                <div className="flex-fill">
                  {Icon &&
                    <span className="me-2">
                      <Icon />
                    </span>}
                  {header}
                </div>
                <div onClick={handleClose}>
                  <IconClose />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body overflow-hidden">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
