import React, { useState } from 'react'
import { FormCheckbox } from '../form'
import { Terms } from '../../legacy/legal/terms'

export const FormTermsAccept = ({ name }) => {
  const [show, updateShow] = useState(false)
  const handleShow = () => updateShow(!show)

  return (
    <React.Fragment>
      <div className="py-2">
        <Expand show={show}>
          <Terms />
        </Expand>

        <div className="d-flex">
          <FormCheckbox label="Accept terms and conditions" name="terms" required />
          <div className="m-2 text-primary text-decoration-underline" style={{ cursor: 'pointer' }} onClick={handleShow}>
            {!show && <span>Show</span>}
            {show && <span>Hide</span>}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Expand = ({ show, children }) => {
  return (
    <React.Fragment>
      <div className={`collapse ${show ? 'show' : ''}`}>
        {children}
      </div>
    </React.Fragment>
  )
}
