import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormRequired } from './hooks/input-hooks'

export const FormSelectListNum = ({ label, subtext, name, state, nullText, nullValue, required, readOnly }) => {
  const { register, watch } = useFormContext()

  let formDisabled = useFormDisable()

  let watchValue = watch(name)

  useFormRequired(name, required)

  const items = useMemo(() => state || [], [state])

  const setValueAs = value => (value ? parseInt(value) : nullValue === undefined ? null : nullValue)

  return (
    <FormatLabel name={name} label={label} subtext={subtext} hasValue={!!watchValue} required={required}>
      <select
        className="form-control"
        {...register(name, { required, setValueAs: setValueAs })}
        value={watchValue || ''}
        readOnly={readOnly}
        disabled={formDisabled}>
        <option value="">
          {nullText || 'Select'}
        </option>
        {items.sort((a, b) => a < b).map((item, i) =>
          <option key={i} value={item}>
            {item}
          </option>
        )}
      </select>
    </FormatLabel>
  )
}
