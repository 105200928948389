import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconCategory, IconTemplate } from '../../../../../shared/icon/icon'
import { Route, useRouteMatch } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useParamsInt } from '../../../../../shared/hooks/location'
import {
  useApiFormNodeTemplateCategory,
  useApiListNodeDecendantTemplate,
  useApiListNodeTemplateCategory
} from '../../../../../api-new/state/content'
import { NodeTemplateGridItem } from './node-template-grid-item'
import { NodeTemplateDetails } from './pages/node-template-details'
import { NodeTemplateFilterList } from './node-template-filter-list'
import { FilterInput, useFilterList } from '../common/filter/filter-input'
import { useDataContext } from '../data-context'
import { isDatatypeCourseTemplate, isDatatypeNodeTemplateCategory } from '../common/shared/metadata'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { NodeCategoryMenuFull } from '../node-category/common/node-category-menu'
import { NodeCategoryGridItem } from '../node-category/node-category-grid-item'
import { NodeTemplateAdd } from './pages/node-template-add'
import { NodeCategory } from '../node-category/node-category'
import { pathNodeTemplateMap } from '../common/shared/path-node-template'

export const NodeTemplateGrid = () => {
  return (
    <React.Fragment>
      <div className="container-fluid p-0 h-100">
        <NodeTemplateGridBranch />
      </div>
    </React.Fragment>
  )
}

const NodeTemplateGridBranch = ({ grandParentURL }) => {
  const { url } = useRouteMatch()
  const { parentID } = useParamsInt()

  const dataContext = useDataContext()

  const itemCurrent = useMemo(() => dataContext.template.find(i => i.id === parentID), [dataContext.template, parentID])
  const children = useMemo(() => dataContext.template.filter(i => i.parentID === (parentID || null)), [dataContext.template, parentID])
  const nodeTemplateCategories = useMemo(() => children.filter(item => isDatatypeNodeTemplateCategory(item)), [children])
  const nodeTemplates = useMemo(() => children.filter(item => isDatatypeCourseTemplate(item)), [children])

  const title = useMemo(
    () => {
      if (parentID) {
        return itemCurrent ? itemCurrent.name : ''
      } else {
        return 'TEMPLATES'
      }
    },
    [parentID, itemCurrent]
  )

  const Icon = useMemo(
    () => {
      if (parentID) return IconCategory
      else return IconTemplate
    },
    [parentID]
  )

  const HeaderMenu = () => NodeCategoryMenuFull(url, parentID)

  const [filter, updateFilter, showFilter, selectFilter] = useFilterList()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={Icon} text={title}>
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <div className="d-flex flex-column h-100">
            <div className="m-2">
              <FilterInput value={filter} onChange={updateFilter} />
            </div>
            <div className="flex-fill" style={{ minHeight: 0 }}>
              <div className="overflow-scroll h-100">
                {showFilter
                  ? <div>
                      <NodeTemplateFilterList nodes={dataContext.template} filter={filter} onSelect={selectFilter} />
                    </div>
                  : <div className="container-fluid">
                      <div className="row g-2">
                        {nodeTemplateCategories
                          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                          .map((node, i) => <NodeCategoryGridItem key={i} node={node} />)}
                        {nodeTemplates
                          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                          .map((node, i) => <NodeTemplateGridItem key={i} node={node} />)}
                      </div>
                    </div>}
              </div>
            </div>
          </div>
        </CardFullHeightScrollY>
      </BreadcrumbView>
      <Route path={`${url}/node/:parentID`}>
        <NodeTemplateGridBranch grandParentURL={url} />
      </Route>
      <Route path={`${url}/template/:nodeID`}>
        <NodeTemplateDetails parentURL={url} />
      </Route>

      <Route path={`${url}/add-type`}>
        <NodeTemplateAdd parentURL={url} parentID={parentID} />
      </Route>

      <NodeCategory
        url={url}
        grandParentURL={grandParentURL}
        nodeID={parentID}
        useApiListCategory={useApiListNodeTemplateCategory}
        useApiFormCategory={useApiFormNodeTemplateCategory}
        useApiListRecycle={useApiListNodeDecendantTemplate}
        pathNodeFn={pathNodeTemplateMap}
      />
    </React.Fragment>
  )
}
