import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import { imageCropConvert } from './image-crop-convert'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../../shared/component/button'

export const ImageEditor = ({ imageURL, onContinue, onCancel }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const [croppedPixels, setCroppedAreaPixels] = useState(1)

  const onCropComplete = (croppedArea, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)

  const handleContinue = async () => {
    const newImageURL = await imageCropConvert(imageURL, croppedPixels)
    onContinue && onContinue(newImageURL)
  }

  const handleCancel = () => onCancel && onCancel()

  return (
    <React.Fragment>
      <div>
        <div>
          <div style={{ position: 'relative', width: 400, height: 200 }}>
            <Cropper
              image={imageURL}
              crop={crop}
              zoom={zoom}
              aspect={400 / 200}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        </div>
        <div>
          <ButtonSecondary onClick={handleCancel}>cancel</ButtonSecondary>
          <ButtonPrimary onClick={handleContinue}>continue</ButtonPrimary>
        </div>
      </div>
    </React.Fragment>
  )
}
