import { useCallback } from 'react'
import { useApiBlobFileContent, useApiFormChunkUploadContent } from '../../../api-new/state/storage'

let chunkSize = 1024 * 1024 * 5
let uploadCancel = false

export const useFileUploadContent = (onStart, onUpdateProgress, onComplete, onCancel) => {
  const apiFormChunkUploadContent = useApiFormChunkUploadContent()

  const handleUpload = async (storageKey, file) => {
    uploadCancel = false

    let result = await apiFormChunkUploadContent.get(`${storageKey}/init`)

    let chunks = Math.floor(file.size / chunkSize)
    let remainder = file.size % chunkSize

    onStart && onStart(file)

    let uploadParts = []

    for (let i = 0; i < chunks; i++) {
      if (uploadCancel) {
        await apiFormChunkUploadContent.delete(`${result.uploadID}/abort`)
        onCancel && onCancel(file)
        return
      }

      let start = i * chunkSize
      let end = start + chunkSize
      let blob = file.slice(start, end)

      let resultPut = await apiFormChunkUploadContent.put(`${storageKey}/${result.uploadID}/${i + 1}/part`, blob)
      uploadParts.push(resultPut)

      onUpdateProgress && onUpdateProgress(end)
    }

    if (uploadCancel) {
      await apiFormChunkUploadContent.delete(`${result.uploadID}/abort`)
      onCancel && onCancel(file)
      return
    }

    let start = chunks * chunkSize
    let end = start + remainder

    let blob = file.slice(start, end)

    let resultPut = await apiFormChunkUploadContent.put(`${storageKey}/${result.uploadID}/${chunks + 1}/part`, blob)
    uploadParts.push(resultPut)

    onUpdateProgress && onUpdateProgress(end)
    await apiFormChunkUploadContent.put(`${storageKey}/${result.uploadID}/complete`, uploadParts)
    onComplete && onComplete(file)
  }

  const cancelUpload = () => {
    uploadCancel = true
  }

  return [handleUpload, cancelUpload]
}

export const useFileDownloadContent = () => {
  const apiBlobFileContent = useApiBlobFileContent()

  const download = useCallback(async (name, key) => {
    try {
      let blob = await apiBlobFileContent.get(`${key}/stream`)
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = url
      a.download = name
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.log(`failed to get assets: ${key}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return download
}
