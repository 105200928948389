import React, { useContext, useEffect, useMemo } from 'react'
import { AuthContext } from './auth.context'

export const DisplayAuth = ({ children, onAuth }) => {
  const authContext = useContext(AuthContext)

  const isAuth = useMemo(() => !!authContext.state.accessToken, [authContext.state.accessToken])

  useEffect(
    () => {
      onAuth && onAuth(isAuth)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuth]
  )

  return (
    <React.Fragment>
      {isAuth && children}
    </React.Fragment>
  )
}
