import { CardHeader } from './components/card-header'

export const CardFullHeightScrollY = ({ children, title, subtitle, HeaderMenu, HeaderComponent }) => {
  return (
    <div className="card h-100">
      <div className="card-body d-flex flex-column pb-0 h-100">
        <CardHeader title={title} subtitle={subtitle} HeaderMenu={HeaderMenu} HeaderComponent={HeaderComponent} />
        <div className="flex-fill" style={{ minHeight: 0 }}>
          <div className="overflow-y-scroll overflow-x-hidden h-100">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
