import React, { useMemo, useRef, useState } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { BadgeInfo, BadgeSuccess, BadgeWarning } from '../../../../../shared/component/badge'
import {
  Menu,
  MenuActivityLog,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuItem,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../shared/menu/menu'

import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnFormat, ColumnDate, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { useApiListTradeshow } from '../../../../../api-new/state/content'
import { IconCopy, IconLaunch, IconPlus, IconTradeShow } from '../../../../../shared/icon/icon'
import { ModalDialog, useModalActions } from '../../../../../shared/component/modal-dialog'
import { useRoleOrgTradeshow } from '../../../../../role/hook/role-org'

export const TradeShowList = () => {
  const apiListTradeshow = useApiListTradeshow()

  const modalActions = useModalActions('copylink')

  const roleOrgTradeshow = useRoleOrgTradeshow()

  const handleReady = async () => await apiListTradeshow.get()

  const [copyIdentifier, updateCopyIdentifier] = useState()

  const handleCopy = identifier => {
    updateCopyIdentifier(identifier)
    modalActions.show()
  }

  const handleOpen = identifier => window.open(`/external/tradeshow/${identifier}/register`)

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuItem text="add" Icon={IconPlus} to="/org/tradeshow/list/add" show={roleOrgTradeshow.accessCreate} pin />
      <MenuRecycle to="/org/tradeshow/list/recycle" show={roleOrgTradeshow.accessDelete} />
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuActivityLog to="/org/tradeshow/list/activity-all" show />
    </Menu>

  return (
    <React.Fragment>
      <CopyLinkModal identifier={copyIdentifier} />
      <BreadcrumbView Icon={IconTradeShow} text="TRADE SHOWS">
        <CardFullHeightScrollY title="tradeshows" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListTradeshow} onReady={handleReady}>
            <Column field="name" sortable filter />
            <ColumnDate field="startOn" header="starts on" sortable />
            <ColumnDate field="endOn" header="ends on" sortable />
            <ColumnFormat field="status" selector={row => <TradeShowStatus tradeShow={row} />} />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/org/tradeshow/list/edit/${row.id}`} show={roleOrgTradeshow.accessUpdate} />
                  <MenuItem Icon={IconCopy} text="copy link" onClick={() => handleCopy(row.identifier)} show />
                  <MenuItem Icon={IconLaunch} text="launch" onClick={() => handleOpen(row.identifier)} show />
                  <MenuDelete to={`/org/tradeshow/list/edit/${row.id}/delete`} show={roleOrgTradeshow.accessDelete} />
                  <MenuActivityLog to={`/org/tradeshow/list/activity-id/${row.id}`} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}

const TradeShowStatus = ({ tradeShow }) => {
  const status = useMemo(
    () => {
      let now = Date.now()
      let startOn = Date.parse(tradeShow.startOn)
      let endOn = Date.parse(tradeShow.endOn)

      if (tradeShow.closed) {
        return <BadgeInfo value="closed" />
      } else if (!startOn && !endOn) {
        return <BadgeSuccess value="open" />
      } else if (tradeShow.activeUntilEnd && now < endOn) {
        return <BadgeSuccess value="open" />
      } else if (now > startOn && now < endOn) {
        return <BadgeSuccess value="open" />
      } else {
        return <BadgeWarning value="inactive" />
      }
    },
    [tradeShow]
  )

  return (
    <React.Fragment>
      {status}
    </React.Fragment>
  )
}

const CopyLinkModal = ({ identifier }) => {
  const [showCopied, updateShowCopied] = useState(false)

  const url = useMemo(
    () => `${process.env.REACT_APP_SITE}/external/tradeshow/${identifier}?email=EMAIL&first-name=FIRST_NAME&last-name=LAST_NAME`,
    [identifier]
  )

  const handleClick = () => {
    navigator.clipboard.writeText(url)
    updateShowCopied(true)
    setTimeout(() => {
      updateShowCopied(false)
    }, 2000)
  }

  return (
    <ModalDialog target="copylink" header="Open Link">
      <div className="input-group">
        <input className="form-control" value={url} readOnly />
        <div className="input-group-append">
          <div className="input-group-text" style={{ cursor: 'pointer' }} onClick={handleClick}>
            <span>
              <IconCopy />Copy
            </span>
          </div>
        </div>
      </div>

      <div className="text-end text-capitalize text-success">
        <small style={{ visibility: `${showCopied ? 'visible' : 'hidden'}` }}>link copied to clipboard</small>
      </div>

      <table className="table">
        <tbody>
          <tr>
            <th>Argument</th>
            <th>Type</th>
          </tr>
          <tr>
            <td>email</td>
            <td>string</td>
          </tr>
          <tr>
            <td>first-name</td>
            <td>string</td>
          </tr>
          <tr>
            <td>last-name</td>
            <td>string</td>
          </tr>
        </tbody>
      </table>
    </ModalDialog>
  )
}
