import React from 'react'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { IconLegal } from '../../shared/icon/icon'
import { Privacy } from '../../shared/legacy/legal/privacy'

export const PrivacyPage = () => {
  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconLegal} text="privacy policy">
        <Privacy />
      </BreadcrumbView>
    </React.Fragment>
  )
}
