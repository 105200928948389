import { useMemo } from 'react'
import { useRouteMatch } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { GridItem } from '../common/shared/grid-item'
import { NodeTemplateMenuPartial } from './common/node-template-menu'
import { IconTemplate } from '../../../../../shared/icon/icon'

export const NodeTemplateGridItem = ({ node }) => {
  const { url } = useRouteMatch()

  const to = useMemo(() => `${url}/template/${node.id}/edit`, [url, node])

  const FooterMenu = () => NodeTemplateMenuPartial(`${url}/template/${node.id}`, node)

  return <GridItem nodeID={node.id} title={node.name} Icon={IconTemplate} FooterMenu={FooterMenu} to={to} />
}
