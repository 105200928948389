import React from 'react'
import { IconClickClose } from '../../shared/icon/icon-click'
import { useItemTypeTitle, useItemTypeHeaderColor } from './hooks'

export const TypeToast = ({ state, onClose }) => {
  const handleClose = id => onClose && onClose(id)

  return (
    <div className="position-fixed fixed-bottom d-flex flex-column p-4 align-items-end" style={{ pointerEvents: 'none' }}>
      {state.map((item, i) => <ToastItem key={i} id={item.id} data={item.data} onClose={handleClose} />)}
    </div>
  )
}

export const ToastItem = ({ id, data, onClose }) => {
  const handleClose = () => onClose && onClose(id)

  const title = useItemTypeTitle(data)
  const headerColor = useItemTypeHeaderColor(data)

  return (
    <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
      <div className={`toast-header d-flex ${headerColor} text-white fw-bold`}>
        <span className="flex-fill">
          {title}
        </span>
        <IconClickClose onClick={handleClose} />
      </div>
      <div className="toast-body">
        {data.text}
      </div>
    </div>
  )
}
