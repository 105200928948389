import { FormSelectListObj } from '../../../../../../shared/form/form-select-list-obj'

const accessData = {
  items: [
    // {
    //   id: 'null',
    //   name: 'Only assigned users or groups',
    //   displayOrder: 1
    // },
    // {
    //   id: 1,
    //   name: 'Hidden',
    //   displayOrder: 2
    // },
    {
      id: 3,
      name: 'All users',
      displayOrder: 3
    },
    {
      id: 2,
      name: 'All users in this organization',
      displayOrder: 4
    }
  ]
}

export const NodeAccess = () => {
  return (
    <FormSelectListObj
      label="access"
      name="accessID"
      state={accessData}
      itemName="name"
      itemValue="id"
      nullText="Only assigned users or groups"
      valueAsNumber
    />
  )
}
