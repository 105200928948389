import React from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconDetails } from '../../../../../shared/icon/icon'
import { AuthStatus } from '../../../../../shared/legacy/auth-status'
import { useAuthStatusForm } from '../../../../../api/state'
import { Card } from '../../../../../shared/card/card'

export const AuthStatusDetails = () => {
  const { refreshToken } = useParams()

  const [authStatusFormActions, authStatusFormState] = useAuthStatusForm()

  const handleMatch = async () => await authStatusFormActions.get(refreshToken)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDetails} text="details" onMatch={handleMatch}>
        <Card title="details">
          <AuthStatus authStatus={authStatusFormState.form} />
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
