import React from 'react'
import { useHistory, useParams } from 'react-router'
import { AuthContext, useAuthContext } from '../../context/auth.context'
import { useLoad } from '../../shared/hooks/load'

export const RedirectContentRoot = () => {
  const history = useHistory()
  const { refreshToken } = useParams()

  const authContext = useAuthContext(AuthContext)

  useLoad(
    async () => {
      if (authContext.state.isAuth) {
        history.replace('/org/node-content')
      } else if (refreshToken) {
        await authContext.actions.refreshRecreate(refreshToken)
      } else {
        history.replace(`/auth/email?redirect=/redirect/content-root`)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state.accessToken]
  )

  return <React.Fragment>REDIRECT NODE EDIT</React.Fragment>
}
