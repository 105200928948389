import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormatLabel } from './format-label'
import { useFormRequired, useFormSetValue, useFormShouldDirty } from './hooks/input-hooks'

export const FormHiddenBool = ({ name, value, required, defaultValue, shouldDirty }) => {
  const { register } = useFormContext()

  useFormRequired(name, required)
  useFormShouldDirty(name, defaultValue, shouldDirty)
  useFormSetValue(name, value, shouldDirty)

  const setValueAs = value => value === true

  return (
    <FormatLabel>
      <input className="form-control" type="hidden" {...register(name, { required, setValueAs })} />
    </FormatLabel>
  )
}
