import { useMemo, useState } from 'react'
import { useApiListGroupUser, useApiListUserLogin } from '../../api-new/state/content'
import { ModalDialog } from '../component/modal-dialog'
import { TextFilter } from './text-filter'
import { IconMinus, IconPlus } from '../icon/icon'

export const PermissionGroupUserForGroupModal = ({ target, groupID, onClose, onClosed }) => {
  const [filter, updateFilter] = useState('')

  const apiListUserLogin = useApiListUserLogin()
  const apiListGroupUser = useApiListGroupUser()

  const filteredItems = useMemo(() => apiListUserLogin.items.filter(i => i.email.includes(filter)), [apiListUserLogin.items, filter])

  const handleOpen = () => {
    apiListUserLogin.get()
    apiListGroupUser.get(`${groupID}/group`)
  }
  const handleClose = () => onClose && onClose()

  const handleAdd = async userID => {
    await apiListGroupUser.post({ groupID: groupID, userID: userID })
    await apiListGroupUser.get(`${groupID}/group`)
  }

  const handleRemove = async userID => {
    await apiListGroupUser.delete(`${groupID}/${userID}`)
    await apiListGroupUser.get(`${groupID}/group`)
  }

  return (
    <ModalDialog target={target} header="add user" fullScreen onOpen={handleOpen} onClose={onClosed}>
      <div className="d-flex flex-column h-100">
        <div>
          <TextFilter value={filter} onChange={updateFilter} />
        </div>
        <div className="flex-fill overflow-auto p-2">
          {filteredItems.map((user, i) =>
            <AddGroupItem key={i} user={user} selectedGroupUsers={apiListGroupUser.items} onAdd={handleAdd} onRemove={handleRemove} />
          )}
        </div>
        <div>
          <button onClick={handleClose}>close</button>
        </div>
      </div>
    </ModalDialog>
  )
}

const AddGroupItem = ({ user, selectedGroupUsers, onAdd, onRemove }) => {
  const isSelected = useMemo(
    () => {
      return selectedGroupUsers.findIndex(selectedGroupUser => selectedGroupUser.userID === user.id) !== -1
    },
    [selectedGroupUsers, user]
  )

  const handleClick = () => {
    if (isSelected) {
      onRemove && onRemove(user.id)
    } else {
      onAdd && onAdd(user.id)
    }
  }

  return (
    <div className="p-1 border-bottom" style={{ cursor: 'pointer' }} onClick={handleClick}>
      <span className="px-1">
        {isSelected ? <IconMinus /> : <IconPlus />}
      </span>
      <span>
        {user.email}
      </span>
    </div>
  )
}
