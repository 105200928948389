import React, { useEffect, useMemo, useState } from 'react'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../shared/hooks/location'
import { Form, FormHiddenNumber, FormHiddenText, FormSubmitCancel } from '../../../../shared/form/form'
import { useApiListManageAssessmentAssignment } from '../../../../api-new/state/assessment'
import { useLoad } from '../../../../shared/hooks/load'
import { useNotificationAlertError } from '../../../../context/notification/type-alert'
import { TableEdit } from '../../../../shared/component/table-edit'
import { FormSelectObj } from '../../../../shared/form/form-select-obj'
import { IconEdit } from '../../../../shared/icon/icon'

export const AssessmentManageAssignment = () => {
  const { nodeID, assessmentID } = useParamsInt()

  const apiListManageAssessmentAssignment = useApiListManageAssessmentAssignment()

  const handleLoad = () => apiListManageAssessmentAssignment.get(assessmentID)
  const handleAdd = data => apiListManageAssessmentAssignment.post(data)
  const handleEdit = data => apiListManageAssessmentAssignment.put(data.id, data)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="Assignments">
        <TableEdit
          columns={[{ header: 'step', value: 'stepTitle' }, { header: 'objective', value: 'objectiveTitle' }]}
          apiList={apiListManageAssessmentAssignment}
          formArgs={{ nodeID: nodeID, assessmentID: assessmentID }}
          FormAdd={FormEdit}
          FormEdit={FormEdit}
          onLoad={handleLoad}
          onAdd={handleAdd}
          onEdit={handleEdit}
        />
      </BreadcrumbView>
    </React.Fragment>
  )
}

const FormEdit = ({ row, nodeID, assessmentID, onSubmit, onClose }) => {
  const apiListManageAssessmentAssignmentSteps = useApiListManageAssessmentAssignment()
  const apiListManageAssessmentAssignmentObjectives = useApiListManageAssessmentAssignment()

  const [selectedStepIdString, updateSelectedStepIdString] = useState()
  const [selectedObjectiveIdString, updateSelectedObjectiveIdString] = useState()

  // useLoad(() => apiListManageAssessmentAssignmentSteps.get(`${nodeID}/steps`), [])
  useLoad(() => apiListManageAssessmentAssignmentSteps.get(`${assessmentID}/steps`), [])

  useLoad(
    () => {
      if (selectedStepIdString) {
        // apiListManageAssessmentAssignmentObjectives.get(`${nodeID}/objectives/${selectedStepIdString}`)
        apiListManageAssessmentAssignmentObjectives.get(`${assessmentID}/objectives/${selectedStepIdString}`)
      }
    },
    [selectedStepIdString]
  )

  const selectedStep = useMemo(() => apiListManageAssessmentAssignmentSteps.items.find(step => step.idString === selectedStepIdString), [
    apiListManageAssessmentAssignmentSteps.items,
    selectedStepIdString
  ])

  const selectedObjective = useMemo(
    () => apiListManageAssessmentAssignmentObjectives.items.find(objective => objective.idString === selectedObjectiveIdString),
    [apiListManageAssessmentAssignmentObjectives.items, selectedObjectiveIdString]
  )

  const alert = useNotificationAlertError()

  useEffect(
    () => {
      const isAssigned = selectedObjective && selectedObjective.isAssigned
      const isCurrent = selectedObjective && row && row.objectiveIdString === selectedObjective.idString

      if (isAssigned && !isCurrent) {
        const text = `Warning: This objective is assigned to ${selectedObjective.assessment}. Adding this objective will remove it from the other assessment.`
        alert.show(text, false)
      } else {
        alert.hide()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedObjective]
  )

  const handleSubmit = data => {
    alert.hide()
    onSubmit(data)
  }

  return (
    <Form state={row} onSubmit={handleSubmit}>
      <div className="row">
        <FormHiddenNumber name="assessmentID" value={assessmentID} />
        <FormHiddenText name="stepTitle" value={selectedStep && selectedStep.title} />
        <FormHiddenText name="objectiveTitle" value={selectedObjective && selectedObjective.title} />

        <FormSelectObj
          className="col-12"
          label="step"
          name="stepIdString"
          state={apiListManageAssessmentAssignmentSteps}
          itemName="title"
          itemValue="idString"
          required
          small
          autoFocus
          onChange={updateSelectedStepIdString}
        />
        <FormSelectObj
          className="col-12"
          label="objective"
          state={apiListManageAssessmentAssignmentObjectives}
          name="objectiveIdString"
          itemName="title"
          itemValue="idString"
          required
          small
          autoFocus
          onChange={updateSelectedObjectiveIdString}
        />
        <FormSubmitCancel small onCancel={onClose} />
      </div>
    </Form>
  )
}
