import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthContext } from '../../../../context/auth.context'

import { Form, FormText, FormSubmit } from '../../../../shared/form/form'
import { NavLink, PositionCenter, TitleMain, TitleSub } from './component'
import { IconLoginDevice, IconLoginEmail } from '../../../../shared/icon/icon'
import { ModalLinkShow } from '../../../../shared/component/modal-dialog'
import { LoginDeviceModal } from './login-device-modal'
import { useNotificationContext } from '../../../../context/notification/context'

export const LoginPin = () => {
  const history = useHistory()

  const authContext = useAuthContext()
  const notificationContext = useNotificationContext()

  const handleSubmit = async data => {
    try {
      await authContext.actions.loginPin(data)
      history.push(`/`)
    } catch (exp) {
      notificationContext.modal.error(exp.error)
      return
    }
  }

  return (
    <React.Fragment>
      <PositionCenter>
        <TitleMain className="text-dark pb-2" value="Login" />
        <TitleSub className="text-dark pb-3" value="Sign in to your account" />
        <Form submitting={authContext.state.authPinFormState.submitting} onSubmit={handleSubmit}>
          <FormText name="pin" placeholder="pin" required />
          <FormSubmit text="sign in" />
        </Form>

        <div>
          <div className="row justify-content-center g-2">
            <div className="col-7 text-center m-1">
              <span>-Or-</span>
            </div>
          </div>
          {/* <div className="row justify-content-center g-2">
            <div className="col-9 col-xl-7 col-xxl-6">
              <ModalLinkShow
                className="btn btn-light border w-100 text-start"
                target="device"
                Icon={IconLoginDevice}
                text="Login from another device"
              />
            </div>
            <div className="col-9 col-xl-5 col-xxl-6">
              <NavLink className="btn btn-light border w-100 text-start" Icon={IconLoginEmail} text="I have a password" to="/auth/email" />
            </div>
          </div> */}
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-lg-6">
              <NavLink className="btn btn-light border w-100 text-start" Icon={IconLoginEmail} text="I have a password" to="/auth/email" />
            </div>
          </div>
        </div>
      </PositionCenter>

      {/* <LoginDeviceModal target="device" /> */}
    </React.Fragment>
  )
}
