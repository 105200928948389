import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../../shared/icon/icon'
import { Form, FormText, FormSubmitCancel } from '../../../../../shared/form/form'
import { FormSelectGroupID } from '../../../../../shared/form/form-control/form-select-group-id'
import { Card } from '../../../../../shared/card/card'
import { useApFormTradeshow } from '../../../../../api-new/state/content'
import { useRoleOrgTradeshow } from '../../../../../role/hook/role-org'

export const TradeShowAdd = () => {
  const history = useHistory()

  const apFormTradeshow = useApFormTradeshow()

  const roleOrgTradeshow = useRoleOrgTradeshow()

  const handleSubmit = async data => {
    let tradeShow = await apFormTradeshow.post(data)
    if (roleOrgTradeshow.accessUpdate) history.push(`/org/tradeshow/list/edit/${tradeShow.id}`)
    else history.push(`/org/tradeshow/list`)
  }

  const handleCancel = () => history.push('/org/tradeshow/list')

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add">
        <Card title="add trade show">
          <Form submitting={apFormTradeshow.submitting} onSubmit={handleSubmit}>
            <FormText label="name" name="name" required />
            <FormSelectGroupID label="group" name="groupID" required />
            <FormSubmitCancel text="add tradeshow" onCancel={handleCancel} />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
