import React from 'react'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { IconLegal } from '../../shared/icon/icon'
import { Terms } from '../../shared/legacy/legal/terms'

export const TermsPage = () => {
  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconLegal} text="terms & conditions">
        <Terms />
      </BreadcrumbView>
    </React.Fragment>
  )
}
