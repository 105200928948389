export const FileToImageURL = async file => {
  return new Promise(result => {
    const reader = new FileReader()
    reader.onload = () => result(reader.result)
    reader.onerror = () => {}
    reader.readAsDataURL(file)
  })
}

export const ImageUrlToBlob = async imageURL => {
  return new Promise(result => {
    fetch(imageURL).then(res => res.blob()).then(blob => result(blob))
  })
}
