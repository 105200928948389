import React, { useState } from 'react'
import { Input } from './input'
import { IconClickAdd } from '../../../../../../shared/icon/icon-click'

export const InputNew = ({ index, placeholder, readOnly, onEnter }) => {
  const [value, updateValue] = useState('')

  const handleChangeValue = value => updateValue(value)
  const handleEnterValue = () => {
    if (value) {
      onEnter && onEnter(value)
      updateValue('')
    }
  }

  const Actions = () => <IconClickAdd onClick={handleEnterValue} />

  return (
    <React.Fragment>
      <Input
        pos={index}
        value={value}
        placeholder={placeholder}
        muteSubmit
        readOnly={readOnly}
        onChange={handleChangeValue}
        onEnter={handleEnterValue}
        Actions={Actions}
      />
    </React.Fragment>
  )
}
