import React, { useContext, useMemo, useState } from 'react'
import { AuthContext } from '../../../../../../context/auth.context'
import { useApiFormNodeCourse, useApiFormNodeMove, useApiListNodeCourseCategory } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconMove } from '../../../../../../shared/icon/icon'
import { Form, FormHiddenNumber, FormSubmitBack } from '../../../../../../shared/form/form'
import { useDataContext } from '../../data-context'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { pathNodeContentMap } from '../../common/shared/path-node-content'
import { NodeContentMenuFull } from '../common/node-content-menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { FormSelectCategoryTemp } from '../../../../../../shared/form/form-control/form-select-category-temp'

export const NodeCourseMove = ({ parentURL, nodeID }) => {
  const authContext = useContext(AuthContext)
  const dataContext = useDataContext()

  const history = useHistory()

  const apiFormNodeCourse = useApiFormNodeCourse()
  const apiFormNodeMove = useApiFormNodeMove()

  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  useLoad(() => authContext.state.jwtClaims.organizationID && updateCategoryOrganizationID(authContext.state.jwtClaims.organizationID), [
    authContext.state.jwtClaims.organizationID
  ])

  const title = useMemo(() => apiFormNodeCourse.form.name, [apiFormNodeCourse.form])

  const handleMatch = async () => await apiFormNodeCourse.get(nodeID)

  const handleSubmit = async data => {
    await apiFormNodeMove.put(data.nodeID, data)
    let node = await apiFormNodeCourse.get(data.nodeID)

    const moveToPath = ({ map }) => {
      dataContext.unsubscribe(moveToPath)
      let path = pathNodeContentMap(map, node.id)
      history.replace(path)
    }

    dataContext.subscribe(moveToPath)
    dataContext.nodeUpdate(node)
  }

  const HeaderMenu = () => NodeContentMenuFull(parentURL, apiFormNodeCourse.form)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconMove} text="move" onMatch={handleMatch}>
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="nodeID" value={apiFormNodeCourse.form.id} required shouldDirty />
            {categoryOrganizationID &&
              <FormSelectCategoryTemp
                lable="category"
                name="parentID"
                organizationID={categoryOrganizationID}
                required
                useApiList={useApiListNodeCourseCategory}
              />}
            <FormSubmitBack text="move course" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
