import React from 'react'
import { IconCsv, IconDelete, IconDetails, IconDown, IconInvite, IconUp } from './icon'

export const IconButtonClick = ({ Icon, text, small, size, disabled, onClick }) => {
  const handleClick = () => {
    if (!disabled) {
      onClick && onClick()
    }
  }
  return (
    <button className={`btn mx-1 btn-primary ${small && 'btn-sm'} m-1`} disabled={disabled} onClick={handleClick}>
      <div className="d-flex text-nowrap text-capitalize">
        <span>
          {Icon && <Icon size={size} />}
        </span>
        <span>
          {text}
        </span>
      </div>
    </button>
  )
}

export const IconButtonClickExportDetails = ({ text, disabled, small, size, onClick }) => {
  return (
    <IconButtonClick Icon={IconDetails} text={text || 'Export Details'} small={small} size={size} disabled={disabled} onClick={onClick} />
  )
}

export const IconButtonClickRemove = ({ text, disabled, small, size, onClick }) => {
  return <IconButtonClick Icon={IconDelete} text={text || 'Remove'} small={small} size={size} disabled={disabled} onClick={onClick} />
}

export const IconButtonClickImportCSV = ({ text, disabled, small, size, onClick }) => {
  return <IconButtonClick Icon={IconCsv} text={text || 'ImportCsv'} small={small} size={size} disabled={disabled} onClick={onClick} />
}

export const IconButtonClickDelete = ({ text, disabled, small, size, onClick }) => {
  return <IconButtonClick Icon={IconDelete} text={text || 'Delete'} small={small} size={size} disabled={disabled} onClick={onClick} />
}

export const IconButtonClickInvite = ({ text, disabled, small, size, onClick }) => {
  return <IconButtonClick Icon={IconInvite} text={text || 'Invite'} small={small} size={size} disabled={disabled} onClick={onClick} />
}

export const IconButtonClickUp = ({ text, disabled, small, size, onClick }) => {
  return <IconButtonClick Icon={IconUp} text={text || 'Up'} small={small} size={size} disabled={disabled} onClick={onClick} />
}

export const IconButtonClickDown = ({ text, disabled, small, size, onClick }) => {
  return <IconButtonClick Icon={IconDown} text={text || 'Down'} small={small} size={size} disabled={disabled} onClick={onClick} />
}
