import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormatLabel } from './format-label'
import { useFormDefaultValue, useFormRequired, useFormSetValue } from './hooks/input-hooks'

export const FormHiddenNumber = ({ name, value, required, defaultValue, shouldDirty }) => {
  const { register } = useFormContext()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useFormRequired(name, required)
  useFormDefaultValue(name, defaultValue, shouldDirty)

  useFormSetValue(name, value, shouldDirty)

  const setValueAs = value => (value ? parseInt(value) : null)

  return (
    <FormatLabel>
      <input className="form-control" type="hidden" {...register(name, { required, setValueAs })} />
    </FormatLabel>
  )
}
