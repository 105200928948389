import React, { useMemo, useState } from 'react'
import { ButtonPrimary } from '../../../../../../../../shared/legacy/button'
import { Progress } from '../../../../../../../../shared/legacy/progress'
import { FileSelect } from '../../../../../../../../shared/legacy/file/file-select'
import { Card } from '../../../../../../../../shared/card/card'

let chunkSize = 1024 * 1024 * 6

export const StorageChunkUpload = ({ useChunkApiForm, storageKey, storeKey, onComplete, onClose }) => {
  const [chunkFormActions] = useChunkApiForm()

  const [file, updateFile] = useState(null)

  const filesize = useMemo(() => (file ? file.size : 0), [file])
  const filename = useMemo(() => (file ? file.name : ''), [file])

  const [fileProgress, updateFileProgress] = useState(0)
  const [uploadState, updateUploadState] = useState(null)

  const handleSelect = async file => {
    updateFile(file)
    updateUploadState('pending')
  }

  const handleUpload = async () => {
    updateUploadState('uploading')

    let postURL = `${file.name}?storage-path=${storeKey}`
    let result = await chunkFormActions.postArgs({ path: postURL, toast: null })

    let chunks = Math.floor(file.size / chunkSize)
    let remainder = file.size % chunkSize

    for (let i = 0; i < chunks; i++) {
      let start = i * chunkSize
      let end = start + chunkSize
      let blob = file.slice(start, end)
      await chunkFormActions.putArgs({ path: `${result.identifier}/${i + 1}`, data: blob, toast: null })
      updateFileProgress(end)
    }

    let start = chunks * chunkSize
    let end = start + remainder

    let blob = file.slice(start, end)

    await chunkFormActions.putArgs({ path: `${result.identifier}/${chunks + 1}`, data: blob, toast: null })
    updateFileProgress(end)

    await chunkFormActions.putArgs({ path: result.identifier, toast: 'upload complete' })

    let getURL = `${file.name}/details?storage-path=${storeKey}`
    let details = await chunkFormActions.get(getURL)
    updateUploadState('completed')

    onComplete && onComplete(details)
  }

  const handleCancel = async () => {
    updateFile(null)
    updateFileProgress(0)
    updateUploadState(null)
    await chunkFormActions.delete(`${storageKey}`)
  }

  const handleClose = () => {
    updateFile(null)
    updateFileProgress(0)
    updateUploadState(null)
    onClose && onClose()
  }

  return (
    <React.Fragment>
      {!file && <FileSelect onSelect={handleSelect} />}
      {file &&
        <Card title="File Upload">
          {uploadState === 'completed' &&
            <div className="alert alert-success" role="alert">
              Upload Complete!
            </div>}

          <table className="table">
            <tbody>
              <tr>
                <td>Filename</td>
                <td>
                  {filename}
                </td>
              </tr>
              <tr>
                <td>Filesize</td>
                <td>
                  {filesize}
                </td>
              </tr>
              <tr>
                <td>Progress</td>
                <td>
                  <Progress current={fileProgress} length={filesize} />
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  {uploadState === 'pending' && <ButtonPrimary text="upload" onClick={handleUpload} />}
                  {uploadState === 'uploading' && <ButtonPrimary text="cancel" onClick={handleCancel} />}
                  {uploadState === 'completed' && <ButtonPrimary text="close" onClick={handleClose} />}
                </td>
              </tr>
            </tbody>
          </table>
        </Card>}
    </React.Fragment>
  )
}
