import React, { useContext, useMemo } from 'react'
import { AuthContext } from './auth.context'

export const DisplayRoleAdmin = ({ children }) => {
  const authContext = useContext(AuthContext)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const show = useMemo(() => authContext.state.isSysAdmin || authContext.state.role === 'admin', [
    authContext.state.isSysAdmin,
    authContext.state.role
  ])

  return (
    <React.Fragment>
      {show && children}
    </React.Fragment>
  )
}
