import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Menu, MenuAdd } from '../../../../../../shared/menu/menu'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { useApiFormLoginUser, useApiListLoginUser } from '../../../../../../api-new/state/content'
import { useApiListOrganization } from '../../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../../shared/table-builder/table-builder'
import { IconOrganization } from '../../../../../../shared/icon/icon'
import { useRoleSysLogin } from '../../../../../../role/hook/role-sys'

export function OrganizationUserAdd() {
  const { loginID } = useParamsInt()

  const apiListOrganization = useApiListOrganization()

  const apiListLoginUser = useApiListLoginUser()
  const apiFormLoginUser = useApiFormLoginUser()

  const handleMatch = async () => {
    await apiListLoginUser.get(`${loginID}/login`)
    await apiListOrganization.get()
  }

  const handleCreate = async item => {
    await apiFormLoginUser.post({ organizationID: item.id, loginID: loginID })
    await apiListLoginUser.get(`${loginID}/login`)
  }

  const roleSysLogin = useRoleSysLogin()

  const handleInitialFilter = items => items.filter(org => !apiListLoginUser.items.some(loginUser => loginUser.organizationID === org.id))

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconOrganization} text="organization" onMatch={handleMatch}>
        <CardFullHeightScrollY title="add login to organization">
          <TableBuilder apiList={apiListOrganization} initialFilterFn={handleInitialFilter}>
            <Column field="name" header="Organization" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuAdd text="add" onClick={() => handleCreate(row)} show={roleSysLogin.accessCreate} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
