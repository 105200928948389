import React from 'react'
import { Route } from 'react-router-dom'
import { Delete } from '../../../../shared/page/page-delete'
import { RoleOrgPermissionAdd } from './page/role-org-permission-add'
import { RoleOrgPermissionEdit } from './page/role-org-permission-edit'
import { RoleOrgPermissionList } from './page/role-org-permission-list'
import { RoleOrgPermissionListIdentifier } from './page/role-org-permission-list-identifier'
import { RoleOrgPermissionListRole } from './page/role-org-permission-list-role'
import { useApiFormAccess } from '../../../../api-new/state/content'

export const LayoutRoleOrgPermission = () => {
  return (
    <React.Fragment>
      <Route path="/root/role-org-permission/list" component={RoleOrgPermissionList} />
      <Route path="/root/role-org-permission/list/add" component={RoleOrgPermissionAdd} />
      <Route path="/root/role-org-permission/list/edit/:roleOrgPermissionID" component={RoleOrgPermissionEdit} />

      <Route path="/root/role-org-permission/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormAccess} titleParam="role" successPath="/root/role-org-permission/list" />
      </Route>

      <Route path="/root/role-org-permission/list/role/:roleOrg" component={RoleOrgPermissionListRole} />
      <Route path="/root/role-org-permission/list/identifier/:identifier" component={RoleOrgPermissionListIdentifier} />
    </React.Fragment>
  )
}
