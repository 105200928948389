import React from 'react'

export default function Component ({details}) {
  return (
    <React.Fragment>
      <h1 className='font-weight-bold'>
        {details.nodeName}
      </h1>
    </React.Fragment>
  )
}
