import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../../shared/icon/icon'
import { Form, FormText, FormSubmitCancel, FormEmail } from '../../../../../shared/form/form'
import { Card } from '../../../../../shared/card/card'
import { useApiFormLogin } from '../../../../../api-new/state/content'
import { useRoleSysLogin } from '../../../../../role/hook/role-sys'

export function LoginAdd() {
  const history = useHistory()

  const apiFormLogin = useApiFormLogin()

  const roleSysLogin = useRoleSysLogin()

  const handleSubmit = async data => {
    let login = await apiFormLogin.post(data)
    if (roleSysLogin.accessUpdate) history.push(`/admin/login/list/edit/${login.id}`)
    else history.push(`/admin/login/list`)
  }

  const handleCancel = () => history.push('/admin/login/list')

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add">
        <Card title="add login">
          <Form submitting={apiFormLogin.submitting} onSubmit={handleSubmit}>
            <FormEmail label="email" name="email" required autoFocus />
            <FormText label="first name" name="firstName" required />
            <FormText label="last name" name="lastName" required />
            <FormSubmitCancel text="add login" onCancel={handleCancel} />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
