import React from 'react'
import { AppLinks } from '../../shared/legacy/app-links'
import { CardFullHeight } from '../../shared/card/card-full-height'

export const DownloadLinks = () => {
  return (
    <React.Fragment>
      <CardFullHeight title="downloads">
        <AppLinks />
      </CardFullHeight>
    </React.Fragment>
  )
}
