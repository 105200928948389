import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useApiListSendgridEvent } from '../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu, ColumnUnixDateTime } from '../../../../../shared/table-builder/table-builder'
import { Menu, MenuDetails } from '../../../../../shared/menu/menu'
import { IconEmail } from '../../../../../shared/icon/icon'

export const SendgridEventList = () => {
  const apiListSendgridEvent = useApiListSendgridEvent()

  const handleReady = () => apiListSendgridEvent.get()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEmail} text="SENDGRID EVENTS">
        <CardFullHeightScrollY title="events">
          <TableBuilder apiList={apiListSendgridEvent} onReady={handleReady}>
            <ColumnUnixDateTime field="timestamp" header="Sent At" sortable filter sort="desc" />
            <Column field="email" sortable filter />
            <Column field="templateName" header="Template" sortable filter />
            <Column field="event" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuDetails to={`/admin/sendgrid-event/list/details/${row.id}`} show pin />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
