import { useMemo } from 'react'
import { DashboardHeader, Widget } from '../../../../../../shared/chart/widget'
import { ChartPie } from '../../../../../../shared/chart/chart'

import { IconPlatform } from '../../../../../../shared/icon/icon'
import { useLoad } from '../../../../../../shared/hooks/load'
import { getPlatformColors } from '../../../../../../shared/chart/chart-color'
import { useApiListGraphGeneralOrg } from '../../../../../../api-new/state/analytics'

export const DashboardAnalyticPlatform = () => {
  const apiListGraphGeneralOrg = useApiListGraphGeneralOrg()

  useLoad(() => apiListGraphGeneralOrg.get('platform'), [])

  const chartData = useMemo(
    () => {
      if (!apiListGraphGeneralOrg.items.length) {
        return { labels: [], data: undefined, value: 0 }
      }

      let value = apiListGraphGeneralOrg.items.reduce((a, i) => a + i.count, 0)
      let sorted = apiListGraphGeneralOrg.items.sort((a, b) => a.os.localeCompare(b.os))
      const labels = sorted.map(i => i.os)
      const data = sorted.map(i => i.count)

      const colors = getPlatformColors(labels)

      const dataSet = {
        data: data,
        borderColor: colors,
        backgroundColor: colors
      }

      return { labels: labels, data: dataSet, value: value }
    },
    [apiListGraphGeneralOrg.items]
  )

  return (
    <Widget
      header={<DashboardHeader Icon={IconPlatform} text="SESSIONS" value={chartData.value} />}
      body={<ChartPie labels={chartData.labels} data={chartData.data} displayLegend legendPosition="left" />}
    />
  )
}
