import React from 'react'
import { Route } from 'react-router-dom'
import { Organization } from './content/organization'

export const LayoutDashboard = () => {
  return (
    <React.Fragment>
      <Route path="/org/dashboard" component={Organization} />
    </React.Fragment>
  )
}
