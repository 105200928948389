import React, { useCallback, useState } from 'react'
import { GoogleReCaptcha, GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { getAsync } from '../api.service'
import { IconRedo } from '../icon/icon'

const state_init = 0
const state_verified = 1
const state_error = 2

export const CaptchaProvider = ({ children }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfR4kIcAAAAAOQQCY68TOjbqbz5d60PAVZSCh5n">
      {children}
    </GoogleReCaptchaProvider>
  )
}

export const Captcha = ({ onVerify }) => {
  const [state, updateState] = useState(state_init)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const verifyCaptcha = useCallback(token => {
    getAsync(process.env.REACT_APP_API_CONTENT, `api/v1/captcha/${token}`)
      .then(() => {
        updateState(state_verified)
        onVerify && onVerify(true)
      })
      .catch(error => {
        updateState(state_error)
        console.log('captcha error: ', error.response.data)
        onVerify && onVerify(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleVerify = token => {
    verifyCaptcha(token)
  }

  const handleReVerify = async () => {
    let token = await executeRecaptcha()
    verifyCaptcha(token)
  }

  return (
    <React.Fragment>
      <GoogleReCaptcha onVerify={handleVerify} />
      {state === state_init &&
        <div className="spinner-border spinner-border-sm text-primary float-right">
          <span className="sr-only" />
        </div>}
      {state === state_error &&
        <div className="float-right">
          <button className="btn btn-sm" onClick={handleReVerify}>
            Rerun Captcha <IconRedo />
          </button>
        </div>}
    </React.Fragment>
  )
}
