import React, { useEffect, useState } from 'react'
import { useNodeGroupList } from '../../../../../../api/state'
import { IconLinkEdit } from '../../../../../../shared/icon/icon-link'
import { Card } from '../../../../../../shared/card/card'

export default function Component({ groupID }) {
  const [nodeGroupListActions, nodeGroupListState] = useNodeGroupList()

  const [nodeCount, updateNodeCount] = useState(0)
  const [nodes, updateNodes] = useState([])

  useEffect(() => {
    nodeGroupListActions.get(`${groupID}/group`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      let length = nodeGroupListState.items.length
      updateNodeCount(length)
      updateNodes(nodeGroupListState.items.map((e, i) => `${e.nodeName}${i === length - 1 ? '' : ', '}`))
    },
    [nodeGroupListState.items]
  )

  const HeaderComponent = () => <IconLinkEdit to={`/org/group/list/edit/${groupID}/content`} />

  return (
    <React.Fragment>
      <Card title={`Content - ${nodeCount || 'none'}`} HeaderComponent={HeaderComponent}>
        {nodes}
      </Card>
    </React.Fragment>
  )
}
