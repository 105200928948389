import { useContext } from 'react'
import { AuthContext } from '../../../../context/auth.context'
import { useRoleUser } from '../../../../api-new/state/content'
import { useHistory } from 'react-router-dom'

export const useProviderSignin = () => {
  const authContext = useContext(AuthContext)

  return async (providerLink, email, redirectUrl) => {
    let authPassave = await authContext.state.authProviderFormState.post()

    let redirectSigninURL = `${process.env.REACT_APP_SITE}/open/auth`
    let redirectCancelURL = `${process.env.REACT_APP_SITE}/auth/email`
    let redirectErrorURL = `${process.env.REACT_APP_SITE}/auth/error`

    if (redirectUrl) {
      redirectSigninURL += `?redirect-url=${redirectUrl}`
    }

    if (providerLink.signoutURL) {
      authContext.actions.setSignoutURL(providerLink.signoutURL)
    }

    let url = `${process.env
      .REACT_APP_API_AUTH}/api/v1/auth-provider/${providerLink.identifier}?token=${authPassave.token}&redirect-signin-url=${redirectSigninURL}&redirect-cancel-url=${redirectCancelURL}&redirect-error-url=${redirectErrorURL}&redirect-url=${redirectUrl}`

    if (email) {
      url += `&email=${email}`
    }

    window.location = url
  }
}

export const useLoginRedirect = () => {
  const history = useHistory()

  const roleUser = useRoleUser()

  return async redirect => {
    let roles = await roleUser.get()

    // let isSysAdmin = roles.some(i => i.role === 'sys-admin')
    // let isAdmin = roles.some(i => i.role === 'admin')
    // let isAuthor = roles.some(i => i.role === 'author')
    // let isOwner = roles.some(i => i.role === 'owner')
    // let isTeacher = roles.some(i => i.role === 'teacher')
    // let isUser = roles.some(i => i.role === 'user')
    let isLearner = roles.some(i => i.role === 'learner')

    if (roles.length === 2 && isLearner) {
      history.push('/download/links')
      return
    }

    if (redirect) {
      history.push(redirect)
      return
    } else {
      history.push('/org/dashboard')
      return
    }
  }
}
