import React from 'react'
import { FileImageSlim } from './file-image-slim'
import { Card } from '../../card/card'
import { Menu, MenuItem } from '../../menu/menu'
import { IconCancel } from '../../icon/icon'

export const FileImageSwap = ({ title, rowColWidth, ratio, logoURL, accept, maxSize, onSave, onClear }) => {
  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconCancel} text="Clear" show onClick={onClear} />
    </Menu>

  return (
    <Card title={title || 'image swap'} HeaderMenu={HeaderMenu}>
      <div className="m-2">
        {logoURL &&
          <div>
            <div className="fs-6 m-2">Current Image</div>
            <div className="row">
              <div className={rowColWidth || 'col-12'}>
                <img className="w-100" src={logoURL} alt="img" />
              </div>
            </div>
          </div>}

        {!logoURL && <div>No Image</div>}

        <div className="mt-2">
          <FileImageSlim rowColWidth={rowColWidth} ratio={ratio} maxSize={maxSize} accept={accept} onContinue={onSave} />
          {ratio && <div className="py-2">{`Image size ratio ${ratio}`}</div>}
        </div>
      </div>
    </Card>
  )
}
