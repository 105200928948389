import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { StorageChunkDetails } from './components/storage-chunk-details'
import { StorageChunkUpload } from './components/storage-chunk-upload'

export const StorageChunk = ({ useChunkApiForm, useFileApiForm, storageKey, storeKey, onComplete, onDelete }) => {
  StorageChunk.propTypes = {
    useChunkApiForm: PropTypes.func.isRequired
  }

  const refDetails = useRef()

  const handleClose = () => refDetails.current.reload()

  return (
    <React.Fragment>
      <StorageChunkDetails ref={refDetails} useFileApiForm={useFileApiForm} storageKey={storageKey} onDelete={onDelete} />
      <StorageChunkUpload
        useChunkApiForm={useChunkApiForm}
        storageKey={storageKey}
        storeKey={storeKey}
        onComplete={onComplete}
        onClose={handleClose}
      />
    </React.Fragment>
  )
}
