import { useCallback, useReducer } from 'react'
import { useGetRandomID } from './random-id'

export const ACTION_ADD = 'ACTION_ADD'
export const ACTION_REMOVE_ID = 'ACTION_REMOVE_ID'
export const ACTION_REMOVE_ALL = 'ACTION_REMOVE_ALL'

export const useReducerList = () => {
  const getRandomID = useGetRandomID()

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.action) {
      case ACTION_ADD:
        return [...state, action.payload]
      case ACTION_REMOVE_ID:
        let index = state.findIndex(i => i.id === action.payload)

        //call close if function
        let item = state[index]
        if (item && item.close && typeof item.close === 'function') {
          item.close()
        }

        state.splice(index, 1)
        return [...state]
      case ACTION_REMOVE_ALL:
        return []
      default:
        return state
    }
  }, [])

  const addItem = useCallback((data, close) => {
    let id = getRandomID()

    dispatch({ action: ACTION_ADD, payload: { id: id, data: data, close: close } })
    if (close) {
      if (typeof close === 'number') {
        setTimeout(() => {
          dispatch({ action: ACTION_REMOVE_ID, payload: id })
        }, close)
      }
    }

    return {
      close: () => {
        dispatch({ action: ACTION_REMOVE_ID, payload: id })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeID = useCallback(id => dispatch({ action: ACTION_REMOVE_ID, payload: id }), [])
  const removeAll = useCallback(() => dispatch({ action: ACTION_REMOVE_ALL }), [])

  return [state, addItem, removeID, removeAll]
}
