import React, { useMemo, useState } from 'react'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useAdminNodeContext } from './admin-node-context'
import { NodeItem } from './shared/node-item'
import { Route } from 'react-router-dom'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { GridChild } from './grid-child'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Filter } from '../../../../../shared/component/filter'
import { AdminNodeEdit } from './node-edit'
import { Delete } from '../../../../../shared/page/page-delete'
import { useApiFormNode } from '../../../../../api-new/state/content'
import { AssetList } from './asset-list'
import { NoChildren } from './shared/no-child-nodes'
import { Menu, MenuAdd, MenuRecycle } from '../../../../../shared/menu/menu'
import { NodeAdd } from './node-add'
import { NodeRecycle } from './node-recycle'
import { IconList } from '../../../../../shared/icon/icon'

export const GridRoot = () => {
  const { url } = useRouteMatch()
  const history = useHistory()

  const adminNodeContext = useAdminNodeContext()

  const [nodeSearch, updateNodeSearch] = useState('')

  const nodes = useMemo(() => adminNodeContext.nodes.filter(i => !i.parentID), [adminNodeContext.nodes])

  const filteredNodes = useMemo(
    () => {
      let lowerValue = nodeSearch.toLowerCase()
      if (nodeSearch) return nodes.filter(i => i.name.toLowerCase().includes(lowerValue))
      else return nodes
    },
    [nodes, nodeSearch]
  )

  const handleAdd = node => history.push(`${url}/node/${node.id}/edit`)
  const handleClick = node => history.push(`${url}/node/${node.id}`)
  const handleAssets = node => history.push(`${url}/node/${node.id}/assets`)
  const handleEdit = node => history.push(`${url}/node/${node.id}/edit`)
  const handleDelete = node => history.push(`${url}/node/${node.id}/delete`)
  const handleSuccessDelete = node => adminNodeContext.nodeRemove(node)

  const headerMenu = () =>
    <Menu>
      <MenuAdd to={`${url}/add`} show />
      <MenuRecycle to={`${url}/recycle`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="Root">
        <CardFullHeightScrollY title="root" HeaderMenu={headerMenu}>
          <Filter value={nodeSearch} onChange={updateNodeSearch} />
          <div className="row g-2 m-2">
            {filteredNodes
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((node, i) =>
                <NodeItem key={i} node={node} onClick={handleClick} onAssets={handleAssets} onEdit={handleEdit} onDelete={handleDelete} />
              )}
            <NoChildren nodes={nodes} />
          </div>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/add`} component={NodeAdd}>
        <NodeAdd onAdd={handleAdd} />
      </Route>

      <Route path={`${url}/node/:nodeID`} component={GridChild} />
      <Route path={`${url}/node/:nodeID/assets`} component={AssetList} />

      <Route path={`${url}/node/:nodeID/edit`}>
        <AdminNodeEdit onChildren={handleClick} onAssets={handleAssets} onDelete={handleDelete} />
      </Route>

      <Route path={`${url}/node/:deleteID/delete`}>
        <Delete useApiForm={useApiFormNode} titleParam="name" successPath={`${url}`} onSuccess={handleSuccessDelete} />
      </Route>

      <Route path={`${url}/recycle`} component={NodeRecycle} />
    </React.Fragment>
  )
}
