import React, { useMemo, useState } from 'react'
import { useAuthProviderForm } from './state'
import { ButtonPrimary, ButtonSecondary } from '../shared/legacy/button'
import { TermsAccept } from '../shared/legacy/legal/termsAccept'
import { useAuthProviderDetailsState } from './hooks'
import { useQueryParam } from '../shared/hooks/location'
import { CardFullHeightScrollY } from '../shared/card/card-full-height-scroll-y'

export const OpenLink = () => {
  const token = useQueryParam('token')

  const [termsConditions, updateTermsConditions] = useState(false)

  const [authProviderFormActions] = useAuthProviderForm()

  const [authProviderDetails, error] = useAuthProviderDetailsState(token)

  const handleSubmit = async () => {
    try {
      await authProviderFormActions.get(`${token}/link`)

      let url = new URL(`${process.env.REACT_APP_SITE}/open/organization`)
      url.searchParams.set('token', token)
      window.location = url.href
    } catch (error) {
      debugger
    }
  }

  const handleCheck = termsConditions => updateTermsConditions(termsConditions)
  const handleCancel = async () => (window.location = authProviderDetails.redirectCancelURL)

  const title = useMemo(
    () => (authProviderDetails ? `Link account ${authProviderDetails.email} to ${authProviderDetails.provider}` : 'Link account'),
    [authProviderDetails]
  )

  return (
    <React.Fragment>
      <CardFullHeightScrollY title={title}>
        {error &&
          <div className="py-2">
            {error}
          </div>}

        <div className="py-2">
          You are about to link this provider with your EducationXR account. Until you disconnect this account it will be able to be used to
          access to your EducationXR account. Please make sure that you trust this provider before linking your account.
        </div>

        <div className="py-2">
          <TermsAccept state={termsConditions} onCheck={handleCheck} />
        </div>

        <div className="py-2">
          <ButtonSecondary text="cancel" state={termsConditions} onClick={handleCancel} />
          <ButtonPrimary text="link account" disabled={!termsConditions} onClick={handleSubmit} />
        </div>
      </CardFullHeightScrollY>
    </React.Fragment>
  )
}
