import { useCallback } from "react"


export const useParseJSON = () => {
    
    return useCallback((value) => {
        try {
            if (typeof value === 'boolean') {
                return value ? "true" : "false"
              }

              return JSON.parse(value)
        }catch {
            return value
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

}
