import { useApiFormState } from './api-form/action'
import { useApiListState } from './api-list/action'
import { useApiGetState } from './api-get/action'
import { useApiWebsocket, useApiWebsocket2 } from './api-websocket/api-websocket'

export const useAssetList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/asset`)
}

export const useAssetForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/asset`)
}

export const useAssetFileForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/asset-file`)
}

export const useAssetFileGet = () => {
  return useApiGetState(process.env.REACT_APP_API_CONTENT, `api/v1/asset-file`)
}

export const useNodeList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/node`)
}

export const useNodeForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/node`)
}

export const useNodeCourseForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/node-course`)
}

export const useNodeCategoryList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/node-category`)
}

export const useNodeCategoryForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/node-category`)
}

export const useNodeTemplateList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/node-template`)
}

export const useNodeTemplateForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/node-template`)
}

export const useAssessmentManageList = () => {
  return useApiListState(process.env.REACT_APP_API_ASSESSMENT, `api/v1/assessment`)
}

export const useAnalyticCourseUserList = () => {
  return useApiListState(process.env.REACT_APP_API_ANALYTICS, 'api/v1/report/course-users')
}

export const useAnalyticReportForm = () => {
  return useApiFormState(process.env.REACT_APP_API_ANALYTICS, 'api/v1/report')
}

export const useHeatmapSessionQueryList = () => {
  return useApiListState(process.env.REACT_APP_API_HEATMAP, `api/v1/session-query`)
}

export const useAuthImpersonateForm = () => {
  return useApiFormState(process.env.REACT_APP_API_AUTH, `api/v1/auth-impersonate`)
}

export const useProviderList = () => {
  return useApiListState(process.env.REACT_APP_API_AUTH, `api/v1/provider`)
}

export const useProviderDetailsGet = () => {
  return useApiGetState(process.env.REACT_APP_API_AUTH, `api/v1/provider/details`)
}

export const useProviderForm = () => {
  return useApiFormState(process.env.REACT_APP_API_AUTH, `api/v1/provider`)
}

export const useProviderOrganizationList = () => {
  return useApiListState(process.env.REACT_APP_API_AUTH, `api/v1/provider-organization`)
}

export const useProviderOrganizationForm = () => {
  return useApiFormState(process.env.REACT_APP_API_AUTH, `api/v1/provider-organization`)
}

export const useAuthProviderLoginList = () => {
  return useApiListState(process.env.REACT_APP_API_AUTH, `api/v1/auth-provider-login`)
}

export const useAuthProviderLoginForm = () => {
  return useApiFormState(process.env.REACT_APP_API_AUTH, `api/v1/auth-provider-login`)
}

export const useAppList = () => {
  return useApiListState(process.env.REACT_APP_API_CONFIG, `api/v2/app`)
}

export const useAppForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONFIG, `api/v2/app`)
}

export const useAppStoreList = () => {
  return useApiListState(process.env.REACT_APP_API_CONFIG, `api/v2/app-store`)
}

export const useAppStoreForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONFIG, `api/v2/app-store`)
}

export const useAppVersionList = () => {
  return useApiListState(process.env.REACT_APP_API_CONFIG, `api/v2/app-version`)
}

export const useAppVersionForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONFIG, `api/v2/app-version`)
}

export const useAppDownloadForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONFIG, `api/v2/app-download`)
}

// export const useAppDisplayList = () => {
//   return useApiListState(process.env.REACT_APP_API_CONFIG, `api/v2/app-display`)
// }

// export const useAppDisplayForm = () => {
//   return useApiFormState(process.env.REACT_APP_API_CONFIG, `api/v2/app-display`)
// }

/////////////////////

export const useWsContent = (endpoint, onOpen, onMessage, onClose, onError) => {
  return useApiWebsocket(process.env.REACT_APP_API_CONTENT_WS, endpoint, onOpen, onMessage, onClose, onError)
}

export const useWsCopy = (endpoint, onOpen, onMessage, onClose, onError) => {
  return useApiWebsocket(process.env.REACT_APP_API_COPY_WS, endpoint, onOpen, onMessage, onClose, onError)
}

export const useWsContent2 = endpoint => {
  return useApiWebsocket2(process.env.REACT_APP_API_CONTENT_WS, endpoint)
}

export const useWsContentAuth = endpoint => {
  return useApiWebsocket2(process.env.REACT_APP_API_AUTH_WS, endpoint)
}

/////////////////////

export const useChunkBuildForm = () => {
  return useApiFormState(process.env.REACT_APP_API_TRANSFER, `api/v1/chunk-build`)
}

export const useChunkFileForm = () => {
  return useApiFormState(process.env.REACT_APP_API_TRANSFER, `api/v1/chunk-file`)
}

export const useFileForm = () => {
  return useApiFormState(process.env.REACT_APP_API_TRANSFER, `api/v1/file`)
}

export const useBuildForm = () => {
  return useApiFormState(process.env.REACT_APP_API_TRANSFER, `api/v1/build`)
}

export const useFileBuildForm = () => {
  return useApiFormState(process.env.REACT_APP_API_TRANSFER, `api/v1/file-build`)
}

////////////////////////////////////////////////////////////
// GROUP
////////////////////////////////////////////////////////////

export const useGroupList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/group`)
}

export const useGroupForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/group`)
}

export const useGroupDetailsGet = () => {
  return useApiGetState(process.env.REACT_APP_API_CONTENT, `api/v1/group/details`)
}

////////////////////////////////////////////////////////////
// NODE GROUP
////////////////////////////////////////////////////////////

export const useNodeGroupList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/node-group`)
}

////////////////////////////////////////////////////////////
// GROUP USER
////////////////////////////////////////////////////////////

export const useGroupUserList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/group-user`)
}

////////////////////////////////////////////////////////////
// USER
////////////////////////////////////////////////////////////

export const useUserForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/user`)
}

export const useUserDetailsGet = () => {
  return useApiGetState(process.env.REACT_APP_API_CONTENT, `api/v1/user/details`)
}

////////////////////////////////////////////////////////////
// USER ORG
////////////////////////////////////////////////////////////

// export const useUserOrgList = () => {
//   return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/user-org`)
// }

// export const useUserOrgForm = () => {
//   return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/user-org`)
// }

////////////////////////////////////////////////////////////
// USER LOGIN
////////////////////////////////////////////////////////////

//REMOVE WHEN CLEANED UP FROM SYSTEM

// export const useUserLoginList = () => {
//   return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/user-login`)
// }

// export const useUserLoginForm = () => {
//   return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/user-login`)
// }

// export const useUserLoginGet = () => {
//   return useApiGetState(process.env.REACT_APP_API_CONTENT, `api/v1/user-login`)
// }

////////////////////////////////////////////////////////////
// NODE USER
////////////////////////////////////////////////////////////

export const useNodeUserList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/node-user`)
}

////////////////////////////////////////////////////////////
// ROLE
////////////////////////////////////////////////////////////

export const useRoleList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/role`)
}

export const useRoleTypeList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/role-type`)
}

export const useRoleOrgList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/role-org`)
}

export const useRoleForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/role`)
}

export const useRoleScopeList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/role-scope`)
}

export const useRoleAccessList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/role-access`)
}

export const useRoleAccessForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/role-access`)
}

export const useRoleAccessAuditList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/role-access-audit`)
}

export const useRoleSyncLoginList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/role-sync-login`)
}

export const useRoleSyncUserOrgList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/role-sync-user-org`)
}

// export const useRoleSyncUserSysList = () => {
//   return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/role-sync-user-sys`)
// }

export const useRoleAccessUserList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/role-access-user`)
}

export const useRoleAccessLoginList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/role-access-login`)
}

export const useRoleUserList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/role-user`)
}

////////////////////////////////////////////////////////////
// META QUERY
////////////////////////////////////////////////////////////

export const useNodeQueryList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/node-meta-query`)
}

export const useContentList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/node-meta-query/datatype:category,course,streaming_video`)
}

export const useAssetMetaQueryList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/asset-meta-query`)
}

////////////////////////////////////////////////////////////
// CONTENT
////////////////////////////////////////////////////////////

export const useContentNodeList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/content/node`)
}

export const useContentAssetList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/content/asset`)
}

////////////////////////////////////////////////////////////
// ORGANIZATION
////////////////////////////////////////////////////////////

export const useOrganizationList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/organization`)
}

export const useOrganizationForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/organization`)
}

export const useOrganizationDetailsGet = () => {
  return useApiGetState(process.env.REACT_APP_API_CONTENT, `api/v1/organization/detail`)
}

export const useWhiteLabelForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/white-label`)
}

////////////////////////////////////////////////////////////
// USER ANALYTIC
////////////////////////////////////////////////////////////

// export const useUserAnalyticList = () => {
//   return useApiListState(process.env.REACT_APP_API_ANALYTICS, `api/v1/user-analytic`)
// }

export const useUserAnalyticForm = () => {
  return useApiFormState(process.env.REACT_APP_API_ANALYTICS, `api/v1/user-analytic`)
}

export const useAnalyticReportList = () => {
  return useApiListState(process.env.REACT_APP_API_ANALYTICS, `api/v1/report`)
}

////////////////////////////////////////////////////////////
// INVITE
////////////////////////////////////////////////////////////

export const useInviteForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/invite`)
}

////////////////////////////////////////////////////////////
// INVITE USER
////////////////////////////////////////////////////////////

export const useInviteUserForm = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/invite-user`)
}

////////////////////////////////////////////////////////////
// HEATMAP USER SESSION
////////////////////////////////////////////////////////////

export const useUserHeatmapSessionForm = () => {
  return useApiFormState(process.env.REACT_APP_API_HEATMAP, `api/v1/user-session`)
}

////////////////////////////////////////////////////////////
// AUTH USER STATUS
////////////////////////////////////////////////////////////

//remove
export const useAuthUserStatusList = () => {
  return useApiListState(process.env.REACT_APP_API_AUTH, `api/v1/user-status`)
}

//remove
export const useAuthUserStatusForm = () => {
  return useApiFormState(process.env.REACT_APP_API_AUTH, `api/v1/user-status`)
}

export const useLoginAuthStatusList = () => {
  return useApiListState(process.env.REACT_APP_API_AUTH, `api/v1/login-auth-status`)
}

export const useLoginAuthStatusForm = () => {
  return useApiFormState(process.env.REACT_APP_API_AUTH, `api/v1/login-auth-status`)
}

////////////////////////////////////////////////////////////
// ASSESSMENT RESULT USER
////////////////////////////////////////////////////////////

export const useAssessmentResultUserList = () => {
  return useApiListState(process.env.REACT_APP_API_ASSESSMENT, `api/v1/result-assessment-user`)
}

export const useAssessmentResultUserForm = () => {
  return useApiFormState(process.env.REACT_APP_API_ASSESSMENT, `api/v1/result-assessment-user`)
}

////////////////////////////////////////////////////////////
// ASSESSMENT - RESULT ASSESSMENT
////////////////////////////////////////////////////////////

export const useAssessmentResultDetailList = () => {
  return useApiListState(process.env.REACT_APP_API_ASSESSMENT, `api/v1/result-assessment`)
}

export const useAssessmentResultDetailForm = () => {
  return useApiFormState(process.env.REACT_APP_API_ASSESSMENT, `api/v1/result-assessment`)
}

////////////////////////////////////////////////////////////
// AUTH - LOGIN PROVIDER
////////////////////////////////////////////////////////////

export const useLoginProviderList = () => {
  return useApiListState(process.env.REACT_APP_API_AUTH, `api/v1/login-provider`)
}

////////////////////////////////////////////////////////////
// DASHBOARD - COURSE
////////////////////////////////////////////////////////////

export const useSessionList = () => {
  return useApiListState(process.env.REACT_APP_API_DASHBOARD, `api/v1/session/course`)
}

export const useSessionCourseList = () => {
  return useApiListState(process.env.REACT_APP_API_DASHBOARD, `api/v1/session/course`)
}

export const usePlatformDashboardList = () => {
  return useApiListState(process.env.REACT_APP_API_DASHBOARD, `api/v1/platform/course`)
}

export const usePermissionDashboardGet = () => {
  return useApiGetState(process.env.REACT_APP_API_DASHBOARD, `api/v1/permission/course`)
}

////////////////////////////////////////////////////////////
// DASHBOARD - COURSE V2
////////////////////////////////////////////////////////////

export const useCoursePermissionDashboardGet = () => {
  return useApiGetState(process.env.REACT_APP_API_DASHBOARD, `api/v2/course/permission`)
}

export const useCoursePlatformDashboardList = () => {
  return useApiListState(process.env.REACT_APP_API_DASHBOARD, `api/v2/course/platform`)
}

export const useCourseSessionList = () => {
  return useApiListState(process.env.REACT_APP_API_DASHBOARD, `api/v2/course/session`)
}

////////////////////////////////////////////////////////////
// AUTH - AUTH STATUS
////////////////////////////////////////////////////////////
export const useAuthStatusList = () => {
  return useApiListState(process.env.REACT_APP_API_AUTH, `api/v1/auth-status`)
}

export const useAuthStatusForm = () => {
  return useApiFormState(process.env.REACT_APP_API_AUTH, `api/v1/auth-status`)
}

////////////////////////////////////////////////////////////
// CONTENT - SENDGRID EVENT
////////////////////////////////////////////////////////////
// export const useSendgridEventList = () => {
//   return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/sendgrid-event`)
// }

// export const useSendgridEventForm = () => {
//   return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/sendgrid-event`)
// }

////////////////////////////////////////////////////////////
// CONTENT - LOG ERROR
////////////////////////////////////////////////////////////
export const useLogErrorList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/log-error`)
}

export const useLogErrorForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/log-error`)
}

export const useLogErrorNotifyList = () => {
  return useApiListState(process.env.REACT_APP_API_CONTENT, `api/v1/log-error-notify`)
}

export const useLogErrorNotifyForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/log-error-notify`)
}

////////////////////////////////////////////////////////////
// ASSESSMENT
////////////////////////////////////////////////////////////

export const useManageAssessmentList = () => {
  return useApiListState(process.env.REACT_APP_API_ASSESSMENT, `api/v1/manage-assessment`)
}

export const useManageAssessmentForm = () => {
  return useApiFormState(process.env.REACT_APP_API_ASSESSMENT, `api/v1/manage-assessment`)
}

export const useManageAssessmentAssignmentList = () => {
  return useApiListState(process.env.REACT_APP_API_ASSESSMENT, `api/v1/manage-assessment-assignment`)
}

export const useAssessmentDetailsGet = () => {
  return useApiGetState(process.env.REACT_APP_API_ASSESSMENT, `api/v1/assessment/details`)
}

export const useAssessmentResultList = () => {
  return useApiListState(process.env.REACT_APP_API_ASSESSMENT, `api/v1/assessment-result`)
}

export const useAssessmentReportList = () => {
  return useApiListState(process.env.REACT_APP_API_ASSESSMENT, `api/v1/assessment-report`)
}
