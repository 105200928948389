import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormText, FormSubmit } from '../../../../../../shared/form/form'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { IconEdit, IconList } from '../../../../../../shared/icon/icon'
import { Menu, MenuActivityLog, MenuDelete, MenuItem } from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useRoleSysApp } from '../../../../../../role/hook/role-sys'
import { useApiFormApp } from '../../../../../../api-new/state/config'

export const AppEdit = () => {
  const { appID } = useParamsInt()

  const apiFormApp = useApiFormApp()

  const handlePath = () => apiFormApp.get(appID)

  const handleSubmit = data => apiFormApp.put(data.id, data)

  const roleSysApp = useRoleSysApp()

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconList} text="stores" to={`/admin/app/edit/${appID}/store`} show={roleSysApp.accessCreate} pin />
      <MenuDelete to={`/admin/app/edit/${appID}/delete`} show={roleSysApp.accessDelete} />
      <MenuActivityLog to="/admin/app/list/activity-all" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={apiFormApp.form.name} onPath={handlePath}>
        <CardFullHeightScrollY title={apiFormApp.form.name} HeaderMenu={HeaderMenu}>
          <Form state={apiFormApp.form} onSubmit={handleSubmit}>
            <FormText label="name" name="name" />
            <FormSubmit text="save app" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
