import { useMemo } from 'react'
import { GridItem } from '../common/shared/grid-item'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { NodeLibraryMenuPartial } from './common/node-library-menu'
import { IconLibrary } from '../../../../../shared/icon/icon'

export const NodeLibraryGridItem = ({ node }) => {
  const { url } = useRouteMatch()

  const to = useMemo(() => `${url}/library/${node.id}`, [url, node])

  const FooterMenu = () => NodeLibraryMenuPartial(`${url}/library/${node.id}`)

  return <GridItem nodeID={node.id} Icon={IconLibrary} FooterMenu={FooterMenu} title={node.name} to={to} />
}
