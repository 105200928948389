import React, { useRef } from 'react'
import { IconAnalyticCustom } from '../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import {
  Column,
  ColumnDateTime,
  ColumnFilter,
  ColumnFormat,
  ColumnYesNo,
  TableBuilder
} from '../../../../../shared/table-builder/table-builder'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListChunkReportCustomOrg } from '../../../../../api-new/state/analytics'
import { Menu, MenuExportCsv, MenuExportExcel } from '../../../../../shared/menu/menu'
import { useParseJSON } from '../course/_common/hook'

export const AnalyticOrgCustom = () => {
  const apiListChunkReportCustomOrg = useApiListChunkReportCustomOrg()

  const handleReady = () => apiListChunkReportCustomOrg.get(1000)

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  let parseValue = useParseJSON()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAnalyticCustom} text="ANALYTICS CUSTOM">
        <CardFullHeightScrollY title="Custom Analytics" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListChunkReportCustomOrg} sortField="sessionID" sortDir="desc" onReady={handleReady}>
            <ColumnDateTime field="capturedAt" sortable filter />
            <Column field="sessionID" sortable />
            <ColumnFilter field="nodeName" header="Course" sortable />

            <ColumnFilter field="topicName" sortable filter />
            <ColumnFilter field="topicDescription" sortable filter />
            <ColumnFilter field="analyticName" sortable filter />

            <ColumnFormat field="value" sortable filter selector={row => parseValue(row.value)} />
            <ColumnFilter field="type" sortable filter />

            <Column field="email" sortable />
            <Column field="firstName" sortable />
            <Column field="lastName" sortable />

            <ColumnDateTime field="startOn" sortable />
            <ColumnDateTime field="endOn" sortable />

            <ColumnFilter field="os" sortable filter />
            <ColumnFilter field="platform" sortable filter />
            <Column field="appVersion" sortable filter />

            <ColumnFilter field="deviceModel" sortable filter />
            <ColumnFilter field="deviceType" sortable filter />
            <ColumnFilter field="vrDeviceModel" sortable filter />
            <ColumnFilter field="vrDeviceName" sortable filter />
            <Column field="vrDevicePresent" sortable filter />
            <Column field="vrDeviceTrackingOriginMode" sortable filter />
            <Column field="vrDeviceTrackingSpaceType" sortable filter />
            <Column field="vrDeviceRefreshRate" sortable filter />
            <ColumnFilter field="graphicsCard" sortable filter />
            <Column field="graphicsCardMemorySize" sortable filter />
            <ColumnFilter field="graphicsCardVendor" sortable filter />
            <ColumnFilter field="processor" sortable filter />
            <Column field="processorCount" sortable filter />
            <Column field="processorFrequency" sortable filter />
            <Column field="systemMemorySize" sortable filter />
            <ColumnYesNo field="isMultiPlayer" sortable filter />

            <Column field="locationCountry" sortable filter />
            <Column field="locationRegion" sortable filter />
            <Column field="locationCity" sortable filter />

            <Column field="groups" sortable />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
