import React from 'react'
import { Route } from 'react-router-dom'
import { RedirectContentEdit } from '../../view/redirect/redirect-content-edit'
import { RedirectContentRoot } from '../../view/redirect/redirect-content-root'
import { RedirectCourseDetails } from '../../view/redirect/redirect-course-details'
import { RedirectImpersonate } from '../../view/redirect/redirect-impersonate'

export const RouteRedirect = () => {
  return (
    <React.Fragment>
      <Route path="/redirect/content-root/:refreshToken?" component={RedirectContentRoot} />
      <Route path="/redirect/content-edit/:nodeID/:refreshToken?" component={RedirectContentEdit} />
      <Route path="/redirect/course-details/:nodeID/:refreshToken?" component={RedirectCourseDetails} />
      <Route path="/redirect/impersonate/:refreshToken" component={RedirectImpersonate} />
    </React.Fragment>
  )
}
