import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { IconList } from '../../shared/icon/icon'
// import { useUserSessionList } from './state'
// import { IconButtonView } from '../../shared/icon/icon-button'
// import { TableFilterCard } from '../../shared-new/table-filter/table-filter-card'
// import { ButtonConfirmDelete } from '../../shared-new/component/button'
// import moment from 'moment'
import { CardFullHeightScrollY } from '../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnDateTime, ColumnMenu, ColumnFormat } from '../../shared/table-builder/table-builder'
import { Menu, MenuDelete, MenuItem } from '../../shared/menu/menu'
import { useApiListHeatmapUserSession } from '../../api-new/state/heatmap'
import { IconEnter } from '../../shared/icon/icon'

export const SessionUserList = () => {
  const { userID } = useParams()
  const { pathname } = useLocation()

  const apiListHeatmapUserSession = useApiListHeatmapUserSession()

  const handleMatch = async () => await apiListHeatmapUserSession.get(`${userID}`)

  const handleDelete = async id => {
    await apiListHeatmapUserSession.delete(id)
    await apiListHeatmapUserSession.get(`${userID}`)
  }

  // let links = item =>
  //   <React.Fragment>
  //     <IconButtonView Icon={IconEnter} small to={`${pathname}/series/${item.sessionID}`} />
  //     <ButtonConfirmDelete confirmValue={item.sessionID} small onConfirm={handleDelete} />
  //   </React.Fragment>

  // let columns = [
  //   { name: 'Name', selector: 'nodeName', grow: 1, sortable: true },
  //   { name: 'Began At', selector: 'beganAt', grow: 1, sortable: true, cell: row => moment(row['beganAt']).format('lll') },
  //   { name: 'Ended At', selector: 'endedAt', grow: 1, sortable: true, cell: row => moment(row['endedAt']).format('lll') },
  //   {
  //     name: 'Duration',
  //     grow: 1,
  //     sortable: true,
  //     cell: row => {
  //       var diff = moment(row['endedAt']) - moment(row['beganAt'])
  //       var duration = moment.duration(diff).asMilliseconds()
  //       return moment.utc(duration).format('HH:mm:ss')
  //     }
  //   },
  //   { name: '', right: true, grow: 1, sortable: false, wrap: false, cell: row => links(row) }
  // ]

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="heatmaps" onMatch={handleMatch}>
        <CardFullHeightScrollY title="user heatmaps">
          <TableBuilder apiList={apiListHeatmapUserSession}>
            <Column field="nodeName" header="name" sortable filter />
            <ColumnDateTime field="beganAt" header="Began At" sortable filter />
            <ColumnDateTime vafieldlue="endedAt" header="Ended At" sortable filter />
            <ColumnFormat field="duration" selector={row => 'ADD DURATION'} />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem text="view" Icon={IconEnter} to={`${pathname}/series/${row.sessionID}`} show pin />
                  <MenuDelete onClick={() => handleDelete(row.sessionID)} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>

        {/* <TableFilterCard title="user heatmaps" columns={columns} state={userSessionListState} /> */}
      </BreadcrumbView>
    </React.Fragment>
  )
}
