import React from 'react'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Menu, MenuDelete } from '../../../../../shared/menu/menu'
import { Form, FormNumber, FormSubmitBack, FormText } from '../../../../../shared/form/form'
import { useApiFormAsset } from '../../../../../api-new/state/content'
// import { FormRawJson } from './form/form-raw-json'
import { StorageFileChunk } from '../../../../../shared/legacy/storage/storage-file-chunk'
import { IconFile } from '../../../../../shared/icon/icon'
import { FormMetadata } from '../../../../../shared/form/form-control/form-metadata'

export const AssetEdit = ({ onDelete }) => {
  const { assetID } = useParamsInt()

  const apiFormAsset = useApiFormAsset()

  const handlePath = () => apiFormAsset.get(assetID)

  const handleDelete = () => onDelete(apiFormAsset.form)

  const handleSubmit = async data => await apiFormAsset.put(data.id, data)

  const headerMenu = () =>
    <Menu>
      <MenuDelete onClick={handleDelete} show pin />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconFile} text={apiFormAsset.form.name} onPath={handlePath}>
        <CardFullHeightScrollY title={apiFormAsset.form.name} HeaderMenu={headerMenu}>
          <Form state={apiFormAsset.form} onSubmit={handleSubmit}>
            <FormText label="name" name="name" required />
            <FormText label="key" name="key" readOnly />
            <FormText label="version" name="version" readOnly />
            <FormNumber label="size" name="length" />
            {/* <FormRawJson label="metadata" name="metaData" /> */}
            <FormMetadata label="metadata" name="metaData" />
            <FormSubmitBack />
          </Form>
          <StorageFileChunk storageKey={apiFormAsset.form.key} />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
