import React from 'react'
import { Route } from 'react-router-dom'
import { Delete } from '../../../../shared/page/page-delete'
import { RoleOrgAdd } from './page/role-org-add'
import { RoleOrgEdit } from './page/role-org-edit'
import { RoleOrgList } from './page/role-org-list'
import { RoleOrgListUser } from './page/role-org-list-user'
import { useApiFormRole } from '../../../../api-new/state/content'

export const LayoutRoleOrg = () => {
  return (
    <React.Fragment>
      <Route path="/root/role-org/list" component={RoleOrgList} />
      <Route path="/root/role-org/list/add" component={RoleOrgAdd} />
      <Route path="/root/role-org/list/edit/:roleOrgID" component={RoleOrgEdit} />

      <Route path="/root/role-org/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormRole} titleParam="role" successPath="/root/role-org/list" />
      </Route>

      <Route path="/root/role-org/list/user/:roleOrg" component={RoleOrgListUser} />
    </React.Fragment>
  )
}
