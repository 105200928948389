import React, { useState } from 'react'
import { Draggable, DragHandle, Droppable } from '../../../../../../shared/legacy/drag-drop/drag-drop'
import { IconGrip } from '../../../../../../shared/icon/icon'

export const Input = ({
  ddGroup,
  index,
  pos,
  correctChoice,
  value,
  placeholder,
  limit,
  muteSubmit,
  draggable,
  readOnly,
  onChange,
  onEnter,
  onDrop,
  Actions
}) => {
  const [focus, updateFocus] = useState(false)

  const handleChange = e => onChange && onChange(e.target.value)
  const handleKeyDown = e => muteSubmit && e.code === 'Enter' && e.preventDefault()
  const handleKeyUp = e => e.code === 'Enter' && onEnter && onEnter()

  return (
    <React.Fragment>
      <Draggable draggable={draggable} dragID={index} dragGroup={ddGroup}>
        <div className="input-group">
          {draggable &&
            <span className="input-group-text">
              <DragHandle>
                <Droppable onDrop={onDrop} dropGroup={ddGroup}>
                  <span className="">
                    <IconGrip />
                  </span>
                </Droppable>
              </DragHandle>
            </span>}

          {pos !== undefined &&
            <span className="input-group-text" style={{ width: '35px' }}>
              {pos}
            </span>}

          {correctChoice !== undefined && <span className={`input-group-text ${correctChoice ? 'bg-success' : 'bg-danger'}`} />}

          <input
            className="form-control"
            value={value}
            placeholder={placeholder}
            readOnly={readOnly}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            onFocus={() => updateFocus(true)}
            onBlur={() => updateFocus(false)}
            maxLength={limit}
          />

          {Actions &&
            <span className="input-group-text">
              <Actions />
            </span>}
        </div>

        {focus &&
          limit &&
          <div className="text-end px-2">
            {value.length}/{limit}
          </div>}
      </Draggable>
    </React.Fragment>
  )
}
