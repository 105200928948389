import React, { useEffect, useState } from 'react'
import { useGroupUserList } from '../../../../../../api/state'
import { IconLinkEdit } from '../../../../../../shared/icon/icon-link'
import { Card } from '../../../../../../shared/card/card'

export default function Component({ groupID }) {
  const [groupUserListActions, groupUserListState] = useGroupUserList()

  const [userCount, updateUserCount] = useState(0)
  const [users, updateUsers] = useState([])

  useEffect(() => {
    groupUserListActions.get(`${groupID}/group`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      let length = groupUserListState.items.length
      updateUserCount(length)
      updateUsers(groupUserListState.items.map((e, i) => `${e.email}${i === length - 1 ? '' : ', '}`))
    },
    [groupUserListState.items]
  )

  const HeaderComponent = () => <IconLinkEdit to={`/org/group/list/edit/${groupID}/user`} />

  return (
    <React.Fragment>
      <Card title={`Users - ${userCount || 'none'}`} HeaderComponent={HeaderComponent}>
        {users}
      </Card>
    </React.Fragment>
  )
}
