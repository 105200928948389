import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormText, FormSubmitBack } from '../../../../../shared/form/form'
import { useProviderForm } from '../../../../../api/state'
import { Card } from '../../../../../shared/card/card'
import { IconEdit } from '../../../../../shared/icon/icon'

export const ProviderAdd = () => {
  const history = useHistory()

  const [providerFormActions] = useProviderForm()

  const handleSubmit = async data => {
    let provider = await providerFormActions.post(data)
    history.push(`/admin/provider/list/edit/${provider.id}`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="add">
        <Card title="provider">
          <Form onSubmit={handleSubmit}>
            <FormText label="provider name" name="providerName" required autoFocus />
            <FormSubmitBack text="add provider" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
