import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconSetup } from '../../../../../../shared/icon/icon'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { AnalyticProvider } from '../../../context/provider-analytic'
import { AnalyticNode } from '../../../controls/analytic-node'
import { AnalyticTopicNode } from '../../../controls/analytic-topic-node'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'

export const CourseAnalyticSetup = () => {
  const { nodeID } = useParamsInt()

  return (
    <BreadcrumbView Icon={IconSetup} text="setup">
      <CardFullHeightScrollY title="Course Analytic Setup">
        <AnalyticProvider>
          <AnalyticTopicNode nodeID={nodeID} />
          <AnalyticNode nodeID={nodeID} />
        </AnalyticProvider>
      </CardFullHeightScrollY>
    </BreadcrumbView>
  )
}
