import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Seperator } from './seperator'
import { Question } from './question'
import { Assignments } from './assignments'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { IconDetails } from '../../../../shared/icon/icon'
import { useManageAssessmentForm, useManageAssessmentAssignmentList } from '../../../../api/state'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'

export const AssessmentView = () => {
  const { assessmentID } = useParams()

  const [manageAssessmentFormActions, manageAssessmentFormState] = useManageAssessmentForm()
  const [manageAssessmentStepObjectiveActions, manageAssessmentStepObjectiveState] = useManageAssessmentAssignmentList()

  const handleMatch = async () => {
    manageAssessmentFormActions.get(assessmentID)
    manageAssessmentStepObjectiveActions.get(assessmentID)
  }

  const type = useMemo(() => manageAssessmentFormState.form.type || 'quiz', [manageAssessmentFormState.form.type])
  const allowReview = useMemo(() => (manageAssessmentFormState.form.allowReview ? 'Yes' : 'No'), [
    manageAssessmentFormState.form.allowReview
  ])
  const passingScore = useMemo(() => manageAssessmentFormState.form.passingScore || 'No Score', [
    manageAssessmentFormState.form.passingScore
  ])
  const allowedAttempts = useMemo(() => manageAssessmentFormState.form.allowedAttempts || 'Unlimited', [
    manageAssessmentFormState.form.allowedAttempts
  ])

  const isQuiz = useMemo(() => manageAssessmentFormState.form.type !== 'survey', [manageAssessmentFormState.form.type])

  const questions = useMemo(
    () => (manageAssessmentFormState.form.contentMeta ? JSON.parse(manageAssessmentFormState.form.contentMeta) : []),
    [manageAssessmentFormState.form.contentMeta]
  )

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDetails} text="Details" onMatch={handleMatch}>
        <CardFullHeightScrollY title={manageAssessmentFormState.form.title}>
          <Seperator label="type" value={type} />
          <Seperator label="title" value={manageAssessmentFormState.form.title} />
          <Seperator label="introduction" value={manageAssessmentFormState.form.introduction} />

          <Seperator label="questions">
            {questions.map((question, i) =>
              <div className="pt-2">
                <Question key={i} pos={i + 1} question={question} showCorrect={isQuiz} />
              </div>
            )}
          </Seperator>

          {isQuiz &&
            <React.Fragment>
              <Seperator label="allow review" value={allowReview} />
              <Seperator label="passing score" value={passingScore} />
            </React.Fragment>}

          <Seperator label="allow attempts" value={allowedAttempts} />

          <Assignments assignments={manageAssessmentStepObjectiveState.items} />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
