import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { Filter, useFilter } from '../../../../../shared/legacy/filter'
import { AuthStatus } from '../../../../../shared/legacy/auth-status'
import { useAuthStatusList } from '../../../../../api/state'
import { Card } from '../../../../../shared/card/card'

export const AuthStatusLogin = () => {
  const { loginID } = useParams()

  const [authStatusListActions, authStatusListState] = useAuthStatusList()

  const name = useMemo(() => (authStatusListState.items.length ? authStatusListState.items[0].login : 'login'), [authStatusListState.items])

  const handleMatch = () => authStatusListActions.get(`${loginID}/login`)

  const handleDelete = async refreshToken => {
    await authStatusListActions.delete(refreshToken)
    await authStatusListActions.get(`${loginID}/login`)
  }

  const [filteredItems, filter, updateFilter] = useFilter(authStatusListState.items)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text={name} onMatch={handleMatch}>
        <Card title={name}>
          <Filter filter={filter} onChange={updateFilter} />
          {filteredItems.map((item, i) => <AuthStatus key={i} authStatus={item} onDelete={handleDelete} />)}
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
