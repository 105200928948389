import React from 'react'
import { useItemTypeTitle, useItemTypeTextColor } from './hooks'

export const TypeModal = ({ state, onClose }) => {
  const handleClose = id => onClose && onClose(id)

  return (
    <React.Fragment>
      {state.map((item, i) => <ModalItem key={i} id={item.id} data={item.data} onClose={handleClose} />)}
    </React.Fragment>
  )
}

const ModalItem = ({ id, data, onClose }) => {
  const handleClose = () => onClose && onClose(id)

  const title = useItemTypeTitle(data)
  const textColor = useItemTypeTextColor(data)

  return (
    <div id="modal-confirm" className="modal show" tabIndex="-1" style={{ display: 'block' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className={'modal-header'}>
            <div className={`modal-title ${textColor} fw-bold`}>
              {title}
            </div>
          </div>
          <div className="modal-body">
            <p>
              {data.text}
            </p>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary text-capitalize" onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
