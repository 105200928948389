export const arrayInit = () => {
  // eslint-disable-next-line no-extend-native
  Array.prototype.first = function() {
    return this.length ? this[0] : null
  }

  // eslint-disable-next-line no-extend-native
  Array.prototype.last = function() {
    return this.length ? this[this.length - 1] : null
  }

  // eslint-disable-next-line no-extend-native
  Array.prototype.forEachLast = function(callback) {
    for (let i = 0; i < this.length; i++) {
      callback(this[i], i === this.length - 1)
    }
  }
}

// eslint-disable-next-line no-extend-native
Date.prototype.getUnixStartTicks = function() {
  return Math.floor(this.setHours(0, 0, 0) / 1000)
}

// eslint-disable-next-line no-extend-native
Date.prototype.getUnixEndTicks = function() {
  return Math.floor(this.setHours(23, 59, 59) / 1000)
}
