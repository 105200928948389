import React from 'react'
import { useApiListAnalyticTopic } from '../../../../api-new/state/analytics'
import { useAnalyticContext } from '../context/provider-analytic'
import { useAnalyticParentCourse } from '../hooks'
import { TableEdit } from '../../../../shared/component/table-edit'
import { Form, FormHiddenNumber, FormHiddenText, FormSubmitCancel, FormText, FormTextArea } from '../../../../shared/form/form'

export const AnalyticTopicNode = ({ nodeID }) => {
  const apiListAnalyticTopic = useApiListAnalyticTopic()

  const analyticContext = useAnalyticContext()

  const analyticParentCourse = useAnalyticParentCourse()

  const handleLoad = async () => {
    await apiListAnalyticTopic.get(`${nodeID}/node`)
    analyticContext.update('topic')
  }

  const confirmDelete = data => {
    if (data.analyticCount) return 'This topic has child analytics, are you sure you want to delete?'
    else return null
  }

  return (
    <TableEdit
      title="topics"
      columns={[{ header: 'name', value: 'name', filter: true }, { header: 'description', value: 'description', filter: true }]}
      apiList={apiListAnalyticTopic}
      formArgs={{ nodeID: nodeID, analyticParentCourseID: analyticParentCourse.id }}
      sortBy="name"
      FormAdd={FormEdit}
      FormEdit={FormEdit}
      onLoad={handleLoad}
      onAdd
      onEdit
      onDelete
      onRestore
      onPurge
      fnConfirmDelete={confirmDelete}
    />
  )
}

const FormEdit = ({ row, nodeID, analyticParentCourseID, onSubmit, onClose }) => {
  return (
    <Form state={row} onSubmit={onSubmit}>
      <div className="row">
        <FormHiddenNumber name="analyticParentID" value={analyticParentCourseID} />
        <FormHiddenText name="parentID" value={nodeID} />
        <FormText className="col-12 col-xl-6" label="name" name="name" small autoFocus />
        <FormTextArea className="col-12 col-xl-6" label="description" name="description" small />
        <FormSubmitCancel small onCancel={onClose} />
      </div>
    </Form>
  )
}
