import { useMemo } from 'react'
import { ModalDialog } from '../../../../../../shared/component/modal-dialog'
import { ButtonPrimary } from '../../../../../../shared/component/button'

export const InviteModalProgress = ({ target, progress, onContinue }) => {
  const value = useMemo(() => `${Math.floor(progress)}%`, [progress])
  const disabled = useMemo(() => progress !== 100, [progress])

  return (
    <ModalDialog header="Add User Progress" target={target} backdropStatic>
      <div>
        <div className="progress" role="progressbar">
          <div className="progress-bar" style={{ width: value }}>
            {value}
          </div>
        </div>
      </div>
      <div className="m-1">
        <ButtonPrimary text="Continue" disabled={disabled} onClick={onContinue} />
      </div>
    </ModalDialog>
  )
}
