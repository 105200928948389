import React, { useMemo } from 'react'
import { SelectList } from '../select-list/select-list'
import { useAddUserContext } from '../context'
import { IconTemplate } from '../../../../../../../shared/icon/icon'
import { IconButtonClick } from '../../../../../../../shared/icon/icon-button-click'
import { useButtonCount } from './hooks'

export const SelectTemplateDropdown = ({ selectedTemplates, readonlyTemplates, disabled, onClick }) => {
  const count = useButtonCount(selectedTemplates, readonlyTemplates)
  const text = useMemo(() => (count ? `templates (${count})` : 'templates'), [count])
  return (
    <button className="dropdown-item" disabled={disabled} onClick={onClick}>
      <span className="me-1">
        <IconTemplate />
      </span>
      <span>
        {text}
      </span>
    </button>
  )
}

export const SelectTemplateButton = ({ selectedTemplates, readonlyTemplates, small, disabled, onClick }) => {
  const count = useButtonCount(selectedTemplates, readonlyTemplates)
  const text = useMemo(() => (count ? `templates (${count})` : 'templates'), [count])
  return <IconButtonClick Icon={IconTemplate} text={text} disabled={disabled} small={small} onClick={onClick} />
}

export const SelectTemplateModal = ({ target, selectedTemplates, readonlyTemplates, onUpdate, onHide }) => {
  const addUserContext = useAddUserContext()

  const handleClose = () => onHide()

  const onAdd = item => {
    let newList = [...selectedTemplates, item]
    onUpdate(newList)
  }

  const onRemove = item => {
    let newList = selectedTemplates.filter(i => i.id !== item.id)
    onUpdate(newList)
  }

  return (
    <React.Fragment>
      <SelectList
        header="Templates"
        selectedItems={selectedTemplates}
        readonlyItems={readonlyTemplates}
        items={addUserContext.allTemplates}
        modalTarget={target}
        fnListFormat={item => item.path}
        fnListFilter={(filter, items) => items.filter(i => i.path.toLowerCase().includes(filter))}
        onAdd={onAdd}
        onRemove={onRemove}
        onClose={handleClose}
      />
    </React.Fragment>
  )
}
