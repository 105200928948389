import React, { useMemo } from 'react'
import { Card } from '../../../card/card'

export const Body = ({ body }) => {
  let value = useMemo(() => (body ? JSON.stringify(body, undefined, 3) : ''), [body])

  return (
    <React.Fragment>
      {value &&
        <Card title="body">
          <pre className="form-control m-1">
            {value}
          </pre>
        </Card>}
    </React.Fragment>
  )
}
