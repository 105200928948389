import React from 'react'
import { useHistory } from 'react-router-dom'
import { useApiFormNodeLibrary } from '../../../../../../api-new/state/content'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormSubmitBack, FormTextConfirm } from '../../../../../../shared/form/form'
import { IconDelete } from '../../../../../../shared/icon/icon'
import { useDataContext } from '../../data-context'
import { NodeLibraryMenuFull } from '../common/node-library-menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'

export const NodeLibraryDelete = ({ grandParentURL, parentURL, nodeID }) => {
  const history = useHistory()

  const dataContext = useDataContext()

  const apiFormNodeLibrary = useApiFormNodeLibrary()

  const handleMatch = async () => await apiFormNodeLibrary.get(nodeID)

  const handleSubmit = async () => {
    await apiFormNodeLibrary.delete(nodeID)
    dataContext.nodeRemoveID(nodeID)
    history.replace(grandParentURL)
  }

  const HeaderMenu = () => NodeLibraryMenuFull(parentURL)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text="delete" onMatch={handleMatch}>
        <CardFullHeightScrollY title={apiFormNodeLibrary.form.name} HeaderMenu={HeaderMenu}>
          <div className="py-2">This is a destructive action</div>

          <Form onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="delete" subtext="Type delete to confirm" confirmValue="delete" name="test" required autoFocus />
            <FormSubmitBack text="delete asset" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
