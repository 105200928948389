import React, { useMemo, useState } from 'react'
import { IconClickClose } from '../../shared/icon/icon-click'
import { useItemTypeAlertColor } from './hooks'
import { useNotificationContext } from './context'

export const useNotificationAlertNotify = (defaultText, defaultClose) => {
  const notificationContext = useNotificationContext()
  const [state, updateState] = useState()

  return useMemo(
    () => {
      return {
        show: (text, close) =>
          updateState(notificationContext.alert.notify(text || defaultText, close === undefined ? defaultClose : close)),
        hide: () => state && state.close()
      }
    },
    [notificationContext, state, defaultText, defaultClose]
  )
}

export const useNotificationAlertWaring = (defaultText, defaultClose) => {
  const notificationContext = useNotificationContext()
  const [state, updateState] = useState()

  return useMemo(
    () => {
      return {
        show: (text, close) =>
          updateState(notificationContext.alert.warning(text || defaultText, close === undefined ? defaultClose : close)),
        hide: () => state && state.close()
      }
    },
    [notificationContext, state, defaultText, defaultClose]
  )
}

export const useNotificationAlertError = (defaultText, defaultClose) => {
  const notificationContext = useNotificationContext()
  const [state, updateState] = useState()

  return useMemo(
    () => {
      return {
        show: (text, close) =>
          updateState(notificationContext.alert.error(text || defaultText, close === undefined ? defaultClose : close)),
        hide: () => state && state.close()
      }
    },
    [notificationContext, state, defaultText, defaultClose]
  )
}

export const TypeAlert = ({ state, onClose }) => {
  const handleClose = id => onClose && onClose(id)

  return (
    <React.Fragment>
      {state.map((item, i) => <AlertItem key={i} id={item.id} data={item.data} onClose={handleClose} />)}
    </React.Fragment>
  )
}

export const AlertItem = ({ id, data, onClose }) => {
  const handleClose = () => onClose && onClose(id)

  const alertColor = useItemTypeAlertColor(data)

  return (
    <div className={`d-flex alert ${alertColor} fw-bold`}>
      <span className="flex-fill">
        {data.text}
      </span>
      <IconClickClose onClick={handleClose} />
    </div>
  )
}
