import { types } from './action'

export const formInitialState = {
  form: {},
  loading: false,
  submitting: false,
  error: undefined
}

export const formReducer = (state = formInitialState, action) => {
  switch (action.type) {
    case types.INIT:
      return { ...formInitialState }
    case types.ERROR:
      return { ...state, loading: false, submitting: false, error: action.payload }
    case types.SUBMIT:
      return { ...state, loading: false, submitting: true, error: undefined }
    case types.GET_SUCCESS:
      return { ...state, form: action.payload || state.form, loading: false, submitting: false, error: undefined }
    case types.ADD_SUCCESS:
      return { ...state, form: action.payload || state.form, loading: false, submitting: false, error: undefined }
    case types.UPDATE_SUCCESS:
      return { ...state, form: action.payload || state.form, loading: false, submitting: false, error: undefined }
    case types.DELETE_SUCCESS:
      return { ...state, loading: false, submitting: false, error: undefined }
    case types.TEST_SUBMIT:
      return { ...state, loading: false, submitting: true, error: undefined }
    case types.TEST_SUCCESS:
      return { ...state, loading: false, submitting: false, error: undefined }
    default:
      return state
  }
}
