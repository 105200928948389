import React from 'react'
import { useParams, useRouteMatch } from 'react-router'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { IconList } from '../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnDateTime, ColumnMenu, ColumnDuration } from '../../shared/table-builder/table-builder'
import { Menu, MenuDelete, MenuItem } from '../../shared/menu/menu'
import { useApiListHeatmapNodeSession } from '../../api-new/state/heatmap'
import { IconEnter } from '../../shared/icon/icon'

export function SessionNodeList() {
  const { url } = useRouteMatch()
  const { nodeID } = useParams()

  const apiListHeatmapNodeSession = useApiListHeatmapNodeSession()

  const handleMatch = async () => await apiListHeatmapNodeSession.get(nodeID)

  const handleDelete = async id => {
    await apiListHeatmapNodeSession.delete(id)
    await apiListHeatmapNodeSession.get(nodeID)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="sessions" onMatch={handleMatch}>
        <CardFullHeightScrollY title="Sessions">
          <TableBuilder apiList={apiListHeatmapNodeSession}>
            <ColumnDateTime field="beganAt" header="Began At" sortable filter />
            <ColumnDateTime field="endedAt" header="Ended At" sortable filter />
            <ColumnDuration field="duration" beganAt="beganAt" endedAt="endedAt" />

            <Column field="email" sortable filter />
            <Column field="firstName" sortable filter />
            <Column field="lastName" sortable filter />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem text="view" Icon={IconEnter} to={`${url}/series/${row.sessionID}`} show pin />
                  <MenuDelete onClick={() => handleDelete(row.sessionID)} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
