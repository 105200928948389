import axios from 'axios'
import { getAccessToken } from '../../storage/auth'

const getConfig = (config, contentType = 'application/json') => {
  config = config || {}

  let headers = {}

  const accessToken = getAccessToken()

  if (accessToken) {
    headers['Authorization'] = accessToken
  }

  headers['Content-Type'] = contentType
  config['headers'] = headers

  return config
}
function checkAuthRedirect(error) {
  if (error.response.status === 401) {
    window.location = '/auth/email'
  }
}

function formatError(error) {
  if (error.response) {
    if (error.response.data.error) {
      return { error: error.response.data.error, status: error.response.status }
    } else if (error.response.data) {
      return { error: error.response.data, status: error.response.status }
    } else {
      return { error: 'an error occured' }
    }
  } else if (error.request) {
    return error.request
  } else {
    return { error: 'an error occured' }
  }
}

export const getAsync = (server, endpoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${server}/${endpoint}`, getConfig())
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        checkAuthRedirect(error)
        reject(formatError(error))
      })
  })
}

export const deleteAsync = (server, endpoint) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${server}/${endpoint}`, getConfig())
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        checkAuthRedirect(error)
        reject(formatError(error))
      })
  })
}
