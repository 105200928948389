import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { Menu, MenuPurge, MenuRestore } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { useApiListOrganization } from '../../../../../api-new/state/content'
import { useRoleSysOrganization } from '../../../../../role/hook/role-sys'

export function OrganizationRecycle() {
  const apiListOrganization = useApiListOrganization()

  const roleSysOrganization = useRoleSysOrganization()

  const handleReady = async () => await apiListOrganization.get('deleted')

  const handleRestore = async user => {
    await apiListOrganization.put(`${user.id}/restore`)
    await apiListOrganization.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListOrganization} hideFloatingFilter onReady={handleReady}>
            <Column field="name" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show={roleSysOrganization.accessDelete} />
                  <MenuPurge to={`/admin/organization/list/recycle/purge/${row.id}`} show={roleSysOrganization.accessPurge} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
