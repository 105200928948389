export const Grid = ({ children }) => {
  return (
    <div className="overflow-scroll h-100">
      <div className="container">
        <div className="row g-0">
          {children}
        </div>
      </div>
    </div>
  )
}

export const GridItem3 = ({ children }) => {
  return (
    <div className="col-12 col-md-6 col-xxl-4">
      <div className="card m-2">
        <div className="card-body">
          {children}
        </div>
      </div>
    </div>
  )
}

export const GridItem2 = ({ children }) => {
  return (
    <div className="col-12 col-md-12 col-lg-6 col-xxl-6">
      <div className="card m-2">
        <div className="card-body">
          {children}
        </div>
      </div>
    </div>
  )
}
