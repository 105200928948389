import React from 'react'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useDataContext } from '../../data-context'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { IconDelete } from '../../../../../../shared/icon/icon'
import { Form, FormSubmitBack, FormTextConfirm } from '../../../../../../shared/form/form'
import { NodeCategoryMenuFull } from '../common/node-category-menu'

export const NodeCategoryDelete = ({ grandParentURL, parentURL, nodeID, useApiForm }) => {
  const history = useHistory()

  const apiForm = useApiForm()

  const dataContext = useDataContext()

  const handleMatch = async () => await apiForm.get(nodeID)

  const handleSubmit = async () => {
    await apiForm.delete(nodeID)
    dataContext.nodeRemoveID(nodeID)
    history.push(grandParentURL)
  }

  const HeaderMenu = () => NodeCategoryMenuFull(parentURL, apiForm.form)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text="delete" onMatch={handleMatch}>
        <CardFullHeightScrollY title={apiForm.form.name} HeaderMenu={HeaderMenu}>
          <div className="py-2">This is a destructive action</div>
          <Form onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="delete" subtext="Type delete to confirm" confirmValue="delete" name="test" required autoFocus />
            <FormSubmitBack text="delete category" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
