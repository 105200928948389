import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { useApiFormSysAdmin } from '../../../../../api-new/state/content'
import { IconEdit } from '../../../../../shared/icon/icon'
import { RoleSelectLogin } from '../../../../../role/role-select/role-select-login'

export function SysAdminEdit() {
  const { loginID } = useParamsInt('loginID')

  const apiFormSysAdmin = useApiFormSysAdmin()

  const handlePath = () => apiFormSysAdmin.get(loginID)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={`${apiFormSysAdmin.form.email}`} onPath={handlePath}>
        <RoleSelectLogin loginID={loginID} />
      </BreadcrumbView>
    </React.Fragment>
  )
}
