import { useReducer } from 'react'
import { useGetRandomID } from '../../../../../../shared/hooks/random-id'

const ACTION_TYPE = {
  SET_USERS: 1,
  ADD_USER: 2,
  UPDATE_USER: 3,
  DELETE_USER: 4,
  DELETE_USER_IDENTIFIER: 5
}

export const useUserListData = () => {
  let rand = useGetRandomID()

  const [users, dispatch] = useReducer((state, action) => {
    // debugger
    switch (action.type) {
      case ACTION_TYPE.SET_USERS:
        let setUsers = action.users.map(user => {
          user.identifier = rand()
          return user
        })
        return setUsers
      case ACTION_TYPE.ADD_USER:
        action.user.identifier = rand()
        return [...state, action.user]
      case ACTION_TYPE.UPDATE_USER:
        let updateUser = [...state]
        updateUser[action.index] = action.user
        return updateUser
      case ACTION_TYPE.DELETE_USER:
        let deleteUser = [...state]
        deleteUser.splice(action.index, 1)
        return deleteUser
      case ACTION_TYPE.DELETE_USER_IDENTIFIER:
        let deleteUserIdentifier = [...state]
        let deleteUserIdentifierIndex = deleteUserIdentifier.findIndex(i => i.identifier === action.identifier)
        deleteUserIdentifier.splice(deleteUserIdentifierIndex, 1)
        return deleteUserIdentifier
      default:
        return state
    }
  }, [])

  const setUsers = users => dispatch({ type: ACTION_TYPE.SET_USERS, users: users })
  const addUser = user => dispatch({ type: ACTION_TYPE.ADD_USER, user: user })
  const updateUser = (index, user) => dispatch({ type: ACTION_TYPE.UPDATE_USER, index: index, user: user })
  const deleteUser = index => dispatch({ type: ACTION_TYPE.DELETE_USER, index: index })
  const deleteUserIdentifier = identifier => dispatch({ type: ACTION_TYPE.DELETE_USER_IDENTIFIER, identifier: identifier })

  return [users, setUsers, addUser, updateUser, deleteUser, deleteUserIdentifier]
}
