import React, { useContext, useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { AuthContext } from '../../context/auth.context'
import { useBuildRoutePath } from '../hook'
import { useQueryParam } from '../../shared/hooks/location'
import { useDocAuthRedirect } from '../../shared/hooks/doc'

const DocAuth = () => {
  const history = useHistory()
  const authContext = useContext(AuthContext)

  let redirectUrl = useQueryParam('redirect-url')

  const docAuthRedirect = useDocAuthRedirect()

  const buildRoutePath = useBuildRoutePath()

  useEffect(
    () => {
      if (!authContext.state.isAuth) {
        let path = buildRoutePath('/auth/email', { redirectUrl: redirectUrl })
        history.push(path)
      } else if (redirectUrl) {
        docAuthRedirect(redirectUrl)
      } else {
        window.location.href = process.env.REACT_APP_EDXR_DOCS
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [redirectUrl]
  )

  return <React.Fragment />
}

export const RouteDoc = () => {
  return (
    <React.Fragment>
      <Route path="/doc" component={DocAuth} />
    </React.Fragment>
  )
}
