import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { BreadcrumbView, useRouteStack } from '../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../shared/icon/icon'
import { Form, FormText, FormHiddenNumber, FormSubmitCancel } from '../../../../shared/form/form'
import { useParamsInt } from '../../../../shared/hooks/location'
import { useManageAssessmentForm } from '../../../../api/state'
import { Card } from '../../../../shared/card/card'

export function AssessmentManageAdd() {
  const history = useHistory()

  const { nodeID } = useParamsInt()

  const [manageAssessmentFormActions] = useManageAssessmentForm()

  const { backRoute, getStackLevel } = useRouteStack()

  useEffect(() => {
    manageAssessmentFormActions.init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async data => {
    let assessment = await manageAssessmentFormActions.post(data)
    let item = getStackLevel(1)
    history.replace(`${item.url}/edit/${assessment.id}`)
  }

  const handleCancel = () => backRoute(1)

  return (
    <React.Fragment>
      <BreadcrumbView icon={IconAdd} text="add">
        <Card title="Add Assessment">
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="nodeID" value={nodeID} />
            <FormText label="title" name="title" required autoFocus />
            <FormSubmitCancel text="add assessment" onCancel={handleCancel} />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
