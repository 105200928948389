import React from 'react'
import { Choice } from './choice'

export const Question = ({ pos, question, showCorrect }) => {
  return (
    <React.Fragment>
      <div className="input-group">
        <span className="input-group-text" style={{ width: '35px' }}>
          {pos}
        </span>
        <div className="form-control">
          {question.text}
        </div>
      </div>

      <div className="ps-4">
        {question.choices.map((choice, i) =>
          <div className="pt-1">
            <Choice key={i} pos={String.fromCharCode(i + 65)} choice={choice} showCorrect={showCorrect} />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
