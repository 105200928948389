import React from 'react'
import { useHistory } from '../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { IconDelete, IconEdit, IconEnter } from './icon'

const IconLink = ({ Icon, size, to, disabled }) => {
  const history = useHistory()

  const handleClick = () => !disabled && history.push(to)

  return (
    <span className="edxr-pointer" onClick={handleClick}>
      <Icon size={size} />
    </span>
  )
}

export function IconLinkEdit({ to, size, disabled }) {
  return <IconLink Icon={IconEdit} to={to} size={size} disabled={disabled} />
}

export function IconLinkEnter({ to, size, disabled }) {
  return <IconLink Icon={IconEnter} to={to} size={size} disabled={disabled} />
}

export function IconLinkDelete({ to, size, disabled }) {
  return <IconLink Icon={IconDelete} to={to} size={size} disabled={disabled} />
}
