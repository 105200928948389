import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useAuthContext } from '../../context/auth.context'
import { useLoad } from '../../shared/hooks/load'

export const RedirectImpersonate = () => {
  const history = useHistory()
  const { refreshToken } = useParams()
  const authContext = useAuthContext()

  useLoad(
    async () => {
      await authContext.actions.impersonateRefresh(refreshToken)
      history.replace('/')
    },
    [refreshToken]
  )

  return (
    <React.Fragment>
      <div>IMPERSONATE REDIRECT</div>
    </React.Fragment>
  )
}
