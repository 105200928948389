import React, { useMemo, useState } from 'react'
import { useApiFormAuthDevice } from '../../api-new/state/auth'
import { ButtonPrimary, ButtonSecondary } from '../../shared/component/button'
import { ModalDialog, useModalActions } from '../../shared/component/modal-dialog'
import { IconLoginDevice } from '../../shared/icon/icon'

export const AccessPinModal = ({ target }) => {
  const apiFormAuthDevice = useApiFormAuthDevice()

  const [deviceAuth, updateDeviceAuth] = useState('')
  const [showInput, updateShowInput] = useState(true)
  const [result, updateResult] = useState(null)
  const [status, updateStatus] = useState(null)

  const handleOpen = () => {
    updateDeviceAuth('')
    updateShowInput(true)
    updateResult(null)
    updateStatus(null)
  }

  const handleNext = async value => {
    try {
      let result = await apiFormAuthDevice.get(`${value}/find`)
      updateResult(result)
      updateStatus(null)
      updateShowInput(false)
    } catch (exception) {
      updateStatus(exception.error)
    }
  }

  const modalActions = useModalActions(target)

  const handleAllow = async () => {
    try {
      let response = await apiFormAuthDevice.put(deviceAuth)

      updateResult(null)
      updateStatus(response.message)

      setTimeout(() => {
        modalActions.hide()
      }, 1000)
    } catch (exception) {
      updateResult(null)
      updateStatus(exception.error)
    }
  }

  const handleReject = async () => {
    updateResult(null)
    updateStatus(null)
    modalActions.hide()
  }

  return (
    <ModalDialog target={target} Icon={IconLoginDevice} header="Login another device" onOpen={handleOpen}>
      {showInput &&
        <div>
          <InputSearch
            placeholder="code"
            value={deviceAuth}
            submitting={apiFormAuthDevice.submitting}
            onChange={updateDeviceAuth}
            onNext={handleNext}
          />
        </div>}

      {status &&
        <div className="py-1">
          <div className="card">
            <div className="card-body">
              <div className="card-text">
                {status}
              </div>
            </div>
          </div>
        </div>}

      {result &&
        <div className="card">
          <div className="card-body">
            <div className="card-text">
              <div>
                {result.message}
              </div>
              <div className="pt-2">
                <ButtonPrimary text="Login" primary small onClick={handleAllow} />
                <ButtonSecondary text="Cancel" small onClick={handleReject} />
              </div>
            </div>
          </div>
        </div>}
    </ModalDialog>
  )
}

const InputSearch = ({ placeholder, value, submitting, onChange, onNext }) => {
  const handleChange = e => onChange && onChange(e.target.value)
  const handleNext = () => !nextDisabled && onNext && onNext(value)
  const handleKeyUp = e => e.code === 'Enter' && handleNext()

  const nextDisabled = useMemo(() => value.length < 4, [value])

  return (
    <div className="d-flex">
      <input
        className="form-control"
        placeholder={placeholder}
        value={value}
        disabled={submitting}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        autoFocus
      />
      <ButtonPrimary text="next" disabled={nextDisabled} onClick={handleNext} />
    </div>
  )
}
