import React from 'react'
import { useDataContext } from '../../data-context'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconEdit } from '../../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { NodeCategoryMenuFull } from './../common/node-category-menu'
import { Route, useRouteMatch } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { NodeAssetList } from '../../common/assets/node-asset-list'
import { FormJsonEditorStr } from '../../../../../../shared/form/form-control/form-json-editor-str'

export const NodeCategoryEditSys = ({ parentURL, nodeID, useApiForm }) => {
  const { url } = useRouteMatch()

  const apiForm = useApiForm()

  const dataContext = useDataContext()

  const handleMatch = () => apiForm.get(nodeID)

  const handleSubmit = async data => {
    let node = await apiForm.put(data.id, data)
    dataContext.nodeUpdate(node)
  }

  const HeaderMenu = () => NodeCategoryMenuFull(parentURL, apiForm.form)

  return (
    <React.Fragment>
      <BreadcrumbView text="sys edit" Icon={IconEdit} onMatch={handleMatch}>
        <CardFullHeightScrollY title="sys edit" HeaderMenu={HeaderMenu}>
          <Card>
            <Form state={apiForm.form} onSubmit={handleSubmit}>
              <FormText label="name" name="name" />
              <FormJsonEditorStr label="metadata" name="metadata" />
              <FormSubmit text="update" />
            </Form>
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/assets`}>
        <NodeAssetList parentURL={url} nodeID={nodeID} />
      </Route>
    </React.Fragment>
  )
}
