import { cloneElement } from 'react'
import { FloatingPortal, offset, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react'

export const Floating = ({ children, content, placement, isOpen, onOpenChange }) => {
  const { context, refs, floatingStyles } = useFloating({
    placement: placement || 'left',
    middleware: [
      offset(({ placement, x, y, rects }) => {
        if (placement === 'top' || placement === 'bottom') {
          let side = x + rects.floating.width

          if (side > window.innerWidth) {
            let offset = window.innerWidth - side - 20
            return { mainAxis: 10, crossAxis: offset }
          }
        }

        if (placement === 'right' || placement === 'left') {
          if (y - 50 < 0) {
            let offset = -y + 20
            return { mainAxis: 2, crossAxis: offset }
          }

          let bottom = y + rects.floating.height

          if (bottom + 50 > window.innerHeight) {
            let offset = window.innerHeight - bottom - 20
            return { mainAxis: 2, crossAxis: offset }
          }
        }
      })
    ],
    open: isOpen,
    onOpenChange: onOpenChange
  })

  const click = useClick(context)

  useDismiss(context, {
    enabled: true,
    escapeKey: true,
    outsidePress: true
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([click])

  return (
    <div>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen &&
        <FloatingPortal>
          <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            {content && cloneElement(content, {})}
          </div>
        </FloatingPortal>}
    </div>
  )
}
