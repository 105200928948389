import React from 'react'
import { useLoad } from '../shared/hooks/load'
import { useQueryParam } from '../shared/hooks/location'
import { useGetAuthProviderDetails } from './hooks'
import { Card } from '../shared/card/card'

export const OpenComplete = () => {
  const token = useQueryParam('token')

  const getAuthProviderDetails = useGetAuthProviderDetails()

  useLoad(async () => {
    let authProviderDetails = await getAuthProviderDetails(token)

    let url = new URL(authProviderDetails.redirectSigninURL)
    url.searchParams.set('token', token)

    window.location = url.href
  }, [])

  return (
    <React.Fragment>
      <Card>OIDC SIGNIN COMPLETE</Card>
    </React.Fragment>
  )
}
