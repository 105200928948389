import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Menu, MenuItem } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { useApiListSysAdmin } from '../../../../../api-new/state/content'
import { IconPromote } from '../../../../../shared/icon/icon'

export function SysAdminPromote() {
  const apiListSysAdmin = useApiListSysAdmin()

  const handleReady = async () => await apiListSysAdmin.get('non-admin')

  const handlePromote = async item => {
    await apiListSysAdmin.put(`${item.id}/promote`)
    await apiListSysAdmin.get('non-admin')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconPromote} text="promote">
        <CardFullHeightScrollY title="Logins">
          <TableBuilder apiList={apiListSysAdmin} onReady={handleReady}>
            <Column field="email" sortable filter />
            <Column field="firstName" sortable filter />
            <Column field="lastName" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem Icon={IconPromote} text="promote" onClick={() => handlePromote(row)} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
