import React from 'react'
import { useBreadcrumbContext } from '../../context/breadcrumb.context'
import { useHistory } from '../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { IconBreadcrumbSeperator } from '../../shared/icon/icon'

export const SubHeader = () => {
  const history = useHistory()

  const breadcrumbContext = useBreadcrumbContext()

  const handleClick = url => history.push(url)

  return (
    <div className="d-flex flex-wrap border-bottom ms-2">
      {breadcrumbContext.stack.map((item, i) => <BreadCrumbItem key={i} item={item} showSeperator={i > 0} onClick={handleClick} />)}
    </div>
  )
}

const BreadCrumbItem = ({ item, showSeperator, onClick }) => {
  const handleClick = () => {
    if (item.block) {
      return
    }

    onClick && onClick(item.url)
  }

  return (
    <div className="edxr-pointer my-1 text-nowrap" onClick={handleClick}>
      {showSeperator && <IconBreadcrumbSeperator size="xs" />}
      {item.Icon && <item.Icon />}
      <span className="mx-1">
        {item.text}
      </span>
    </div>
  )
}
