import { useApiForm } from '../api-form'
import { useApiList } from '../api-list'
import { urlArgs } from '../state'

///////////////////////////////////////////////

export const useApiListApp = args => useApiList(process.env.REACT_APP_API_CONFIG, `api/ddd/app${urlArgs(args)}`)
export const useApiFormApp = args => useApiForm(process.env.REACT_APP_API_CONFIG, `api/ddd/app${urlArgs(args)}`)
export const useApiListAppStore = args => useApiList(process.env.REACT_APP_API_CONFIG, `api/ddd/app-store${urlArgs(args)}`)
export const useApiFormAppStore = args => useApiForm(process.env.REACT_APP_API_CONFIG, `api/ddd/app-store${urlArgs(args)}`)
export const useApiListAppVersion = args => useApiList(process.env.REACT_APP_API_CONFIG, `api/ddd/app-version${urlArgs(args)}`)
export const useApiFormAppVersion = args => useApiForm(process.env.REACT_APP_API_CONFIG, `api/ddd/app-version${urlArgs(args)}`)
export const useApiFormAppDownload = args => useApiForm(process.env.REACT_APP_API_CONFIG, `api/ddd/app-download${urlArgs(args)}`)
