import React, { useMemo, useRef } from 'react'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { useApiListReportCustomOrg } from '../../../../../api-new/state/analytics'
import { IconTable } from '../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useLoad } from '../../../../../shared/hooks/load'
import {
  Column,
  ColumnDateTime,
  ColumnFilter,
  ColumnFormat,
  ColumnYesNo,
  TableBuilder
} from '../../../../../shared/table-builder/table-builder'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiFormUserLogin } from '../../../../../api-new/state/content'
import { Menu, MenuExportCsv, MenuExportExcel } from '../../../../../shared/menu/menu'
import { useParseJSON } from '../course/_common/hook'

export const AnalyticCustomUser = () => {
  const { userID } = useParamsInt()

  const apiFormUserLogin = useApiFormUserLogin()
  const apiListReportCustomOrg = useApiListReportCustomOrg()

  useLoad(() => apiFormUserLogin.get(userID), [])

  const handleReady = () => apiListReportCustomOrg.get(`${userID}/user`)

  const title = useMemo(() => `Analytics - ${apiFormUserLogin.form.email}`, [apiFormUserLogin.form])

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  let parseValue = useParseJSON()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTable} text="analytics">
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListReportCustomOrg} onReady={handleReady}>
            <ColumnDateTime field="capturedAt" sortable filter />
            <Column field="sessionID" sortable filter />
            <ColumnFilter field="nodeName" header="Course" sortable />

            <ColumnFilter field="topicName" sortable filter />
            <ColumnFilter field="analyticName" sortable filter />

            <ColumnFormat field="value" sortable filter selector={row => parseValue(row.value)} />
            <ColumnFilter field="type" sortable filter />

            <Column field="email" sortable filter />
            <Column field="firstName" sortable filter />
            <Column field="lastName" sortable filter />

            <ColumnDateTime field="startOn" sortable filter />
            <ColumnDateTime field="endOn" sortable filter />

            <ColumnFilter field="os" sortable filter />
            <ColumnFilter field="platform" sortable filter />
            <ColumnFilter field="appVersion" sortable filter />

            <Column field="deviceModel" sortable filter />
            <Column field="deviceType" sortable filter />
            <Column field="vrDeviceModel" sortable filter />
            <Column field="vrDeviceName" sortable filter />
            <Column field="vrDevicePresent" sortable filter />
            <Column field="vrDeviceTrackingOriginMode" sortable filter />
            <Column field="vrDeviceTrackingSpaceType" sortable filter />
            <Column field="vrDeviceRefreshRate" sortable filter />
            <Column field="graphicsCard" sortable filter />
            <Column field="graphicsCardMemorySize" sortable filter />
            <Column field="graphicsCardVendor" sortable filter />
            <Column field="processor" sortable filter />
            <Column field="processorCount" sortable filter />
            <Column field="processorFrequency" sortable filter />
            <Column field="systemMemorySize" sortable filter />
            <ColumnYesNo field="isMultiPlayer" sortable filter />

            <Column field="locationCountry" sortable filter />
            <Column field="locationRegion" sortable filter />
            <Column field="locationCity" sortable filter />

            <Column field="groups" sortable />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
