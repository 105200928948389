import React from 'react'
import { Route } from 'react-router-dom'
import { DeleteAccount } from './content/delete-account'

export const LayoutDeleteAccount = () => {
  return (
    <React.Fragment>
      <Route path="/org/delete-account" component={DeleteAccount} />
    </React.Fragment>
  )
}
