import React from 'react'
import { Menu, MenuDelete, MenuEdit, MenuRecycle } from '../../../../../../shared/menu/menu'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnDateTime, ColumnMenu, TableBuilder } from '../../../../../../shared/table-builder/table-builder'
import { IconList } from '../../../../../../shared/icon/icon'
import { useApiListChunkLogEvent } from '../../../../../../api-new/state/content'

export function LogEventList() {
  const apiListChunkLogEvent = useApiListChunkLogEvent()

  const handleReady = async () => {
    try {
      await apiListChunkLogEvent.get(1000)
    } catch (error) {}
  }

  const handleDismiss = async item => {
    try {
      debugger
      await apiListChunkLogEvent.delete(item.id)
      await apiListChunkLogEvent.remove(item)
    } catch (error) {}
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuRecycle to="/admin/dashboard/log-event/list/recycle" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="events">
        <CardFullHeightScrollY title="events" HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListChunkLogEvent} onReady={handleReady}>
            <ColumnDateTime field="createdAt" header="Occured" sortable filter sort="desc" />
            <Column field="sessionID" sortable filter />
            <Column field="organization" sortable filter />
            <Column field="email" sortable filter />
            <Column field="subject" sortable filter />
            <Column field="path" sortable filter />
            <Column field="method" sortable filter />
            <Column field="action" sortable filter />
            <Column field="ipAddress" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/admin/dashboard/log-event/list/edit/${row.id}`} show />
                  <MenuDelete onClick={() => handleDismiss(row)} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
