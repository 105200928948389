import React, { useMemo } from 'react'
import { SelectList } from '../select-list/select-list'
import { IconRole } from '../../../../../../../shared/icon/icon'
import { useAddUserContext } from '../context'
import { IconButtonClick } from '../../../../../../../shared/icon/icon-button-click'
import { useButtonCount } from './hooks'

export const SelectRoleDropdown = ({ selectedRoles, readonlyRoles, disabled, onClick }) => {
  const count = useButtonCount(selectedRoles, readonlyRoles)
  const text = useMemo(() => (count ? `roles (${count})` : 'roles'), [count])
  return (
    <button className="dropdown-item" disabled={disabled} onClick={onClick}>
      <span className="me-1">
        <IconRole />
      </span>
      <span>
        {text}
      </span>
    </button>
  )
}

export const SelectRoleButton = ({ selectedRoles, readonlyRoles, small, disabled, onClick }) => {
  const count = useButtonCount(selectedRoles, readonlyRoles)
  const text = useMemo(() => (count ? `roles (${count})` : 'roles'), [count])
  return <IconButtonClick Icon={IconRole} text={text} disabled={disabled} small={small} onClick={onClick} />
}

export const SelectRoleModal = ({ target, selectedRoles, readonlyRoles, onUpdate, onHide }) => {
  const addUserContext = useAddUserContext()

  const handleClose = () => onHide()

  const onAdd = item => {
    let newList = [...selectedRoles, item]
    onUpdate(newList)
  }

  const onRemove = item => {
    let newList = selectedRoles.filter(i => i.id !== item.id)
    onUpdate(newList)
  }

  return (
    <React.Fragment>
      <SelectList
        header="Roles"
        selectedItems={selectedRoles}
        readonlyItems={readonlyRoles}
        items={addUserContext.allRoles}
        modalTarget={target}
        fnTagFormat={item => item.role}
        fnListFormat={item => item.role}
        fnListFilter={(filter, items) => items.filter(i => i.role.toLowerCase().includes(filter))}
        onAdd={onAdd}
        onRemove={onRemove}
        onClose={handleClose}
      />
    </React.Fragment>
  )
}
