export const SerializeHtml = nodes => {
  if (!nodes) {
    return ''
  }

  let results = nodes.map(node => {
    switch (node.type) {
      case 'paragraph':
        return `<p style='text-align: ${node.align || 'left'};'>${children(node.children)}</p>`
      case 'list-bulleted':
        return `<ul>${children(node.children)}</ul>`
      case 'list-numbered':
        return `<ol>${children(node.children)}</ol>`
      default:
        return 'DEFAULT'
    }
  })

  return results.reduce((result, value) => (result += value), '')
}

const children = items => {
  const result = items.reduce((result, item) => {
    let value = item.text

    if (item.type === 'list-item') {
      value = `<li>${children(item.children)}</li>`
    }

    if (item.underline) {
      value = `<u>${value}</u>`
    }

    if (item.strikethrough) {
      value = `<s>${value}</s>`
    }

    if (item.bold) {
      value = `<b>${value}</b>`
    }

    if (item.italic) {
      value = `<i>${value}</i>`
    }

    if (item.indent) {
      value = `<div style='text-indent:${item.indent * 5}%'>${value}</div>`
    }

    return (result += value)
  }, '')

  return result
}
