import { useRoleOrgNode } from '../../../../../../role/hook/role-org'
import { IconCopy, IconEdit, IconFile, IconGroup, IconMove, IconUser } from '../../../../../../shared/icon/icon'
import { Menu, MenuActivityLog, MenuDelete, MenuDetails, MenuEdit, MenuItem, MenuItemHeader } from '../../../../../../shared/menu/menu'
import { useLoginSysAdmin } from '../../../../../../role/hook/login-sys-admin'

export const NodeLibraryMenuFull = (url, onAddUnityPackage) => {
  const roleOrgNode = useRoleOrgNode()
  const loginSysAdmin = useLoginSysAdmin()

  return (
    <Menu>
      <MenuItemHeader text="Sys Admin" show={loginSysAdmin} />
      <MenuItem Icon={IconEdit} text="sys edit" to={`${url}/edit-sys`} show={loginSysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${url}/edit-sys/assets`} show={loginSysAdmin} />
      <MenuItem Icon={IconFile} text="add unity package" onClick={onAddUnityPackage} show={loginSysAdmin} />

      <MenuItemHeader text="Org" show={loginSysAdmin} />
      <MenuDetails to={`${url}`} show={roleOrgNode.accessRead} pin={false} />
      <MenuEdit to={`${url}/edit`} show={roleOrgNode.accessUpdate} pin={false} />
      <MenuItem Icon={IconMove} text="move" to={`${url}/move`} show={roleOrgNode.accessUpdate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${url}/copy`} disabled={false} show={roleOrgNode.accessUpdate} />
      <MenuItem Icon={IconUser} text="user permissions" to={`${url}/user-permission`} show={roleOrgNode.accessRead} />
      <MenuItem Icon={IconGroup} text="group permissions" to={`${url}/group-permission`} show={roleOrgNode.accessRead} />
      <MenuDelete to={`${url}/delete`} show={roleOrgNode.accessDelete} />
      <MenuActivityLog to={`${url}/activity`} show={roleOrgNode.accessRead} />
    </Menu>
  )
}

export const NodeLibraryMenuPartial = url => {
  const roleOrgNode = useRoleOrgNode()
  const loginSysAdmin = useLoginSysAdmin()

  return (
    <Menu>
      <MenuItemHeader text="Sys Admin" show={loginSysAdmin} />
      <MenuItem Icon={IconEdit} text="sys edit" to={`${url}/edit-sys`} show={loginSysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${url}/edit-sys/assets`} show={loginSysAdmin} />

      <MenuItemHeader text="Org" show={loginSysAdmin} />
      <MenuEdit to={`${url}/edit`} show={roleOrgNode.accessUpdate} pin={false} />
      <MenuItem Icon={IconMove} text="move" to={`${url}/move`} show={roleOrgNode.accessUpdate} />
      <MenuItem Icon={IconCopy} text="copy" to={`${url}/copy`} disabled={false} show={roleOrgNode.accessUpdate} />
      <MenuDelete to={`${url}/delete`} show={roleOrgNode.accessDelete} />
    </Menu>
  )
}
