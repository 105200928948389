import React from 'react'
import { GraphSession } from './graph-session'
import { GraphDevice } from './graph-device'

export function Graph({ nodeID, sessionCount }) {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 col-lg-8">
          <GraphSession sessionCount={sessionCount} />
        </div>
        <div className="col-12 col-lg-4">
          <GraphDevice nodeID={nodeID} />
        </div>
      </div>
    </React.Fragment>
  )
}
