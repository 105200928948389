import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormRequired } from './hooks/input-hooks'

export const FormSelectListObj = ({
  label,
  subtext,
  name,
  state,
  itemName,
  itemValue,
  fnItemDisabled,
  nullText,
  nullValue,
  required,
  disabled,
  readOnly,
  valueAsNumber,
  onChange
}) => {
  const { register, watch, setValue } = useFormContext()

  let formDisabled = useFormDisable()

  let watchValue = watch(name)

  const items = useMemo(
    () => {
      setValue(name, '')
      let values = (state && state.items) || state
      return values ? values.sort((a, b) => a[itemName].localeCompare(b[itemName])) : []
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, itemName]
  )

  useFormRequired(name, required, disabled)

  const setValueAs = value => {
    if (value === '') {
      let value = nullValue || null
      onChange && onChange(value)
      return value
    }

    if (valueAsNumber) {
      let result = Number.parseInt(value)

      if (isNaN(result)) {
        let value = nullValue || null
        onChange && onChange(value)
        return value
      } else {
        onChange && onChange(result)
        return result
      }
    }

    onChange && onChange(value)
    return value
  }

  return (
    <FormatLabel name={name} label={label} subtext={subtext} hasValue={!!watchValue} required={required}>
      <select
        className="form-control"
        {...register(name, { required, setValueAs })}
        value={watchValue || ''}
        readOnly={readOnly}
        disabled={disabled || formDisabled}>
        <option value="">
          {nullText || 'Select'}
        </option>
        {items.map((item, i) =>
          <option key={i} value={item[itemValue]} disabled={fnItemDisabled && fnItemDisabled(item)}>
            {item[itemName]}
          </option>
        )}
      </select>
    </FormatLabel>
  )
}
