import React, { useMemo } from 'react'

export const Progress = ({ current, length }) => {
  const precent = useMemo(() => (length ? `${(current / length * 100).toFixed(0)}%` : 0), [current, length])

  return (
    <React.Fragment>
      <div className="progress">
        <div className="progress-bar" role="progressbar" style={{ width: precent }}>
          {precent}
        </div>
      </div>
    </React.Fragment>
  )
}
