import React, { useMemo } from 'react'
import { CourseHeader, Footer, FooterButton, Widget } from '../../../../../../shared/chart/widget'
import { IconAnalytic, IconAnalyticGeneral } from '../../../../../../shared/icon/icon'
import { ChartBar } from '../../../../../../shared/chart/chart'
import { getPlatformColors } from '../../../../../../shared/chart/chart-color'
import { useLoad } from '../../../../../../shared/hooks/load'
import { useRouteMatch } from 'react-router-dom'
import { useApiListGraphGeneralCourse } from '../../../../../../api-new/state/analytics'

export const AnalyticGeneralCourse = ({ nodeID }) => {
  const { url } = useRouteMatch()

  const apiListGraphGeneralCourse = useApiListGraphGeneralCourse()

  useLoad(() => apiListGraphGeneralCourse.get(`${nodeID}/node`), [nodeID])

  const chartData = useMemo(
    () => {
      if (!apiListGraphGeneralCourse.items.length) {
        return { labels: [], data: undefined, value: 0 }
      }

      let value = apiListGraphGeneralCourse.items.reduce((a, i) => a + i.count, 0)
      let sorted = apiListGraphGeneralCourse.items.sort((a, b) => a.os.localeCompare(b.os))
      const labels = sorted.map(i => i.os)
      const data = sorted.map(i => i.count)

      const colors = getPlatformColors(labels)

      const dataSet = {
        data: data,
        borderColor: colors,
        backgroundColor: colors
      }

      return { labels: labels, data: dataSet, value: value }
    },
    [apiListGraphGeneralCourse.items]
  )

  return (
    <Widget
      header={<CourseHeader Icon={IconAnalyticGeneral} text="General Analytics" value={chartData.value} />}
      body={<ChartBar labels={chartData.labels} data={chartData.data} displayX displayY />}
      footer={
        <React.Fragment>
          <Footer>
            <FooterButton text="Legacy" to={`${url}/analytic-legacy/course/${nodeID}`} />
            <FooterButton text="Reports" to={`${url}/analytic-general/${nodeID}`} />
          </Footer>
        </React.Fragment>
      }
    />
  )
}
