import React, { useContext, useEffect, useMemo } from 'react'
import { useAuthContext } from '../context/auth.context'
import { useApiListRoleAccess } from '../api-new/state/content'

export const RoleContext = React.createContext()

export const useRoleContext = () => useContext(RoleContext)

export const RoleProvider = ({ children }) => {
  const authContext = useAuthContext()

  const apiListRoleAccessLogin = useApiListRoleAccess()
  const apiListRoleAccessUser = useApiListRoleAccess()

  useEffect(
    () => {
      authContext.state.loginID && apiListRoleAccessLogin.get(`${authContext.state.loginID}/login`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state.loginID]
  )

  useEffect(
    () => {
      authContext.state.userID && apiListRoleAccessUser.get(`${authContext.state.userID}/user`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state.userID]
  )

  const sysRoles = useMemo(
    () => {
      let roles = {}
      apiListRoleAccessLogin.items.forEach(role => (roles[role.identifier] = role))
      return roles
    },
    [apiListRoleAccessLogin.items]
  )

  const orgRoles = useMemo(
    () => {
      let roles = {}
      apiListRoleAccessUser.items.forEach(role => (roles[role.identifier] = role))
      return roles
    },
    [apiListRoleAccessUser.items]
  )

  return (
    <RoleContext.Provider value={{ orgRoles, sysRoles }}>
      {children}
    </RoleContext.Provider>
  )
}
