import { isDatatypeCourseTemplate, isDatatypeNodeTemplateCategory } from './metadata'
import { getMap } from './node-path'

export const pathNodeTemplate = (nodes, nodeID) => {
  const nodeMap = getMap(nodes)
  return pathNodeTemplateMap(nodeMap, nodeID)
}

export const pathNodeTemplateMap = (nodeMap, nodeID) => {
  const recBuild = (nodeMap, nodeID) => {
    let node = nodeMap[nodeID]

    if (!node) {
      return
    }

    if (node.parentID) {
      let path = recBuild(nodeMap, node.parentID, true)
      return templatepath(path, node)
    } else {
      return templatepath(`/org/node-template`, node)
    }
  }

  return recBuild(nodeMap, nodeID, false)
}

const templatepath = (path, node) => {
  if (isDatatypeCourseTemplate(node)) {
    return `${path}/template/${node.id}`
  }

  if (isDatatypeNodeTemplateCategory(node)) {
    return `${path}/node/${node.id}`
  }

  return path
}
