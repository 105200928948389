import React, { useContext, useMemo, useState } from 'react'
import { AuthContext } from '../../../../../../context/auth.context'
import { useApiFormNodeMove, useApiFormNodeTemplate, useApiListNodeTemplateCategory } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconMove } from '../../../../../../shared/icon/icon'
import { Form, FormHiddenNumber, FormSubmitBack } from '../../../../../../shared/form/form'
import { useDataContext } from '../../data-context'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { FormSelectCategoryTemp } from '../../../../../../shared/form/form-control/form-select-category-temp'
import { NodeTemplateMenuFull } from '../common/node-template-menu'
import { pathNodeTemplateMap } from '../../common/shared/path-node-template'

export const NodeTemplateMove = ({ parentURL, nodeID }) => {
  const authContext = useContext(AuthContext)
  const dataContext = useDataContext()

  const history = useHistory()

  const apiFormNodeTemplate = useApiFormNodeTemplate()
  const apiFormNodeMove = useApiFormNodeMove()

  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  useLoad(() => authContext.state.jwtClaims.organizationID && updateCategoryOrganizationID(authContext.state.jwtClaims.organizationID), [
    authContext.state.jwtClaims.organizationID
  ])

  const title = useMemo(() => apiFormNodeTemplate.form.name, [apiFormNodeTemplate.form])

  const handleMatch = async () => await apiFormNodeTemplate.get(nodeID)

  const handleSubmit = async data => {
    if (data.parentID === -1) {
      data.parentID = null
    }

    await apiFormNodeMove.put(data.nodeID, data)
    let node = await apiFormNodeTemplate.get(data.nodeID)

    const moveToPath = ({ map }) => {
      dataContext.unsubscribe(moveToPath)
      let path = pathNodeTemplateMap(map, node.id)
      history.replace(path)
    }

    dataContext.subscribe(moveToPath)
    dataContext.nodeUpdate(node)
  }

  const HeaderMenu = () => NodeTemplateMenuFull(parentURL)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconMove} text="move" onMatch={handleMatch}>
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="nodeID" value={apiFormNodeTemplate.form.id} required shouldDirty />
            {categoryOrganizationID &&
              <FormSelectCategoryTemp
                lable="category"
                name="parentID"
                organizationID={categoryOrganizationID}
                useApiList={useApiListNodeTemplateCategory}
                includeRoot
                required
              />}
            <FormSubmitBack text="move template" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
