import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../../shared/icon/icon'
import { IconAdd } from '../../../../../../shared/icon/icon'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { Link } from 'react-router-dom'
import { Menu, MenuItem } from '../../../../../../shared/menu/menu'
import moment from 'moment'
import { Card } from '../../../../../../shared/card/card'
import { useApiListAppVersion } from '../../../../../../api-new/state/config'
import { RoleSysApp } from '../../../../../../role/components/role-sys'

export const AppVersionDownloads = () => {
  const { appID, appStoreID } = useParamsInt()

  const apiListAppVersion = useApiListAppVersion()

  const handleMatch = async () => await apiListAppVersion.get(`${appStoreID}/store`)

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconAdd} text="add" to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/add`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="downloads" onMatch={handleMatch}>
        <Card title="Version Downloads" HeaderMenu={HeaderMenu}>
          {apiListAppVersion.items.sort((a, b) => b.code - a.code).map((appVersion, i) =>
            <div key={i}>
              <Card title={appVersion.name}>
                <div className="d-flex">
                  <div className="flex-fill">
                    <Item name="Code" value={appVersion.code} />
                    {appVersion.storageKey && <LinkButton appVersion={appVersion} />}
                  </div>
                  <RoleSysApp accessUpdate>
                    <Link to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/edit/${appVersion.id}`}>edit</Link>
                  </RoleSysApp>
                </div>
              </Card>
            </div>
          )}
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}

const Item = ({ name, value }) =>
  <div>
    <span className="mr-2">
      {name}:
    </span>
    <span>
      {value}
    </span>
  </div>

const LinkButton = ({ appVersion }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(appVersion.storageURL)
  }

  return (
    <React.Fragment>
      <div className="my-2">
        <Item name="Uploaded" value={moment(appVersion.storageUpdatedAt).format('MMM/DD/YY - hh:mm:ss a')} />
        <Item name="Url" value={appVersion.storageURL} />
      </div>
      <div>
        <span className="btn btn-primary mr-1" style={{ cursor: 'pointer' }} onClick={handleCopy}>
          copy
        </span>
        <span className="btn btn-primary mr-1">
          <a className="text-white" href={appVersion.storageURL}>
            download
          </a>
        </span>
      </div>
    </React.Fragment>
  )
}
