export function Cache() {
  return {
    data: {},
    get: function(key) {
      return this.data[key]
    },
    add: function(key, value) {
      this.data[key] = value
    },
    remove: function(key) {
      delete this.data[key]
    }
  }
}
