import { createContext, useContext, useMemo } from 'react'
import { useApiListAssetThumbnail, useApiListNodeOrg } from '../../../../api-new/state/content'
import { useLoad } from '../../../../shared/hooks/load'
import { Cache } from './common/shared/cache'
import { useItemState } from './common/hooks/item-state'
import {
  isDatatypeNodeLibraryCategory,
  isDatatypeNodeLibrary,
  isDatatypeCourseTemplate,
  isDatatypeStreamingVideo,
  isDatatypeCourse,
  isDatatypeCategory,
  isDatatypeNodeTemplateCategory
} from './common/shared/metadata'

const DataContext = createContext()

export const useDataContext = () => useContext(DataContext)

export const DataProvider = ({ children }) => {
  const apiListNodeOrg = useApiListNodeOrg()
  const apiListAssetThumbnail = useApiListAssetThumbnail()

  const itemsNode = useItemState(apiListNodeOrg.items)
  const assetThumbnails = useItemState(apiListAssetThumbnail.items)

  useLoad(() => {
    apiListNodeOrg.get()
    apiListAssetThumbnail.get()
  }, [])

  const content = useMemo(
    () => {
      return itemsNode.array.filter(node => {
        return isDatatypeCourse(node) || isDatatypeCategory(node) || isDatatypeStreamingVideo(node)
      })
    },
    [itemsNode.array]
  )

  const template = useMemo(
    () => {
      return itemsNode.array.filter(node => {
        return isDatatypeNodeTemplateCategory(node) || isDatatypeCourseTemplate(node)
      })
    },
    [itemsNode.array]
  )

  const library = useMemo(
    () => {
      return itemsNode.array.filter(node => {
        return isDatatypeNodeLibraryCategory(node) || isDatatypeNodeLibrary(node)
      })
    },
    [itemsNode.array]
  )

  const thumbnailCache = useMemo(() => new Cache(), [])

  return (
    <DataContext.Provider
      value={{
        nodes: itemsNode.array,
        nodeMap: itemsNode.map,

        content: content,
        template: template,
        library: library,

        thumbnailArray: assetThumbnails.array,
        thumbnailMap: assetThumbnails.map,

        thumbnailInsert: assetThumbnails.insert,
        thumbnailUpdate: assetThumbnails.update,
        thumbnailRemove: assetThumbnails.remove,
        thumbnailRemoveID: assetThumbnails.removeID,

        thumbnailCache: thumbnailCache,

        //get rid of this
        assets: assetThumbnails.array,
        subscribe: listener => itemsNode.subscribe(listener),
        unsubscribe: listener => itemsNode.unsubscribe(listener),
        //get rid of this

        nodeSubscribe: listener => itemsNode.subscribe(listener),
        nodeUnsubscribe: listener => itemsNode.unsubscribe(listener),

        nodeInsert: node => itemsNode.insert(node),
        nodeUpdate: node => itemsNode.update(node),
        nodeRemove: node => itemsNode.remove(node),
        nodeRemoveID: id => itemsNode.removeID(id)
      }}>
      {children}
    </DataContext.Provider>
  )
}
