import { useMemo, useReducer } from 'react'
import { deleteAsync, getAsync, postAsync, putAsync } from '../shared/api.service'
import { useNotificationContext } from '../context/notification/context'

export const types = {
  INIT: 'INIT',
  ERROR: 'ERROR',
  LOAD: 'LOAD',
  LOAD_SUCCESS: 'LOAD_SUCCESS',
  SUBMIT: 'SUBMIT',
  POST_SUCCESS: 'POST_SUCCESS',
  PUT_SUCCESS: 'PUT_SUCCESS',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  REMOVE: 'REMOVE'
}

const initialState = {
  items: [],
  loading: false,
  loaded: false,
  submitting: false,
  error: undefined
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.INIT:
      return { ...initialState }
    case types.ERROR:
      return { ...state, loading: false, submitting: false, error: action.payload }
    case types.LOAD:
      return { ...state, loaded: false, loading: true, error: undefined }
    case types.LOAD_SUCCESS:
      return { ...state, items: action.payload, loaded: true, loading: false, error: undefined }
    case types.SUBMIT:
      return { ...state, submitting: true, error: undefined }
    case types.POST_SUCCESS:
      return { ...state, items: [...state.items, action.payload], submitting: false, error: undefined }
    case types.PUT_SUCCESS:
      let indexPut = state.items.indexOf(action.payload)
      state.items[indexPut] = action.payload
      return { ...state, items: [...state.items], submitting: false, error: undefined }
    case types.DELETE_SUCCESS:
      return { ...state, submitting: false, error: undefined }
    case types.REMOVE:
      let indexDelete = state.items.indexOf(action.payload)
      state.items.splice(indexDelete, 1)
      return { ...state, items: [...state.items], submitting: false, error: undefined }
    default:
      return state
  }
}

export const useApiList = (server, endpoint) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const notificationContext = useNotificationContext()

  const actions = useMemo(() => {
    return {
      init: () => initAction(dispatch)(),
      get: arg => getAction(server, endpoint, dispatch, notificationContext)(arg),
      post: data => postAction(server, endpoint, dispatch, notificationContext)(data),
      postArg: (arg, data) => postArgAction(server, endpoint, arg, dispatch, notificationContext)(data),
      put: (arg, data) => putAction(server, endpoint, dispatch, notificationContext)(arg, data),
      delete: arg => deleteAction(server, endpoint, dispatch, notificationContext)(arg),
      remove: item => removeAction(dispatch)(item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(
    () => {
      return { ...state, ...actions }
    },
    [state, actions]
  )
}

const initAction = dispatch => () => {
  dispatch({ type: types.INIT })
}

const getAction = (server, endpoint, dispatch, notificationContext) => async arg => {
  try {
    if (arg) {
      endpoint = `${endpoint}/${arg}`
    }

    dispatch({ type: types.LOAD })
    const result = await getAsync(server, endpoint)
    dispatch({ type: types.LOAD_SUCCESS, payload: result.data })
    return result.data
  } catch (exception) {
    // debugger
    // notificationContext.modal.error(exception.error)
    dispatch({ type: types.ERROR, payload: exception.error || '' })
    // throw exception
  }
}

const postAction = (server, endpoint, dispatch, notificationContext) => async data => {
  try {
    dispatch({ type: types.SUBMIT })
    const result = await postAsync(server, endpoint, data)
    dispatch({ type: types.POST_SUCCESS, payload: result.data })
    return result.data
  } catch (exception) {
    // let hold = exception
    // debugger
    // notificationContext.modal.error(exception.error)
    dispatch({ type: types.ERROR, payload: exception.error || '' })
    // throw exception
  }
}

const postArgAction = (server, endpoint, arg, dispatch, notificationContext) => async data => {
  try {
    dispatch({ type: types.SUBMIT })
    const result = await postAsync(server, endpoint + arg, data)
    dispatch({ type: types.POST_SUCCESS, payload: result.data })
    return result.data
  } catch (exception) {
    // let hold = exception
    // debugger
    // notificationContext.modal.error(exception.error)
    dispatch({ type: types.ERROR, payload: exception.error || '' })
    // throw exception
  }
}

const putAction = (server, endpoint, dispatch, notificationContext) => async (arg, data) => {
  try {
    endpoint = `${endpoint}/${arg}`

    dispatch({ type: types.SUBMIT })
    const result = await putAsync(server, endpoint, data)
    dispatch({ type: types.PUT_SUCCESS, payload: result.data })
    return result.data
  } catch (exception) {
    // let hold = exception
    // debugger
    // notificationContext.modal.error(exception.error)
    dispatch({ type: types.ERROR, payload: exception.error || '' })
    // throw exception
  }
}

const deleteAction = (server, endpoint, dispatch, notificationContext) => async arg => {
  try {
    endpoint = `${endpoint}/${arg}`

    dispatch({ type: types.SUBMIT })
    const result = await deleteAsync(server, endpoint)
    dispatch({ type: types.DELETE_SUCCESS })
    notificationContext.toast.notify('delete success', 3000)
    return result
  } catch (exception) {
    // let hold = exception
    // debugger
    // notificationContext.modal.error(exception.error)
    dispatch({ type: types.ERROR, payload: exception.error || '' })
    // throw exception
  }
}

const removeAction = dispatch => async item => {
  dispatch({ type: types.REMOVE, payload: item })
}
