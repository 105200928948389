import React from 'react'
import { useApiListAnalyticType } from '../../../../api-new/state/analytics'
import { Form, FormSubmitCancel, FormText, FormTextArea } from '../../../../shared/form/form'
import { TableEdit } from '../../../../shared/component/table-edit'

export const AnalyticType = () => {
  const apiListAnalyticType = useApiListAnalyticType()

  const handleLoad = () => apiListAnalyticType.get()

  const confirmDelete = data => {
    if (data.analyticCount) return 'This parent type has child analytics, are you sure you want to delete?'
    else return null
  }

  return (
    <TableEdit
      title="analytic type"
      columns={[{ header: 'name', value: 'name', filter: true }, { header: 'description', value: 'description', filter: true }]}
      apiList={apiListAnalyticType}
      sortBy="name"
      FormAdd={FormEdit}
      FormEdit={FormEdit}
      onLoad={handleLoad}
      onAdd
      onEdit
      onDelete
      onPurge
      onRestore
      fnConfirmDelete={confirmDelete}
    />
  )
}

const FormEdit = ({ row, onSubmit, onClose }) => {
  return (
    <Form state={row} onSubmit={onSubmit}>
      <div className="row">
        <FormText className="col-12" label="name" name="name" required small autoFocus />
        <FormTextArea className="col-12" label="description" name="description" small />
        <FormSubmitCancel small onCancel={onClose} />
      </div>
    </Form>
  )
}
