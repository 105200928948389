import { useCallback, useState } from 'react'
import { IconClickClose, IconClickSearch } from '../icon/icon-click'

export const Filter = ({ className, value, onChange }) => {
  const handleChange = e => onChange && onChange(e.target.value)
  const handleSearch = () => onChange && onChange(value)
  const handleClear = () => onChange && onChange('')
  return (
    <div className={className}>
      <div className="input-group p-1">
        <input className="form-control" placeholder="Search" value={value} autoComplete="off" onChange={handleChange} />
        <span className="input-group-text">
          <IconClickSearch onClick={handleSearch} />
        </span>
        <span className="input-group-text">
          <IconClickClose onClick={handleClear} />
        </span>
      </div>
    </div>
  )
}

export const useFilter = () => {
  const [state, updateState] = useState('')
  const updateFilter = useCallback(value => updateState(value), [])
  return [state, updateFilter]
}
