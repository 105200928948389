import { useMemo } from 'react'

export const Switch = ({ text, value, onChange }) => {
  const handleChange = e => onChange && onChange(e.target.checked)

  const bool = useMemo(() => value || false, [value])
  const id = useMemo(() => `switch-${Math.floor(Math.random() * 100000000)}`, [])

  return (
    <div className="form-check form-switch text-capitalize m-0">
      <input className="form-check-input" type="checkbox" id={id} checked={bool} onChange={handleChange} />
      <label className="form-check-label ml-3" htmlFor={id}>
        {text}
      </label>
    </div>
  )
}

export const SwitchSmall = ({ text, value, onChange }) => {
  const handleChange = e => onChange && onChange(e.target.checked)

  const bool = useMemo(() => value || false, [value])
  const id = useMemo(() => `switch-${Math.floor(Math.random() * 100000000)}`, [])

  return (
    <small className="form-check form-switch text-capitalize m-0">
      <input className="form-check-input" type="checkbox" id={id} checked={bool} onChange={handleChange} />
      <label className="form-check-label ml-3" htmlFor={id}>
        {text}
      </label>
    </small>
  )
}
