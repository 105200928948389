import React, { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormOnChange, useFormRequired, useFormShouldDirty } from './hooks/input-hooks'

export const FormEmail = ({
  label,
  placeholder,
  subtext,
  id,
  name,
  required,
  readOnly,
  defaultValue,
  shouldDirty,
  autoFocus,
  hidden,
  onChange,
  onEnter
}) => {
  const { register, watch, setError, clearErrors } = useFormContext()

  let formDisabled = useFormDisable()

  let watchValue = watch(name)

  useFormRequired(name, required)
  useFormShouldDirty(name, defaultValue, shouldDirty)
  useFormOnChange(name, onChange)

  let emailValid = useMemo(() => new RegExp(process.env.REACT_APP_REGEX_EMAIL, 'g').test(watchValue), [watchValue])

  useEffect(
    () => {
      if (emailValid) {
        clearErrors(name)
      } else {
        setError(name, 'email error')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emailValid, watchValue]
  )

  const handleKeyUp = e => e.code === 'Enter' && onEnter && onEnter()

  return (
    <React.Fragment>
      {!hidden &&
        <FormatLabel name={name} label={label} subtext={subtext} hasValue={!!watchValue} required={required}>
          <input
            id={id}
            className="form-control"
            type="email"
            {...register(name, { required })}
            placeholder={placeholder || label}
            autoFocus={autoFocus}
            disabled={formDisabled}
            readOnly={readOnly}
            onKeyUp={handleKeyUp}
          />
        </FormatLabel>}
    </React.Fragment>
  )
}
