import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable, FormatLabel } from './form'
import { useFormShouldDirty } from './hooks/input-hooks'

export const FormYesNo = ({ label, name, readOnly, defaultValue, shouldDirty, subtext }) => {
  const { watch, setValue, getValues } = useFormContext()

  let formDisabled = useFormDisable()

  let watchValue = watch(name)

  useFormShouldDirty(name, defaultValue, shouldDirty)

  let values = getValues()

  const value = useMemo(() => values[name], [values, name])

  useEffect(
    () => {
      updateStrValue(value ? 'Yes' : 'No')
    },
    [value]
  )

  const [strValue, updateStrValue] = useState()

  const handleChange = e => setValue(name, e.target.value === 'Yes' ? true : false, { shouldDirty: true })

  return (
    <React.Fragment>
      <FormatLabel name={name} label={label} subtext={subtext} hasValue={!!watchValue}>
        <select className="form-control" value={strValue} onChange={handleChange} disabled={formDisabled} readOnly={readOnly}>
          <option>Yes</option>
          <option>No</option>
        </select>
      </FormatLabel>
    </React.Fragment>
  )
}
