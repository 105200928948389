export const mapPeriod = (periods, group) => {
  if (!periods) {
    return []
  }

  let items = periods.map(period => {
    return {
      id: `period-${period.id}`,
      start: period.beganAt,
      end: period.endedAt,
      content: period.name,
      type: 'range',
      group: group
    }
  })

  return items
}
