import React from 'react'
import { useHistory } from 'react-router-dom'
import { useApiFormAsset } from '../../../../../../api-new/state/content'
import { IconDelete } from '../../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Card } from '../../../../../../shared/card/card'
import { Form, FormSubmitCancel, FormTextConfirm } from '../../../../../../shared/form/form'

export const NodeAssetDelete = ({ parentURL, assetID }) => {
  const history = useHistory()

  const apiFormAsset = useApiFormAsset()

  const handleMatch = () => apiFormAsset.get(assetID)

  const handleSubmit = async data => {
    await apiFormAsset.delete(data.id)
    history.replace(parentURL)
  }

  const handleCancel = () => history.push(parentURL)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text="delete" onMatch={handleMatch}>
        <Card title={apiFormAsset.form.name}>
          <Form state={apiFormAsset.form} onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="delete" subtext="Type delete to confirm" confirmValue="delete" name="test" required autoFocus />
            <FormSubmitCancel text="delete asset" onCancel={handleCancel} />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
