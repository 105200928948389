import React from 'react'
import { useParams } from 'react-router-dom'
import { PositionCenter, TitleMain } from './component'

export const PasswordResult = () => {
  const { email } = useParams()

  return (
    <PositionCenter>
      <TitleMain className="text-dark pb-2" value="request password reset" />
      <div>
        Password request sent to {email}
      </div>
    </PositionCenter>
  )
}
