import React from 'react'

export const Choice = ({ pos, choice, showCorrect }) => {
  return (
    <React.Fragment>
      <div className="input-group">
        <span className="input-group-text" style={{ width: '35px' }}>
          {pos}
        </span>
        {showCorrect && <span className={`input-group-text ${choice.correct ? 'bg-success' : 'bg-danger'}`} />}
        <div className="form-control">
          {choice.text}
        </div>
      </div>
    </React.Fragment>
  )
}
