import React, { useMemo } from 'react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { Form, FormSubmitBack, FormTextConfirm } from '../form/form'
import { useParamsInt } from '../hooks/location'
import { Card } from '../card/card'
import { IconDelete } from '../icon/icon'

export const Delete = ({ useApiForm, titleParam, successPath, onSuccess }) => {
  const { deleteID } = useParamsInt()

  const params = useParams()
  const history = useHistory()

  const apiForm = useApiForm()

  const title = useMemo(() => `delete ${apiForm.form[titleParam] || ''}`, [apiForm.form, titleParam])

  const handleMatch = () => apiForm.get(deleteID)

  const handleSubmit = async data => {
    await apiForm.delete(data.id)

    if (onSuccess) {
      onSuccess(apiForm.form)
    } else {
      let path = generatePath(successPath, params)
      history.push(path)
    }
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text="delete" onMatch={handleMatch}>
        <Card title={title}>
          <div className="py-2">This is a destructive action</div>
          <Form state={apiForm.form} onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="delete" subtext="Type delete to confirm" confirmValue="delete" name="test" required autoFocus />
            <FormSubmitBack text="delete" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
