import React, { useEffect } from 'react'
import { useApiListAnalytic } from '../../../../api-new/state/analytics'
import { useAnalyticContext } from '../context/provider-analytic'
import { TableEdit } from '../../../../shared/component/table-edit'
import { Form, FormHiddenText, FormSubmitCancel, FormText, FormTextArea } from '../../../../shared/form/form'
import { FormSelectObj } from '../../../../shared/form/form-select-obj'
import { useLoad } from '../../../../shared/hooks/load'
import { useApiListAnalyticTopic, useApiListAnalyticType } from '../../../../api-new/state/analytics'

export const AnalyticNode = ({ nodeID }) => {
  const apiListAnalytic = useApiListAnalytic()

  const analyticContext = useAnalyticContext()

  useEffect(() => {
    analyticContext.subscribe('topic', async () => await apiListAnalytic.get(`${nodeID}/node`))
    return () => analyticContext.unsubscribe('topic')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoad = () => apiListAnalytic.get(`${nodeID}/node`)

  return (
    <TableEdit
      title="analytics"
      columns={[
        { header: 'name', value: 'name', filter: true },
        { header: 'topic', value: 'analyticTopic', filter: true },
        { header: 'type', value: 'analyticType', filter: true },
        { header: 'description', value: 'description', filter: true }
      ]}
      apiList={apiListAnalytic}
      formArgs={{ nodeID: nodeID }}
      sortBy="name"
      FormAdd={FormEdit}
      FormEdit={FormEdit}
      onLoad={handleLoad}
      onAdd
      onEdit
      onDelete
      onRestore
      onPurge
    />
  )
}

const FormEdit = ({ row, nodeID, onSubmit, onClose }) => {
  const apiListAnalyticTopic = useApiListAnalyticTopic()
  const apiListAnalyticType = useApiListAnalyticType()

  useLoad(() => {
    apiListAnalyticTopic.get(`${nodeID}/node`)
    apiListAnalyticType.get()
  }, [])

  return (
    <Form state={row} onSubmit={onSubmit}>
      <div className="row">
        <FormHiddenText name="parentID" value={nodeID} />

        <FormSelectObj
          className="col-12 col-md-6"
          label="topic"
          state={apiListAnalyticTopic}
          name="analyticTopicID"
          itemName="name"
          itemValue="id"
          valueAsNumber
          required
          small
          autoFocus
        />
        <FormSelectObj
          className="col-12 col-md-6"
          label="type"
          state={apiListAnalyticType}
          name="analyticTypeID"
          itemName="name"
          itemValue="id"
          valueAsNumber
          required
          small
        />
        <FormText className="col-12" label="name" name="name" required small />
        <FormTextArea className="col-12" label="description" name="description" small />
        <FormSubmitCancel small onCancel={onClose} />
      </div>
    </Form>
  )
}
