import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

import {} from 'react-image-crop/dist/ReactCrop.css'

import 'bootstrap/dist/js/bootstrap.min.js'

import { arrayInit } from '../src/proto'

arrayInit()

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
