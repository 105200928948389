import React from 'react'

export const Seperator = ({ children, label, value }) => {
  return (
    <div className="form-group">
      <label className="text-capitalize">
        {label}
      </label>
      {value &&
        <div className="form-control">
          {value}
        </div>}
      {children}
    </div>
  )
}
