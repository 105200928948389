import React from 'react'
import { Offcanvas } from 'bootstrap'

export const OffcanvasOpen = ({ children, target }) => {
  const handleOpen = () => {
    const offcanvas = Offcanvas.getOrCreateInstance(`#${target}`)
    offcanvas.show()
  }

  return (
    <React.Fragment>
      {target
        ? <span onClick={handleOpen}>
            {children}
          </span>
        : <span>
            {children}
          </span>}
    </React.Fragment>
  )
}

export const OffcanvasClose = ({ children, target }) => {
  const handleClose = () => {
    const offcanvas = Offcanvas.getInstance(`#${target}`)
    offcanvas.hide()
  }

  return (
    <React.Fragment>
      {target
        ? <span onClick={handleClose}>
            {children}
          </span>
        : <span>
            {children}
          </span>}
    </React.Fragment>
  )
}

export const OffcanvasStart = ({ children, header, target, width }) => {
  return (
    <div className="offcanvas offcanvas-start" tabIndex="-1" id={target} style={{ width: `${width}px` }}>
      {header &&
        <div className="offcanvas-header border-bottom ">
          <h5 className="offcanvas-title text-nowrap text-truncate" id="offcanvasLabel">
            {header}
          </h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>}
      <div className="offcanvas-body p-0">
        {children}
      </div>
    </div>
  )
}

export const OffcanvasEnd = ({ children, header, login, target, width }) => {
  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id={target} style={{ width: `${width}px` }}>
      {header &&
        <div className="offcanvas-header border-bottom ">
          <h5 className="offcanvas-title text-nowrap text-truncate" id="offcanvasLabel">
            {header}
          </h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>}

      <div className="text-nowrap text-truncate px-3 py-1 1bg-danger border-bottom">
        <span>
          {login}
        </span>
      </div>

      <div className="offcanvas-body p-0">
        {children}
      </div>
    </div>
  )
}
