import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { Menu, MenuPurge, MenuRestore } from '../../../../../shared/menu/menu'
import { useApiListGroup } from '../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { useRoleOrgGroup } from '../../../../../role/hook/role-org'

export function GroupRecycle() {
  const apiListGroup = useApiListGroup()

  const roleOrgGroup = useRoleOrgGroup()

  const handeReady = async () => await apiListGroup.get('deleted')

  const handleRestore = async item => {
    await apiListGroup.put(`${item.id}/restore`)
    await apiListGroup.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListGroup} hideFloatingFilter onReady={handeReady}>
            <Column field="name" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show={roleOrgGroup.accessDelete} />
                  <MenuPurge to={`/org/group/list/recycle/${row.id}/purge`} show={roleOrgGroup.accessPurge} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
