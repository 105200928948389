import React, { useMemo } from 'react'
import { SelectList } from '../select-list/select-list'
import { IconGroup } from '../../../../../../../shared/icon/icon'
import { useAddUserContext } from '../context'
import { IconButtonClick } from '../../../../../../../shared/icon/icon-button-click'
import { useButtonCount } from './hooks'

export const SelectGroupDropdown = ({ selectedGroups, readonlyGroups, disabled, onClick }) => {
  const count = useButtonCount(selectedGroups, readonlyGroups)
  const text = useMemo(() => (count ? `groups (${count})` : 'groups'), [count])
  return (
    <button className="dropdown-item" disabled={disabled} onClick={onClick}>
      <span className="me-1">
        <IconGroup />
      </span>
      <span>
        {text}
      </span>
    </button>
  )
}

export const SelectGroupButton = ({ selectedGroups, readonlyGroups, small, disabled, onClick }) => {
  const count = useButtonCount(selectedGroups, readonlyGroups)
  const text = useMemo(() => (count ? `groups (${count})` : 'groups'), [count])
  return <IconButtonClick Icon={IconGroup} text={text} disabled={disabled} small={small} onClick={onClick} />
}

export const SelectGroupModal = ({ target, selectedGroups, readonlyGroups, onUpdate, onHide }) => {
  const addUserContext = useAddUserContext()

  const handleClose = () => onHide()

  const onAdd = item => {
    let newList = [...selectedGroups, item]
    onUpdate(newList)
  }

  const onRemove = item => {
    let newList = selectedGroups.filter(i => i.id !== item.id)
    onUpdate(newList)
  }

  return (
    <React.Fragment>
      <SelectList
        header="Groups"
        selectedItems={selectedGroups}
        readonlyItems={readonlyGroups}
        items={addUserContext.allGroups}
        modalTarget={target}
        fnTagFormat={item => item.name}
        fnListFormat={item => item.name}
        fnListFilter={(filter, items) => items.filter(i => i.name.toLowerCase().includes(filter))}
        onAdd={onAdd}
        onRemove={onRemove}
        onClose={handleClose}
      />
    </React.Fragment>
  )
}
