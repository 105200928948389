import { useMemo } from 'react'
import { useRoleContext } from '../role.context'
import { useAuthContext } from '../../context/auth.context'
import { ROLE_ORG } from '../role-const'

const fullAccess = { accessCreate: true, accessRead: true, accessUpdate: true, accessDelete: true, accessPurge: true }
const noAccess = { accessCreate: false, accessRead: false, accessUpdate: false, accessDelete: false, accessPurge: false }

export const useRoleOrg = type => {
  const authContext = useAuthContext()
  const roleContext = useRoleContext()

  return useMemo(
    () => {
      if (authContext.state.isSysRoot) {
        return fullAccess
      }

      if (authContext.state.isSysAdmin) {
        return fullAccess
      }

      return roleContext.orgRoles[type] || noAccess
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state, roleContext.orgRoles]
  )
}

export const useRoleOrgGroup = () => useRoleOrg(ROLE_ORG.GROUP)
export const useRoleOrgGroupUser = () => useRoleOrg(ROLE_ORG.GROUP_USER)
export const useRoleOrgNodeGroup = () => useRoleOrg(ROLE_ORG.NODE_GROUP)
export const useRoleOrgNodeUser = () => useRoleOrg(ROLE_ORG.NODE_USER)
export const useRoleOrgNode = () => useRoleOrg(ROLE_ORG.NODE)
export const useRoleOrgAssessmentManage = () => useRoleOrg(ROLE_ORG.ASSESSMENT_MANAGE)
export const useRoleOrgAssessmentReport = () => useRoleOrg(ROLE_ORG.ASSESSMENT_REPORT)
export const useRoleOrgTradeshow = () => useRoleOrg(ROLE_ORG.TRADESHOW)
export const useRoleOrgAnalytic = () => useRoleOrg(ROLE_ORG.ANALYTIC)
export const useRoleOrgUser = () => useRoleOrg(ROLE_ORG.USER)
