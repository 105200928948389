import React from 'react'
import { Form, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { Card } from '../../../../../../shared/card/card'

export const LoginDetails = ({ state, onSubmit }) => {
  return (
    <React.Fragment>
      <Card title="login">
        <Form state={state.form} submitting={state.submitting} onSubmit={onSubmit}>
          <FormText label="email" name="email" />
          <FormText label="first name" name="firstName" />
          <FormText label="last name" name="lastName" />
          <FormSubmit text="update login" />
        </Form>
      </Card>
    </React.Fragment>
  )
}
