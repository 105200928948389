import React, { useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { SearchListDevice } from '../../../../../../../shared/legacy/dropdown-search/search-list-device'
import { SearchListSession } from '../../../../../../../shared/legacy/dropdown-search/search-list-session'
import { SearchListCourseUser } from '../../../../../../../shared/legacy/dropdown-search/search-list-course-user'
import { useQuery } from '../../../../../../../shared/hooks/location'

export function Selector({ userID, users, sessionID, steps, devices, deviceSteps }) {
  const history = useHistory()
  const query = useQuery()
  const { url } = useRouteMatch()

  let device = query.get('device')

  let deviceSessions = useMemo(
    () => {
      let sessionIDs = deviceSteps.map(i => i.sessionID)

      sessionIDs = [...new Set(sessionIDs)]

      let sessions = []

      for (let sessionID of sessionIDs) {
        let session = deviceSteps.find(i => i.sessionID === sessionID)
        sessions.push(session)
      }

      return sessions.sort((a, b) => (a.startOn > b.startOn ? -1 : 1))
    },
    [deviceSteps]
  )

  const buildParams = (userID, sessionID, device) => {
    let params = '?'

    if (userID) {
      params += `&user=${userID}`
    }

    if (sessionID) {
      params += `&session=${sessionID}`
    }

    if (device) {
      params += `&device=${device}`
    }

    return params
  }

  const handleUserSelect = user => {
    if (user) {
      let params = buildParams(user && user.userID, user ? sessionID : null, null)
      history.push(`${url}${params}`)
    } else {
      let params = buildParams(null, null, null)
      history.push(`${url}${params}`)
    }
  }

  const handleDeviceSelect = device => {
    if (device) {
      let params = buildParams(userID, device ? sessionID : null, device)
      history.push(`${url}${params}`)
    } else {
      let params = buildParams(userID, device ? sessionID : null, device)
      history.push(`${url}${params}`)
    }
  }

  const handleSessionSelect = session => {
    if (session) {
      let params = buildParams(userID, session && session.sessionID, session && session.deviceType)
      history.push(`${url}${params}`)
    } else {
      let params = buildParams(userID, null, null)
      history.push(`${url}${params}`)
    }
  }

  let selectedUser = useMemo(
    () => {
      if (!userID) {
        return null
      }

      let id = parseInt(userID)

      return users.find(i => i.userID === id)
    },
    [users, userID]
  )

  let selectedSession = useMemo(
    () => {
      if (!sessionID) {
        return null
      }

      let id = parseInt(sessionID)

      return deviceSessions.find(i => i.sessionID === id)
    },
    [deviceSessions, sessionID]
  )

  return (
    <React.Fragment>
      <div className="row m-2">
        <div className="col-4">
          <SearchListCourseUser
            selectText="all users"
            nullText="all users"
            items={users}
            selectedItem={selectedUser}
            onSelect={handleUserSelect}
          />
        </div>
        <div className="col-4">
          <SearchListDevice
            selectText="all devices"
            nullText="all devices"
            items={devices}
            selectedItem={device}
            onSelect={handleDeviceSelect}
          />
        </div>
        <div className="col-4">
          <SearchListSession
            selectText="all sessions"
            nullText="all sessions"
            items={deviceSessions}
            selectedItem={selectedSession}
            disabled={!userID}
            onSelect={handleSessionSelect}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
