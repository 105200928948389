import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useModalActionID } from '../../../../../../../shared/component/modal-dialog'
import { SelectFile } from './select-file'
import { ImportContext, useImportContext } from './context'
import { ModalImport } from './modal-import'
import Papa from 'papaparse'

export const ImportCSV = forwardRef(({ onImport }, ref) => {
  const refFileSelect = useRef()

  const [data, updateData] = useState(null)

  useImperativeHandle(
    ref,
    () => ({
      open: () => refFileSelect.current.click()
    }),
    []
  )

  const handleFileSelect = files => {
    const reader = new FileReader()
    reader.onload = () => {
      const result = Papa.parse(reader.result, { header: false, delimiter: ',' })
      updateData(result.data)
    }
    reader.readAsText(files[0])
  }

  const handleImport = data => {
    onImport && onImport(data)
    updateData(null)
  }

  return (
    <React.Fragment>
      <ImportContext>
        <SelectFile ref={refFileSelect} onFileSelect={handleFileSelect} />
        {data && <Import data={data} onImport={handleImport} />}
      </ImportContext>
    </React.Fragment>
  )
})

export const Import = ({ data, onImport }) => {
  const importContext = useImportContext()

  const [target, show, hide] = useModalActionID()

  useEffect(
    () => {
      if (data) {
        var headers = data[0].filter(i => i !== '')
        let rows = data.slice(1)
        importContext.updateHeaders(headers)
        importContext.updateRows(rows)
        let email = headers.findIndex(i => i.replace(' ', '').toLowerCase().includes('email'))
        let firstName = headers.findIndex(i => i.replace(' ', '').toLowerCase().includes('firstname'))
        let lastName = headers.findIndex(i => i.replace(' ', '').toLowerCase().includes('lastname'))
        let role = headers.findIndex(i => i.replace(' ', '').toLowerCase().includes('role'))
        let group = headers.findIndex(i => i.replace(' ', '').toLowerCase().includes('group'))
        let content = headers.findIndex(i => i.replace(' ', '').toLowerCase().includes('content'))
        let template = headers.findIndex(i => i.replace(' ', '').toLowerCase().includes('template'))
        let library = headers.findIndex(i => i.replace(' ', '').toLowerCase().includes('library'))
        let indexes = {
          email: email === -1 ? undefined : email,
          firstName: firstName === -1 ? undefined : firstName,
          lastName: lastName === -1 ? undefined : lastName,
          role: role === -1 ? undefined : role,
          group: group === -1 ? undefined : group,
          content: content === -1 ? undefined : content,
          template: template === -1 ? undefined : template,
          library: library === -1 ? undefined : library
        }
        importContext.updateIndexes(indexes)
        importContext.updateRoleValues({})
        importContext.updateGroupValues({})
        importContext.updateContentValues({})
        importContext.updateTemplateValues({})
        importContext.updateLibraryValues({})
        importContext.updateCurrentStep(0)
        show()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  )

  const handleComplete = () => {
    let roleValues = Object.values(importContext.roleValues)
    let groupValues = Object.values(importContext.groupValues)
    let contentValues = Object.values(importContext.contentValues)
    let templateValues = Object.values(importContext.templateValues)
    let libraryValues = Object.values(importContext.libraryValues)

    onImport &&
      onImport({
        rows: importContext.rows,
        indexes: importContext.indexes,
        roleValues: roleValues,
        groupValues: groupValues,
        contentValues: contentValues,
        templateValues: templateValues,
        libraryValues: libraryValues
      })

    hide()
  }

  return (
    <React.Fragment>
      <ModalImport target={target} onComplete={handleComplete} />
    </React.Fragment>
  )
}
