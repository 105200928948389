export const AnalyticCard = ({ id, name, description, onClick }) => {
  const handleClick = () => onClick && onClick(id)

  return (
    <div className="card" style={{ cursor: 'pointer' }} onClick={handleClick}>
      <div className="card-body">
        <div className="card-title text-primary fw-bold text-capitalize fs-5">
          {name}
        </div>
        <div className="card-text text-muted">
          {description}
        </div>
      </div>
    </div>
  )
}
