import React, { useContext, useMemo, useRef, useState } from 'react'
import { AuthContext } from '../../../../../../context/auth.context'
import { useApiFormNodeCourse, useApiFormNodeTemplate, useNodeCourseProgress } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../../../../shared/form/form'
import { IconCopy } from '../../../../../../shared/icon/icon'
import { useDataContext } from '../../data-context'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Modal } from '../../../../../../shared/component/modal'
import { NodeCopyProgress } from '../../common/node-copy/node-copy-progress'
import { pathNodeBreadcrumbMap } from '../../common/shared/path-node-breadcrumb'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { FormSelectCategory } from '../../../../../../shared/form/form-control/form-select-category'
import { NodeTemplateMenuFull } from '../common/node-template-menu'
import { pathNodeContentMap } from '../../common/shared/path-node-content'
import { NodeCaffeine } from '../../common/shared/node-caffeine'

export const NodeTemplateCourse = ({ parentURL, nodeID }) => {
  const history = useHistory()

  const refModal = useRef()

  const authContext = useContext(AuthContext)
  const dataContext = useDataContext()

  const apiFormNodeTemplate = useApiFormNodeTemplate()
  const apiFormNodeCourse = useApiFormNodeCourse()

  const title = useMemo(() => `create course ${apiFormNodeTemplate.form.name}`, [apiFormNodeTemplate.form.name])

  const handleMatch = () => apiFormNodeTemplate.get(nodeID)

  const [copiedNodeID, updateCopiedNodeID] = useState()
  const [destLocation, updateDestLocation] = useState()

  const handleConfirm = () => {
    refModal.current.hide()

    let path = pathNodeContentMap(dataContext.nodeMap, copiedNodeID)
    history.push(path)
  }

  const handleComplete = async progress => {
    let node = await apiFormNodeCourse.get(progress.nodeID)

    updateCopiedNodeID(node.id)

    const moveToPath = ({ map }) => {
      dataContext.unsubscribe(moveToPath)
      const destLocation = pathNodeBreadcrumbMap(map, node.id)
      updateDestLocation(destLocation)
      refModal.current.show()
    }

    dataContext.subscribe(moveToPath)
    dataContext.nodeInsert(node)
  }

  const [start, progress] = useNodeCourseProgress(handleComplete)

  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  useLoad(() => authContext.state.jwtClaims.organizationID && updateCategoryOrganizationID(authContext.state.jwtClaims.organizationID), [
    authContext.state.jwtClaims.organizationID
  ])

  const handleSubmit = async data => {
    if (data.parentID === -1) {
      data.parentID = null
    }

    await start(data)
  }

  const HeaderMenu = () => NodeTemplateMenuFull(parentURL, apiFormNodeTemplate.form)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconCopy} text="create template" onMatch={handleMatch}>
        <Modal ref={refModal} title={title} confirmText="ok" backdropStatic onConfirm={handleConfirm}>
          <p>
            {apiFormNodeTemplate.form.name} has successfully been created {destLocation && `at ${destLocation}`}
          </p>
        </Modal>

        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <NodeCaffeine node={apiFormNodeTemplate.form}>
            <Form onSubmit={handleSubmit}>
              <FormHiddenNumber name="templateNodeID" value={nodeID} required shouldDirty />
              <FormSelectCategory lable="category" name="destParentID" organizationID={categoryOrganizationID} required />
              <FormText label="name" name="destName" defaultValue={apiFormNodeTemplate.form.name} required />
              <FormSubmitBack text="create course" />
            </Form>

            <NodeCopyProgress progress={progress} />
          </NodeCaffeine>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
