import React from 'react'
import { Switch } from 'react-router-dom'

import { DisplayAuth } from '../../context/display-auth'

import { RedirectDashboard } from '../redirect-dashboard'
import { Timeout } from '../../shared/component/timeout'
import { LayoutRoot } from '../../layout/authorized/root/layout-root'
import { LayoutAdmin } from '../../layout/authorized/admin/layout-admin'
import { LayoutOrg } from '../../layout/authorized/org/layout-org'

export const RouteAdmin = () => {
  return (
    <React.Fragment>
      <RedirectDashboard>
        <Timeout />
        <Switch>
          <DisplayAuth>
            <LayoutRoot />
            <LayoutAdmin />
            <LayoutOrg />
          </DisplayAuth>
        </Switch>
      </RedirectDashboard>
    </React.Fragment>
  )
}
