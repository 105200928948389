import { useCallback, useState } from 'react'
import { useAuthProviderForm } from './state'
import { useLoad } from '../shared/hooks/load'

export const useAuthProviderDetailsState = token => {
  const [authProviderFormActions] = useAuthProviderForm()

  const [authPassave, updateAuthPassave] = useState(null)
  const [error, updateError] = useState('')

  const getAuthPassave = useCallback(async token => {
    try {
      let authProviderDetails = await authProviderFormActions.get(`${token}/details`)

      updateAuthPassave(authProviderDetails)
    } catch (error) {
      if (error.response.status === 404) {
        updateError('not found')
      } else {
        updateError('error')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLoad(
    () => getAuthPassave(token),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  )

  return [authPassave, error]
}

export const useGetAuthProviderDetails = () => {
  const [authProviderFormActions] = useAuthProviderForm()

  return useCallback(async token => {
    let authProviderDetails = await authProviderFormActions.get(`${token}/details`)
    return authProviderDetails
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
