import { useEffect, useState } from 'react'

export const useSelectValue = value => {
  const [state, updateState] = useState()

  useEffect(
    () => {
      updateState(value)
    },
    [value]
  )

  return [state, updateState]
}
