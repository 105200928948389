import React, { useEffect } from 'react'
import { useApiListRoleOrgUser } from '../../api-new/state/content'
import { Card } from '../../shared/card/card'
import { SelectTag } from '../../shared/select-tag/select-tag'

export const RoleSelectUser = ({ userID }) => {
  const apiListRoleOrgUser = useApiListRoleOrgUser()

  useEffect(
    () => {
      apiListRoleOrgUser.get(`${userID}/user`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userID]
  )

  const handleAdd = async item => {
    await apiListRoleOrgUser.post({ roleID: item.roleID, userID: userID })
    await apiListRoleOrgUser.get(`${userID}/user`)
  }

  const handleRemove = async item => {
    await apiListRoleOrgUser.delete(`${item.roleID}/${userID}`)
    await apiListRoleOrgUser.get(`${userID}/user`)
  }

  return (
    <React.Fragment>
      <Card title="User Roles">
        <SelectTag
          selectText="select role"
          data={apiListRoleOrgUser.items}
          fnLabel={item => item.roleName}
          fnActive={item => item.isActive}
          fnReadOnly={item => item.isReadOnly}
          onAdd={handleAdd}
          onRemove={handleRemove}
        />
      </Card>
    </React.Fragment>
  )
}
