import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormCheckbox, FormSubmitBack, FormText, FormTextArea } from '../../../../../shared/form/form'
import { IconAdd } from '../../../../../shared/icon/icon'
import { Card } from '../../../../../shared/card/card'
import { useApiFormRoleOrg } from '../../../../../api-new/state/content'

export const RoleOrgAdd = () => {
  const history = useHistory()

  const apiFormRoleOrg = useApiFormRoleOrg()

  const handleSubmit = async data => {
    await apiFormRoleOrg.post(data)
    history.goBack()
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add">
        <Card title="add role">
          <Form onSubmit={handleSubmit}>
            <FormText label="role" name="role" autoFocus />
            <FormTextArea label="description" name="description" />
            <FormCheckbox label="Auto Owner" name="isAutoOwner" />
            <FormCheckbox label="Auto User" name="isAutoUser" />
            <FormCheckbox label="Read Only" name="isReadOnly" />
            <FormSubmitBack text="add role" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
