import React, { useCallback, useMemo, useState } from 'react'
import { IconSearch, IconTimes } from '../../../../../../shared/icon/icon'
import { useHistory } from 'react-router-dom'

export const FilterInput = ({ value, onChange }) => {
  const handleChange = e => onChange && onChange(e.target.value)
  const handlerClear = () => onChange && onChange('')

  return (
    <React.Fragment>
      <div className="input-group">
        <span className="input-group-text bg-theme border-end-0">
          <IconSearch />
        </span>
        <input className="form-control border-start-0" placeholder="search" value={value} onChange={handleChange} />
        <span className="input-group-text edxr-pointer" onClick={handlerClear}>
          <IconTimes />
        </span>
      </div>
    </React.Fragment>
  )
}

export const useFilterList = () => {
  const history = useHistory()
  const [filter, updateFilter] = useState('')

  const selectFilter = useCallback(path => {
    updateFilter('')
    history.replace(path)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showFilter = useMemo(() => filter.length > 0, [filter])

  return [filter, updateFilter, showFilter, selectFilter]
}
