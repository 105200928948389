export const chartColorAndroid = `#8CC16B`
export const chartColorMac = '#4D91F7'
export const chartColorMobileVR = '#EC662B'
export const chartColorIOS = '#464646'
export const chartColorWindows = '#F4BB42'
export const chartColorUnknown = '#795548'

export const chartColorMain = '#3F4B62'

export const getPlatformColors = platforms => {
  const colors = []

  platforms.forEach(platform => {
    switch (platform.toLowerCase()) {
      case 'android':
        colors.push(chartColorAndroid)
        break
      case 'ios':
        colors.push(chartColorIOS)
        break
      case 'mac':
        colors.push(chartColorMac)
        break
      case 'mobile vr':
        colors.push(chartColorMobileVR)
        break
      case 'windows':
        colors.push(chartColorWindows)
        break
      default:
        colors.push(chartColorUnknown)
    }
  })

  return colors
}
