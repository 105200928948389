import React, { useCallback } from 'react'
import { SearchList } from './search-list'
import { IconOrganization } from '../../icon/icon'

const DisplayItemSelected = ({ selectText, item }) => {
  return (
    <React.Fragment>
      {item &&
        <span>
          <IconOrganization />
          {item.name}
        </span>}
      {!item &&
        <span className="text-capitalize">
          {selectText || 'select'}
        </span>}
    </React.Fragment>
  )
}

const DisplayItemList = ({ item }) => {
  return (
    <React.Fragment>
      {item &&
        <span>
          <IconOrganization />
          {item.name}
        </span>}
    </React.Fragment>
  )
}

const handleFilter = (items, value) => {
  if (!value) {
    return items
  }

  const searchValues = value.toLowerCase().trim().split(' ')

  return items.filter(item => {
    let value = item.name.toLowerCase()
    return searchValues.every(i => value.includes(i))
  })
}

const handleSort = items => {
  return items.sort((a, b) => a.loginID - b.loginID)
}

export const SearchListOrganization = ({ dropLeft, selectText, actionText, items, selectedItem, onAdd, onSelect }) => {
  const handleSelectedItem = useCallback(
    item => {
      if (item && selectedItem) {
        return item.id === selectedItem.id
      }

      return false
    },
    [selectedItem]
  )

  return (
    <React.Fragment>
      <SearchList
        dropLeft={dropLeft}
        items={items}
        selectedItem={selectedItem}
        DisplayItemSelected={DisplayItemSelected}
        DisplayItemList={DisplayItemList}
        selectText={selectText}
        actionText={actionText}
        fnFilter={handleFilter}
        fnSort={handleSort}
        fnSelectedItem={handleSelectedItem}
        onAdd={onAdd}
        onSelect={onSelect}
      />
    </React.Fragment>
  )
}
