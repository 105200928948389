import React from 'react'
import { Route } from 'react-router-dom'
import { AssessmentUserSummary } from './assessment-user-summary'
import { AssessmentUserAttempts } from './assessment-user-attempts'
import { AssessmentResultDetails } from '../assessment-result/assessment-result-details'
import { Delete } from '../../../../shared/page/page-delete'
import { useApiFormUserAssessment } from '../../../../api-new/state/assessment'
import { useHistory } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { AssessmentUserRecycle } from './assessment-user-recycle'
import { Purge } from '../../../../shared/page/page-purge'
import { AssessmentView } from '../assessment-view/assessment-view'

export const RouteAssessmentResultUser = () => {
  const history = useHistory()

  const handleDelete = () => history.goBack()
  const handlePurge = () => history.goBack()

  return (
    <React.Fragment>
      <Route path="/org/user/list/edit/:userID/assessment-reports" component={AssessmentUserSummary} />
      <Route path="/org/user/list/edit/:userID/assessment-reports/view/:assessmentID" component={AssessmentView} />
      <Route path="/org/user/list/edit/:userID/assessment-reports/:assessmentID/assessment" component={AssessmentUserAttempts} />
      <Route
        path="/org/user/list/edit/:userID/assessment-reports/:assessmentID/assessment/:userAssessmentID/details"
        component={AssessmentResultDetails}
      />

      <Route path="/org/user/list/edit/:userID/assessment-reports/:assessmentID/assessment/delete/:deleteID">
        <Delete useApiForm={useApiFormUserAssessment} titleParam="assessment" onSuccess={handleDelete} />
      </Route>
      <Route path="/org/user/list/edit/:userID/assessment-reports/recycle" component={AssessmentUserRecycle} />
      <Route path="/org/user/list/edit/:userID/assessment-reports/recycle/purge/:purgeID">
        <Purge useApiForm={useApiFormUserAssessment} titleParam="assessment" onSuccess={handlePurge} />
      </Route>
    </React.Fragment>
  )
}
