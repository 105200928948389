import React from 'react'
import { Route } from 'react-router-dom'
import { AssessmentCourseReports } from './assessment-course-reports'
import { useHistory, useRouteMatch } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { AssessmentCourseUsers } from './assessment-course-users'
import { AssessmentResultDetails } from '../assessment-result/assessment-result-details'
import { AssessmentView } from '../assessment-view/assessment-view'
import { Delete } from '../../../../shared/page/page-delete'
import { useApiFormUserAssessment } from '../../../../api-new/state/assessment'
import { AssessmentCourseRecycle } from './assessment-course-recycle'
import { Purge } from '../../../../shared/page/page-purge'
import { AssessmentCourseAttempts } from './assessment-course-attempts'

export const RouteAssessmentResultCourse = () => {
  const { url } = useRouteMatch()
  const history = useHistory()

  const handleDelete = () => history.goBack()
  const handlePurge = () => history.goBack()

  return (
    <React.Fragment>
      <Route path={`${url}/assessment-report/list/:nodeID`} component={AssessmentCourseReports} />
      <Route path={`${url}/assessment-report/list/:nodeID/view/:assessmentID`} component={AssessmentView} />
      <Route path={`${url}/assessment-report/list/:nodeID/users/:assessmentID`} component={AssessmentCourseUsers} />

      <Route
        path={`${url}/assessment-report/list/:nodeID/users/:assessmentID/attemps/:assessmentID/:userID`}
        component={AssessmentCourseAttempts}
      />

      <Route
        path={`${url}/assessment-report/list/:nodeID/users/:assessmentID/attemps/:assessmentID/:userID/:userAssessmentID/details`}
        component={AssessmentResultDetails}
      />

      <Route path={`${url}/assessment-report/list/:nodeID/users/:assessmentID/attemps/:assessmentID/:userID/delete/:deleteID`}>
        <Delete useApiForm={useApiFormUserAssessment} titleParam="assessment" onSuccess={handleDelete} />
      </Route>
      <Route path={`${url}/assessment-report/list/:nodeID/recycle`} component={AssessmentCourseRecycle} />
      <Route path={`${url}/assessment-report/list/:nodeID/recycle/purge/:purgeID`}>
        <Purge useApiForm={useApiFormUserAssessment} titleParam="assessment" onSuccess={handlePurge} />
      </Route>
    </React.Fragment>
  )
}
