import { isDatatypeCategory, isDatatypeCourse, isDatatypeStreamingVideo } from './metadata'
import { getMap } from './node-path'

export const pathNodeContent = (nodes, nodeID) => {
  const nodeMap = getMap(nodes)
  return pathNodeContentMap(nodeMap, nodeID)
}

export const pathNodeContentMap = (nodeMap, nodeID) => {
  const recBuild = (nodeMap, nodeID) => {
    let node = nodeMap[nodeID]

    if (!node) {
      return
    }

    if (node.parentID) {
      let path = recBuild(nodeMap, node.parentID, true)
      return contentPath(path, node)
    } else {
      return contentPath(`/org/node-content`, node)
    }
  }

  return recBuild(nodeMap, nodeID, false)
}

const contentPath = (path, node) => {
  if (isDatatypeCourse(node)) {
    return `${path}/course/${node.id}`
  }

  if (isDatatypeStreamingVideo(node)) {
    return `${path}/course/${node.id}`
  }

  if (isDatatypeCategory(node)) {
    return `${path}/node/${node.id}`
  }

  return path
}
