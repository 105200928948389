import React from 'react'
import { useHistory } from 'react-router-dom'
import { useApiFormNodeCourse } from '../../../../../../api-new/state/content'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormSubmitBack, FormTextConfirm } from '../../../../../../shared/form/form'
import { IconDelete } from '../../../../../../shared/icon/icon'
import { useDataContext } from '../../data-context'
import { NodeContentMenuFull } from '../common/node-content-menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'

export const NodeCourseDelete = ({ grandParentURL, parentURL, nodeID }) => {
  const history = useHistory()

  const dataContext = useDataContext()

  const apiFormNodeCourse = useApiFormNodeCourse()

  const handleMatch = async () => await apiFormNodeCourse.get(nodeID)

  const handleSubmit = async () => {
    await apiFormNodeCourse.delete(nodeID)
    dataContext.nodeRemoveID(nodeID)
    history.replace(grandParentURL)
  }

  const HeaderMenu = () => NodeContentMenuFull(parentURL, apiFormNodeCourse.form)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text="delete course" onMatch={handleMatch}>
        <CardFullHeightScrollY title={apiFormNodeCourse.form.name} HeaderMenu={HeaderMenu}>
          <div className="py-2">This is a destructive action</div>

          <Form onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="delete" subtext="Type delete to confirm" confirmValue="delete" name="test" required autoFocus />
            <FormSubmitBack text="delete course" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
