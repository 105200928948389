import React from 'react'
import { useImportContext } from './context'

export const Breadcrumb = () => {
  const importContext = useImportContext()

  return (
    <React.Fragment>
      <div class="stepper-wrapper">
        {importContext.steps.map((step, i) =>
          <div
            key={i}
            className={`stepper-item ${importContext.currentStep === i && 'active'} ${importContext.currentStep > i && 'completed'}`}>
            <div class="step-counter">
              {i + 1}
            </div>
            <small className="text-uppercase">
              {step.name}
            </small>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
