import { useMemo, useState } from 'react'
import { ChartBar } from '../../../../../../shared/chart/chart'
import { ChartHeader, SelectRangeInterval, Widget } from '../../../../../../shared/chart/widget'
import { IconEvent } from '../../../../../../shared/icon/icon'
import { Link } from 'react-router-dom'
import { useApiFormLogEvent, useApiListLogEvent } from '../../../../../../api-new/state/content'
import { chartColorMain } from '../../../../../../shared/chart/chart-color'
import { useLoad } from '../../../../../../shared/hooks/load'
import moment from 'moment'

export const LogEventChart = () => {
  const apiFormLogEvent = useApiFormLogEvent()
  const apiListLogEvent = useApiListLogEvent()

  const [start, updateStart] = useState(null)
  const [end, updateEnd] = useState(null)
  const [interval, updateInterval] = useState('month1')

  const chartData = useMemo(
    () => {
      const labels = apiListLogEvent.items.map(item => item.dateStart)
      const data = apiListLogEvent.items.map(item => item.count)

      const dataSet = {
        data: data,
        borderColor: chartColorMain,
        backgroundColor: chartColorMain,
        borderWidth: 1
      }

      return { labels: labels, dataSet: dataSet }
    },
    [apiListLogEvent.items]
  )

  useLoad(async () => {
    const result = await apiFormLogEvent.get('chart/range/details')
    let start = moment(result.min).format('YYYY-MM-DD')
    let end = moment().format('YYYY-MM-DD')
    updateStart(start)
    updateEnd(end)
  }, [])

  useLoad(
    () => {
      if (start && end && interval) {
        apiListLogEvent.get(`chart/range?start=${start}&end=${end}&interval=${interval}`)
      }
    },
    [start, end, interval]
  )

  const LinkComponent = () => <Link to="/admin/dashboard/log-event/list">view all</Link>

  return (
    <Widget
      header={<ChartHeader Icon={IconEvent} text="EVENTS" Component={LinkComponent} />}
      body={<ChartBar labels={chartData.labels} data={chartData.dataSet} displayX displayY />}
      footer={
        <SelectRangeInterval
          start={start}
          end={end}
          intervals={apiFormLogEvent.form.intervals}
          interval={interval}
          minDate={apiFormLogEvent.form.min}
          maxDate={apiFormLogEvent.form.max}
          onChangeStart={updateStart}
          onChangeEnd={updateEnd}
          onChangeInterval={updateInterval}
        />
      }
    />
  )
}
