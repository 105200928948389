import React from 'react'
import { useMetadataTypeIsCaffeine } from '../hooks/hooks'

export const NodeCaffeine = ({ children, node }) => {
  const isCaffeine = useMetadataTypeIsCaffeine(node)

  return (
    <React.Fragment>
      {isCaffeine
        ? <React.Fragment>
            {children}
          </React.Fragment>
        : <React.Fragment>
            <div className="p-3">This action is not allowed</div>
          </React.Fragment>}
    </React.Fragment>
  )
}
