import React from 'react'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Menu, MenuAdd, MenuDelete, MenuEdit, MenuRecycle } from '../../../../../shared/menu/menu'
import { useApiFormNode } from '../../../../../api-new/state/content'
import { useApiFormAsset } from '../../../../../api-new/state/content'
import { useApiListAsset } from '../../../../../api-new/state/content'
import { TableBuilder, Column, ColumnFormat, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { Route, useHistory, useRouteMatch } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { AssetEdit } from './asset-edit'
import { Delete } from '../../../../../shared/page/page-delete'
import { AssetAdd } from './asset-add'
import { AssetRecycle } from './asset-recycle'
import { IconList } from '../../../../../shared/icon/icon'

export const AssetList = () => {
  const { url } = useRouteMatch()
  const { nodeID } = useParamsInt()
  const history = useHistory()

  const apiFormNode = useApiFormNode()
  const apiListAsset = useApiListAsset()

  const handleMatch = () => apiFormNode.get(nodeID)

  const handleReady = () => apiListAsset.get(`${nodeID}/node`)

  const handleAdd = asset => history.push(`${url}/asset/${asset.id}`)
  const handleDelete = asset => history.push(`${url}/asset/${asset.id}/delete`)

  const headerMenu = () =>
    <Menu>
      <MenuAdd to={`${url}/add/${nodeID}`} show pin />
      <MenuRecycle to={`${url}/recycle`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="assets" onMatch={handleMatch}>
        <CardFullHeightScrollY title={apiFormNode.form.name} HeaderMenu={headerMenu}>
          <TableBuilder apiList={apiListAsset} onReady={handleReady}>
            <Column field="name" />
            <ColumnFormat field="metaData" selector={row => (row.metaData ? JSON.parse(row.metaData.toLowerCase()).datatype : '')} />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`${url}/asset/${row.id}`} show />
                  <MenuDelete to={`${url}/asset/${row.id}/delete`} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/add/:nodeID`}>
        <AssetAdd onAdd={handleAdd} />
      </Route>

      <Route path={`${url}/asset/:assetID`}>
        <AssetEdit onDelete={handleDelete} />
      </Route>

      <Route path={`${url}/asset/:deleteID/delete`}>
        <Delete useApiForm={useApiFormAsset} titleParam="name" successPath={`${url}`} />
      </Route>

      <Route path={`${url}/recycle`} component={AssetRecycle} />
    </React.Fragment>
  )
}
