import React, { useEffect, useMemo, useState } from 'react'
import { Progress } from '../progress'
import { FileSelect } from '../file/file-select'
import { useFileUploadContent } from './hooks'
import { Card } from '../../card/card'
import { SizeDisplay } from '../size-display'
import { ButtonPrimary, ButtonSecondary } from '../button'

const STATE_UPLOAD_READY = 1
const STATE_UPLOAD_PROGRESS = 2
const STATE_UPLOAD_COMPLETE = 3
const STATE_UPLOAD_CANCEL = 4

export const FileUpload = ({ storageKey, onFile, onComplete, onCancel }) => {
  const [uploadState, updateUploadState] = useState(STATE_UPLOAD_READY)
  const [fileProgress, updateFileProgress] = useState(0)
  const [file, updateFile] = useState()

  const handleStart = () => updateUploadState(STATE_UPLOAD_PROGRESS)

  const handleUpdateProgress = value => updateFileProgress(value)

  const handleComplete = file => {
    onComplete && onComplete(file)
    updateUploadState(STATE_UPLOAD_COMPLETE)
  }

  const handleCancel = file => {
    onCancel && onCancel(file)
    updateUploadState(STATE_UPLOAD_CANCEL)
  }

  const [upload, cancel] = useFileUploadContent(handleStart, handleUpdateProgress, handleComplete, handleCancel)

  const fileSelected = useMemo(() => !!file, [file])
  const fileSize = useMemo(() => (file ? file.size : 0), [file])
  const fileName = useMemo(() => (file ? file.name : ''), [file])

  useEffect(() => updateFileProgress(0), [fileSelected])

  const handleSelect = file => {
    updateFile(file)
    updateUploadState(STATE_UPLOAD_READY)
    onFile && onFile(file)
  }

  const handleUploadStart = () => upload(storageKey, file)

  const handleUploadCloseReady = () => {
    updateFile(null)
    updateUploadState(STATE_UPLOAD_READY)
    onFile && onFile(null)
  }

  const handleUploadCloseComplete = () => {
    updateFile(null)
    updateUploadState(STATE_UPLOAD_READY)
    onFile && onFile(null)
  }

  const handleUploadCloseCancel = () => {
    updateFile(null)
    updateUploadState(STATE_UPLOAD_READY)
    onFile && onFile(null)
  }

  const handleUploadCancel = () => cancel()

  return (
    <React.Fragment>
      {fileSelected
        ? <Card>
            <div className="p-1">
              <div>
                File: {fileName} (<SizeDisplay size={fileSize} />)
              </div>
            </div>
            <div className="p-1">
              <Progress current={fileProgress} length={fileSize} />
            </div>
            <div className="p-1">
              {uploadState === STATE_UPLOAD_READY &&
                <div>
                  <ButtonPrimary text="start upload" onClick={handleUploadStart} />
                  <ButtonSecondary text="cancel upload" onClick={handleUploadCloseReady} />
                </div>}
              {uploadState === STATE_UPLOAD_PROGRESS &&
                <div>
                  <ButtonSecondary text="cancel upload" onClick={handleUploadCancel} />
                </div>}
              {uploadState === STATE_UPLOAD_COMPLETE &&
                <div>
                  <ButtonSecondary text="close" onClick={handleUploadCloseComplete} />
                </div>}
              {uploadState === STATE_UPLOAD_CANCEL &&
                <div>
                  <ButtonSecondary text="close" onClick={handleUploadCloseCancel} />
                </div>}
            </div>
          </Card>
        : <div className="p-2">
            <FileSelect onSelect={handleSelect} />
          </div>}
    </React.Fragment>
  )
}
