export const pathNodeBreadcrumbMap = (nodeMap, nodeID) => {
  const recBuild = (nodeMap, nodeID) => {
    let node = nodeMap[nodeID]

    if (!node) {
      return
    }

    if (node.parentID) {
      let path = recBuild(nodeMap, node.parentID)
      return `${path}/${node.name}`
    } else {
      return `/${node.name}`
    }
  }

  return recBuild(nodeMap, nodeID, false)
}
