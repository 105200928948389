import React from 'react'
import { Route } from 'react-router-dom'
import { RoleSysList } from './page/role-sys-list'
import { RoleSysAdd } from './page/role-sys-add'
import { RoleSysEdit } from './page/role-sys-edit'
import { Delete } from '../../../../shared/page/page-delete'
import { useApiFormRoleSys } from '../../../../api-new/state/content'
import { RoleSysListLogins } from './page/role-sys-list-logins'

export const LayoutRoleSys = () => {
  return (
    <React.Fragment>
      <Route path="/root/role-sys/list" component={RoleSysList} />

      <Route path="/root/role-sys/list/add" component={RoleSysAdd} />
      <Route path="/root/role-sys/list/edit/:roleID" component={RoleSysEdit} />

      <Route path="/root/role-sys/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormRoleSys} titleParam="role" successPath="/root/role-sys/list" />
      </Route>

      <Route path="/root/role-sys/list/logins/:roleSys/role" component={RoleSysListLogins} />
    </React.Fragment>
  )
}
