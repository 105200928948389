import React, { useState } from 'react'
import { useHistory } from '../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { ModalDialog, useModalActions } from './modal-dialog'
import { useRandomID } from '../hooks/random-id'

const ButtonBase = ({ children, type, Icon, text, small, border, disabled, hide, submit, onClick }) => {
  return (
    <React.Fragment>
      {hide
        ? <React.Fragment />
        : <button
            type={submit ? 'submit' : 'button'}
            className={`btn ${type} ${small && 'btn-sm'} ${border && 'border'} text-capitalize text-nowrap m-1`}
            disabled={disabled}
            onClick={onClick}>
            {Icon && <Icon />}
            {children ||
              <span>
                {text}
              </span>}
          </button>}
    </React.Fragment>
  )
}

export const ButtonPrimary = ({ children, Icon, text, small, disabled, hide, submit, onClick }) => {
  return (
    <ButtonBase
      children={children}
      Icon={Icon}
      text={text}
      type="btn-primary"
      small={small}
      disabled={disabled}
      hide={hide}
      submit={submit}
      onClick={onClick}
    />
  )
}

export const ButtonSecondary = ({ children, Icon, text, small, disabled, hide, submit, onClick }) => {
  return (
    <ButtonBase
      children={children}
      Icon={Icon}
      text={text}
      type="btn-secondary"
      small={small}
      disabled={disabled}
      hide={hide}
      submit={submit}
      onClick={onClick}
    />
  )
}

export const ButtonLight = ({ children, Icon, text, small, disabled, hide, submit, onClick }) => {
  return (
    <ButtonBase
      children={children}
      Icon={Icon}
      text={text}
      type="btn-light"
      border={true}
      small={small}
      disabled={disabled}
      hide={hide}
      submit={submit}
      onClick={onClick}
    />
  )
}

export const ButtonPrimaryLink = ({ children, Icon, text, small, disabled, hide, to }) => {
  const history = useHistory()

  const handleClick = () => history.push(to)

  return <ButtonPrimary children={children} Icon={Icon} text={text} small={small} disabled={disabled} hide={hide} onClick={handleClick} />
}

export const ButtonPrimaryDelay = ({ children, Icon, text, small, disabled, hide, delayMS, onClick }) => {
  const [delay, updateDelay] = useState(false)

  const handleClick = () => {
    updateDelay(true)
    setTimeout(() => updateDelay(false), delayMS || 3000)
    onClick && onClick()
  }

  return (
    <ButtonPrimary
      children={children}
      Icon={Icon}
      text={text}
      small={small}
      disabled={disabled || delay}
      hide={hide}
      onClick={handleClick}
    />
  )
}

export const ButtonPrimaryConfirm = ({ children, Icon, text, small, disabled, hide, confirmText, onConfirm }) => {
  const test = useRandomID()

  const modalActions = useModalActions(test)

  const handleClick = () => modalActions.show()
  const handleCancel = () => modalActions.hide()
  const handleConfirm = () => {
    onConfirm && onConfirm()
    modalActions.hide()
  }

  return (
    <React.Fragment>
      <ModalDialog target={test} header="Confirm">
        <div className="m-3">
          {confirmText}
        </div>
        <div>
          <ButtonPrimary text="Confirm" onClick={handleConfirm} />
          <ButtonSecondary text="Cancel" onClick={handleCancel} />
        </div>
      </ModalDialog>
      <ButtonPrimary children={children} Icon={Icon} text={text} small={small} disabled={disabled} hide={hide} onClick={handleClick} />
    </React.Fragment>
  )
}

export const ButtonPrimaryToggle = ({ children, Icon, textTrue, textFalse, small, disabled, hide, value, onChange }) => {
  const handleClick = () => onChange && onChange(!value)

  return (
    <ButtonPrimary
      children={children}
      Icon={Icon}
      text={value ? textTrue : textFalse}
      small={small}
      disabled={disabled}
      hide={hide}
      onClick={handleClick}
    />
  )
}
