import React from 'react'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Menu, MenuPurge, MenuRestore } from '../../../../../shared/menu/menu'
import { useApiFormAsset } from '../../../../../api-new/state/content'
import { useApiListAsset } from '../../../../../api-new/state/content'
import { TableBuilder, Column, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { Route, useRouteMatch } from 'react-router-dom'

import { Purge } from '../../../../../shared/page/page-purge'
import { IconRecycle } from '../../../../../shared/icon/icon'

export const AssetRecycle = () => {
  const { url } = useRouteMatch()

  const apiListAsset = useApiListAsset()

  const handleReady = () => apiListAsset.get('deleted')

  const handleRestore = async id => {
    await apiListAsset.put(`${id}/restore`)
    await apiListAsset.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconRecycle} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListAsset} onReady={handleReady}>
            <Column field="name" sortable />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row.id)} show />
                  <MenuPurge to={`${url}/${row.id}/purge`} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/:purgeID/purge`}>
        <Purge useApiForm={useApiFormAsset} titleParam="name" successPath={`${url}`} />
      </Route>
    </React.Fragment>
  )
}
