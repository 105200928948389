import React from 'react'
import { LayoutRoleOrg } from './role-org/layout-role-org'
import { LayoutRoleOrgPermission } from './role-org-permission/layout-role-org-permission'
import { LayoutSysAdmin } from './sys-admin/layout-sys-admin'
import { LayoutAnalytic } from './analytic/layout-analytic'
import { LayoutRoleSys } from './role-sys/layout-role-sys'
import { LayoutRoleSysPermission } from './role-sys-permission/layout-role-login'
import { LoginSysRoot } from '../../../role/components/login-sys-root'

export const LayoutRoot = () => {
  return (
    <LoginSysRoot>
      <LayoutRoleSys />
      <LayoutRoleSysPermission />

      <LayoutRoleOrg />
      <LayoutRoleOrgPermission />

      <LayoutSysAdmin />
      <LayoutAnalytic />
    </LoginSysRoot>
  )
}
