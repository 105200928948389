import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useLoad } from '../shared/hooks/load'

const PageContext = React.createContext()

export const usePageContext = () => useContext(PageContext)

const LOCALHOST = 'localhost'

export const THEME_TYPE_SYSTEM = 'system'
export const THEME_TYPE_LIGHT = 'light'
export const THEME_TYPE_DARK = 'dark'

export const PageProvider = ({ children }) => {
  ///////////HOST///////////
  const [hostname, updateHostname] = useState()

  useLoad(() => updateHostname(window.location.hostname), [window.location])

  const host = {
    isLocalhost: hostname === LOCALHOST
  }
  ///////////HOST///////////

  ///////////THEME///////////
  const [themeType, updateThemeType] = useState()
  const [isDark, updateIsDark] = useState(false)

  const hasSystemTheme = useMemo(() => window.matchMedia('(prefers-color-scheme)').matches, [])

  const media = useMemo(
    () => {
      let media = window.matchMedia('(prefers-color-scheme: dark)')
      media.onchange = media => setMediaAttribute(media)
      return media
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasSystemTheme]
  )

  const setMediaAttribute = useCallback(media => {
    if (media.matches) updateIsDark(true)
    else updateIsDark(false)
  }, [])

  useLoad(() => {
    const theme = localStorage.getItem('theme')
    if (theme) {
      updateThemeType(theme)
    } else {
      if (hasSystemTheme) {
        updateThemeType(THEME_TYPE_SYSTEM)
      } else {
        updateThemeType(THEME_TYPE_LIGHT)
      }
    }
  }, [])

  useLoad(
    () => {
      if (themeType) {
        localStorage.setItem('theme', themeType)
        switch (themeType) {
          case THEME_TYPE_DARK:
            updateIsDark(true)
            break
          case THEME_TYPE_LIGHT:
            updateIsDark(false)
            break
          case THEME_TYPE_SYSTEM:
          default:
            setMediaAttribute(media)
        }
      }
    },
    [themeType]
  )

  useLoad(
    () => {
      if (isDark) {
        document.body.setAttribute('data-bs-theme', THEME_TYPE_DARK)
      } else {
        document.body.setAttribute('data-bs-theme', THEME_TYPE_LIGHT)
      }
    },
    [isDark]
  )

  const theme = {
    actions: {
      setThemeLight: () => updateThemeType(THEME_TYPE_LIGHT),
      setThemeDark: () => updateThemeType(THEME_TYPE_DARK),
      setThemeSystem: () => updateThemeType(THEME_TYPE_SYSTEM)
    },
    type: themeType,
    isDark: isDark,
    hasSystemTheme: hasSystemTheme
  }

  ///////////THEME///////////

  return (
    <PageContext.Provider value={{ theme, host }}>
      {children}
    </PageContext.Provider>
  )
}
