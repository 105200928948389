import { useCallback } from 'react'

export const useFetchPost = () => {
  return useCallback(async (url, body) => {
    let result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: body
    })
    return result
  }, [])
}
