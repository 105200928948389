import React, { useEffect, useMemo } from 'react'
import { InputEmail, InputFirstName, InputLastName } from './inputs'
import { useAddUserContext } from './context'
import { IconButtonClickDelete, IconButtonClickInvite } from '../../../../../../shared/icon/icon-button-click'
import { SelectRoleDropdown, SelectRoleModal } from './select/select-roles'
import { SelectGroupDropdown, SelectGroupModal } from './select/select-groups'
import { SelectContentDropdown, SelectContentModal } from './select/select-content'
import { SelectTemplateDropdown, SelectTemplateModal } from './select/select-template'
// import { SelectLibraryDropdown, SelectLibraryModal } from './select/select-libraries'
import { useModalActionID } from '../../../../../../shared/component/modal-dialog'
import { IconDelete, IconInvite, IconMenu } from '../../../../../../shared/icon/icon'

export const UserList = ({ users, disabled, onChange, onDelete, onInvite }) => {
  return (
    <React.Fragment>
      <div className="">
        {users.map((user, i) =>
          <UserRow key={i} index={i} user={user} disabled={disabled} onChange={onChange} onDelete={onDelete} onInvite={onInvite} />
        )}
      </div>
    </React.Fragment>
  )
}

const UserRow = ({ index, user, disabled, onChange, onDelete, onInvite }) => {
  const addUserContext = useAddUserContext()

  const updateError = (name, value) => addUserContext.setUserError(index, name, value)

  let hasError = useMemo(
    () => {
      let errors = addUserContext.userErrors[index]
      if (errors) {
        for (const [, value] of Object.entries(errors)) {
          if (Object.values(value).some(i => i === true)) {
            return true
          }
        }
      }
      return false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addUserContext.userErrors]
  )

  useEffect(() => {
    return () => addUserContext.clearUserError(index)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const disableRow = useMemo(() => !!(user.isSubmitting || disabled), [user.isSubmitting, disabled])

  const handleChangeEmail = value => {
    user.email = value
    onChange(index, user)
  }

  const handleChangeFirstName = value => {
    user.firstName = value
    onChange(index, user)
  }

  const handleChangeLastName = value => {
    user.lastName = value
    onChange(index, user)
  }

  const selectedRoles = useMemo(() => user.selectedRoles || [], [user])
  const selectedGroups = useMemo(() => user.selectedGroups || [], [user])
  const selectedContents = useMemo(() => user.selectedContents || [], [user])
  const selectedTemplates = useMemo(() => user.selectedTemplates || [], [user])
  // const selectedLibraries = useMemo(() => user.selectedLibraries || [], [user])

  const updateSelectedRoles = selectedRoles => onChange(index, { ...user, selectedRoles })
  const updateSelectedGroups = selectedGroups => onChange(index, { ...user, selectedGroups })
  const updateSelectedContents = selectedContents => onChange(index, { ...user, selectedContents })
  const updateSelectedTemplates = selectedTemplates => onChange(index, { ...user, selectedTemplates })
  // const updateSelectedLibraries = selectedLibraries => onChange(index, { ...user, selectedLibraries })

  const handleDelete = () => onDelete && onDelete(index)
  const handleInvite = () => onInvite && onInvite(index)

  const [roleTarget, roleShow, roleHide] = useModalActionID()
  const [groupTarget, groupShow, groupHide] = useModalActionID()
  const [contentTarget, contentShow, contentHide] = useModalActionID()
  const [templateTarget, templateShow, templateHide] = useModalActionID()
  // const [libraryTarget, libraryShow, libraryHide] = useModalActionID()

  return (
    <React.Fragment>
      <div className="border-bottom py-2">
        <div className="d-flex flex-column flex-md-row">
          <div className="flex-fill m-1">
            <InputEmail value={user.email} disabled={disableRow} onChange={handleChangeEmail} onError={updateError} />
          </div>
          <div className="flex-fill m-1">
            <InputFirstName value={user.firstName} onChange={handleChangeFirstName} disabled={disableRow} onError={updateError} />
          </div>
          <div className="flex-fill m-1">
            <InputLastName value={user.lastName} onChange={handleChangeLastName} disabled={disableRow} onError={updateError} />
          </div>
          <div className="d-flex flex-nowrap">
            <span>
              <div className="dropdown">
                <button className="btn btn-primary btn-sm m-1" data-bs-toggle="dropdown" disabled={disableRow}>
                  <IconMenu />More
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <SelectRoleDropdown selectedRoles={selectedRoles} readonlyRoles={addUserContext.commonRoles} onClick={roleShow} />
                  </li>
                  <li>
                    <SelectGroupDropdown selectedGroups={selectedGroups} readonlyGroups={addUserContext.commonGroups} onClick={groupShow} />
                  </li>
                  <li>
                    <SelectContentDropdown
                      selectedContents={selectedContents}
                      readonlyContents={addUserContext.commonContents}
                      onClick={contentShow}
                    />
                  </li>
                  <li>
                    <SelectTemplateDropdown
                      selectedTemplates={selectedTemplates}
                      readonlyTemplates={addUserContext.commonTemplates}
                      onClick={templateShow}
                    />
                  </li>
                  {/* <li>
                    <SelectLibraryDropdown
                      selectedLibraries={selectedLibraries}
                      readonlyLibraries={addUserContext.commonLibraries}
                      onClick={libraryShow}
                    />
                  </li> */}
                  <li className="d-block d-xl-none">
                    <DropdownItem Icon={IconDelete} text="delete" disabled={disableRow} onClick={handleDelete} />
                  </li>
                  <li className="d-block d-xl-none">
                    <DropdownItem Icon={IconInvite} text="invite" disabled={hasError || disableRow} onClick={handleInvite} />
                  </li>
                </ul>
              </div>
            </span>

            <span className="d-none d-xl-block text-nowrap">
              <IconButtonClickDelete small onClick={handleDelete} />
              <IconButtonClickInvite small disabled={hasError} onClick={handleInvite} />
            </span>
          </div>
        </div>

        {user.isSubmitting &&
          <div className="progress" role="progressbar" style={{ height: 10 }}>
            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: '100%' }} />
          </div>}
      </div>

      <SelectRoleModal
        target={roleTarget}
        selectedRoles={selectedRoles}
        readonlyRoles={addUserContext.commonRoles}
        onUpdate={updateSelectedRoles}
        onHide={roleHide}
      />

      <SelectGroupModal
        target={groupTarget}
        selectedGroups={selectedGroups}
        readonlyGroups={addUserContext.commonGroups}
        onUpdate={updateSelectedGroups}
        onHide={groupHide}
      />

      <SelectContentModal
        target={contentTarget}
        selectedContents={selectedContents}
        readonlyContents={addUserContext.commonContents}
        onUpdate={updateSelectedContents}
        onHide={contentHide}
      />

      <SelectTemplateModal
        target={templateTarget}
        selectedTemplates={selectedTemplates}
        readonlyTemplates={addUserContext.commonTemplates}
        onUpdate={updateSelectedTemplates}
        onHide={templateHide}
      />

      {/* <SelectLibraryModal
        target={libraryTarget}
        selectedLibraries={selectedLibraries}
        readonlyLibraries={addUserContext.commonLibraries}
        onUpdate={updateSelectedLibraries}
        onHide={libraryHide}
      /> */}
    </React.Fragment>
  )
}

export const DropdownItem = ({ Icon, text, disabled, onClick }) => {
  return (
    <button className="dropdown-item" disabled={disabled} onClick={onClick}>
      <span className="me-1">
        <Icon />
      </span>
      <span>
        {text}
      </span>
    </button>
  )
}
