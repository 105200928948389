export const DisplayDesktop = ({ children, onClick }) => {
  return (
    <div className="d-none d-sm-inline" onClick={onClick}>
      {children}
    </div>
  )
}

export const DisplayMobile = ({ children, onClick }) => {
  return (
    <div className="d-inline d-sm-none" onClick={onClick}>
      {children}
    </div>
  )
}
