import React from 'react'

import { IconAdd, IconCheck, IconClose, IconDelete, IconDetails, IconDownload, IconEdit, IconReload, IconSearch, IconTimes } from './icon'

const IconClick = ({ children, disabled, hide, onClick }) => {
  const handleClick = () => {
    if (!disabled) {
      onClick && onClick()
    }
  }

  return (
    <React.Fragment>
      {hide
        ? <React.Fragment />
        : <span className="edxr-pointer" onClick={handleClick}>
            {children}
          </span>}
    </React.Fragment>
  )
}

export function IconClickAdd({ className, size, disabled, hide, onClick }) {
  return (
    <IconClick disabled={disabled} hide={hide} onClick={onClick}>
      <IconAdd className={className} size={size} />
    </IconClick>
  )
}

export function IconClickDelete({ className, size, disabled, hide, onClick }) {
  return (
    <IconClick disabled={disabled} hide={hide} onClick={onClick}>
      <IconDelete className={className} size={size} />
    </IconClick>
  )
}

export function IconClickEdit({ className, size, disabled, hide, onClick }) {
  return (
    <IconClick disabled={disabled} hide={hide} onClick={onClick}>
      <IconEdit className={className} size={size} />
    </IconClick>
  )
}

export function IconClickDetails({ className, size, disabled, hide, onClick }) {
  return (
    <IconClick disabled={disabled} hide={hide} onClick={onClick}>
      <IconDetails className={className} size={size} />
    </IconClick>
  )
}

export function IconClickClose({ className, size, disabled, hide, onClick }) {
  return (
    <IconClick disabled={disabled} hide={hide} onClick={onClick}>
      <IconClose className={className} size={size} />
    </IconClick>
  )
}

export function IconClickReload({ className, size, disabled, hide, onClick }) {
  return (
    <IconClick disabled={disabled} hide={hide} onClick={onClick}>
      <IconReload className={className} size={size} />
    </IconClick>
  )
}

export function IconClickSearch({ className, size, disabled, hide, onClick }) {
  return (
    <IconClick disabled={disabled} hide={hide} onClick={onClick}>
      <IconSearch className={className} size={size} />
    </IconClick>
  )
}

export function IconClickCheck({ className, size, disabled, hide, onClick }) {
  return (
    <IconClick disabled={disabled} hide={hide} onClick={onClick}>
      <IconCheck className={className} size={size} />
    </IconClick>
  )
}

export function IconClickTimes({ className, size, disabled, hide, onClick }) {
  return (
    <IconClick disabled={disabled} hide={hide} onClick={onClick}>
      <IconTimes className={className} size={size} />
    </IconClick>
  )
}

export function IconClickDownload({ className, size, disabled, hide, onClick }) {
  return (
    <IconClick disabled={disabled} hide={hide} onClick={onClick}>
      <IconDownload className={className} size={size} />
    </IconClick>
  )
}
