import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormatLabel } from './format-label'
import { useFormDisable } from './form'
import { useFormRequired } from './hooks/input-hooks'
import moment from 'moment'
import datepicker from 'js-datepicker'
import { IconClose, IconCalender } from '../icon/icon'

export const FormDate = ({ className, label, name, minDate, maxDate, required, hidden, small, onChange }) => {
  const { register, watch, setValue } = useFormContext()

  const watchValue = watch(name)
  let formDisabled = useFormDisable()

  useFormRequired(name, required)

  const ref = useRef()
  const [picker, updatePicker] = useState()
  const [isShowing, updateIsShowing] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    let picker = datepicker(ref.current, {
      showAllDates: true,
      onSelect: (instance, date) => {
        setValue(name, date, { shouldDirty: true })
        onChange && onChange(date)
      },
      onShow: () => updateIsShowing(true),
      onHide: () => updateIsShowing(false)
    })

    updatePicker(picker)

    return () => {
      picker.remove()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      if (picker && watchValue) {
        let value = moment(watchValue)._d
        picker.setDate(value, true)
      }
    },
    [picker, watchValue]
  )

  useEffect(
    () => {
      if (picker && picker.setMin) {
        picker.setMin(minDate)
      }
    },
    [picker, minDate]
  )

  useEffect(
    () => {
      if (picker && picker.setMax) {
        picker.setMax(maxDate)
      }
    },
    [picker, maxDate]
  )

  const display = useMemo(() => (watchValue ? moment(watchValue).format('MM-DD-YYYY') : ''), [watchValue])

  const handelClear = e => {
    if (!formDisabled) {
      setValue(name, null, { shouldDirty: true })
      onChange && onChange(null)
    }
  }

  const handleCalendar = e => {
    e.stopPropagation()
    if (!formDisabled) {
      if (isShowing) picker.hide()
      else picker.show()
    }
  }

  //required to avoid warning
  const handleChange = () => {}

  return (
    <FormatLabel className={className} name={name} label={label} hasValue={!!watchValue} required={required} hidden={hidden}>
      <input type="hidden" {...register(name, { required })} />
      <div className={`input-group ${small && 'input-group-sm'}`}>
        <span className="input-group-text" onClick={handleCalendar}>
          <IconCalender />
        </span>
        <input
          ref={ref}
          className={`form-control ${small && 'form-control-sm'}`}
          type="text"
          placeholder="MM/DD/YYYY"
          value={display}
          disabled={formDisabled}
          onChange={handleChange}
        />
        <span className="input-group-text" onClick={handelClear}>
          <IconClose />
        </span>
      </div>
    </FormatLabel>
  )
}
