import { useMemo, useReducer } from 'react'
import { getAsync, deleteAsync } from './service/api.service'

export const types = {
  INIT: 'INIT',
  ERROR: 'ERROR',
  START: 'START',
  COUNT: 'COUNT',
  RANGE: 'RANGE',
  COMPLETE: 'COMPLETE',
  SUBMIT: 'SUBMIT',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  REMOVE: 'REMOVE'
}

const initialState = {
  count: 0,
  items: [],

  loaded: false,
  submitting: false,

  chunking: false,
  chunkProgress: 0,
  error: undefined
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.INIT:
      return { ...initialState }
    case types.ERROR:
      return { ...state, chunking: false, submitting: false, error: action.payload }
    case types.START:
      return { ...initialState, loaded: false, chunking: true }
    case types.COUNT:
      return { ...state, count: action.payload.count, chunkProgress: 0 }
    case types.RANGE:
      let rangeItems = [...state.items, ...action.payload]
      return { ...state, items: rangeItems, chunkProgress: rangeItems.length }
    case types.COMPLETE:
      return { ...state, loaded: true, chunking: false }
    case types.SUBMIT:
      return { ...state, submitting: true }
    case types.DELETE_SUCCESS:
      return { ...state, submitting: false }
    case types.REMOVE:
      let index = state.items.indexOf(action.payload)
      state.items.splice(index, 1)
      return { ...state, items: [...state.items] }
    default:
      return state
  }
}

export const useApiListChunk = (server, endpoint) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const actions = useMemo(() => {
    return {
      init: () => initAction(dispatch)(),
      get: size => getAction(server, endpoint, dispatch)(size),
      delete: arg => deleteAction(server, endpoint, dispatch)(arg),
      remove: item => removeAction(dispatch)(item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(() => ({ ...state, ...actions }), [state, actions])
}

const initAction = dispatch => () => {
  dispatch({ type: types.INIT })
}

const getAction = (server, endpoint, dispatch) => async size => {
  try {
    dispatch({ type: types.START })
    let endpointCount = `${endpoint}/range/count`
    const resultCount = await getAsync(server, endpointCount)
    dispatch({ type: types.COUNT, payload: resultCount.data })

    for (let i = 0; i < resultCount.data.count; i += size) {
      let endpointRange = `${endpoint}/range/${i}/${size}`
      const resultRange = await getAsync(server, endpointRange)
      dispatch({ type: types.RANGE, payload: resultRange.data })
    }

    dispatch({ type: types.COMPLETE })
  } catch (exception) {
    dispatch({ type: types.ERROR, payload: exception.error || '' })
    throw exception
  }
}

const deleteAction = (server, endpoint, dispatch) => async arg => {
  try {
    endpoint = `${endpoint}/${arg}`
    dispatch({ type: types.DELETE })
    const result = await deleteAsync(server, endpoint)
    dispatch({ type: types.DELETE_SUCCESS })
    return result
  } catch (exception) {
    dispatch({ type: types.ERROR, payload: exception.error || '' })
    throw exception
  }
}

const removeAction = dispatch => async item => {
  dispatch({ type: types.REMOVE, payload: item })
}
