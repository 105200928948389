import React from 'react'
import { Route } from 'react-router-dom'
import { SysAdminList } from './page/sys-admin-list'
import { SysAdminPromote } from './page/sys-admin-promote'
import { SysAdminEdit } from './page/sys-admin-edit'

export function LayoutSysAdmin() {
  return (
    <React.Fragment>
      <Route path="/root/sys-admin/list" component={SysAdminList} />
      <Route path="/root/sys-admin/list/edit/:loginID" component={SysAdminEdit} />
      <Route path="/root/sys-admin/list/promote" component={SysAdminPromote} />
    </React.Fragment>
  )
}
