import React, {useState, useEffect, useRef} from 'react'

let currentDragGroup

export const Draggable = ({children, draggable, dragID, dragGroup}) => {
  const ref = useRef(null)

  const [target, updateTarget] = useState(null)

  const handleMouseDown = e => {
    updateTarget(e.currentTarget)
  }

  useEffect(() => {
    let dragHandle = ref.current.querySelector('[drag-handle="true"]')

    if (dragHandle) {
      dragHandle.addEventListener('mousedown', handleMouseDown)
    }

    return () => {
      if (dragHandle) {
        dragHandle.removeEventListener('mousedown', handleMouseDown)
      }
    }
  }, [])

  const handleDragStart = e => {
    let dragHandle = ref.current.querySelector('[drag-handle="true"]')
    if (dragHandle.contains(target)) {
      e.dataTransfer.setData('dragID', dragID)
      if (dragGroup) {
        e.dataTransfer.setData('dragGroup', dragGroup)

        if (dragGroup) {
          currentDragGroup = dragGroup
        }
      }
    } else e.preventDefault()
  }

  const handleDragEnd = e => {
    updateTarget(null)
  }

  return (
    <div ref={ref} draggable={draggable} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      {children}
    </div>
  )
}

export const DragHandle = ({children}) => {
  return (
    <span drag-handle='true'>
      {children}
    </span>
  )
}

export const Droppable = ({children, onDrop, dropGroup}) => {
  const handleDragOver = e => {
    if (currentDragGroup === dropGroup) {
      e.preventDefault()
    }
  }

  const handleDrop = e => {
    e.preventDefault()
    let dragID = e.dataTransfer.getData('dragID')
    let dragGroup = e.dataTransfer.getData('dragGroup')

    if (dragGroup === '') {
      dragGroup = undefined
    }

    if (!dragGroup && !dropGroup && dragGroup !== dropGroup) {
      return
    }

    dragID = parseInt(dragID)
    onDrop && onDrop(dragID)

    currentDragGroup = undefined
  }

  return (
    <div onDrop={handleDrop} onDragOver={handleDragOver}>
      {children}
    </div>
  )
}
