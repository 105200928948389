import React from 'react'
import { useSlate } from 'slate-react'
import { Editor } from 'slate'
import { Button } from './button'

export const ButtonTag = ({ Icon, format, attribute }) => {
  const editor = useSlate()

  const isActive = isMarkActive(editor, format, attribute)

  const handleClick = event => {
    event.preventDefault()
    toggleMark(editor, format, attribute)
  }

  return <Button Icon={Icon} isActive={isActive} onClick={handleClick} />
}

const isMarkActive = (editor, format, attribute) => {
  const marks = Editor.marks(editor)

  if (!marks) {
    return false
  }

  return marks[format] === attribute ? true : false
}

const toggleMark = (editor, format, attribute) => {
  const isActive = isMarkActive(editor, format, attribute)
  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, attribute)
  }
}
