import React, { useEffect, useState } from 'react'
import { Editor } from './component/editor'
import { Result } from './component/result'
import { SerializeHtml } from './serialize/serialize-html'
import { SerializeTMP } from './serialize/serialize-tmp'

export const useEditorValue = value => {
  const [state, updateState] = useState()

  useEffect(
    () => {
      if (!state && value) {
        updateState(JSON.parse(value))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  return state
}

export const RichTextEditor = ({ initialValue, onChange }) => {
  const [resultHtml, updateResultHtml] = useState()
  const [resultTMP, updateResultTMP] = useState()

  const handleChange = value => {
    const valueJSON = JSON.stringify(value)
    const valueHTML = SerializeHtml(value)
    const valueTMP = SerializeTMP(value)

    updateResultHtml(valueHTML)
    updateResultTMP(valueTMP)

    onChange && onChange(valueJSON, valueHTML, valueTMP)
  }

  return (
    <React.Fragment>
      <nav>
        <div className="nav nav-tabs" role="tablist">
          <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-editor" type="button" role="tab">
            Editor
          </button>
          <button className="nav-link" data-bs-toggle="tab" data-bs-target="#nav-html" type="button" role="tab">
            HTML
          </button>
          <button className="nav-link" data-bs-toggle="tab" data-bs-target="#nav-tmp" type="button" role="tab">
            Text Mesh Pro
          </button>
        </div>
      </nav>

      <div className="tab-content border-start border-end">
        <div className="tab-pane fade show active" id="nav-editor" role="tabpanel" tabindex="0">
          <Editor initialValue={initialValue} onChange={handleChange} />
        </div>
        <div className="tab-pane fade" id="nav-html" role="tabpanel" tabindex="0">
          <Result value={resultHtml} />
        </div>
        <div className="tab-pane fade" id="nav-tmp" role="tabpanel" tabindex="0">
          <Result value={resultTMP} />
        </div>
      </div>
    </React.Fragment>
  )
}
