import React from 'react'
import { Route } from 'react-router-dom'
import { AnalyticSetup } from './content/analytic-setup'

export const LayoutAnalytic = () => {
  return (
    <React.Fragment>
      <Route path="/root/analytic-setup" component={AnalyticSetup} />
    </React.Fragment>
  )
}
