import React, { useContext } from 'react'
import { AuthContext } from './auth.context'

export function DisplayFeatureTradeShow({ children }) {
  const authContext = useContext(AuthContext)

  return (
    <React.Fragment>
      {authContext.state.jwtClaims.featureTradeShow && children}
    </React.Fragment>
  )
}
