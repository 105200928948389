import React from 'react'

import { DashboardUser } from './component/dashboard-user'
import { DashboardCourse } from './component/dashboard-course'
import { DashboardAnalyticPlatform } from './component/dashboard-analytic-platform'
import { DashboardAnalyticSession } from './component/dashboard-analytic-session'
import { Grid, GridItem3 } from '../../../../../shared/chart/grid'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconDashboard } from '../../../../../shared/icon/icon'
import { RoleOrgAssessmentReport, RoleOrgNode, RoleOrgUser } from '../../../../../role/components/role-org'

export const Organization = () => {
  return (
    <BreadcrumbView Icon={IconDashboard} text="DASHBOARD">
      <React.Fragment>
        <Grid>
          <RoleOrgUser accessRead>
            <GridItem3>
              <DashboardUser />
            </GridItem3>
          </RoleOrgUser>

          <RoleOrgNode accessRead>
            <GridItem3>
              <DashboardCourse />
            </GridItem3>
          </RoleOrgNode>

          <RoleOrgAssessmentReport accessRead>
            <GridItem3>
              <DashboardAnalyticPlatform />
            </GridItem3>
            <GridItem3>
              <DashboardAnalyticSession />
            </GridItem3>
          </RoleOrgAssessmentReport>
        </Grid>
      </React.Fragment>
    </BreadcrumbView>
  )
}
