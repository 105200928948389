import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Card } from '../../../../../../shared/card/card'
import { IconTimes } from '../../../../../../shared/icon/icon'
import { useDataContext } from '../../data-context'

export const NodeTags = ({ apiForm }) => {
  const [items, updateItems] = useState([])
  const [edit, updateEdit] = useState(false)

  const dataContext = useDataContext()

  useEffect(
    () => {
      if (apiForm.form.tags) {
        updateItems(apiForm.form.tags)
      }
    },
    [apiForm.form]
  )

  const handleHide = () => updateEdit(false)
  const handleFocus = () => updateEdit(true)
  const handleBlur = () => updateEdit(false)

  const handleAdd = async value => {
    items.push(value)
    let tags = [...new Set(items)]
    updateItems(tags)

    apiForm.form.tags = tags
    let node = await apiForm.put(apiForm.form.id, apiForm.form)
    dataContext.nodeUpdate(node)
  }

  const handleDelete = async value => {
    var index = items.indexOf(value)
    items.splice(index, 1)
    updateItems([...items])

    let node = apiForm.form
    node.tags = items
    await apiForm.put(node.id, node)
  }

  const itemList = useMemo(() => items.sort(), [items])

  return (
    <React.Fragment>
      <Card title="tags">
        <div className="d-flex flex-wrap border pb-1 pe-1" style={{ minHeight: 50 }} onClick={handleFocus} onBlur={handleBlur}>
          {itemList.map((item, i) => <TagItem key={i} value={item} onDelete={handleDelete} />)}
          {edit && <TagInput onAdd={handleAdd} onHide={handleHide} />}
        </div>
      </Card>
    </React.Fragment>
  )
}

const TagInput = ({ onAdd, onHide }) => {
  const ref = useRef()

  useEffect(() => ref.current.focus(), [])

  const [state, updateState] = useState('')

  const handleChange = e => updateState(e.target.value)

  const handleKeyUp = e => {
    if (e.code === 'Enter') {
      onAdd && onAdd(state.trim())
      updateState('')
    }
  }

  const handleKeyDown = e => {
    if (e.code === 'Space') {
      e.preventDefault()
    }
  }

  const handleDelete = () => onHide && onHide()

  return (
    <div>
      <input
        ref={ref}
        type="text"
        className="border rounded text-nowrap py-1 mt-1 ms-1"
        value={state}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
      />
      <span style={{ cursor: 'pointer' }} onClick={handleDelete}>
        <IconTimes />
      </span>
    </div>
  )
}

const TagItem = ({ value, onDelete }) => {
  const handleDelete = () => onDelete && onDelete(value)

  const handleClick = e => e.preventDefault()

  return (
    <React.Fragment>
      <div onClick={handleClick}>
        <div className="border rounded text-nowrap py-1 mt-1 ms-1 bg-primary text-light">
          <span className="px-2">
            {value}
          </span>
          <span className="px-2" style={{ cursor: 'pointer' }} onClick={handleDelete}>
            <IconTimes />
          </span>
        </div>
      </div>
    </React.Fragment>
  )
}
