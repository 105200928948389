import { IconTimes } from '../icon/icon'

export const TextFilter = ({ value, onChange }) => {
  const handleChange = e => onChange && onChange(e.target.value)
  const handleClear = () => onChange && onChange('')

  return (
    <div className="input-group">
      <input className="form-control" value={value} onChange={handleChange} />
      <span className="input-group-text" onClick={handleClear}>
        <IconTimes />
      </span>
    </div>
  )
}
