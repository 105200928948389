import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormCheckbox, FormSubmitBack } from '../../../../../shared/form/form'
import { IconAdd } from '../../../../../shared/icon/icon'
import { Card } from '../../../../../shared/card/card'
import { useApiFormRoleSysPermission, useApiListRoleSys, useApiListRoleSysScope } from '../../../../../api-new/state/content'
import { FormSelectObj } from '../../../../../shared/form/form-select-obj'

export const RoleSysPermissionAdd = () => {
  const history = useHistory()

  const apiListRoleSys = useApiListRoleSys()
  const apiListRoleSysScope = useApiListRoleSysScope()
  const apiFormRoleSysPermission = useApiFormRoleSysPermission()

  const handlePath = () => {
    apiListRoleSys.get()
    apiListRoleSysScope.get()
  }

  const handleSubmit = async data => {
    let roleSysPermission = await apiFormRoleSysPermission.post(data)
    history.push(`/root/role-sys-permission/list/edit/${roleSysPermission.id}`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add" onPath={handlePath}>
        <Card title="add role permission">
          <Form onSubmit={handleSubmit}>
            <FormSelectObj
              label="Role"
              name="roleSysID"
              state={apiListRoleSys}
              itemName="name"
              itemValue="id"
              valueAsNumber={true}
              required
            />

            <FormSelectObj label="Scope" name="identifier" state={apiListRoleSysScope} itemName="display" itemValue="identifier" required />

            <Card>
              <FormCheckbox label="Create" name="accessCreate" />
              <FormCheckbox label="Read" name="accessRead" />
              <FormCheckbox label="Update" name="accessUpdate" />
              <FormCheckbox label="Delete" name="accessDelete" />
            </Card>

            <FormSubmitBack text="add role permission" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
