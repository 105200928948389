import React, { useMemo } from 'react'
import { FormError, FormErrorRequired } from './format-error'
import { useFormContext } from 'react-hook-form'

export const FormatLabel = ({ className, style, children, label, name, subtext, hasValue, required, hidden }) => {
  const { formState } = useFormContext()

  const showLabel = useMemo(() => !!label, [label])
  const showHidden = useMemo(() => !!hidden, [hidden])

  const hasErrorRequired = useMemo(() => required && !hasValue, [required, hasValue])
  const nameError = useMemo(() => formState.errors[name], [formState, name])

  return (
    <React.Fragment>
      {!showHidden &&
        <div className={className}>
          <div className="form-group my-2" style={style}>
            {showLabel &&
              <label className="text-capitalize mb-2">
                {label}
              </label>}
            {children}
            {subtext &&
              <div className="">
                <small className="text-small">
                  {subtext}
                </small>
              </div>}
            {required && <FormErrorRequired hasError={hasErrorRequired} />}
            {nameError && <FormError hasError error={nameError.error} />}
          </div>
        </div>}
    </React.Fragment>
  )
}
