import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { useInviteRegisterForm } from './state'
import { Form, FormText, FormPasswordValidate, FormSubmit, FormHiddenText } from '../../shared/form/form'
import { FormTermsAccept } from '../../shared/form/form-control/form-terms-accept'
import { CardFullHeightScrollY } from '../../shared/card/card-full-height-scroll-y'

export const InviteRegister = () => {
  const history = useHistory()
  const { key } = useParams()

  const [inviteRegisterFormActions, inviteRegisterFormState] = useInviteRegisterForm()

  const handleMatch = async () => await inviteRegisterFormActions.get(key)

  const hasError = useMemo(() => !!inviteRegisterFormState.error, [inviteRegisterFormState.error])

  const handleSubmit = async data => {
    await inviteRegisterFormActions.putArgs({ path: key, data: data, toast: 'user registered' })
    history.push('/public/invite/success')
  }

  return (
    <React.Fragment>
      <BreadcrumbView text="invite" onMatch={handleMatch}>
        <CardFullHeightScrollY title="welcome">
          <Form state={inviteRegisterFormState.form} onSubmit={handleSubmit} disabled={hasError}>
            <FormHiddenText name="registerKey" value={key} />
            <FormHiddenText name="organizationID" value={key} parseInt />
            <FormText label="First Name" name="firstName" required />
            <FormText label="Last Name" name="lastName" required />
            <FormText label="Organization" name="organizationName" readOnly />
            <FormText label="Email" name="email" readOnly />
            {inviteRegisterFormState.form.hasPassword === false && <FormPasswordValidate label="Password" name="password" required />}
            <FormTermsAccept name="terms" required />
            <FormSubmit text="register" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
