import React from 'react'
import { RoleSelectUser } from '../../../../../../role/role-select/role-select-user'

export const UserRoles = ({ userID }) => {
  return (
    <React.Fragment>
      <RoleSelectUser userID={userID} />
    </React.Fragment>
  )
}
