import { urlArgs } from '../state'
import { useApiList } from '../api-list'
import { useApiForm } from '../api-form'

export const useApiListDashboardUser = args => useApiList(process.env.REACT_APP_API_DASHBOARD, `api/v1/user${urlArgs(args)}`)
export const useApiFormDashboardUser = args => useApiForm(process.env.REACT_APP_API_DASHBOARD, `api/v1/user${urlArgs(args)}`)
export const useApiListDashboardCourse = args => useApiList(process.env.REACT_APP_API_DASHBOARD, `api/v1/course${urlArgs(args)}`)
export const useApiFormDashboardCourse = args => useApiForm(process.env.REACT_APP_API_DASHBOARD, `api/v1/course${urlArgs(args)}`)
export const useApiListDashboardAssessment = args => useApiList(process.env.REACT_APP_API_DASHBOARD, `api/v1/assessment${urlArgs(args)}`)
export const useApiFormDashboardAssessment = args => useApiForm(process.env.REACT_APP_API_DASHBOARD, `api/v1/assessment${urlArgs(args)}`)
