import React, { useRef } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuItem,
  MenuTableSettingToggle
} from '../../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu, ColumnYesNo } from '../../../../../../shared/table-builder/table-builder'
import { useApiListAppVersion } from '../../../../../../api-new/state/config'
import { IconDownload, IconList, IconRecycle, IconDelete } from '../../../../../../shared/icon/icon'
import { useRoleSysApp } from '../../../../../../role/hook/role-sys'

export const AppVersionList = () => {
  const { appID, appStoreID } = useParamsInt()

  const apiListAppVersion = useApiListAppVersion()

  const roleSysApp = useRoleSysApp()

  const handleReady = () => apiListAppVersion.get(`${appStoreID}/store`)

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/add`} show={roleSysApp.accessCreate} />
      <MenuItem
        Icon={IconDownload}
        text="downloads"
        to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/downloads`}
        show={roleSysApp.accessRead}
      />
      <MenuItem
        Icon={IconRecycle}
        text="recycle"
        to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/recycle`}
        show={roleSysApp.accessDelete}
      />

      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuActivityLog to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/activity-all`} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="versions">
        <CardFullHeightScrollY title="app store versions" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListAppVersion} onReady={handleReady}>
            <Column field="name" sortable filter />
            <Column field="code" sortable filter />
            <ColumnYesNo field="isRequired" header="Required" sortable />
            <ColumnYesNo field="isActive" header="Active" sortable />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem
                    Icon={IconDelete}
                    text="delete"
                    to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/edit/${row.id}/delete`}
                    show={roleSysApp.accessDelete}
                  />
                  <MenuEdit
                    to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/edit/${row.id}`}
                    show={roleSysApp.accessUpdate}
                  />
                  <MenuActivityLog to={`/admin/app/edit/${appID}/store/edit/${appStoreID}/version/activity-id/${row.id}`} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
