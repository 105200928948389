import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useLoad } from '../../shared/hooks/load'
import { useBuildRoutePath } from '../hook'

export const AuthRedirect = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  const buildRoutePath = useBuildRoutePath()

  useLoad(() => {
    let path = buildRoutePath('/auth/email', { redirect: pathname })
    history.push(path)
  }, [])

  return <React.Fragment />
}
