import { useMemo } from 'react'
import { Menu, MenuDelete, MenuEdit, MenuItem } from '../../../../../../shared/menu/menu'
import { IconEnter } from '../../../../../../shared/icon/icon'

export const NodeItem = ({ node, onClick, onAssets, onEdit, onDelete }) => {
  const handleClick = () => onClick(node)
  const handleChildren = () => onClick(node)
  const handleAssets = () => onAssets(node)
  const handleEdit = () => onEdit(node)
  const handleDelete = () => onDelete(node)

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
      <div className="card h-100">
        <div className="card-body">
          <div className="card-title text-truncate border-bottom edxr-pointer" onClick={handleClick}>    
            {node.name || 'NOT SET'}
          </div>
          <div className="card-text">
            <div>
              <NodeItemType node={node} />
            </div>
            <div>
              <Menu small>
                <MenuItem Icon={IconEnter} text='children' onClick={handleChildren} show  />
                <MenuItem Icon={IconEnter} text='assets' onClick={handleAssets} show />
                <MenuEdit onClick={handleEdit} show  />
                <MenuDelete onClick={handleDelete} show />
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const NodeItemType = ({node}) => {

    const datatype = useMemo(
        () => {
            try {
                let datatype = node.metaData ? JSON.parse(node.metaData.toLowerCase()).datatype : ""

                switch(datatype) {
                    case 'category': return "Category";
                    case 'course': return "Course";
                    case 'course_template': return "Template";
                    default: return "Unknown"
                }
            } catch {
                return "ERROR"
            }
        },
        [node]
      )

    return <span className=''>{datatype}</span>
}
