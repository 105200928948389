import React, {useEffect, useRef} from 'react'

export const MouseClickAway = ({children, onClick}) => {
  const ref = useRef()

  useEffect(() => {
    function handleClick (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick && onClick()
      }
    }

    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <div ref={ref}>
        {children}
      </div>
    </React.Fragment>
  )
}
