import { useMemo, useRef } from 'react'
import { useApiFormAssessment } from '../../../../api-new/state/assessment'
import { useApiListResultAssessmentUser } from '../../../../api-new/state/assessment'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnDateTime, ColumnMenu, ColumnNum, TableBuilder } from '../../../../shared/table-builder/table-builder'
import { useParamsInt } from '../../../../shared/hooks/location'
import { Menu, MenuDelete, MenuExportCsv, MenuExportExcel, MenuItem } from '../../../../shared/menu/menu'
import { IconView } from '../../../../shared/icon/icon'
import { useRouteMatch } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { IconList } from '../../../../shared/icon/icon'

export const AssessmentUserAttempts = () => {
  const { url } = useRouteMatch()
  const { userID, assessmentID } = useParamsInt()

  const apiListResultAssessmentUser = useApiListResultAssessmentUser()

  const handleReady = () => apiListResultAssessmentUser.get(`attempts/${assessmentID}/assessment/${userID}/user`)

  const title = useMemo(
    () => {
      let first = apiListResultAssessmentUser.items[0]
      return first ? first.title : ''
    },
    [apiListResultAssessmentUser.items]
  )

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  return (
    <BreadcrumbView Icon={IconList} text={title}>
      <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
        <TableBuilder ref={tableRef} apiList={apiListResultAssessmentUser} onReady={handleReady}>
          <Column field="course" sortable filter />
          <Column field="title" sortable filter />
          <Column field="type" sortable filter />
          <ColumnNum field="passingScore" sortable filter />
          <ColumnNum field="score" sortable filter />
          <Column field="passed" sortable filter />
          <ColumnDateTime field="start" sortable filter />
          <ColumnDateTime field="end" sortable filter />
          <Column field="duration" sortable filter />
          <ColumnMenu
            menu={row =>
              <Menu small>
                <MenuItem text="details" Icon={IconView} to={`${url}/${row.userAssessmentID}/details`} show pin />
                <MenuDelete to={`${url}/delete/${row.userAssessmentID}`} show pin />
              </Menu>}
          />
        </TableBuilder>
      </CardFullHeightScrollY>
    </BreadcrumbView>
  )
}
