import React, { useMemo } from 'react'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { useApiListReportCustomOrg } from '../../../../../../api-new/state/analytics'
import { IconTable } from '../../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { AnalyticCustomTable } from './table'
import { useLoad } from '../../../../../../shared/hooks/load'
import { useApiFormAnalyticTopic } from '../../../../../../api-new/state/analytics'

export const TableTopic = () => {
  const { topicID } = useParamsInt()

  const apiFormAnalyticTopic = useApiFormAnalyticTopic()
  const apiListReportCustomOrg = useApiListReportCustomOrg()

  useLoad(() => apiFormAnalyticTopic.get(topicID), [])

  const handleReady = () => apiListReportCustomOrg.get(`${topicID}/topic`)

  const title = useMemo(() => `All ${apiFormAnalyticTopic.form.name}`, [apiFormAnalyticTopic.form.name])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTable} text={title}>
        <AnalyticCustomTable title={title} apiList={apiListReportCustomOrg} onReady={handleReady} />
      </BreadcrumbView>
    </React.Fragment>
  )
}
