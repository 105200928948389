import React, { useMemo } from 'react'
import { ModalDialog } from '../../../../../../../shared/component/modal-dialog'
import { Breadcrumb } from './breadcrumb'
import { useImportContext } from './context'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../../shared/legacy/button'
import { StepRole } from './step-role'
import { StepGroup } from './step-group'
import { StepContent } from './step-contents'
import { StepTemplate } from './step-template'
// import { StepLibrary } from './step-library'
import { StepColumn } from './step-column'

export const ModalImport = ({ target, onComplete }) => {
  const importContext = useImportContext()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showStep = useMemo(() => importContext.steps[importContext.currentStep].name, [importContext.currentStep])

  const showBack = useMemo(() => importContext.currentStep > 0, [importContext.currentStep])
  const showNext = useMemo(() => importContext.currentStep < importContext.steps.length - 1, [
    importContext.currentStep,
    importContext.steps.length
  ])
  const showComplete = useMemo(() => importContext.currentStep === importContext.steps.length - 1, [
    importContext.currentStep,
    importContext.steps.length
  ])

  const handleNext = () => {
    importContext.updateCurrentStep(importContext.currentStep + 1)
  }

  const handleBack = () => {
    importContext.updateCurrentStep(importContext.currentStep - 1)
  }

  return (
    <React.Fragment>
      <ModalDialog target={target} header="Import Users" backdropStatic>
        <div>
          <Breadcrumb />
        </div>
        <div>
          <div>
            {showStep === 'columns' && <StepColumn />}
            {showStep === 'roles' && <StepRole />}
            {showStep === 'groups' && <StepGroup />}
            {showStep === 'contents' && <StepContent />}
            {showStep === 'templates' && <StepTemplate />}
            {/* {showStep === 'libraries' && <StepLibrary />} */}
          </div>

          <div>
            {showBack && <ButtonSecondary text="back" onClick={handleBack} />}
            {showNext && <ButtonPrimary text="next" onClick={handleNext} />}
            {showComplete && <ButtonPrimary text="complete" onClick={onComplete} />}
          </div>
        </div>
      </ModalDialog>
    </React.Fragment>
  )
}
