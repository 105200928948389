import React from 'react'
import { Route } from 'react-router'
import { ViewRegister } from './view-register'

export const LayoutRegister = () => {
  return (
    <React.Fragment>
      <Route path="/public/register" component={ViewRegister} />
    </React.Fragment>
  )
}
