import React from 'react'
import moment from 'moment'
import { Card } from '../card/card'

export const dateFormat = date => moment(date).format('MM/DD/YY hh:mm:ss a')

const AuthStatusContainer = ({ children }) => {
  return (
    <React.Fragment>
      <table className="table table-sm table-borderless">
        <tbody>
          {children}
        </tbody>
      </table>
    </React.Fragment>
  )
}

const AuthStatusField = ({ name, value }) => {
  return (
    <React.Fragment>
      <tr>
        <td className="text-capitalize">
          {name}
        </td>
        <td>
          {value}
        </td>
      </tr>
    </React.Fragment>
  )
}

export const AuthStatus = ({ authStatus }) => {
  return (
    <React.Fragment>
      <Card>
        <AuthStatusContainer>
          <AuthStatusField name="organization" value={authStatus.organization} />
          <AuthStatusField name="login" value={authStatus.login} />
          <AuthStatusField name="ip address" value={authStatus.ipAddress} />
          <AuthStatusField name="ip location" value={authStatus.ipLocation} />
          <AuthStatusField name="created" value={dateFormat(authStatus.createdOn)} />
          <AuthStatusField name="updated" value={dateFormat(authStatus.updatedOn)} />
          <AuthStatusField name="expires" value={dateFormat(authStatus.expiresOn)} />
          <AuthStatusField name="device" value={authStatus.deviceType} />
        </AuthStatusContainer>
      </Card>
    </React.Fragment>
  )
}
