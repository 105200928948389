import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconUsers } from '../../../../../shared/icon/icon'
import { IconDetails } from '../../../../../shared/icon/icon'
import { Menu, MenuDelete, MenuItem } from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnDateTime, ColumnLink, ColumnMenu } from '../../../../../shared/table-builder/table-builder'
import { useApiListAuthStatus } from '../../../../../api-new/state/auth'
import { useRoleSysAuthStatus } from '../../../../../role/hook/role-sys'

export const AuthStatusList = () => {
  const apiListAuthStatus = useApiListAuthStatus()

  const roleSysAuthStatus = useRoleSysAuthStatus()

  const handleRefresh = async () => await apiListAuthStatus.get()

  const handleDelete = async refreshToken => {
    await apiListAuthStatus.delete(refreshToken)
    await apiListAuthStatus.get()
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconUsers} text="AUTH STATUS">
        <CardFullHeightScrollY title="auth status">
          <TableBuilder apiList={apiListAuthStatus} onReady={handleRefresh}>
            <ColumnLink field="organization" to={row => `/admin/auth-status/list/${row['organizationID']}/organization`} sortable filter />
            <ColumnLink field="login" to={row => `/admin/auth-status/list/${row['loginID']}/login`} sortable filter />
            <Column field="ipAddress" header="ip address" sortable filter />
            <ColumnDateTime field="expiresOn" header="expires" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem
                    Icon={IconDetails}
                    text="details"
                    to={`/admin/auth-status/list/${row.refreshToken}/details`}
                    show={roleSysAuthStatus.accessRead}
                    pin
                  />
                  <MenuDelete onClick={() => handleDelete(row.refreshToken)} show={roleSysAuthStatus.accessDelete} />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
