import React from 'react'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { AppLinks } from '../../shared/legacy/app-links'
import { IconContactUs } from '../../shared/icon/icon'
import { ScrollY } from '../../shared/component/scroll'
import { CardFullHeightScrollY } from '../../shared/card/card-full-height-scroll-y'

export const ContactUsComplete = () => {
  return (
    <ScrollY>
      <BreadcrumbView Icon={IconContactUs} text="contact us">
        <CardFullHeightScrollY title="contact us">
          <div className="alert alert-success">Your request has been submitted</div>
          <AppLinks header="home" />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </ScrollY>
  )
}
