import React from 'react'
import { Route } from 'react-router-dom'
import { GroupList } from './content/group-list'
import { GroupAdd } from './content/group-add'
import { GroupEdit } from './content/group-edit'
import { GroupListUser } from './content/group-edit/group-list-user'
import { GroupRecycle } from './content/group-recycle'
import { Delete } from '../../../../shared/page/page-delete'
import { useApiFormGroup } from '../../../../api-new/state/content'
import { Purge } from '../../../../shared/page/page-purge'
import { LogEventOrg } from '../../../../shared/page/log-event/log-event-org'
import { GroupListContent } from './content/group-edit/group-list-content'

export const LayoutGroup = () => {
  return (
    <React.Fragment>
      <Route path="/org/group/list" component={GroupList} />
      <Route path="/org/group/list/add" component={GroupAdd} />
      <Route path="/org/group/list/edit/:groupID" component={GroupEdit} />
      <Route path="/org/group/list/edit/:groupID/content" component={GroupListContent} />
      <Route path="/org/group/list/edit/:groupID/user" component={GroupListUser} />
      <Route path="/org/group/list/recycle" component={GroupRecycle} />
      <Route path="/org/group/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormGroup} titleParam="name" successPath="/org/group/list" />
      </Route>
      <Route path="/org/group/list/recycle/:purgeID/purge">
        <Purge useApiForm={useApiFormGroup} titleParam="name" successPath="/org/group/list/recycle" />
      </Route>
      <Route path="/org/group/list/activity-all">
        <LogEventOrg subject="group" />
      </Route>
      <Route path="/org/group/list/activity-id/:id">
        <LogEventOrg subject="group" />
      </Route>
    </React.Fragment>
  )
}
