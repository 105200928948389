import React from 'react'
import { Route } from 'react-router'
import { InviteRegister } from './invite-register'
import { InviteSuccess } from './invite-success'

export const InviteLayout = () => {
  return (
    <React.Fragment>
      <Route path="/public/invite/register/:key" component={InviteRegister} />
      <Route path="/public/invite/success" component={InviteSuccess} />
    </React.Fragment>
  )
}
