import React from 'react'
import { FormSelectListNum } from '../../../../../shared/form/form'

const attemps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const AllowedAttempts = ({ label, name, readOnly }) => {
  return (
    <React.Fragment>
      <FormSelectListNum label={label} name={name} state={attemps} nullText="Unlimited" nullValue={0} readOnly={readOnly} />
    </React.Fragment>
  )
}
