import React, { useEffect } from 'react'
import { useAnalyticContext } from '../context/provider-analytic'
import { useLoad } from '../../../../shared/hooks/load'
import { Form, FormSubmitCancel, FormText, FormTextArea } from '../../../../shared/form/form'
import { FormSelectObj } from '../../../../shared/form/form-select-obj'
import { TableEdit } from '../../../../shared/component/table-edit'
import { useApiListAnalyticParent, useApiListAnalyticParentType } from '../../../../api-new/state/analytics'

export const AnalyticParent = () => {
  const apiListAnalyticParent = useApiListAnalyticParent()

  const analyticContext = useAnalyticContext()

  useEffect(() => {
    analyticContext.subscribe('parent-type', async () => await apiListAnalyticParent.get())
    return () => analyticContext.unsubscribe('parent-type')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoad = () => apiListAnalyticParent.get()

  return (
    <TableEdit
      title="analytic parent"
      columns={[
        { header: 'name', value: 'name', filter: true },
        { header: 'parent type', value: 'analyticParentType', filter: true },
        { header: 'description', value: 'description', filter: true },
        { header: 'resource', value: 'resource', filter: true }
      ]}
      apiList={apiListAnalyticParent}
      sortBy="name"
      FormAdd={FormEdit}
      FormEdit={FormEdit}
      onLoad={handleLoad}
      onAdd
      onEdit
      onDelete
      onRestore
      onPurge
    />
  )
}

const FormEdit = ({ row, onSubmit, onClose }) => {
  const apiListAnalyticParentType = useApiListAnalyticParentType()

  useLoad(() => apiListAnalyticParentType.get(), [])

  return (
    <Form state={row} onSubmit={onSubmit}>
      <div className="row">
        <FormSelectObj
          className="col-12 col-md-6"
          label="parent type"
          state={apiListAnalyticParentType}
          name="analyticParentTypeID"
          itemName="name"
          itemValue="id"
          valueAsNumber
          required
          small
        />
        <FormText className="col-12 col-md-6" label="resource" name="resource" required small />
        <FormText className="col-12" label="name" name="name" required small />
        <FormTextArea className="col-12" label="description" name="description" small />
        <FormSubmitCancel small onCancel={onClose} />
      </div>
    </Form>
  )
}
