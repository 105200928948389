import React, { useMemo, useState } from 'react'
import { useLoad } from '../../hooks/load'
import { SwitchSmall } from '../../component/switch'
import {
  IconTableFill,
  IconBegin,
  IconEnd,
  IconNext,
  IconPrevious,
  IconPipe,
  IconRefresh,
  IconTableFit,
  IconSettings
} from '../../icon/icon'
import { useTableContext } from '../table-context'

export const Footer = ({ tableRef, gridRef, pagination, onPaginationChange, onRefresh }) => {
  const tableContext = useTableContext()

  return (
    <div className="d-flex flex-column flex-sm-row m-1">
      <div className="d-flex flex-fill flex-nowrap">
        {onRefresh && <Reload onClick={onRefresh} />}
        <IconPipe />
        <Advanced tableRef={tableRef} />
        <IconPipe />
        <Fit gridRef={gridRef} />
        <IconPipe />
        <Fill gridRef={gridRef} />
      </div>
      <div className="d-flex flex-fill justify-content-end">
        {tableContext.isChunking &&
          <div className="w-100">
            <ChunkProgress />
          </div>}

        {tableContext.isLoaded &&
          <div className="d-flex flex-wrap">
            <PaginationSwitch value={pagination} onChange={onPaginationChange} />
            <Pagination gridRef={gridRef} pagination={pagination} />
          </div>}
      </div>
    </div>
  )
}

const ChunkProgress = () => {
  const tableContext = useTableContext()

  let progress = useMemo(
    () => {
      if (!tableContext.chunkCount) {
        return `0%`
      }

      return `${parseInt(tableContext.chunkProgress / tableContext.chunkCount * 100)}%`
    },
    [tableContext]
  )

  return (
    <div className="progress m-1" role="progressbar">
      {progress === `0%`
        ? <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: '100%' }} />
        : <div className="progress-bar" style={{ width: progress }}>
            {progress}
          </div>}
    </div>
  )
}

const Advanced = ({ tableRef }) => {
  const [selected, updateSelected] = useState(false)

  const handleSetting = () => {
    const selected = tableRef.current.toggleSideBar()
    updateSelected(selected)
  }

  return (
    <small className={`edxr-pointer ${selected && 'bg-primary rounded text-white'}`}>
      <span className="mx-1 text-nowrap" onClick={handleSetting} data-bs-toggle="tooltip" data-bs-placement="top">
        <IconSettings /> advanced
      </span>
    </small>
  )
}
const Fit = ({ gridRef }) => {
  const resize = () => {
    if (gridRef.current.api.getColumns) {
      let allColumnIds = gridRef.current.api.getColumns().map(column => column.getId())
      gridRef.current.api.autoSizeColumns(allColumnIds, false)
    }
  }

  return (
    <small className="d-flex text-nowrap edxr-pointer" onClick={resize}>
      <span className="mx-1">
        <IconTableFit /> fit
      </span>
    </small>
  )
}

const Fill = ({ gridRef }) => {
  const resize = () => {
    if (gridRef.current.api) {
      gridRef.current.api.sizeColumnsToFit()
    }
  }

  return (
    <small className="d-flex text-nowrap edxr-pointer" onClick={resize}>
      <span className="mx-1">
        <IconTableFill /> fill
      </span>
    </small>
  )
}

const Reload = ({ onClick }) => {
  const [loading, updateLoading] = useState(false)

  const handleRefresh = async () => {
    updateLoading(true)
    let start = Date.now()
    await onClick()
    let end = Date.now()
    setTimeout(() => updateLoading(false), 1000 - (end - start))
  }

  return (
    <small className="d-flex text-nowrap edxr-pointer" onClick={handleRefresh}>
      <span className="mx-1">
        {loading
          ? <span className="spinner-border spinner-border-sm mx-1" role="status">
              <span className="visually-hidden">loading...</span>
            </span>
          : <IconRefresh />}
        reload
      </span>
    </small>
  )
}

const PaginationSwitch = ({ value, onChange }) => {
  return (
    <div className="mx-2">
      <SwitchSmall text="Pagination" value={value} onChange={onChange} />
    </div>
  )
}

const Pagination = ({ gridRef, pagination }) => {
  const tableContext = useTableContext()

  return (
    <React.Fragment>
      {!pagination && <PaginationAll displayedRowCount={tableContext.displayedRowCount} />}
      {pagination &&
        <React.Fragment>
          <PaginationCurrent
            currentPage={tableContext.currentPage}
            currentPageRows={tableContext.pageSize}
            displayedRowCount={tableContext.displayedRowCount}
          />
          <PaginationNav gridRef={gridRef} currentPage={tableContext.currentPage} totalPages={tableContext.totalPages} />
        </React.Fragment>}
    </React.Fragment>
  )
}

const PaginationNav = ({ gridRef, currentPage, totalPages }) => {
  const handleFirst = () => gridRef.current.api && gridRef.current.api.paginationGoToFirstPage()
  const handleLast = () => gridRef.current.api && gridRef.current.api.paginationGoToLastPage()
  const handleNext = () => gridRef.current.api && gridRef.current.api.paginationGoToNextPage()
  const handlePrevious = () => gridRef.current.api && gridRef.current.api.paginationGoToPreviousPage()

  return (
    <React.Fragment>
      <div className="d-flex mx-1">
        <small className="mx-1 edxr-pointer" onClick={handleFirst}>
          <IconBegin />{' '}
        </small>
        <small className="mx-1 edxr-pointer" onClick={handlePrevious}>
          <IconPrevious />
        </small>
        <small className="ms-1">Page</small>
        <small className="ms-1 fw-bold">
          {currentPage + 1}
        </small>
        <small className="ms-1">of</small>
        <small className="ms-1 fw-bold">
          {totalPages || 1}
        </small>
        <small className="mx-1 edxr-pointer" onClick={handleNext}>
          <IconNext />
        </small>
        <small className="mx-1 edxr-pointer" onClick={handleLast}>
          <IconEnd />
        </small>
      </div>
    </React.Fragment>
  )
}

const PaginationAll = ({ displayedRowCount }) => {
  return (
    <React.Fragment>
      <div className="d-flex mx-1">
        <small>Rows</small>
        <small className="mx-1 fw-bold">
          {displayedRowCount}
        </small>
      </div>
    </React.Fragment>
  )
}

const PaginationCurrent = ({ currentPage, currentPageRows, displayedRowCount }) => {
  const [rowStart, updateRowStart] = useState(0)
  const [rowEnd, updateRowEnd] = useState(0)

  useLoad(
    () => {
      let start = currentPage * currentPageRows
      updateRowStart(start)

      let end = start + currentPageRows
      if (end > displayedRowCount) {
        end = displayedRowCount
      }

      updateRowEnd(end)
    },
    [currentPage, currentPageRows, displayedRowCount]
  )

  return (
    <React.Fragment>
      <div className="d-flex mx-1">
        <small className="mx-1 fw-bold">
          {rowStart + 1}
        </small>

        <small>to</small>

        <small className="mx-1 fw-bold">
          {rowEnd}
        </small>

        <small>of</small>

        <small className="mx-1 fw-bold">
          {displayedRowCount}
        </small>
      </div>
    </React.Fragment>
  )
}
