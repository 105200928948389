import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'

export const useQuery = () => {
  let location = useLocation()
  return useMemo(() => new URLSearchParams(location.search), [location.search])
}

export const useQueryParam = param => {
  const { search } = useLocation()

  let value = useMemo(
    () => {
      let searchParams = new URLSearchParams(search)
      return searchParams.get(param)
    },
    [param, search]
  )

  return value
}

export const useLocationSearchObject = keys => {
  const location = useLocation()

  const params = useMemo(() => new URLSearchParams(location.search), [location.search])

  return useMemo(
    () => {
      let items = {}

      keys.forEach(key => {
        let parts = key.split(':')
        let value = params.get(parts[0])
        items[parts[1]] = value
      })

      return items
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params]
  )
}

export const useParamsInt = () => {
  const params = useParams()

  return useMemo(
    () => {
      let result = {}

      Object.keys(params).forEach(key => {
        let value = parseInt(params[key])
        if (!isNaN(value)) {
          result[key] = value
        }
      })

      return result
    },
    [params]
  )
}
