export const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align || 'left' }

  switch (element.type) {
    case 'list-bulleted':
      return (
        <ul className="m-2" {...attributes} style={style}>
          {children}
        </ul>
      )
    case 'list-numbered':
      return (
        <ol className="m-2" {...attributes} style={style}>
          {children}
        </ol>
      )
    case 'list-item':
      return (
        <li {...attributes} style={style}>
          {children}
        </li>
      )
    default:
      return (
        <div {...attributes} className="m-2" style={style}>
          {children}
        </div>
      )
  }
}
