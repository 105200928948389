import React from 'react'
import { Route } from 'react-router-dom'
import { PasswordUpdate } from './content/password-update'

export const LayoutPasswordUpdate = () => {
  return (
    <React.Fragment>
      <Route path="/org/password-update" component={PasswordUpdate} />
    </React.Fragment>
  )
}
