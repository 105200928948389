import { useContext, useReducer } from 'react'
import { getAsync, postAsync, putAsync, deleteAsync } from '../../shared/api.service'
import { formReducer, formInitialState } from './reducer'
import { AuthContext } from '../../context/auth.context'
import { handleException, getEndpoint } from '../common/common'
import { useNotificationContext } from '../../context/notification/context'

export const types = {
  INIT: 'INIT',
  ERROR: 'ERROR',
  SUBMIT: 'SUBMIT',
  GET_SUCCESS: 'GET_SUCCESS',
  ADD_SUCCESS: 'ADD_SUCCESS',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  TEST_SUBMIT: 'TEST_SUBMIT',
  TEST_SUCCESS: 'TEST_SUCCESS'
}

const actionInit = () => dispatch => dispatch({ type: types.INIT })

const actionGet = (server, uri, args) => dispatch => (authContext, notificationContext) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (authContext) {
        await authContext.actions.checkExpRefresh()
      }

      let endpoint = getEndpoint(uri, args)

      dispatch({ type: types.SUBMIT })

      getAsync(server, endpoint)
        .then(({ data }) => {
          dispatch({ type: types.GET_SUCCESS, payload: data })
          resolve(data)
        })
        .catch(error => {
          // notificationContext.modal.error(error)
          dispatch({ type: types.ERROR, payload: error || '' })
          reject(error)
        })
    } catch (error) {
      handleException(error)
      reject(error)
    }
  })
}

const actionPost = (server, uri, args) => dispatch => (authContext, notificationContext) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (authContext) {
        await authContext.actions.checkExpRefresh()
      }

      let endpoint = getEndpoint(uri, args)

      dispatch({ type: types.SUBMIT })

      postAsync(server, endpoint, args.data)
        .then(({ data }) => {
          dispatch({ type: types.ADD_SUCCESS, payload: data })
          resolve(data)
        })
        .catch(error => {
          notificationContext.modal.error(error)
          dispatch({ type: types.ERROR, payload: error || '' })
          reject(error)
        })
    } catch (error) {
      dispatch({ type: types.ERROR, payload: error || '' })
      reject(error)
    }
  })
}

const actionPut = (server, uri, args) => dispatch => (authContext, notificationContext) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (authContext) {
        await authContext.actions.checkExpRefresh()
      }

      let endpoint = getEndpoint(uri, args)

      dispatch({ type: types.SUBMIT })

      putAsync(server, endpoint, args.data)
        .then(({ data }) => {
          dispatch({ type: types.UPDATE_SUCCESS, payload: data })
          resolve(data)
        })
        .catch(error => {
          notificationContext.modal.error(error)
          dispatch({ type: types.ERROR, payload: error || '' })
          reject(error)
        })
    } catch (error) {
      handleException(error)
      reject(error)
    }
  })
}

const actionDelete = (server, uri, args) => dispatch => (authContext, notificationContext) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (authContext) {
        await authContext.actions.checkExpRefresh()
      }

      let endpoint = getEndpoint(uri, args)

      dispatch({ type: types.SUBMIT })

      deleteAsync(server, endpoint)
        .then(({ data }) => {
          dispatch({ type: types.DELETE_SUCCESS })
          resolve(data)
          notificationContext.toast.notify('delete success', 3000)
        })
        .catch(error => {
          notificationContext.modal.error(error)
          dispatch({ type: types.ERROR, payload: error || '' })
          reject(error)
        })
    } catch (error) {
      handleException(error)
      reject(error)
    }
  })
}

const actionTest = ms => dispatch => notificationContext => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: types.TEST_SUBMIT })

      setTimeout(() => {
        dispatch({ type: types.TEST_SUCCESS })
        resolve()
      }, ms || 2000)
    } catch (error) {
      notificationContext.modal.error(error)
      handleException(error)
      reject(error)
    }
  })
}

export const useApiFormState = (server, uri) => {
  const [state, dispatch] = useReducer(formReducer, formInitialState)

  const authContext = useContext(AuthContext)
  const notificationContext = useNotificationContext()

  const actions = {
    init: () => actionInit()(dispatch),

    get: (path, toast) => actionGet(server, uri, { path, toast })(dispatch)(authContext, notificationContext),
    getArgs: args => actionGet(server, uri, args)(dispatch)(authContext, notificationContext),

    post: (data, toast) => actionPost(server, uri, { data, toast })(dispatch)(authContext, notificationContext),
    postArgs: args => actionPost(server, uri, args)(dispatch)(authContext, notificationContext),

    put: (data, toast) => actionPut(server, uri, { path: data.id, data, toast })(dispatch)(authContext, notificationContext),
    putArgs: args => actionPut(server, uri, args)(dispatch)(authContext, notificationContext),

    delete: (path, toast) => actionDelete(server, uri, { path, toast })(dispatch)(authContext, notificationContext),
    deleteArgs: args => actionDelete(server, uri, args)(dispatch)(authContext, notificationContext),

    test: ms => actionTest(ms)(dispatch, notificationContext)()
  }

  return [actions, state]
}
