export const DATATYPE_COURSE = 'course'
export const DATATYPE_CATEGORY = 'category'
export const DATATYPE_STREAMING_VIDEO = 'streaming_video'
export const DATATYPE_COURSE_TEMPLATE = 'course_template'
export const DATATYPE_COURSE_TEMPLATE_CATEGORY = 'course_template_category'
export const DATATYPE_NODE_LIBRARY = 'node_library'
export const DATATYPE_NODE_LIBRARY_CATEGORY = 'node_library_category'

const isDataType = (node, type) => {
  try {
    let metadata = getMetadata(node)

    if (!metadata.datatype) {
      return false
    }

    return metadata.datatype === type.toLowerCase()
  } catch (e) {
    return false
  }
}

export const isDatatypeCourse = node => isDataType(node, DATATYPE_COURSE)
export const isDatatypeCategory = node => isDataType(node, DATATYPE_CATEGORY)
export const isDatatypeStreamingVideo = node => isDataType(node, DATATYPE_STREAMING_VIDEO)
export const isDatatypeCourseTemplate = node => isDataType(node, DATATYPE_COURSE_TEMPLATE)
export const isDatatypeNodeTemplateCategory = node => isDataType(node, DATATYPE_COURSE_TEMPLATE_CATEGORY)
export const isDatatypeNodeLibrary = node => isDataType(node, DATATYPE_NODE_LIBRARY)
export const isDatatypeNodeLibraryCategory = node => isDataType(node, DATATYPE_NODE_LIBRARY_CATEGORY)

export const getDatatype = node => {
  if (!node.metadata) {
    return ''
  }

  try {
    return node.metadata.DataType.toLowerCase()
  } catch (e) {
    return ''
  }
}

export const getMetadata = node => {
  if (!node.metadata) {
    return {}
  }

  if (!node._metadata) {
    let lower = node.metadata.toLowerCase()
    node._metadata = JSON.parse(lower)
  }

  return node._metadata
}
