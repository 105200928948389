import { useEffect, useReducer, useState } from 'react'

const LOAD = 'LOAD'
const INSERT = 'INSERT'
const UPDATE = 'UPDATE'
const REMOVE = 'REMOVE'
const REMOVE_ID = 'REMOVE_ID'

var initialState = { array: [], map: {} }

const arrToMap = arr => {
  let map = {}
  arr.forEach(item => {
    map[item.id] = item
  })
  return map
}

export const useItemState = items => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case LOAD:
        let map = arrToMap(action.payload)
        return { array: [...action.payload], map: map }
      case INSERT:
        state.array.push(action.payload)
        state.map[action.payload.id] = action.payload
        return { array: [...state.array], map: { ...state.map } }
      case UPDATE:
        const insertIndex = state.array.findIndex(i => i.id === action.payload.id)
        state.array[insertIndex] = action.payload
        state.map[action.payload.id] = action.payload
        return { array: [...state.array], map: { ...state.map } }
      case REMOVE:
        let removeArray = state.array.filter(i => i.id !== action.payload.id)
        delete state.map[action.payload.id]
        return { array: [...removeArray], map: { ...state.map } }
      case REMOVE_ID:
        let removeIDArray = state.array.filter(i => i.id !== action.payload)
        delete state.map[action.payload.id]
        return { array: [...removeIDArray], map: { ...state.map } }
      default:
        return state
    }
  }, initialState)

  useEffect(() => load(items), [items])

  const load = items => dispatch({ type: LOAD, payload: items })
  const insert = item => dispatch({ type: INSERT, payload: item })
  const update = item => dispatch({ type: UPDATE, payload: item })
  const remove = item => dispatch({ type: REMOVE, payload: item })
  const removeID = id => dispatch({ type: REMOVE_ID, payload: id })

  const [listeners, updateListeners] = useState([])

  useEffect(
    () => {
      listeners.forEach(listener => listener({ array: state.array, map: state.map }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  const subscribe = listener => {
    updateListeners([...listeners, listener])
  }

  const unsubscribe = listener => {
    let hold = listeners.filter(i => i !== listener)
    updateListeners([...hold])
  }

  let array = state.array
  let map = state.map

  return { array, map, load, insert, update, remove, removeID, subscribe, unsubscribe }
}
