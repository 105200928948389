import { useMemo } from 'react'
import { useRoleContext } from '../role.context'
import { useAuthContext } from '../../context/auth.context'
import { ROLE_SYS } from '../role-const'

const fullAccess = { accessCreate: true, accessRead: true, accessUpdate: true, accessDelete: true, accessPurge: true }
const noAccess = { accessCreate: false, accessRead: false, accessUpdate: false, accessDelete: false, accessPurge: false }

export const useRoleSys = type => {
  const authContext = useAuthContext()
  const roleContext = useRoleContext()

  return useMemo(
    () => {
      if (authContext.state.isSysRoot) {
        return fullAccess
      }

      return roleContext.sysRoles[type] || noAccess
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state, roleContext.sysRoles]
  )
}

export const useRoleSysApp = () => useRoleSys(ROLE_SYS.SYS_APP)
export const useRoleSysLogin = () => useRoleSys(ROLE_SYS.SYS_LOGIN)
export const useRoleSysAuthStatus = () => useRoleSys(ROLE_SYS.SYS_AUTH_STATUS)
export const useRoleSysOrganization = () => useRoleSys(ROLE_SYS.SYS_ORGANIZATION)
export const useRoleSysProvider = () => useRoleSys(ROLE_SYS.SYS_PROVIDER)
