import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormSubmitBack, FormText, FormTextArea } from '../../../../../shared/form/form'
import { IconAdd } from '../../../../../shared/icon/icon'
import { Card } from '../../../../../shared/card/card'
import { useApiFormRoleSys } from '../../../../../api-new/state/content'

export const RoleSysAdd = () => {
  const history = useHistory()

  const apiFormRoleSys = useApiFormRoleSys()

  const handleSubmit = async data => {
    await apiFormRoleSys.post(data)
    history.goBack()
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add">
        <Card title="add role">
          <Form onSubmit={handleSubmit}>
            <FormText label="name" name="name" autoFocus />
            <FormTextArea label="description" name="description" />
            <FormSubmitBack text="add role" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
