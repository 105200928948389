import React from 'react'
import { Route } from '../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { NodeCategoryEdit } from './page/node-category-edit'
import { NodeCategoryEditSys } from './page/node-category-edit-sys'
import { NodeCategoryMove } from './page/node-category-move'
import { NodeCategoryAdd } from './page/node-category-add'
import { NodeCategoryDelete } from './page/node-category-delete'
import { PermissionNode } from '../common/permission/permission-node'
import { NodeRecycle } from '../common/recycle/node-recycle'
import { LogEventOrgSubjectID } from '../../../../../shared/page/log-event/log-event-org'

export const NodeCategory = ({ url, grandParentURL, nodeID, useApiListCategory, useApiFormCategory, useApiListRecycle, pathNodeFn }) => {
  return (
    <React.Fragment>
      <Route path={`${url}/edit-sys`}>
        <NodeCategoryEditSys parentURL={url} nodeID={nodeID} useApiForm={useApiFormCategory} />
      </Route>

      <Route path={`${url}/add`}>
        <NodeCategoryAdd parentURL={url} parentID={nodeID} useApiForm={useApiFormCategory} />
      </Route>

      <Route path={`${url}/edit`}>
        <NodeCategoryEdit parentURL={url} nodeID={nodeID} useApiForm={useApiFormCategory} />
      </Route>

      <Route path={`${url}/move`}>
        <NodeCategoryMove
          parentURL={url}
          nodeID={nodeID}
          useApiList={useApiListCategory}
          useApiForm={useApiFormCategory}
          pathNodeFn={pathNodeFn}
        />
      </Route>

      <Route path={`${url}/delete`}>
        <NodeCategoryDelete grandParentURL={grandParentURL} parentURL={url} nodeID={nodeID} useApiForm={useApiFormCategory} />
      </Route>

      <Route path={`${url}/recycle`}>
        <NodeRecycle nodeID={nodeID} useApiList={useApiListRecycle} />
      </Route>

      <PermissionNode url={url} nodeID={nodeID} />

      <Route path={`${url}/activity`}>
        <LogEventOrgSubjectID subject="node" subjectID={nodeID} />
      </Route>
    </React.Fragment>
  )
}
