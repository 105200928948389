const Badge = ({ badge, value }) =>
  <span className={`badge bg-${badge} text-capitalize p-2`}>
    {value}
  </span>

export const BadgePrimary = ({ value }) => <Badge badge="primary" value={value} />
export const BadgeSecondary = ({ value }) => <Badge badge="secondary" value={value} />
export const BadgeSuccess = ({ value }) => <Badge badge="success" value={value} />
export const BadgeDanger = ({ value }) => <Badge badge="danger" value={value} />
export const BadgeWarning = ({ value }) => <Badge badge="warning" value={value} />
export const BadgeInfo = ({ value }) => <Badge badge="info" value={value} />
export const BadgeLight = ({ value }) => <Badge badge="light" value={value} />
export const BadgeDark = ({ value }) => <Badge badge="dark" value={value} />
