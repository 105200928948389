import { useApiListState } from '../../api/api-list/action'
import { useApiFormState } from '../../api/api-form/action'
import { useApiGetState } from '../../api/api-get/action'

export const useNodeGet = () => {
  return useApiGetState(process.env.REACT_APP_API_CONTENT, `api/v1/node`)
}

export const useSessionQueryList = () => {
  return useApiListState(process.env.REACT_APP_API_HEATMAP, `api/v1/session-query`)
}

export const useSessionList = () => {
  return useApiListState(process.env.REACT_APP_API_HEATMAP, `api/v1/session`)
}

export const useSeriesList = () => {
  return useApiListState(process.env.REACT_APP_API_HEATMAP, `api/v1/series`)
}

export const useBuildFrom = () => {
  return useApiFormState(process.env.REACT_APP_API_HEATMAP, `api/v1/build`)
}

export const useExportGet = () => {
  return useApiGetState(process.env.REACT_APP_API_HEATMAP, `api/v1/export`)
}

export const useSeriesGet = () => {
  return useApiGetState(process.env.REACT_APP_API_HEATMAP, `api/v1/series-hold`)
}

export const useNodeSessionList = () => {
  return useApiListState(process.env.REACT_APP_API_HEATMAP, `api/v1/node-session`)
}

export const useUserSessionList = () => {
  return useApiListState(process.env.REACT_APP_API_HEATMAP, `api/v1/user-session`)
}
