import React, { useMemo } from 'react'
import { FilterList } from '../common/filter/filter-list'
import { getMap } from '../common/shared/node-path'
import { IconTemplate } from '../../../../../shared/icon/icon'
import { pathNodeTemplateMap } from '../common/shared/path-node-template'

export const NodeTemplateFilterList = ({ nodes, filter, onSelect }) => {
  let nodeMap = useMemo(() => getMap(nodes), [nodes])

  const handleClick = nodeID => {
    let path = pathNodeTemplateMap(nodeMap, nodeID)
    onSelect(path)
  }

  return <FilterList RootIcon={IconTemplate} nodes={nodes} nodeMap={nodeMap} filter={filter} onClick={handleClick} />
}
