import React, { useContext, useMemo, useReducer } from 'react'
import { useApiListNode } from '../../../../../api-new/state/content'
import { useLoad } from '../../../../../shared/hooks/load'

const AdminNodeContext = React.createContext()

export const useAdminNodeContext = () => useContext(AdminNodeContext)

export const AdminNodeProvider = ({ children }) => {
  const apiListNode = useApiListNode()

  useLoad(async () => await apiListNode.get(), [])

  const { nodeState, nodeInit, nodeAdd, nodeUpdate, nodeRemove, nodeValues } = useNodeCache()

  useLoad(() => nodeInit(apiListNode.items), [apiListNode.items])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let nodes = useMemo(() => nodeValues(), [nodeState])

  return (
    <AdminNodeContext.Provider value={{ nodes, nodeAdd, nodeUpdate, nodeRemove }}>
      {children}
    </AdminNodeContext.Provider>
  )
}

///////////////////////

const TYPE_INIT = 'TYPE_INIT'
const TYPE_ADD = 'TYPE_ADD'
const TYPE_UPDATE = 'TYPE_UPDATE'
const TYPE_REMOVE = 'TYPE_REMOVE'

const useNodeCache = () => {
  const [nodeState, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case TYPE_INIT:
        return action.payload
      case TYPE_ADD:
        let newAdd = { ...state }
        newAdd[action.payload.id] = action.payload
        return newAdd
      case TYPE_UPDATE:
        let newUpdate = { ...state }
        newUpdate[action.payload.id] = action.payload
        return newUpdate
      case TYPE_REMOVE:
        let newRemove = { ...state }
        delete newRemove[action.payload.id]
        return newRemove
      default:
        return state
    }
  }, {})

  const nodeInit = items => {
    let hold = items.reduce((acc, value) => {
      acc[value.id] = value
      return acc
    }, {})

    dispatch({ type: TYPE_INIT, payload: hold })
  }

  const nodeAdd = item => dispatch({ type: TYPE_ADD, payload: item })
  const nodeUpdate = item => dispatch({ type: TYPE_UPDATE, payload: item })
  const nodeRemove = item => dispatch({ type: TYPE_REMOVE, payload: item })
  const nodeValues = () => Object.values(nodeState)

  return { nodeState, nodeInit, nodeAdd, nodeUpdate, nodeRemove, nodeValues }
}
