import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import {
  Chart,
  //controllers
  LineController,
  PieController,
  BarController,

  //elements
  PointElement,
  LineElement,
  ArcElement,
  BarElement,

  //scales
  LinearScale,
  CategoryScale,

  //other
  Tooltip,
  Filler,
  Legend,
  Title
} from 'chart.js'

import { usePageContext } from '../../context/page.context'
import { useLoad } from '../hooks/load'

Chart.register(LineController, PieController, BarController)
Chart.register(PointElement, LineElement, ArcElement, BarElement)
Chart.register(CategoryScale, LinearScale)
Chart.register(Title, Legend, Tooltip, Filler)

const getConfig = (type, displayX, displayY, displayLegend, legendPosition, legendTextColor, title) => {
  return {
    type: type,
    data: {
      labels: [],
      datasets: []
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxis: {
          display: !!displayX,
          ticks: {
            autoSkip: true,
            maxRotation: 90,
            color: `${legendTextColor}`
          }
        },
        yAxis: {
          display: !!displayY,
          ticks: {
            autoSkip: true,
            maxRotation: 90,
            color: `${legendTextColor}`
          }
        }
      },
      plugins: {
        legend: {
          display: !!displayLegend,
          position: `${legendPosition || 'top'}`,
          labels: {
            color: `${legendTextColor}`
          }
        },
        title: {
          display: !!title,
          text: title,
          color: `${legendTextColor}`
        }
      }
    }
  }
}

const ChartCanvas = forwardRef(({ config, labels, data }, ref) => {
  const chartRef = useRef()

  const [chart, updateChart] = useState()

  useImperativeHandle(ref, () => ({
    update: () => {
      if (chart) {
        chart.destroy()
        let newChart = new Chart(chartRef.current, config)
        updateChart(newChart)
      }
    }
  }))

  useEffect(() => {
    let chart = new Chart(chartRef.current, config)
    updateChart(chart)
    return () => chart.destroy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      if (!chart) return
      if (labels) chart.data.labels = labels
      if (data) chart.data.datasets = [data]
      chart.update()
    },
    [chart, labels, data]
  )

  return <canvas ref={chartRef} style={{ margin: 0, padding: 0 }} />
})

export const ChartLine = ({ title, labels, data, displayX, displayY, displayLegend, legendPosition }) => {
  const ref = useRef()

  const pageContext = usePageContext()

  const legendColor = useMemo(() => (pageContext.theme.isDark ? 'white' : 'black'), [pageContext.theme.isDark])

  useLoad(() => ref.current.update(), [legendColor])

  const config = useMemo(() => getConfig('line', displayX, displayY, displayLegend, legendPosition, legendColor, title), [
    displayX,
    displayY,
    displayLegend,
    legendPosition,
    legendColor,
    title
  ])

  return <ChartCanvas ref={ref} config={config} labels={labels} data={data} />
}

export const ChartBar = ({ title, labels, data, displayX, displayY, displayLegend, legendPosition }) => {
  const ref = useRef()

  const pageContext = usePageContext()

  const legendColor = useMemo(() => (pageContext.theme.isDark ? 'white' : 'black'), [pageContext.theme.isDark])

  useLoad(() => ref.current.update(), [legendColor])

  const config = useMemo(() => getConfig('bar', displayX, displayY, displayLegend, legendPosition, legendColor, title), [
    displayX,
    displayY,
    displayLegend,
    legendPosition,
    legendColor,
    title
  ])

  return <ChartCanvas ref={ref} config={config} labels={labels} data={data} />
}

export const ChartPie = ({ title, labels, data, displayX, displayY, displayLegend, legendPosition }) => {
  const ref = useRef()

  const pageContext = usePageContext()

  const legendColor = useMemo(() => (pageContext.theme.isDark ? 'white' : 'black'), [pageContext.theme.isDark])

  useLoad(() => ref.current.update(), [legendColor])

  const config = useMemo(() => getConfig('pie', displayX, displayY, displayLegend, legendPosition, legendColor, title), [
    displayX,
    displayY,
    displayLegend,
    legendPosition,
    legendColor,
    title
  ])

  return <ChartCanvas ref={ref} config={config} labels={labels} data={data} />
}
