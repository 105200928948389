import moment from 'moment'

var bytes = 8
var kilabyte = 1024
var megabyte = kilabyte * 1024
var gigabyte = megabyte * 1024

export const FormatSize = ({ value }) => {
  const getSize = value => {
    if (value >= gigabyte) {
      return `${(value / gigabyte).toFixed(2)} GB`
    } else if (value >= megabyte) {
      return `${(value / megabyte).toFixed(2)} MB`
    } else if (value >= kilabyte) {
      return `${(value / kilabyte).toFixed(2)} KB`
    } else {
      return `${(value / bytes).toFixed(2)} KB`
    }
  }

  return (
    <span>
      {getSize(value)}
    </span>
  )
}

export const FormatDate = ({ date }) => {
  let dateObj = new Date(date)
  return (
    <span>
      {dateObj.toLocaleString()}
    </span>
  )
}

export const FormatPrecent = ({ value }) => {
  let percent = (value * 100).toFixed(0)
  return (
    <span>
      {percent}%
    </span>
  )
}

export const FormatDateTimeUnix = ({ date }) => {
  return (
    <span>
      {moment.unix(date).local().format('MM/DD/YYYY-hh:mm:ss')}
    </span>
  )
}
