import React, { useEffect } from 'react'
import { useNodeGet } from './state'
import { useParams, useRouteMatch } from 'react-router'
import { BreadcrumbView } from '../../context/breadcrumb.context'
import { IconList } from '../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../shared/card/card-full-height-scroll-y'
import { Column, ColumnDateTime, ColumnDuration, ColumnMenu, TableBuilder } from '../../shared/table-builder/table-builder'
import { Menu, MenuDelete, MenuItem } from '../../shared/menu/menu'
import { useApiListHeatmapSeries } from '../../api-new/state/heatmap'
import { useLoad } from '../../shared/hooks/load'
import { IconEnter } from '../../shared/icon/icon'

export function SeriesList() {
  const { url } = useRouteMatch()
  const { nodeID, sessionID } = useParams()

  const [nodeGetActions] = useNodeGet()

  const apiListHeatmapSeries = useApiListHeatmapSeries()

  useEffect(
    () => {
      nodeGetActions.get(nodeID)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  useLoad(() => apiListHeatmapSeries.get(`${sessionID}/session`), [])

  const handleDelete = async id => {
    await apiListHeatmapSeries.delete(id)
    await apiListHeatmapSeries.get(`${sessionID}/session`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="scenes">
        <CardFullHeightScrollY title="Sessions">
          <TableBuilder apiList={apiListHeatmapSeries}>
            <ColumnDateTime field="beganAt" header="Began At" sortable filter />
            <ColumnDateTime field="endedAt" header="Ended At" sortable filter />
            <ColumnDuration field="duration" beganAt="beganAt" endedAt="endedAt" />

            <Column field="displayName" sortable filter />

            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuItem text="view" Icon={IconEnter} to={`${url}/display/${row.id}`} show pin />
                  <MenuDelete onClick={() => handleDelete(row.id)} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
