import React, { useContext } from 'react'
import { TypeAlert } from './type-alert'
import { TypeToast } from './type-toast'
import { TypeModal } from './type-modal'
import { itemType } from './hooks'
import { useReducerList } from '../../shared/hooks/reducer-list'

const NotificationContext = React.createContext()

export const useNotificationContext = () => {
  return useContext(NotificationContext)
}

export const NotificationItems = () => {
  const notificationContext = useContext(NotificationContext)

  return (
    <React.Fragment>
      <TypeAlert state={notificationContext.state.alertState} onClose={notificationContext.state.alertRemoveID} />
      <TypeToast state={notificationContext.state.toastState} onClose={notificationContext.state.toastRemoveID} />
      <TypeModal state={notificationContext.state.modalState} onClose={notificationContext.state.modalRemoveID} />
    </React.Fragment>
  )
}

export const NotificationProvider = ({ children }) => {
  const [alertState, alertAddItem, alertRemoveID, alertRemoveAll] = useReducerList()
  const [toastState, toastAddItem, toastRemoveID, toastRemoveAll] = useReducerList()
  const [modalState, modalAddItem, modalRemoveID, modalRemoveAll] = useReducerList()

  return (
    <NotificationContext.Provider
      value={{
        state: {
          alertState,
          alertRemoveID,
          toastState,
          toastRemoveID,
          modalState,
          modalRemoveID
        },

        alert: {
          notify: (text, close) => alertAddItem({ type: itemType.NOTIFY, text: text }, close),
          warning: (text, close) => alertAddItem({ type: itemType.WARNING, text: text }, close),
          error: (text, close) => alertAddItem({ type: itemType.ERROR, text: text }, close),
          removeID: id => alertRemoveID(id),
          removeAll: () => alertRemoveAll()
        },
        toast: {
          notify: (text, close) => toastAddItem({ type: itemType.NOTIFY, text: text }, close),
          warning: (text, close) => toastAddItem({ type: itemType.WARNING, text: text }, close),
          error: (text, close) => toastAddItem({ type: itemType.ERROR, text: text }, close),
          removeID: id => toastRemoveID(id),
          removeAll: () => toastRemoveAll()
        },
        modal: {
          notify: (text, close) => modalAddItem({ type: itemType.NOTIFY, text: text }, close),
          warning: (text, close) => modalAddItem({ type: itemType.WARNING, text: text }, close),
          error: (text, close) => modalAddItem({ type: itemType.ERROR, text: text }, close),
          removeID: id => modalRemoveID(id),
          removeAll: () => modalRemoveAll()
        }
      }}>
      <NotificationItems />
      {children}
    </NotificationContext.Provider>
  )
}
