import React from 'react'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useAdminNodeContext } from './admin-node-context'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Form, FormSubmitBack, FormText } from '../../../../../shared/form/form'
import { useApiFormNode } from '../../../../../api-new/state/content'
import { NodeAccess } from './form/node-access'
import { FormJsonEditorStr } from '../../../../../shared/form/form-control/form-json-editor-str'

export const NodeAdd = ({ onAdd }) => {
  const { parentID } = useParamsInt()

  const adminNodeContext = useAdminNodeContext()

  const apiFormNode = useApiFormNode()

  const handleSubmit = async data => {
    data.parentID = parentID
    let node = await apiFormNode.post(data)
    adminNodeContext.nodeAdd(node)
    onAdd(node)
  }
  return (
    <React.Fragment>
      <BreadcrumbView text="add">
        <CardFullHeightScrollY title="add">
          <Form state={apiFormNode.form} onSubmit={handleSubmit}>
            <FormText label="name" name="name" required />
            <NodeAccess />
            <FormJsonEditorStr label="metadata" name="metadata" />
            <FormSubmitBack />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
