export const ScrollY = ({ children }) => {
  return (
    <div className="overflow-y-scroll h-100">
      {children}
    </div>
  )
}

export const ScrollXY = ({ children }) => {
  return (
    <div className="overflow-scroll h-100">
      {children}
    </div>
  )
}
