import { isDatatypeNodeLibrary, isDatatypeNodeLibraryCategory } from './metadata'

export const pathNodeLibraryMap = (nodeMap, nodeID) => {
  const recBuild = (nodeMap, nodeID) => {
    let node = nodeMap[nodeID]

    if (!node) {
      return
    }

    if (node.parentID) {
      let path = recBuild(nodeMap, node.parentID, true)
      return libraryPath(path, node)
    } else {
      return libraryPath(`/org/node-library`, node)
    }
  }

  return recBuild(nodeMap, nodeID, false)
}

const libraryPath = (path, node) => {
  if (isDatatypeNodeLibrary(node)) {
    return `${path}/library/${node.id}`
  }

  if (isDatatypeNodeLibraryCategory(node)) {
    return `${path}/node/${node.id}`
  }

  return path
}
