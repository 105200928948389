import React, { useMemo, useState } from 'react'
import { useQueryParam } from '../shared/hooks/location'
import { useLoad } from '../shared/hooks/load'
import { useAuthProviderForm } from './state'
import { CardFullHeightScrollY } from '../shared/card/card-full-height-scroll-y'

export const OpenOrganization = () => {
  const [authProviderFormActions] = useAuthProviderForm()

  const [organizations, updateOrganizations] = useState([])

  const token = useQueryParam('token')

  const showOrganizations = useMemo(() => !!organizations.length, [organizations])

  useLoad(async () => {
    let organizations = await authProviderFormActions.get(`${token}/organization`)

    if (organizations) {
      updateOrganizations(organizations)
      return
    } else {
      let url = new URL(`${process.env.REACT_APP_SITE}/open/complete`)
      url.searchParams.set('token', token)
      window.location = url.href
    }
  }, [])

  const handleSelect = async organization => {
    await authProviderFormActions.postArgs({ path: `${token}/organization`, data: organization })

    let url = new URL(`${process.env.REACT_APP_SITE}/open/complete`)
    url.searchParams.set('token', token)
    window.location = url.href
  }

  return (
    <React.Fragment>
      {showOrganizations &&
        <CardFullHeightScrollY title="select organization">
          <div className="table-responsive">
            <table className="table table-striped table-hover m-2">
              <tbody>
                {organizations.map((organization, i) => <OrganizationLine key={i} organization={organization} onSelect={handleSelect} />)}
              </tbody>
            </table>
          </div>
        </CardFullHeightScrollY>}
    </React.Fragment>
  )
}

const OrganizationLine = ({ organization, onSelect }) => {
  const handleSelect = () => onSelect && onSelect(organization)

  return (
    <tr onClick={handleSelect} style={{ cursor: 'pointer' }}>
      <td>
        <span className="m-2">
          {organization.name}
        </span>
      </td>
    </tr>
  )
}
