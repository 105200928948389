import { FormatDate, FormatDateTimeUnix } from '../format'

export const DetailsDisplayTable = ({ children }) => {
  return (
    <div className="container-fluid">
      {children}
    </div>
  )
}

export const DetailsDisplayText = ({ label, value }) => {
  return (
    <div className="row border-bottom">
      <div className="col-3 text-capitalize">
        {label}
      </div>
      <div className="col-9">
        {value || 'NA'}
      </div>
    </div>
  )
}

export const DetailsDisplayJSON = ({ label, value }) => {
  return (
    <div className="row border-bottom">
      <div className="col-3 text-capitalize">
        {label}
      </div>
      <div className="col-9">
        {value || 'NA'}
      </div>
    </div>
  )
}

export const DetailsDisplayDate = ({ label, value }) => {
  return (
    <div className="row border-bottom">
      <div className="col-3 text-capitalize">
        {label}
      </div>
      <div className="col-9">
        <FormatDate date={value} />
      </div>
    </div>
  )
}

export const DetailsDisplayDateUnix = ({ label, value }) => {
  return (
    <div className="row border-bottom">
      <div className="col-3 text-capitalize">
        {label}
      </div>
      <div className="col-9">
        <FormatDateTimeUnix date={value} />
      </div>
    </div>
  )
}
