export const getEndpoint = (uri, args) => {
  let result = uri

  if (args.path) {
    result += '/' + args.path
  }

  if (args.search) {
    result += args.search[0] === '?' ? args.search : '?' + args.search
  }

  return result
}

export const handleException = error => {
  let message = `ERROR: ${error}`
  console.log(message)
  alert(message)
}
