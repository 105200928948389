import React from 'react'
import { Paragraph } from './paragraph'
import { CardFullHeightScrollY } from '../../card/card-full-height-scroll-y'

export const Privacy = () => {
  return (
    <CardFullHeightScrollY title="privacy">
      <Paragraph header="PRIVACY POLICY">
        <p>
          Heizenrader built the EducationXR app as a Commercial app. This SERVICE is provided by Heizenrader and is intended for use as is.
        </p>

        <p>
          This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if
          anyone decided to use our Service.
        </p>

        <p>
          If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal
          Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone
          except as described in this Privacy Policy.
        </p>

        <p>
          The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at EducationXR
          unless otherwise de fined in this Privacy Policy.
        </p>
      </Paragraph>

      <Paragraph header="INFORMATION COLLECTION AND USE">
        <p>
          For a better experience, while using our Service, we may require you to provide us with certain personally identifiable
          information. The information that we request will be retained by us and used as described in this privacy policy.
        </p>

        <p>The app does use third party services that may collect information used to identify you.</p>

        <p>Link to privacy policy of third party service providers used by the app</p>

        <ul>
          <li>Google Play Services</li>
        </ul>
      </Paragraph>

      <Paragraph header="RETENTION POLICY">
        <p>Collected data includes the following:</p>

        <ul>
          <li>First name</li>
          <li>Last name</li>
          <li>Email address</li>
          <li>Analytics</li>
          <ul>
            <li>User behavior (steps, objectives, attachments, time to complete course, etc)</li>
            <li>Information about device used (operating system, manufacturer, hardware, etc)</li>
          </ul>
          <li>Assessment results</li>
          <li>Survey results</li>
          <li>Multiplayer statistics</li>
          <ul>
            <li>Room created</li>
            <li>Attendees</li>
            <li>Duration</li>
          </ul>
        </ul>

        <p>
          Data is retained only for as long as necessary for the identified purposes. Unless otherwise specified, data is retained for a
          period of five years. Users can otherwise request for data to be removed manually. In order to make this request, please email{' '}
          <a href="mailto:privacy@educationxr.com">privacy@educationxr.com</a>.
        </p>
      </Paragraph>

      <Paragraph header="LOG DATA AND COOKIES">
        <p>
          We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and inform ation
          (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet
          Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time
          and date of your use of the Service, and other statistics.
        </p>

        <p>
          Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your
          browser from the websites that you visit and are stored on your device’s internal memory.
        </p>

        <p>
          This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies”
          to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a
          cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this
          Service.
        </p>
      </Paragraph>

      <Paragraph header="THIRD-PARTY SERVICE PROVIDERS">
        <p>
          We work with third-party service providers to help us provide our Services. When we share information with third-party service
          providers in this capacity, we require them to use your information on our behalf in accordance with our instructions and terms
          and only process as necessary for the purpose of the contract. We work with different types of third-party service providers.
        </p>

        <p>Types of Third-Party Service Providers We Use:</p>

        <ul>
          <li>Marketing and promotions providers (to promote our Services)</li>
          <li>Telecommunications technology providers (to send you messages, including SMS messages)</li>
          <li>Data hosting service providers and payment vendors (for off-site data hosting)</li>
          <li>Security service providers (for investigating fraud and security incidents)</li>
          <li>Analytics providers (to understand how you use our Services)</li>
          <li>Payment processing companies (to process transactions on our behalf)</li>
          <li>Document repository services providers</li>
          <li>Customer support vendors</li>
        </ul>

        <p>What Information We Share:</p>

        <ul>
          <li>Basic Customer Information</li>
          <li>Usage Information</li>
        </ul>
      </Paragraph>

      <Paragraph header="SECURITY">
        <p>
          We value your trust in providing us your Personal Information, thus we are stri ving to use commercially acceptable means of
          protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and
          reliable, and we cannot guarantee its absolute security.
        </p>
      </Paragraph>

      <Paragraph header="LINKS TO OTHER SITES">
        <p>
          This Service may contain links to other sites. If you click on a third party link, you will be directed to that site. Note that
          these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We
          have no control over and assume no responsibility for the content, privacy policies, or practices of any third party sites or
          services.
        </p>
      </Paragraph>

      <Paragraph header="CHILDREN’S PRIVACY">
        <p>
          These Services do not address anyone under the age of 13. We do not knowingly collect personally id entifiable information from
          children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete
          this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal
          information, please contact us so that we will be able to do necessary actions.
        </p>
      </Paragraph>

      <Paragraph header="SINGLE SIGN-ON">
        <p>
          When logging in through an OAuth provider (Google, Apple, Facebook, etc.), the email and profile name are stored for the
          EducationXR account and user creation. This information is purged upon the deletion of the account by the user. This information
          is not sold or shared with third parties.
        </p>
      </Paragraph>

      <Paragraph header="CHANGES TO THIS PRIVACY POLICY">
        <p>
          We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We
          will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they
          are posted on this page.
        </p>
      </Paragraph>

      <Paragraph header="CONTACT US">
        <p>
          If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at{' '}
          <a href="mailto:info@heizenrader.com">info@heizenrader.com</a>.
        </p>
      </Paragraph>
    </CardFullHeightScrollY>
  )
}
