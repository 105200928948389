export const chartGradientRed = { backgroundImage: 'linear-gradient(#e57373, #d32f2f)' } // red 300 - 700
export const chartGradientPurple = { backgroundImage: 'linear-gradient(#ba68c8, #7b1fa2)' } // purple 300 - 700
export const chartGradientIndigo = { backgroundImage: 'linear-gradient(#7986cb, #303f9f)' } // indigo 300 - 700
export const chartGradientBlue = { backgroundImage: 'linear-gradient(#64b5f6, #1976d2)' } // blue 300 - 700
export const chartGradientCyan = { backgroundImage: 'linear-gradient(#4dd0e1, #0097a7)' } // cyan 300 - 700
export const chartGradientGreen = { backgroundImage: 'linear-gradient(#81c784, #388e3c)' } // green 300 - 700
export const chartGradientYellow = { backgroundImage: 'linear-gradient(#fff176, #fbc02d)' } // yellow 300 - 700
export const chartGradientOrange = { backgroundImage: 'linear-gradient(#ffb74d, #f57c00)' } // orange 300 - 700
export const chartGradientBrown = { backgroundImage: 'linear-gradient(#a1887f, #5d4037)' } // brown 300 - 700
export const chartGradientGray = { backgroundImage: 'linear-gradient(#e0e0e0, #616161)' } // gray 300 - 700
export const chartGradientBlueGray = { backgroundImage: 'linear-gradient(#90a4ae, #455a64)' } // blue gray 300 - 700

const chartBlack = '#000000'

const chartWhite = '#ffffff'

// const chartRed100 = '#ffcdd2'
const chartRed500 = '#f44336'

// const chartIndigo100 = '#c5cae9'
const chartIndigo500 = '#3f51b5'

const chartLightBlue100 = '#b3e5fc'
// const chartLightBlue300 = '#4fc3f7'
const chartLightBlue500 = '#03a9f4'

// const chartGreen100 = '#c8e6c9'
const chartGreen500 = '#4caf50'

// const chartYellow100 = '#fff9c4'
const chartYellow500 = '#ffeb3b'

// const chartBrown100 = '#bcaaa4'
const chartBrown500 = '#795548'

const chartGray100 = '#eeeeee'
const chartGray500 = '#9e9e9e'

// const chartBlueGray100 = '#b0bec5'
const chartBlueGray500 = '#607d8b'

const chartBarBackgroudAlpha = 80

export const chartBlackBackground = `${chartBlack}${chartBarBackgroudAlpha}`
export const chartBlackLine = chartBlack
export const chartBlackPoint = chartBlack

export const chartWhiteBackground = `${chartWhite}${chartBarBackgroudAlpha}`
export const chartWhiteLine = chartWhite
export const chartWhitePoint = chartWhite

export const chartGrayBackground = `${chartGray100}${chartBarBackgroudAlpha}`
export const chartGrayLine = chartGray500
export const chartGrayPoint = chartGray500

export const chartBlueBackground = `${chartLightBlue100}${chartBarBackgroudAlpha}`
export const chartBlueLine = chartLightBlue500
export const chartBluePoint = chartLightBlue500

export const chartPieBackgroud = [
  chartRed500,
  chartIndigo500,
  chartBrown500,
  chartLightBlue500,
  chartGreen500,
  chartYellow500,
  chartGray500,
  chartBlueGray500
]
