import moment from 'moment'
import { useMemo } from 'react'

export const DetailValue = ({ label, value }) => {
  return (
    <div className="form-group">
      {label &&
        <label className="text-capitalize">
          {label}
        </label>}
      <div className="form-control">
        {value}
      </div>
    </div>
  )
}

export const DetailValueBool = ({ label, value }) => {
  return (
    <div className="form-group">
      {label &&
        <label className="text-capitalize">
          {label}
        </label>}
      <div className="form-control">
        {value ? 'Yes' : 'No'}
      </div>
    </div>
  )
}

export const DetailValueDateTime = ({ label, value }) => {
  const formattedValue = useMemo(
    () => {
      let hold = moment(value).format('DD/MM/YYYY : hh:mm:ss a')
      return hold
    },
    [value]
  )

  return (
    <div className="form-group">
      {label &&
        <label className="text-capitalize">
          {label}
        </label>}
      <div className="form-control">
        {formattedValue}
      </div>
    </div>
  )
}
