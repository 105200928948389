import React, { useMemo } from 'react'
import { FormSelectListObj } from '../../../../../shared/form/form-select-list-obj'

export const PassingScore = ({ label, name, questionCount, readOnly }) => {
  let state = useMemo(
    () => {
      let scoreArray = []

      for (let i = 1; i <= questionCount; i++) {
        let precent = (i / questionCount * 100.0).toFixed(2)
        let text = `${i} correct answers - ${precent}%`
        scoreArray.push({ value: i, text: text })
      }

      return { items: scoreArray }
    },
    [questionCount]
  )

  return (
    <React.Fragment>
      <FormSelectListObj
        label={label}
        name={name}
        state={state}
        itemName="text"
        itemValue="value"
        nullText="No Score"
        nullValue={0}
        valueAsNumber
        readOnly={readOnly}
      />
    </React.Fragment>
  )
}
