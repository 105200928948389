import React, { useEffect, useRef, useState } from 'react'

const useInputErrors = value => {
  const ref = useRef(null)

  const [state, updateState] = useState({ required: false, valid: false })

  useEffect(
    () => {
      if (ref.current) {
        updateState({ required: ref.current.validity.valueMissing, valid: ref.current.validity.patternMismatch })
      }
    },
    [value]
  )

  return [ref, state]
}

const useUpdateError = (name, errorState, onError) => {
  useEffect(
    () => {
      onError && onError(name, errorState)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorState]
  )
}

export const InputEmail = ({ value, disabled, onChange, onError }) => {
  const [ref, errorState] = useInputErrors(value)
  useUpdateError('email', errorState, onError)

  let matchEmailPattern = process.env.REACT_APP_REGEX_EMAIL

  const handleChange = e => onChange && onChange(e.target.value)

  return (
    <React.Fragment>
      <div className="d-flex">
        <input
          ref={ref}
          className="form-control disabled"
          value={value}
          placeholder="email"
          pattern={matchEmailPattern}
          disabled={disabled}
          required
          onChange={handleChange}
        />
      </div>
      <div>
        {errorState.required && <small className={'p-1 text-danger'}>Required</small>}
        {errorState.valid && <small className={'p-1 text-danger'}>Invalid Email</small>}
      </div>
    </React.Fragment>
  )
}

export const InputFirstName = ({ value, disabled, onChange, onError }) => {
  const [ref, errorState] = useInputErrors(value)
  useUpdateError('first', errorState, onError)

  const handleChange = e => onChange && onChange(e.target.value)

  return (
    <React.Fragment>
      <input
        ref={ref}
        className="form-control"
        value={value}
        placeholder="firstname"
        disabled={disabled}
        required
        onChange={handleChange}
      />
      <div>
        {errorState.required && <small className={'p-1 text-danger'}>Required</small>}
      </div>
    </React.Fragment>
  )
}

export const InputLastName = ({ value, disabled, onChange, onError }) => {
  const [ref, errorState] = useInputErrors(value)
  useUpdateError('last', errorState, onError)

  const handleChange = e => onChange && onChange(e.target.value)

  return (
    <React.Fragment>
      <input ref={ref} className="form-control" value={value} disabled={disabled} placeholder="lastname" required onChange={handleChange} />
      <div>
        {errorState.required && <small className={'p-1 text-danger'}>Required</small>}
      </div>
    </React.Fragment>
  )
}

export const Input = ({ children, label, showRequired, showError }) => {
  return (
    <div class="mb-2">
      <label for="input" class="form-label">
        {showRequired
          ? <span className="text-danger">
              {label} (required)
            </span>
          : <React.Fragment>
              {showError
                ? <span className="text-danger">
                    {label} ({showError})
                  </span>
                : <span>
                    {label}
                  </span>}
            </React.Fragment>}
      </label>
      <div>
        {children}
      </div>
    </div>
  )
}
