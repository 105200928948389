export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.underline) {
    children = (
      <u>
        {children}
      </u>
    )
  }

  if (leaf.strikethrough) {
    children = (
      <s>
        {children}
      </s>
    )
  }

  if (leaf.bold) {
    children = (
      <b>
        {children}
      </b>
    )
  }

  if (leaf.italic) {
    children = (
      <i>
        {children}
      </i>
    )
  }

  if (leaf.indent) {
    children = (
      <div style={{ textIndent: `${leaf.indent * 5}%` }}>
        {children}
      </div>
    )
  }

  return (
    <span {...attributes}>
      {children}
    </span>
  )
}
