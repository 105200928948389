import React from 'react'
import { Card } from '../../../../../../shared/card/card'

export const NoChildren = ({ nodes }) => {
  return (
    <React.Fragment>
      {!nodes.length && <Card>No child nodes</Card>}
    </React.Fragment>
  )
}
