import React from 'react'
import { Route } from 'react-router-dom'
import { LayoutContactUs } from '../../view/contact-us/layout'
import { InviteLayout } from '../../view/invite/layout'
import { LegalLayout } from '../../view/legal/layout'
import { LayoutPasswordRecover } from '../../view/password-recover/layout'
import { LayoutRegister } from '../../view/register/layout-register'

export const RoutePublic = () => {
  return (
    <React.Fragment>
      <Route path="/public/legal" component={LegalLayout} />
      <Route path="/public/invite" component={InviteLayout} />
      <Route path="/public/register" component={LayoutRegister} />
      <Route path="/public/password-recover" component={LayoutPasswordRecover} />
      <Route path="/public/contact-us" component={LayoutContactUs} />
    </React.Fragment>
  )
}
