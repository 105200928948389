export const useBuildRoutePath = () => {
  return (path, args) => {
    let url = new URL(path, process.env.REACT_APP_SITE)

    if (args.email) {
      url.searchParams.append('email', args.email)
    }

    if (args.redirect) {
      url.searchParams.append('redirect', args.redirect)
    }

    if (args.redirectUrl) {
      url.searchParams.append('redirect-url', args.redirectUrl)
    }

    return `${url.pathname}${url.search}`
  }
}
