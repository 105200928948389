import React, { useMemo, useState } from 'react'
import { IconTimes } from '../icon/icon'

export const Filter = ({ filter, onChange }) => {
  const handleChange = e => onChange(e.target.value)
  const handlerClear = () => onChange('')

  return (
    <React.Fragment>
      <div className="input-group py-2">
        <input className="form-control" name="search" value={filter} onChange={handleChange} placeholder="filter" />
        <div className="input-group-append" onClick={handlerClear}>
          <div className="input-group-text">
            <IconTimes />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export const useFilter = items => {
  const [filter, updateFilter] = useState('')

  const filteredItems = useMemo(
    () => {
      if (!filter) return items
      const valueLower = filter.toLocaleLowerCase()
      return items.filter(item => Object.values(item).join().toLowerCase().includes(valueLower))
    },
    [items, filter]
  )

  return [filteredItems, filter, updateFilter]
}

export const useFilterProp = (items, prop) => {
  const [filter, updateFilter] = useState('')

  const filteredItems = useMemo(
    () => {
      if (!filter) return items
      const valueLower = filter.toLocaleLowerCase()
      return items.filter(item => item[prop].toLowerCase().includes(valueLower))
    },
    [items, prop, filter]
  )

  return [filteredItems, filter, updateFilter]
}
