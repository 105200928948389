import React from 'react'
import { Route } from 'react-router-dom'
import { LayoutAuth } from '../../layout/unauthorized/auth/layout-auth'

export const RouteAuth = () => {
  return (
    <React.Fragment>
      <Route path="/auth" component={LayoutAuth} />
    </React.Fragment>
  )
}
