import React, { useEffect } from 'react'
import { Paragraph } from './paragraph'
import { CardFullHeightScrollY } from '../../card/card-full-height-scroll-y'

export const Cookie = () => {
  useEffect(() => {
    const cookieDeclaration = document.getElementById('CookieDeclaration')

    if (process.env.REACT_APP_ENV === 'LOCAL') {
      cookieDeclaration.innerText = 'disabled in local env'
    } else {
      var script = document.createElement('script')
      script.src = 'https://consent.cookiebot.com/d3deb571-0b40-42d3-a873-285ae946bf7a/cd.js'
      script.async = true

      cookieDeclaration.appendChild(script)
    }
  }, [])

  return (
    <CardFullHeightScrollY title="cookie">
      <Paragraph header="COOKIE POLICY">
        <div id="CookieDeclaration" />
      </Paragraph>
    </CardFullHeightScrollY>
  )
}
