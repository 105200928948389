import React, { useState } from 'react'
import { ButtonSecondary } from '../button'
import { FileSelect } from './file-select'
import Slim from '../image-slim/slim.react'

export const FileImageSlim = ({ rowColWidth, ratio, textCancel, accept, maxSize, onContinue }) => {
  const [imageFile, updateImageFile] = useState(null)
  const [imageURL, updateImageURL] = useState(null)

  const handleSelect = async file => {
    const reader = new FileReader()
    reader.onload = () => updateImageURL(reader.result)
    reader.readAsDataURL(file)
    updateImageFile(file)
  }

  const handleClickCancel = () => {
    updateImageFile(null)
    updateImageURL(null)
  }

  const handleService = formdata => {
    onContinue && onContinue(formdata[0])
    updateImageFile(null)
    updateImageURL(null)
  }

  return (
    <React.Fragment>
      <div>
        {imageURL &&
          <React.Fragment>
            <div className="row">
              <div className={rowColWidth || 'col-12'}>
                <Slim ratio={ratio} initialImage={imageURL} service={handleService} serviceFormat="file" saveInitialImage />
                <div className="py-2">
                  <ButtonSecondary text={textCancel || 'cancel'} onClick={handleClickCancel} />
                </div>
              </div>
            </div>
          </React.Fragment>}
        {!imageFile && <FileSelect accept={accept} maxSize={maxSize} onSelect={handleSelect} />}
      </div>
    </React.Fragment>
  )
}
