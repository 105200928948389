import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormCheckbox, FormSubmitBack } from '../../../../../shared/form/form'
import { IconAdd } from '../../../../../shared/icon/icon'
import { Card } from '../../../../../shared/card/card'
import { useApiFormRoleOrgPermission, useApiListRoleOrg, useApiListRoleOrgScope } from '../../../../../api-new/state/content'
import { FormSelectObj } from '../../../../../shared/form/form-select-obj'

export const RoleOrgPermissionAdd = () => {
  const history = useHistory()

  const apiListRoleOrg = useApiListRoleOrg()
  const apiListRoleOrgScope = useApiListRoleOrgScope()
  const apiFormRoleOrgPermission = useApiFormRoleOrgPermission()

  const handlePath = () => {
    apiListRoleOrg.get()
    apiListRoleOrgScope.get()
  }

  const handleSubmit = async data => {
    let roleAccess = await apiFormRoleOrgPermission.post(data)
    history.push(`/root/role-org-permission/list/edit/${roleAccess.id}`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add" onPath={handlePath}>
        <Card title="add role permission">
          <Form onSubmit={handleSubmit}>
            <FormSelectObj label="Role" name="roleID" state={apiListRoleOrg} itemName="role" itemValue="id" valueAsNumber={true} required />

            <FormSelectObj label="Scope" name="identifier" state={apiListRoleOrgScope} itemName="display" itemValue="identifier" required />

            <Card>
              <FormCheckbox label="Create" name="accessCreate" />
              <FormCheckbox label="Read" name="accessRead" />
              <FormCheckbox label="Update" name="accessUpdate" />
              <FormCheckbox label="Delete" name="accessDelete" />
            </Card>

            <FormSubmitBack text="add role permission" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
