import React from 'react'
import { Route } from 'react-router'
import { PrivacyPage } from './privacy'
import { TermsPage } from './terms'
import { CookiePage } from './cookie'

export const LegalLayout = () => {
  return (
    <React.Fragment>
      <Route path="/public/legal/terms" component={TermsPage} />
      <Route path="/public/legal/privacy" component={PrivacyPage} />
      <Route path="/public/legal/cookie" component={CookiePage} />
    </React.Fragment>
  )
}
