import React, { useMemo, useState } from 'react'
import { ModalDialog } from '../../../../../../../shared/component/modal-dialog'
import { CardFullHeight } from '../../../../../../../shared/card/card-full-height'
import { SelectListFilter } from './select-list-filter'
import { SelectListItem } from './select-list-item'
import { ButtonPrimary } from '../../../../../../../shared/component/button'
import { IconPlus } from '../../../../../../../shared/icon/icon'

export const SelectList = ({
  header,
  selectedItems,
  readonlyItems,
  items,
  modalTarget,
  fnListFilter,
  fnListFormat,
  onAdd,
  onRemove,
  onClose
}) => {
  const [filter, updateFilter] = useState('')

  const filterItems = useMemo(
    () => {
      if (filter) {
        return fnListFilter(filter, items)
      }
      return items
    },
    [items, filter, fnListFilter]
  )

  return (
    <React.Fragment>
      <ModalDialog target={modalTarget} header={header} Icon={IconPlus} fullScreen>
        <CardFullHeight>
          <div className="d-flex flex-column h-100">
            <div>
              <SelectListFilter value={filter} onChange={updateFilter} />
            </div>
            <div className="flex-fill overflow-y-scroll">
              {filterItems.map((item, i) =>
                <SelectListItem
                  selectedItems={selectedItems || []}
                  readonlyItems={readonlyItems || []}
                  key={i}
                  item={item}
                  fnListFormat={fnListFormat}
                  onAdd={onAdd}
                  onRemove={onRemove}
                />
              )}
            </div>
            <div>
              <ButtonPrimary text="close" onClick={onClose} />
            </div>
          </div>
        </CardFullHeight>
      </ModalDialog>
    </React.Fragment>
  )
}
