import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormCheckbox, FormSubmitBack, FormText, FormTextArea } from '../../../../../shared/form/form'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Card } from '../../../../../shared/card/card'
import { IconEdit } from '../../../../../shared/icon/icon'
import { useApiFormRoleOrg } from '../../../../../api-new/state/content'

export const RoleOrgEdit = () => {
  const { roleOrgID } = useParamsInt()

  const apiFormRoleOrg = useApiFormRoleOrg()

  const handlePath = () => apiFormRoleOrg.get(roleOrgID)

  const handleSubmit = data => apiFormRoleOrg.put(data.id, data)

  const title = useMemo(() => `${apiFormRoleOrg.form.role}`, [apiFormRoleOrg.form])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={title} onPath={handlePath}>
        <Card title="edit role">
          <Form state={apiFormRoleOrg.form} onSubmit={handleSubmit}>
            <FormText label="role" name="role" autoFocus />
            <FormTextArea label="description" name="description" />
            <FormCheckbox label="Auto Owner" name="isAutoOwner" />
            <FormCheckbox label="Auto User" name="isAutoUser" />
            <FormCheckbox label="Read Only" name="isReadOnly" />
            <FormSubmitBack text="update role" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
