import { useMemo } from 'react'
import { getMetadata } from '../shared/metadata'

export const useMetadataType = (node, type, value) => {
  return useMemo(
    () => {
      let metadata = getMetadata(node)

      if (metadata.datatype) {
        let lowerType = type.toLowerCase()
        if (metadata[lowerType]) {
          let hold = metadata[lowerType].includes(value.toLowerCase())
          return hold
        }
      }

      return false
    },
    [node, type, value]
  )
}

export const useMetadataTypeIsCaffeine = form => {
  return useMetadataType(form, 'CourseAssemblyName', 'caffeine')
}
