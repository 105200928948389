import React, { useEffect, useMemo } from 'react'
import { useApiListAppVersion } from '../../../../../../api-new/state/config'

export const AppVersionSelect = ({ appStoreID, currentVersionID, onSelect }) => {
  const apiListAppVersion = useApiListAppVersion()

  useEffect(
    () => {
      appStoreID && apiListAppVersion.get(`${appStoreID}/store`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appStoreID]
  )

  const hasVersions = useMemo(() => apiListAppVersion.items.length !== 0, [apiListAppVersion.items])

  const handleChange = e => {
    let value = parseInt(e.target.value)
    onSelect && onSelect(value)
  }

  return (
    <React.Fragment>
      {hasVersions
        ? <select className="form-control" value={currentVersionID || 0} onChange={handleChange}>
            <option value={0}>latest version</option>
            {apiListAppVersion.items.map((item, i) =>
              <option key={i} value={item.id} disabled={!item.isActive}>
                {item.name}
              </option>
            )}
          </select>
        : <div>No Versions Found</div>}
    </React.Fragment>
  )
}
