import React, { useContext, useMemo, useState } from 'react'
import { useDataContext } from '../../data-context'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiFormNodeMove } from '../../../../../../api-new/state/content'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Form, FormHiddenNumber, FormSubmitBack } from '../../../../../../shared/form/form'
import { IconMove } from '../../../../../../shared/icon/icon'
import { useLoad } from '../../../../../../shared/hooks/load'
import { AuthContext } from '../../../../../../context/auth.context'
import { FormSelectCategoryTemp } from '../../../../../../shared/form/form-control/form-select-category-temp'
import { useNotificationContext } from '../../../../../../context/notification/context'
import { NodeCategoryMenuFull } from '../common/node-category-menu'

export const NodeCategoryMove = ({ parentURL, nodeID, useApiList, useApiForm, pathNodeFn }) => {
  const history = useHistory()

  const authContext = useContext(AuthContext)
  const dataContext = useDataContext()

  const notification = useNotificationContext()

  const apiForm = useApiForm()
  const apiFormNodeMove = useApiFormNodeMove()

  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  useLoad(() => authContext.state.jwtClaims.organizationID && updateCategoryOrganizationID(authContext.state.jwtClaims.organizationID), [
    authContext.state.jwtClaims.organizationID
  ])

  const title = useMemo(() => apiForm.form.name, [apiForm.form])

  const handleMatch = async () => await apiForm.get(nodeID)

  const handleSubmit = async data => {
    try {
      if (data.parentID === -1) {
        data.parentID = null
      }

      await apiFormNodeMove.put(data.nodeID, data)
      let node = await apiForm.get(nodeID)

      const moveToPath = ({ map }) => {
        dataContext.unsubscribe(moveToPath)
        let path = pathNodeFn(map, node.id)
        history.replace(path)
      }

      dataContext.subscribe(moveToPath)
      dataContext.nodeUpdate(node)
    } catch (exp) {
      notification.alert.error(exp.error)
    }
  }

  const HeaderMenu = () => NodeCategoryMenuFull(parentURL, apiForm.form)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconMove} text="move" onMatch={handleMatch}>
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="nodeID" value={nodeID} required shouldDirty />

            <FormSelectCategoryTemp
              lable="category"
              name="parentID"
              includeRoot
              organizationID={categoryOrganizationID}
              required
              useApiList={useApiList}
            />
            <FormSubmitBack text="move category" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
