import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconTemplate } from '../../../../../../shared/icon/icon'
import { useDataContext } from '../../data-context'
import { Route, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { NodeLibraryEdit } from './node-library-edit'
import { useApiFormNodeLibrary, useApiListAssetUnityPackage } from '../../../../../../api-new/state/content'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { NodeLibraryMove } from './node-library-move'
import { NodeLibraryDelete } from './node-library-delete'
import { LoginSysAdmin } from '../../../../../../role/components/login-sys-admin'
import { NodeLibraryEditSys } from './node-library-edit-sys'
import { LogEventOrgSubjectID } from '../../../../../../shared/page/log-event/log-event-org'
import { NodeLibraryCopy } from './node-library-copy'
import { NodeLibraryMenuFull } from '../common/node-library-menu'
import { AssetUnityPackage } from '../common/asset-unity-package'
import { PermissionNode } from '../../common/permission/permission-node'

export const NodeLibraryDetails = ({ parentURL }) => {
  const { url } = useRouteMatch()
  const { nodeID } = useParamsInt()

  const apiFormNodeLibrary = useApiFormNodeLibrary()

  const apiListAssetUnityPackage = useApiListAssetUnityPackage()

  const dataContext = useDataContext()

  const itemCurrent = useMemo(() => dataContext.nodes.find(i => i.id === nodeID) || {}, [dataContext.nodes, nodeID])

  const handleMatch = async () => {
    await apiFormNodeLibrary.get(nodeID)
    await apiListAssetUnityPackage.get(`${nodeID}/node`)
  }

  const handleUploadComplete = async (asset, file) => {
    asset.name = file.name
    asset.length = file.size
    await apiListAssetUnityPackage.put(asset.id, asset)
  }

  const handleAddUnityPackage = async () => {
    await apiListAssetUnityPackage.post({ nodeID: nodeID, name: 'file' })
    await apiListAssetUnityPackage.get(`${nodeID}/node`)
  }

  const HeaderMenu = () => NodeLibraryMenuFull(url, handleAddUnityPackage)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconTemplate} text={itemCurrent.name} onMatch={handleMatch}>
        <CardFullHeightScrollY title={itemCurrent.name} HeaderMenu={HeaderMenu}>
          {apiListAssetUnityPackage.items.map((asset, i) =>
            <AssetUnityPackage key={i} asset={asset} onUploadComplete={handleUploadComplete} />
          )}
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <PermissionNode url={url} nodeID={nodeID} />

      <Route path={`${url}/edit`}>
        <NodeLibraryEdit parentURL={url} nodeID={nodeID} />
      </Route>

      <LoginSysAdmin>
        <Route path={`${url}/edit-sys`}>
          <NodeLibraryEditSys parentURL={url} nodeID={nodeID} />
        </Route>
      </LoginSysAdmin>

      <Route path={`${url}/move`}>
        <NodeLibraryMove parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/copy`}>
        <NodeLibraryCopy parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/delete`}>
        <NodeLibraryDelete grandParentURL={parentURL} parentURL={url} nodeID={nodeID} />
      </Route>

      <Route path={`${url}/activity`}>
        <LogEventOrgSubjectID subject="node" subjectID={nodeID} />
      </Route>
    </React.Fragment>
  )
}
