import React from 'react'
import { Route } from 'react-router-dom'
import { Delete } from '../../../../shared/page/page-delete'
import { Purge } from '../../../../shared/page/page-purge'
import { AppStoreAdd } from './content/app-store/app-store-add'
import { AppStoreEdit } from './content/app-store/app-store-edit'
import { AppStoreList } from './content/app-store/app-store-list'
import { AppStoreRecycle } from './content/app-store/app-store-recycle'
import { AppVersionAdd } from './content/app-version/app-version-add'
import { AppVersionDownloads } from './content/app-version/app-version-downloads'
import { AppVersionEdit } from './content/app-version/app-version-edit'
import { AppVersionList } from './content/app-version/app-version-list'
import { AppVersionRecycle } from './content/app-version/app-version-recycle'
import { AppVersionUpload } from './content/app-version/app-version-upload'
import { AppAdd } from './content/app/app-add'
import { AppEdit } from './content/app/app-edit'
import { AppList } from './content/app/app-list'
import { AppRecycle } from './content/app/app-recycle'
import { useApiFormApp, useApiFormAppStore, useApiFormAppVersion } from '../../../../api-new/state/config'
import { LogEventSys } from '../../../../shared/page/log-event/log-event-sys'

export const LayoutApp = () => {
  return (
    <React.Fragment>
      {/* APP */}
      <Route path="/admin/app" component={AppList} />
      <Route path="/admin/app/add" component={AppAdd} />
      <Route path="/admin/app/edit/:appID" component={AppEdit} />
      <Route path="/admin/app/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormApp} titleParam="name" successPath="/admin/app" />
      </Route>
      <Route path="/admin/app/recycle" component={AppRecycle} />
      <Route path="/admin/app/recycle/purge/:purgeID">
        <Purge useApiForm={useApiFormApp} titleParam="name" successPath="/admin/app/recycle" />
      </Route>
      <Route path="/admin/app/list/activity-all">
        <LogEventSys subject="app" />
      </Route>
      <Route path="/admin/app/list/activity-id/:id">
        <LogEventSys subject="app" />
      </Route>

      {/* APP STORE */}
      <Route path="/admin/app/edit/:appID/store" component={AppStoreList} />
      <Route path="/admin/app/edit/:appID/store/add" component={AppStoreAdd} />
      <Route path="/admin/app/edit/:appID/store/edit/:appStoreID" component={AppStoreEdit} />
      <Route path="/admin/app/edit/:appID/store/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormAppStore} titleParam="name" successPath="/admin/app/edit/:appID/store" />
      </Route>
      <Route path="/admin/app/edit/:appID/store/recycle" component={AppStoreRecycle} />
      <Route path="/admin/app/edit/:appID/store/recycle/purge/:purgeID">
        <Purge useApiForm={useApiFormAppStore} titleParam="name" successPath="/admin/app/edit/:appID/store/recycle" />
      </Route>
      <Route path="/admin/app/edit/:appID/store/activity-all">
        <LogEventSys subject="app_store" />
      </Route>
      <Route path="/admin/app/edit/:appID/store/activity-id/:id">
        <LogEventSys subject="app_store" />
      </Route>

      {/* APP VERSION */}
      <Route path="/admin/app/edit/:appID/store/edit/:appStoreID/version" component={AppVersionList} />
      <Route path="/admin/app/edit/:appID/store/edit/:appStoreID/version/downloads" component={AppVersionDownloads} />
      <Route path="/admin/app/edit/:appID/store/edit/:appStoreID/version/add" component={AppVersionAdd} />
      <Route path="/admin/app/edit/:appID/store/edit/:appStoreID/version/edit/:appVersionID" component={AppVersionEdit} />
      <Route path="/admin/app/edit/:appID/store/edit/:appStoreID/version/edit/:appVersionID/upload" component={AppVersionUpload} />
      <Route path="/admin/app/edit/:appID/store/edit/:appStoreID/version/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormAppVersion} titleParam="name" successPath="/admin/app/edit/:appID/store/edit/:appStoreID/version" />
      </Route>
      <Route path="/admin/app/edit/:appID/store/edit/:appStoreID/version/recycle" component={AppVersionRecycle} />
      <Route path="/admin/app/edit/:appID/store/edit/:appStoreID/version/recycle/purge/:purgeID">
        <Purge
          useApiForm={useApiFormAppVersion}
          titleParam="name"
          successPath="/admin/app/edit/:appID/store/edit/:appStoreID/version/recycle"
        />
      </Route>
      <Route path="/admin/app/edit/:appID/store/edit/:appstoreID/version/activity-all">
        <LogEventSys subject="app_version" />
      </Route>
      <Route path="/admin/app/edit/:appID/store/edit/:appstoreID/version/activity-id/:id">
        <LogEventSys subject="app_version" />
      </Route>
    </React.Fragment>
  )
}
