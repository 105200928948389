import React from 'react'
import { Route } from 'react-router-dom'
import { LoginList } from './page/login-list'
import { LoginEdit } from './page/login-edit'
import { Delete } from '../../../../shared/page/page-delete'
import { Purge } from '../../../../shared/page/page-purge'
import { LoginRecycle } from './page/login-recycle'
import { LoginAdd } from './page/login-add'
import { OrganizationUserEdit } from './page/organization/organization-user-edit'
import { OrganizationUserAdd } from './page/organization/organization-user-add'
import { useApiFormLogin, useApiFormLoginUser } from '../../../../api-new/state/content'
import { OrganizationUserList } from './page/organization/organization-user-list'

export const LayoutLogin = () => {
  return (
    <React.Fragment>
      <Route path="/admin/login/list" component={LoginList} />
      <Route path="/admin/login/list/add" component={LoginAdd} />
      <Route path="/admin/login/list/edit/:loginID" component={LoginEdit} />
      <Route path="/admin/login/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormLogin} titleParam="email" successPath="/admin/login/list" />
      </Route>
      <Route path="/admin/login/list/recycle" component={LoginRecycle} />
      <Route path="/admin/login/list/recycle/purge/:purgeID">
        <Purge useApiForm={useApiFormLogin} titleParam="email" successPath="/admin/login/list/recycle" />
      </Route>

      <Route path="/admin/login/list/edit/:loginID/organizations" component={OrganizationUserList} />
      <Route path="/admin/login/list/edit/:loginID/organizations/edit/:userID" component={OrganizationUserEdit} />
      <Route path="/admin/login/list/edit/:loginID/organizations/add" component={OrganizationUserAdd} />

      <Route path="/admin/login/list/edit/:loginID/organizations/edit/:userID/purge/:purgeID">
        <Purge useApiForm={useApiFormLoginUser} titleParam="organizationName" successPath="/admin/login/list/edit/:loginID/organizations" />
      </Route>
    </React.Fragment>
  )
}
