import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDataContext } from '../../data-context'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../../../../shared/form/form'
import { IconAdd } from '../../../../../../shared/icon/icon'
import { useApiFormNodeCourse } from '../../../../../../api-new/state/content'
import { NodeContentMenuFull } from '../common/node-content-menu'
import { pathNodeContentMap } from '../../common/shared/path-node-content'

export const NodeCourseAdd = ({ parentURL, parentID }) => {
  const history = useHistory()

  const apiFormNodeCourse = useApiFormNodeCourse()

  const dataContext = useDataContext()

  const handleSubmit = async data => {
    let node = await apiFormNodeCourse.post(data)

    const moveToPath = ({ map }) => {
      dataContext.unsubscribe(moveToPath)
      let path = pathNodeContentMap(map, node.id)
      history.push(path)
    }

    dataContext.subscribe(moveToPath)
    dataContext.nodeInsert(node)
  }

  const HeaderMenu = () => NodeContentMenuFull(parentURL, apiFormNodeCourse.form)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add">
        <CardFullHeightScrollY title="course add" HeaderMenu={HeaderMenu}>
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="parentID" value={parentID} />
            <FormText label="name" name="name" autoFocus />
            <FormSubmitBack text="create course" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
