import React, { useEffect, useMemo } from 'react'
import { useGetUniqueRowValues } from './hooks'
import { useAddUserContext } from '../context'
import { useImportContext } from './context'
import { Card } from '../../../../../../../shared/card/card'

export const StepContent = () => {
  const addUserContext = useAddUserContext()
  const importContext = useImportContext()

  const uniqueContentNames = useGetUniqueRowValues(importContext.rows, importContext.indexes.content)

  const handleUpdate = (index, contentName, content) => {
    let newValues = { ...importContext.contentValues }

    if (content) newValues[index] = { contentName, content }
    else delete newValues[index]

    importContext.updateContentValues(newValues)
  }

  useEffect(
    () => {
      if (Object.keys(importContext.contentValues).length) {
        return
      }

      let values = {}

      uniqueContentNames.forEach((contentName, index) => {
        let contentOptions = addUserContext.allContents.filter(content => content.name.toLowerCase().includes(contentName))

        if (contentOptions.length === 1) {
          let content = contentOptions[0]
          values[index] = { contentName, content }
        }
      })

      importContext.updateContentValues(values)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addUserContext.allContents, uniqueContentNames]
  )

  return (
    <React.Fragment>
      <Card title="map contents">
        {uniqueContentNames.map((contentName, i) =>
          <div key={i} className="m-2">
            <SelectContent index={i} label={contentName} contentName={contentName} onUpdate={handleUpdate} />
          </div>
        )}
      </Card>
    </React.Fragment>
  )
}

const SelectContent = ({ index, label, contentName, onUpdate }) => {
  const addUserContext = useAddUserContext()
  const importContext = useImportContext()

  const displayContents = useMemo(() => addUserContext.allContents.filter(content => content.name.toLowerCase().includes(contentName)), [
    addUserContext.allContents,
    contentName
  ])

  const value = useMemo(
    () => {
      let item = importContext.contentValues[index]
      if (item) {
        return item.content.id
      }
      return -1
    },
    [index, importContext.contentValues]
  )

  useEffect(
    () => {
      let warning = value === -1 && displayContents.length !== 0
      importContext.contentWarnings[index] = warning
      importContext.updateContentWarnings({ ...importContext.contentWarnings })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  const showWarning = useMemo(() => !!importContext.contentWarnings[index], [importContext.contentWarnings, index])

  const handleChange = e => {
    let id = parseInt(e.target.value)
    let content = addUserContext.allContents.find(i => i.id === id)
    onUpdate && onUpdate(index, contentName, content)
  }

  return (
    <div>
      <label className="form-label">
        {label}
      </label>
      <select className={`form-control ${showWarning && 'border-danger'}`} value={value} onChange={handleChange}>
        <option value={-1}>select</option>
        {displayContents.map((content, i) =>
          <option key={i} value={content.id}>
            {content.name}
          </option>
        )}
      </select>
    </div>
  )
}
