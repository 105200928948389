import { useMemo, useState } from 'react'
import { ChartBar } from '../../../../../../shared/chart/chart'
import { ChartHeader, SelectRangeInterval, Widget } from '../../../../../../shared/chart/widget'
import { Link } from 'react-router-dom'
import { useApiFormLogError, useApiListLogError } from '../../../../../../api-new/state/content'
import { chartColorMain } from '../../../../../../shared/chart/chart-color'
import { useLoad } from '../../../../../../shared/hooks/load'
import moment from 'moment'
import { IconError } from '../../../../../../shared/icon/icon'

export const LogErrorBar = () => {
  const apiFormLogError = useApiFormLogError()
  const apiListLogError = useApiListLogError()

  const [start, updateStart] = useState(null)
  const [end, updateEnd] = useState(null)
  const [interval, updateInterval] = useState('month1')

  const chartData = useMemo(
    () => {
      const labels = apiListLogError.items.map(item => item.dateStart)
      const data = apiListLogError.items.map(item => item.count)

      const dataSet = {
        data: data,
        borderColor: chartColorMain,
        backgroundColor: chartColorMain,
        borderWidth: 1
      }

      return { labels: labels, dataSet: dataSet }
    },
    [apiListLogError.items]
  )

  useLoad(async () => {
    const result = await apiFormLogError.get('chart/range/details')
    let start = moment(result.min).format('YYYY-MM-DD')
    let end = moment().format('YYYY-MM-DD')
    updateStart(start)
    updateEnd(end)
  }, [])

  useLoad(
    () => {
      if (start && end && interval) {
        apiListLogError.get(`chart/range?start=${start}&end=${end}&interval=${interval}`)
      }
    },
    [start, end, interval]
  )

  const LinkComponent = () => <Link to="/admin/dashboard/log-error/list">view all</Link>

  return (
    <Widget
      header={<ChartHeader Icon={IconError} text="ERRORS" Component={LinkComponent} />}
      body={<ChartBar labels={chartData.labels} data={chartData.dataSet} displayX displayY />}
      footer={
        <SelectRangeInterval
          start={start}
          end={end}
          intervals={apiFormLogError.form.intervals}
          interval={interval}
          minDate={apiFormLogError.form.min}
          maxDate={apiFormLogError.form.max}
          onChangeStart={updateStart}
          onChangeEnd={updateEnd}
          onChangeInterval={updateInterval}
        />
      }
    />
  )
}
