import React from 'react'

export const Paragraph = ({ header, children }) => {
  return (
    <React.Fragment>
      <h5>
        {header}
      </h5>
      <div>
        {children}
      </div>
    </React.Fragment>
  )
}
