import React, { useEffect, useMemo } from 'react'
import { useCourseDeviceList } from '../state'
import { ChartPie } from '../../../../../../../shared/chart/chart'

const chartRed500 = '#f44336'
const chartIndigo500 = '#3f51b5'
const chartLightBlue500 = '#03a9f4'
const chartGreen500 = '#4caf50'
const chartYellow500 = '#ffeb3b'
const chartBrown500 = '#795548'
const chartGray500 = '#9e9e9e'
const chartBlueGray500 = '#607d8b'

export const chartPieBackgroud = [
  chartRed500,
  chartIndigo500,
  chartBrown500,
  chartLightBlue500,
  chartGreen500,
  chartYellow500,
  chartGray500,
  chartBlueGray500
]

export function GraphDevice({ nodeID }) {
  const [courseDeviceListActions, courseDeviceListState] = useCourseDeviceList()

  useEffect(
    () => {
      courseDeviceListActions.get(`${nodeID}`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeID]
  )

  const labels = useMemo(
    () => {
      return courseDeviceListState.items.map(item => item.deviceType)
    },
    [courseDeviceListState.items]
  )
  const deviceData = useMemo(
    () => {
      return courseDeviceListState.items.map(item => item.count)
    },
    [courseDeviceListState.items]
  )

  const data = {
    label: 'Device Type',
    data: deviceData,
    backgroundColor: chartPieBackgroud
  }

  return (
    <div>
      <ChartPie labels={labels} data={data} displayLegend legendPosition="top" />
    </div>
  )
}
