import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import {
  useApiListGroup,
  useApiListNodeDecendantContent,
  useApiListNodeDecendantLibrary,
  useApiListNodeDecendantTemplate,
  useApiListRoleOrg
} from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'

const Context = React.createContext()

export const useAddUserContext = () => useContext(Context)

export const AddUserContext = ({ children }) => {
  const apiListRoleOrg = useApiListRoleOrg()
  const apiListGroup = useApiListGroup()
  const apiListNodeDecendantContent = useApiListNodeDecendantContent()
  const apiListNodeDecendantTemplate = useApiListNodeDecendantTemplate()
  const apiListNodeDecendantLibrary = useApiListNodeDecendantLibrary()

  useLoad(() => apiListRoleOrg.get(), [])
  useLoad(() => apiListGroup.get(), [])
  useLoad(() => apiListNodeDecendantContent.get(), [])
  useLoad(() => apiListNodeDecendantTemplate.get(), [])
  useLoad(() => apiListNodeDecendantLibrary.get(), [])

  const [commonRoles, updateCommonRoles] = useState([])
  const [commonGroups, updateCommonGroups] = useState([])
  const [commonContents, updateCommonContents] = useState([])
  const [commonTemplates, updateCommonTemplates] = useState([])
  const [commonLibraries, updateCommonLibraries] = useState([])

  useEffect(
    () => {
      let learner = apiListRoleOrg.items.find(i => i.role === 'learner')
      if (learner) {
        updateCommonRoles([learner])
      }
    },
    [apiListRoleOrg.items]
  )

  const allRoles = useMemo(() => apiListRoleOrg.items, [apiListRoleOrg.items])
  const allGroups = useMemo(() => apiListGroup.items, [apiListGroup.items])
  const allContents = useMemo(() => apiListNodeDecendantContent.items, [apiListNodeDecendantContent.items])
  const allTemplates = useMemo(() => apiListNodeDecendantTemplate.items, [apiListNodeDecendantTemplate.items])
  const allLibraries = useMemo(() => apiListNodeDecendantLibrary.items, [apiListNodeDecendantLibrary.items])

  const [userErrors, setUserError, clearUserError] = useUserErrorData()

  return (
    <Context.Provider
      value={{
        allRoles,
        allGroups,
        allContents,
        allTemplates,
        allLibraries,
        userErrors,
        setUserError,
        clearUserError,
        commonRoles,
        updateCommonRoles,
        commonGroups,
        updateCommonGroups,
        commonContents,
        updateCommonContents,
        commonTemplates,
        updateCommonTemplates,
        commonLibraries,
        updateCommonLibraries
      }}>
      {children}
    </Context.Provider>
  )
}

const ERROR_TYPES = {
  SET_ERROR: 1,
  CLEAR_ERROR: 2
}

const useUserErrorData = () => {
  const [userErrors, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case ERROR_TYPES.SET_ERROR:
        let setError = { ...state }
        let userError = setError[action.index]
        if (!userError) {
          userError = {}
        }
        userError[action.name] = action.value
        setError[action.index] = userError
        return setError
      case ERROR_TYPES.CLEAR_ERROR:
        let clearError = { ...state }
        delete clearError[action.index]
        return clearError
      default:
        return state
    }
  }, {})

  const setUserError = (index, name, value) => dispatch({ type: ERROR_TYPES.SET_ERROR, index: index, name: name, value: value })
  const clearUserError = index => dispatch({ type: ERROR_TYPES.CLEAR_ERROR, index: index })

  return [userErrors, setUserError, clearUserError]
}
