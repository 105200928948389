import React from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconDetails } from '../../../../../shared/icon/icon'
import {
  DetailsDisplayDateUnix,
  DetailsDisplayTable,
  DetailsDisplayText
} from '../../../../../shared/legacy/details-display/details-display'
import { useApiFormSendgridEvent } from '../../../../../api-new/state/content'
import { useLoad } from '../../../../../shared/hooks/load'
import { Card } from '../../../../../shared/card/card'

export const SendgridEventDetails = () => {
  const { sendgridID } = useParams()

  const apiFormSendgridEvent = useApiFormSendgridEvent()

  useLoad(() => apiFormSendgridEvent.get(sendgridID), [sendgridID])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDetails} text="sendgrid events">
        <Card title="sendgrid event details">
          <DetailsDisplayTable>
            <DetailsDisplayText label="template" value={apiFormSendgridEvent.form.templateName} />
            <DetailsDisplayText label="email" value={apiFormSendgridEvent.form.email} />
            <DetailsDisplayDateUnix label="sent at" value={apiFormSendgridEvent.form.timestamp} />
            <DetailsDisplayText label="event" value={apiFormSendgridEvent.form.event} />
            <DetailsDisplayText label="ip" value={apiFormSendgridEvent.form.ip} />
            <DetailsDisplayText label="reason" value={apiFormSendgridEvent.form.reason} />
            <DetailsDisplayText label="response" value={apiFormSendgridEvent.form.response} />
            <DetailsDisplayText label="status" value={apiFormSendgridEvent.form.status} />
            <DetailsDisplayText label="attempt" value={apiFormSendgridEvent.form.attempt} />
            <DetailsDisplayText label="type" value={apiFormSendgridEvent.form.type} />
            <DetailsDisplayText label="bounce classification" value={apiFormSendgridEvent.form.bounceClassification} />
          </DetailsDisplayTable>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
