import React from 'react'
import { IconCancel, IconConfirm, IconDelete, IconEdit, IconPurge, IconRestore } from './icon'
import { ButtonPrimary, ButtonSecondary } from '../component/button'

export const IconButtonEdit = ({ text, small, disabled, hide, onClick }) => {
  return (
    <ButtonPrimary
      text={text !== undefined ? text : 'edit'}
      Icon={IconEdit}
      small={small}
      disabled={disabled}
      hide={hide}
      onClick={onClick}
    />
  )
}

export const IconButtonDelete = ({ text, small, disabled, hide, onClick }) => {
  return (
    <ButtonSecondary
      text={text !== undefined ? text : 'delete'}
      Icon={IconDelete}
      small={small}
      disabled={disabled}
      hide={hide}
      onClick={onClick}
    />
  )
}

export const IconButtonCancel = ({ text, small, disabled, hide, onClick }) => {
  return (
    <ButtonSecondary
      text={text !== undefined ? text : 'cancel'}
      Icon={IconCancel}
      small={small}
      disabled={disabled}
      hide={hide}
      onClick={onClick}
    />
  )
}

export const IconButtonConfirm = ({ text, small, disabled, hide, onClick }) => {
  return (
    <ButtonPrimary
      text={text !== undefined ? text : 'confirm'}
      Icon={IconConfirm}
      small={small}
      disabled={disabled}
      hide={hide}
      onClick={onClick}
    />
  )
}

export const IconButtonRestore = ({ text, small, disabled, hide, onClick }) => {
  return (
    <ButtonSecondary
      text={text !== undefined ? text : 'restore'}
      Icon={IconRestore}
      small={small}
      disabled={disabled}
      hide={hide}
      onClick={onClick}
    />
  )
}

export const IconButtonPurge = ({ text, small, disabled, hide, onClick }) => {
  return (
    <ButtonPrimary
      text={text !== undefined ? text : 'purge'}
      Icon={IconPurge}
      small={small}
      disabled={disabled}
      hide={hide}
      onClick={onClick}
    />
  )
}
