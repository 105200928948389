import { createContext, useContext, useMemo, useState } from 'react'

const TableContext = createContext()

export const useTableContext = () => {
  return useContext(TableContext)
}

export const TableProvider = ({ children, apiList }) => {
  const [displayedRowCount, updateDisplayedRowCount] = useState(0)
  const [currentPage, updateCurrentPage] = useState(0)
  const [pageSize, updatePageSize] = useState(0)
  const [totalPages, updateTotalPages] = useState(0)

  const isLoaded = useMemo(() => apiList.loaded, [apiList])
  const isChunking = useMemo(() => apiList.chunking, [apiList])
  const rowCount = useMemo(() => apiList.count, [apiList])
  const chunkProgress = useMemo(() => apiList.chunkProgress, [apiList])

  return (
    <TableContext.Provider
      value={{
        isLoaded: isLoaded,
        isChunking: isChunking,
        chunkCount: rowCount,
        chunkProgress: chunkProgress,
        displayedRowCount: displayedRowCount,
        updateDisplayedRowCount: updateDisplayedRowCount,
        currentPage: currentPage,
        updateCurrentPage: updateCurrentPage,
        pageSize: pageSize,
        updatePageSize: updatePageSize,
        totalPages: totalPages,
        updateTotalPages: updateTotalPages
      }}>
      {children}
    </TableContext.Provider>
  )
}
