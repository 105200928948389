import React, { useEffect, useMemo, useState } from 'react'
import { IconLinkEdit } from '../../../../../../shared/icon/icon-link'
import { useRouteMatch } from 'react-router'
import { Card } from '../../../../../../shared/card/card'
import { useApiListNodeUser } from '../../../../../../api-new/state/content'

export default function Component({ userID }) {
  const { url } = useRouteMatch()

  const apiListNodeUser = useApiListNodeUser()

  const [nodes, updateNodes] = useState([])

  const nodeCount = useMemo(() => apiListNodeUser.items.length || 'none', [apiListNodeUser.items])

  useEffect(
    () => {
      apiListNodeUser.get(`${userID}/user`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userID]
  )

  useEffect(
    () => {
      let length = apiListNodeUser.items.length
      updateNodes(apiListNodeUser.items.map((e, i) => `${e.nodeName}${i === length - 1 ? '' : ', '}`))
    },
    [apiListNodeUser.items]
  )

  const HeaderComponent = () => <IconLinkEdit to={`${url}/content`} />

  return (
    <React.Fragment>
      <Card title={`Category/Course - ${nodeCount}`} HeaderMenu={HeaderComponent}>
        {nodes}
      </Card>
    </React.Fragment>
  )
}
