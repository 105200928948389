import React, { useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import { BreadcrumbView } from '../../../../context/breadcrumb.context'
import { Export } from '../../../../shared/component/export'
import { IconButtonClickExportDetails } from '../../../../shared/icon/icon-button-click'
import { DetailValue, DetailValueBool, DetailValueDateTime } from '../../../../shared/component/assessment/detail-value'
import { useAssessmentReportList } from '../../../../api/state'
import { Card } from '../../../../shared/card/card'
import { useApiFormResultAssessment, useApiListResultAssessment } from '../../../../api-new/state/assessment'
import { CardFullHeightScrollY } from '../../../../shared/card/card-full-height-scroll-y'
import { IconCheck, IconClose, IconFile } from '../../../../shared/icon/icon'

export const AssessmentResultDetails = () => {
  const { assessmentID, userAssessmentID } = useParams()

  const apiFormTestResultAssessment = useApiFormResultAssessment()
  const apiListTestResultAssessment = useApiListResultAssessment()

  const handleMatch = async () => {
    apiFormTestResultAssessment.get(`${userAssessmentID}/details`)
    apiListTestResultAssessment.get(`${userAssessmentID}/questions`)
  }

  const refExportDetails = useRef()

  const [assessmentReportListActions] = useAssessmentReportList()

  const handleExportDetails = async () => {
    let results = await assessmentReportListActions.get(`${assessmentID}/details/${userAssessmentID}/instance`)
    refExportDetails.current.export(results)
  }

  const HeaderComponent = () => <IconButtonClickExportDetails primary onClick={handleExportDetails} />

  return (
    <React.Fragment>
      <Export name={apiFormTestResultAssessment.form.title} type="instance_details" fileType="text/csv" ref={refExportDetails} />
      <BreadcrumbView Icon={IconFile} text="Details" onMatch={handleMatch}>
        <CardFullHeightScrollY title="Details" HeaderComponent={HeaderComponent}>
          <DetailValue label="title" value={apiFormTestResultAssessment.form.title} />
          <DetailValueDateTime label="taken" value={apiFormTestResultAssessment.form.start} />
          <DetailValue label="duration (Min:Sec)" value={apiFormTestResultAssessment.form.duration} />
          <DetailValue label="email" value={apiFormTestResultAssessment.form.email} />
          <DetailValue label="first name" value={apiFormTestResultAssessment.form.firstName} />
          <DetailValue label="last name" value={apiFormTestResultAssessment.form.lastName} />
          <DetailValue label="passing score" value={apiFormTestResultAssessment.form.passingScore} />
          <DetailValue label="score" value={apiFormTestResultAssessment.form.score} />
          <DetailValueBool label="passed" value={apiFormTestResultAssessment.form.passed} />

          <Card title="questions">
            <DetailQuestions apiList={apiListTestResultAssessment} />
          </Card>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}

const DetailQuestions = ({ apiList }) => {
  let questions = useMemo(
    () => {
      let unique = new Map()

      apiList.items.forEach(item => {
        if (!unique.has(item.questionID)) {
          unique.set(item.questionID, item)
        }
      })

      let uniqueArray = Array.from(unique)

      uniqueArray = uniqueArray.map(item => {
        let questionID = item[0]
        let question = item[1].question
        let choiceSelected = item[1].choiceSelected
        let isCorrectAnswer = item[1].isCorrectAnswer

        return { questionID, question, choiceSelected, isCorrectAnswer }
      })

      return uniqueArray
    },
    [apiList.items]
  )

  return (
    <React.Fragment>
      <ol>
        {questions.map((question, i) =>
          <li className="border-bottom my-3">
            <DetailQuestion key={i} question={question}>
              <DetailQuestionOptions question={question} items={apiList.items} />
            </DetailQuestion>
          </li>
        )}
      </ol>
    </React.Fragment>
  )
}

const DetailQuestion = ({ children, question }) => {
  return (
    <div className="">
      <div>
        <span>
          {question.question}
        </span>
      </div>
      <div className="my-2">
        {children}
      </div>
    </div>
  )
}

const DetailQuestionOptions = ({ question, items }) => {
  const options = useMemo(() => items.filter(i => i.questionID === question.questionID), [question, items])

  return (
    <ol>
      {options.map((option, i) =>
        <li key={i}>
          <DetailQuestionOption option={option} />
        </li>
      )}
    </ol>
  )
}

const DetailQuestionOption = ({ option }) => {
  return (
    <div>
      <span className="mx-1">
        {option.isCorrectChoice ? <IconCheck className="text-success" /> : <IconClose />}
      </span>
      <span className="mx-1">
        {option.isSelected &&
          <React.Fragment>
            {option.isCorrectChoice && <DetailQuestionOptionCorrect option={option} />}
            {!option.isCorrectChoice && <DetailQuestionOptionIncorrect option={option} />}
          </React.Fragment>}

        {!option.isSelected && option.choice}
      </span>
    </div>
  )
}

const DetailQuestionOptionCorrect = ({ option }) => {
  return (
    <span className="text-success border-bottom">
      {option.choice} - correct
    </span>
  )
}

const DetailQuestionOptionIncorrect = ({ option }) => {
  return (
    <span className="text-danger border-bottom">
      {option.choice} - incorrect
    </span>
  )
}
