import React from 'react'
import { Route, useRouteMatch } from 'react-router'
import { SessionUserList } from './session-user-list'
import { SeriesList } from './series-list'
import { Display } from './display/display'

export function HeatmapLayoutUser() {
  const { url } = useRouteMatch()

  return (
    <React.Fragment>
      <Route path={`${url}/heatmap/session/:userID`} component={SessionUserList} />
      <Route path={`${url}/heatmap/session/:userID/series/:sessionID`} component={SeriesList} />
      <Route path={`${url}/heatmap/session/:userID/series/:sessionID/display/:seriesID`} component={Display} />
    </React.Fragment>
  )
}
