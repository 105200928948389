import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormSubmitBack, FormText, FormTextArea } from '../../../../../shared/form/form'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Card } from '../../../../../shared/card/card'
import { IconEdit } from '../../../../../shared/icon/icon'
import { useApiFormRoleSys } from '../../../../../api-new/state/content'

export const RoleSysEdit = () => {
  const { roleID } = useParamsInt()

  const apiFormRoleSys = useApiFormRoleSys()

  const handlePath = async () => {
    await apiFormRoleSys.get(roleID)
  }

  const handleSubmit = async data => await apiFormRoleSys.put(data.id, data)

  const title = useMemo(() => `${apiFormRoleSys.form.name}`, [apiFormRoleSys.form])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={title} onPath={handlePath}>
        <Card title="edit role">
          <Form state={apiFormRoleSys.form} onSubmit={handleSubmit}>
            <FormText label="name" name="name" autoFocus />
            <FormTextArea label="description" name="description" />
            <FormSubmitBack text="update role" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
