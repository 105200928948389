import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormRequired, useFormShouldDirty } from './hooks/input-hooks'

export const FormPassword = ({
  id,
  style,
  label,
  placeholder,
  subtext,
  name,
  required,
  readOnly,
  defaultValue,
  shouldDirty,
  autoFocus,
  hidden
}) => {
  const { register, watch } = useFormContext()

  let formDisabled = useFormDisable()

  let watchValue = watch(name)

  useFormRequired(name, required)
  useFormShouldDirty(name, defaultValue, shouldDirty)

  const [show, updateShow] = useState(false)

  const handleShowToggle = () => updateShow(!show)

  return (
    <React.Fragment>
      {!hidden &&
        <FormatLabel style={style} name={name} label={label} subtext={subtext} hasValue={!!watchValue} required={required}>
          <div className="input-group">
            <input
              id={id}
              className="form-control"
              placeholder={placeholder || label}
              autoFocus={autoFocus}
              type={show ? 'text' : 'password'}
              {...register(name, { required })}
              disabled={formDisabled}
              readOnly={readOnly}
            />
            <div className="input-group-text edxr-pointer" onClick={handleShowToggle}>
              {show ? 'Hide' : 'Show'}
            </div>
          </div>
        </FormatLabel>}
    </React.Fragment>
  )
}
