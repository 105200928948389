import { types } from './action'

export const listInitialState = {
  items: [],
  submitting: false,
  error: undefined
}

export const listReducer = (state = listInitialState, action) => {
  switch (action.type) {
    case types.INIT:
      return { ...listInitialState }
    case types.ERROR:
      return { ...state, submitting: false, error: action.payload }
    case types.SUBMIT:
      return { ...state, submitting: true, error: undefined }
    case types.GET_SUCCESS:
      return { ...state, items: action.payload, submitting: false, error: undefined }
    case types.ADD_SUCCESS:
      return { ...state, items: [...state.items, action.payload], submitting: false, error: undefined }
    case types.DELETE_SUCCESS:
      return { ...state, submitting: false, error: undefined }
    default:
      return state
  }
}
