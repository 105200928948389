import React, { useEffect, useMemo } from 'react'
import { useGetUniqueRowValues } from './hooks'
import { useAddUserContext } from '../context'
import { useImportContext } from './context'
import { Card } from '../../../../../../../shared/card/card'

export const StepGroup = () => {
  const addUserContext = useAddUserContext()
  const importContext = useImportContext()

  const uniqueGroupNames = useGetUniqueRowValues(importContext.rows, importContext.indexes.group)

  const handleUpdate = (index, groupName, group) => {
    let newValues = { ...importContext.groupValues }

    if (group) newValues[index] = { groupName, group }
    else delete newValues[index]

    importContext.updateGroupValues(newValues)
  }

  useEffect(
    () => {
      if (Object.keys(importContext.groupValues).length) {
        return
      }

      let values = {}

      uniqueGroupNames.forEach((groupName, index) => {
        let groupOptions = addUserContext.allGroups.filter(group => group.name.toLowerCase().includes(groupName))

        if (groupOptions.length === 1) {
          let group = groupOptions[0]
          values[index] = { groupName, group }
        }
      })

      importContext.updateGroupValues(values)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addUserContext.allGroups, uniqueGroupNames]
  )
  return (
    <React.Fragment>
      <Card title="map groups">
        {uniqueGroupNames.map((groupName, i) =>
          <div key={i} className="m-2">
            <SelectGroup index={i} label={groupName} groupName={groupName} onUpdate={handleUpdate} />
          </div>
        )}
      </Card>
    </React.Fragment>
  )
}

const SelectGroup = ({ index, label, groupName, onUpdate }) => {
  const addUserContext = useAddUserContext()
  const importContext = useImportContext()

  const displayGroups = useMemo(() => addUserContext.allGroups.filter(group => group.name.toLowerCase().includes(groupName)), [
    addUserContext.allGroups,
    groupName
  ])

  const value = useMemo(
    () => {
      let item = importContext.groupValues[index]
      if (item) {
        return item.group.id
      }
      return -1
    },
    [index, importContext.groupValues]
  )

  useEffect(
    () => {
      let warning = value === -1 && displayGroups.length !== 0
      importContext.groupWarnings[index] = warning
      importContext.updateGroupWarnings({ ...importContext.groupWarnings })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  const showWarning = useMemo(() => !!importContext.groupWarnings[index], [importContext.groupWarnings, index])

  const handleChange = e => {
    let id = parseInt(e.target.value)
    let group = addUserContext.allGroups.find(i => i.id === id)
    onUpdate && onUpdate(index, groupName, group)
  }

  return (
    <div>
      <label className="form-label">
        {label}
      </label>
      <select className={`form-control ${showWarning && 'border-danger'}`} value={value} onChange={handleChange}>
        <option value={-1}>select</option>
        {displayGroups.map((groups, i) =>
          <option key={i} value={groups.id}>
            {groups.name}
          </option>
        )}
      </select>
    </div>
  )
}
