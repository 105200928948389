import { useApiFormState } from '../api/api-form/action'

export const useTradeShowRegisterForm = () => {
  return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/trade-show-register`)
}

// export const useTradeShowResultForm = () => {
//   return useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/trade-show-register`)
// }

export const useTradeShowResultForm = () => {
  return useApiFormState(process.env.REACT_APP_API_AUTH, `api/v1/register-tradeshow`)
}
