import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormatLabel } from './format-label'

const progressStyle = { width: '100%', height: '15px' }
const progressBarStyle = { width: '100%' }

export const FormPasswordValidate = ({ label, name, placeholder, required, readOnly, autoFocus }) => {
  const { register, watch, setError, clearErrors } = useFormContext()

  const watchValue = watch(name)

  const [showPassword, setShowPassword] = useState(false)
  const [level, updateLevel] = useState({ text: 'strength', color: 'bg-danger' })

  let valueErrors = useMemo(
    () => {
      if (!watchValue) {
        return 'required value'
      } else if (/^.*\s.*$/.test(watchValue)) {
        return 'cannot contain whitespace'
      } else if (!/(.){8,}$/.test(watchValue)) {
        return 'required minimum of 8 characters'
      } else {
        return ''
      }
    },
    [watchValue]
  )

  useEffect(
    () => {
      if (valueErrors) setError(name, valueErrors)
      else clearErrors(name)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [valueErrors]
  )

  let lowercase = useMemo(() => watchValue && /^.*[a-z]+.*$/.test(watchValue), [watchValue])
  let uppercase = useMemo(() => watchValue && /^.*[A-Z]+.*$/.test(watchValue), [watchValue])
  let digit = useMemo(() => watchValue && /^.*[0-9]+.*$/.test(watchValue), [watchValue])
  let special = useMemo(() => watchValue && /^.*[^a-zA-Z0-9]+.*$/.test(watchValue), [watchValue])

  useEffect(
    () => {
      let count = 0

      // debugger
      if (lowercase) {
        count++
      }
      if (uppercase) {
        count++
      }
      if (digit) {
        count++
      }
      if (special) {
        count++
      }

      switch (count) {
        case 1:
          updateLevel({ text: 'weak', color: 'password-verify-weak' })
          break
        case 2:
          updateLevel({ text: 'medium', color: 'password-verify-medium' })
          break
        case 3:
          updateLevel({ text: 'strong', color: 'password-verify-strong' })
          break
        case 4:
          updateLevel({ text: 'very strong', color: 'password-verify-very-strong' })
          break
        default:
          updateLevel({ text: 'NONE', color: 'bg-danger' })
      }
    },
    [lowercase, uppercase, digit, special]
  )

  return (
    <React.Fragment>
      <FormatLabel name={name} label={label} hasValue={!!watchValue} required={required}>
        <div className="input-group">
          <input
            className="form-control"
            type={showPassword ? 'text' : 'password'}
            {...register(name, { required })}
            placeholder={placeholder || `enter ${label || name}`}
          />
          <div className="input-group-append">
            <div className="input-group-text">
              {!showPassword && <span onClick={() => setShowPassword(true)}>show</span>}
              {showPassword && <span onClick={() => setShowPassword(false)}>hide</span>}
            </div>
          </div>

          <div className="progress my-1" style={progressStyle}>
            {valueErrors &&
              <div className="progress-bar bg-danger" style={progressBarStyle}>
                {valueErrors}
              </div>}
            {!valueErrors &&
              <div className={`progress-bar ${level.color}`} style={progressBarStyle}>
                {level.text}
              </div>}
          </div>

          <small>
            <span>Suggested: </span>
            <span className={`${lowercase ? '' : 'text-danger'}`}>1 Lowercase character, </span>
            <span className={`${uppercase ? '' : 'text-danger'}`}>1 Uppercase character, </span>
            <span className={`${digit ? '' : 'text-danger'}`}>1 Digit, </span>
            <span className={`${special ? '' : 'text-danger'}`}>1 Special character, </span>
          </small>
        </div>
      </FormatLabel>
    </React.Fragment>
  )
}
