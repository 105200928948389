import { Link } from 'react-router-dom'
import { Card } from '../../../../shared/card/card'
import { useHistory } from '../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'

export const PositionCenter = ({ children }) => {
  return (
    <div className="row h-100">
      <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-5 col-xxl-4 mx-auto my-auto">
        <Card>
          {children}
        </Card>
      </div>
    </div>
  )
}

export const TitleMain = ({ value }) => {
  return (
    <div className="my-2">
      <h3 className="text-capitalize">
        {value}
      </h3>
    </div>
  )
}

export const TitleSub = ({ value }) => {
  return (
    <div className="my-2 fw-light">
      {value}
    </div>
  )
}

export const NavLink = ({ className, Icon, text, to }) => {
  const history = useHistory()
  const handleClick = () => history.push(to)

  return (
    <div className={className} onClick={handleClick}>
      <span className="edxr-pointer">
        {Icon && <Icon />}
        {text}
      </span>
    </div>
  )
}
