import React, { useMemo } from 'react'
import { parseDiff, Diff, Hunk } from 'react-diff-view'
import { diffLines, formatLines } from 'unidiff'
import { Card } from '../../../card/card'

export const JsonDiff = ({ current, previous }) => {
  const currentStr = useMemo(() => (current ? JSON.stringify(current, undefined, 3) : undefined), [current])
  const previousStr = useMemo(() => (previous ? JSON.stringify(previous, undefined, 3) : undefined), [previous])

  const files = useMemo(
    () => {
      if (currentStr && previousStr) {
        const lines = diffLines(currentStr, previousStr)
        const diffText = formatLines(lines, { context: 3 })
        let hold = parseDiff(diffText)
        return hold
      }

      return []
    },
    [currentStr, previousStr]
  )

  return (
    <React.Fragment>
      {currentStr &&
        previousStr &&
        <Card title="what's changed">
          <div className="form-control m-1">
            <div className="d-block d-md-none">
              {files.map(diffUnified)}
            </div>
            <div className="d-none d-md-block">
              {files.map(diffSplit)}
            </div>
          </div>
        </Card>}
    </React.Fragment>
  )
}

const diffUnified = ({ oldRevision, newRevision, type, hunks }) => {
  return (
    <Diff key={oldRevision + '-' + newRevision} viewType="unified" type={type} hunks={hunks}>
      {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
    </Diff>
  )
}

const diffSplit = ({ oldRevision, newRevision, type, hunks }) => {
  return (
    <Diff key={oldRevision + '-' + newRevision} viewType="split" type={type} hunks={hunks}>
      {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
    </Diff>
  )
}
