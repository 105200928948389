import { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react'
import { useRandomID } from '../hooks/random-id'
import bootstrap from 'bootstrap/dist/js/bootstrap'

export const Modal = forwardRef(({ children, backdropStatic, title, confirmText, closeText, onConfirm, onClose }, ref) => {
  const rand = useRandomID()
  const identifier = useMemo(() => `modal-${rand}`, [rand])

  const show = useCallback(() => {
    try {
      new bootstrap.Modal(`#${identifier}`, { backdrop: backdropStatic ? 'static' : true }).show()
    } catch (e) {
      console.error(`could not find target ${identifier} when opening modal`, e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hide = useCallback(() => {
    try {
      bootstrap.Modal.getInstance(`#${identifier}`).hide()
    } catch (e) {
      console.error(`could not find target ${identifier} when opening modal`, e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useImperativeHandle(
    ref,
    () => {
      return {
        show: show,
        hide: hide
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleClick = () => onConfirm && onConfirm()
  const handleClose = () => onClose && onClose()

  return (
    <div id={identifier} className="modal" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title fw-bold text-capitalize">
              {title}
            </div>
          </div>
          <div className="modal-body">
            {children}
          </div>
          <div className="modal-footer">
            {onConfirm &&
              <button type="button" className="btn btn-primary text-capitalize" onClick={handleClick}>
                {confirmText}
              </button>}
            {onClose &&
              <button type="button" className="btn btn-secondary text-capitalize" onClick={handleClose}>
                {closeText || 'cancel'}
              </button>}
          </div>
        </div>
      </div>
    </div>
  )
})
