import React, { useContext, useMemo, useState } from 'react'
import { AuthContext } from '../../../../../../context/auth.context'
import { useApiFormNodeLibrary, useApiListNodeLibraryCategory, useNodeCopyProgress } from '../../../../../../api-new/state/content'
import { useLoad } from '../../../../../../shared/hooks/load'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconMove } from '../../../../../../shared/icon/icon'
import { Form, FormHiddenNumber, FormSubmitBack, FormText } from '../../../../../../shared/form/form'
import { useDataContext } from '../../data-context'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { pathNodeLibraryMap } from '../../common/shared/path-node-library'
import { NodeCopyProgress } from '../../common/node-copy/node-copy-progress'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { NodeLibraryMenuFull } from '../common/node-library-menu'
import { FormSelectCategoryTemp } from '../../../../../../shared/form/form-control/form-select-category-temp'

export const NodeLibraryCopy = ({ parentURL, nodeID }) => {
  const authContext = useContext(AuthContext)
  const dataContext = useDataContext()

  const history = useHistory()

  const apiFormNodeLibrary = useApiFormNodeLibrary()

  const handleComplete = async progress => {
    let node = await apiFormNodeLibrary.get(progress.nodeID)

    const moveToPath = ({ map }) => {
      dataContext.unsubscribe(moveToPath)
      let path = pathNodeLibraryMap(map, node.id)
      history.replace(path)
    }

    dataContext.subscribe(moveToPath)
    dataContext.nodeInsert(node)
  }

  const [start, progress] = useNodeCopyProgress(handleComplete)

  const [categoryOrganizationID, updateCategoryOrganizationID] = useState()

  useLoad(() => authContext.state.jwtClaims.organizationID && updateCategoryOrganizationID(authContext.state.jwtClaims.organizationID), [
    authContext.state.jwtClaims.organizationID
  ])

  const title = useMemo(() => `copy ${apiFormNodeLibrary.form.name}`, [apiFormNodeLibrary.form.name])

  const handleMatch = async () => await apiFormNodeLibrary.get(nodeID)

  const handleSubmit = async data => {
    if (data.parentID === -1) {
      data.parentID = null
    }

    await start(data)
  }

  const HeaderMenu = () => NodeLibraryMenuFull(parentURL)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconMove} text="copy library" onMatch={handleMatch}>
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <Form onSubmit={handleSubmit}>
            <FormHiddenNumber name="nodeID" value={nodeID} required shouldDirty />
            {categoryOrganizationID &&
              <FormSelectCategoryTemp
                lable="category"
                name="parentID"
                organizationID={categoryOrganizationID}
                useApiList={useApiListNodeLibraryCategory}
                includeRoot
                required
              />}

            <FormText label="name" name="name" defaultValue={apiFormNodeLibrary.form.name} required />

            <FormSubmitBack text="copy library" />
          </Form>

          <NodeCopyProgress progress={progress} />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
