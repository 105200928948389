import React from 'react'
import { Route, useRouteMatch } from 'react-router'
import { AssessmentManageList } from './assessment-manage/assessment-manage-list'
import { AssessmentManageRecycle } from './assessment-manage/assessment-manage-recycle'
import { AssessmentManageEdit } from './assessment-manage/assessment-manage-edit'
import { AssessmentManageClear } from './assessment-manage/assessment-manage-clear'
import { AssessmentManageDepublish } from './assessment-manage/assessment-manage-depublish'
import { AssessmentManageAdd } from './assessment-manage/assessment-manage-add'
import { AssessmentView } from './assessment-view/assessment-view'
import { AssessmentManageAssignment } from './assessment-manage/assessment-manage-assignment'
import { RouteAssessmentResultCourse } from './assessment-result-course/route-assessment-result-course'
import { Purge } from '../../../shared/page/page-purge'
import { useApiFormManageAssessment } from '../../../api-new/state/assessment'
import { Delete } from '../../../shared/page/page-delete'

export const AssessmentRoute = () => {
  const { url } = useRouteMatch()

  return (
    <React.Fragment>
      <Route path={`${url}/assessment-manage/list/:nodeID`} component={AssessmentManageList} />
      <Route path={`${url}/assessment-manage/list/:nodeID/add`} component={AssessmentManageAdd} />
      <Route path={`${url}/assessment-manage/list/:nodeID/edit/:assessmentID`} component={AssessmentManageEdit} />
      <Route path={`${url}/assessment-manage/list/:nodeID/edit/:assessmentID/assignment`} component={AssessmentManageAssignment} />
      <Route path={`${url}/assessment-manage/list/:nodeID/edit/:assessmentID/clear`} component={AssessmentManageClear} />
      <Route path={`${url}/assessment-manage/list/:nodeID/edit/:assessmentID/depublish`} component={AssessmentManageDepublish} />
      <Route path={`${url}/assessment-manage/list/:nodeID/recycle`} component={AssessmentManageRecycle} />

      <Route path={`${url}/assessment-manage/list/:nodeID/edit/:assessmentID/delete/:deleteID`}>
        <Delete useApiForm={useApiFormManageAssessment} titleParam="name" successPath={`${url}/assessment-manage/list/:nodeID`} />
      </Route>

      <Route path={`${url}/assessment-manage/list/:nodeID/recycle/purge/:purgeID`}>
        <Purge useApiForm={useApiFormManageAssessment} titleParam="name" successPath={`${url}/assessment-manage/list/:nodeID/recycle`} />
      </Route>

      <Route path={`${url}/assessment-manage/list/:nodeID/view/:assessmentID`} component={AssessmentView} />

      <RouteAssessmentResultCourse />
    </React.Fragment>
  )
}
