import React from 'react'
import { Route } from 'react-router-dom'
import { LoginProvider } from './content/login-provider'

export const LayoutLoginProvider = () => {
  return (
    <React.Fragment>
      <Route path="/org/login-provider" component={LoginProvider} />
    </React.Fragment>
  )
}
