import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { useAuthProviderLoginForm } from '../../../../../api/state'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Menu, MenuDelete } from '../../../../../shared/menu/menu'
import { IconView } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useRoleSysProvider } from '../../../../../role/hook/role-sys'

const DisplayString = ({ label, value }) => {
  return (
    <React.Fragment>
      <div className="form-group">
        <label>
          {label}
        </label>
        <input className="form-control" value={value} readOnly />
      </div>
    </React.Fragment>
  )
}

const DisplayTextArea = ({ label, value }) => {
  return (
    <React.Fragment>
      <div className="form-group">
        <label>
          {label}
        </label>
        <textarea value={value} className="form-control" readOnly />
      </div>
    </React.Fragment>
  )
}

export const ProviderAuthView = () => {
  const { authProviderID } = useParamsInt()

  const history = useHistory()

  const [authProviderLoginFormActions, authProviderLoginFormState] = useAuthProviderLoginForm()

  const roleSysProvider = useRoleSysProvider()

  const title = useMemo(() => `view ${authProviderLoginFormState.form.email}`, [authProviderLoginFormState.form])

  const handlePath = async () => await authProviderLoginFormActions.get(authProviderID)

  const handleDelete = async () => {
    let data = authProviderLoginFormState.form
    await authProviderLoginFormActions.delete(data.id)
    history.goBack()
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuDelete onClick={() => handleDelete()} show={roleSysProvider.accessDelete} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconView} text={title} onPath={handlePath}>
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <DisplayString label="provider" value={authProviderLoginFormState.form.provider} />
          <DisplayString label="identifier" value={authProviderLoginFormState.form.identifier} />
          <DisplayString label="email" value={authProviderLoginFormState.form.email} />
          <DisplayString label="first name" value={authProviderLoginFormState.form.firstName} />
          <DisplayString label="last name" value={authProviderLoginFormState.form.lastName} />
          <DisplayString label="ipAddress" value={authProviderLoginFormState.form.ipAddress} />
          <DisplayString label="ipLocation" value={authProviderLoginFormState.form.ipLocation} />
          <DisplayString label="deviceType" value={authProviderLoginFormState.form.deviceType} />
          <DisplayString label="authorized" value={authProviderLoginFormState.form.authorized} />
          <DisplayTextArea label="claims json" value={authProviderLoginFormState.form.claimsJson} />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
