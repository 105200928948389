import React from 'react'

export const TradeShowInvalid = ({ state }) => {
  return (
    <React.Fragment>
      <div>
        {state.form.inactiveMessage && state.form.inactiveMessage}
        {!state.form.inactiveMessage && 'Tradeshow is no longer active'}
      </div>
    </React.Fragment>
  )
}
