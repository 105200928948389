import React from 'react'
import { useSlate } from 'slate-react'
import { Editor } from 'slate'
import { Button } from './button'

export const ButtonTagValue = ({ Icon, format, value }) => {
  const editor = useSlate()

  const handleClick = event => {
    event.preventDefault()
    toggleValue(editor, format, value)
  }

  return <Button Icon={Icon} onClick={handleClick} />
}

const getMarkValue = (editor, format) => {
  const marks = Editor.marks(editor)

  if (!marks) {
    return null
  }

  let hold = marks[format] || 0
  return hold
}

const toggleValue = (editor, format, value) => {
  const markValue = getMarkValue(editor, format)

  let newValue = markValue + value

  if (newValue > 0) {
    Editor.addMark(editor, format, newValue)
  } else {
    Editor.removeMark(editor, format)
  }
}
