import React, { useContext, useEffect, useMemo } from 'react'
import { AuthContext } from '../../context/auth.context'
import * as action from '../../api/api-form/action'
import { useHistory } from '../../../node_modules/react-router-dom/cjs/react-router-dom.min'

const logoPath = '/org/dashboard'

const useWhiteLabelForm = () => {
  return action.useApiFormState(process.env.REACT_APP_API_CONTENT, `api/v1/white-label`)
}

export const HeaderLogo = ({ small, gray }) => {
  const history = useHistory()

  const authContext = useContext(AuthContext)

  const [whiteLableFormActions, whiteLabelFormState] = useWhiteLabelForm()

  useEffect(
    () => {
      if (authContext.state.jwtClaims.organizationID) {
        whiteLableFormActions.get(authContext.state.jwtClaims.organizationID)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state.jwtClaims]
  )

  const logo = useMemo(() => (whiteLabelFormState.form.logoURL ? whiteLabelFormState.form.logoURL : '/EDXR-Logo.png'), [
    whiteLabelFormState.form
  ])

  const handleClick = () => history.push(logoPath)

  return (
    <React.Fragment>
      <div className="d-inline" onClick={handleClick}>
        <img className={`edxr-pointer ${small ? 'logo-sm' : 'logo'} ${gray && 'logo-gray'}`} src={logo} alt="header logo" />
      </div>
    </React.Fragment>
  )
}
