import React, { useMemo } from 'react'
import { useHistory } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { useApiFormTemplate } from '../../../../../../api-new/state/content'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormSubmitBack, FormTextConfirm } from '../../../../../../shared/form/form'

import { useDataContext } from '../../data-context'
import { IconDelete } from '../../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { NodeTemplateMenuFull } from '../common/node-template-menu'

export const NodeTemplateDelete = ({ grandParentURL, parentURL, nodeID }) => {
  const history = useHistory()

  const apiFormTemplate = useApiFormTemplate()

  const handleMatch = async () => await apiFormTemplate.get(nodeID)

  const title = useMemo(() => apiFormTemplate.form.name, [apiFormTemplate.form])

  const dataContext = useDataContext()

  const handleSubmit = async () => {
    await apiFormTemplate.delete(nodeID)
    dataContext.nodeRemoveID(nodeID)
    history.replace(grandParentURL)
  }

  const HeaderMenu = () => NodeTemplateMenuFull(parentURL, apiFormTemplate.form)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconDelete} text="delete template" onMatch={handleMatch}>
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <div className="py-2">This is a destructive action</div>

          <Form onSubmit={handleSubmit}>
            <FormTextConfirm placeholder="delete" subtext="Type delete to confirm" confirmValue="delete" name="test" required autoFocus />
            <FormSubmitBack text="delete template" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
