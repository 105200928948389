export const GROUP_PERIOD = 1
export const GROUP_EVENT_TOPIC = 2
export const GROUP_EVENT_ANALYTIC = 3

export const getGroups = (topics, analytics, periods) => {
  const groups = []

  if (topics && topics.length) {
    groups.push({ id: GROUP_EVENT_TOPIC, content: 'Topics', className: 'edxr-vis-group' })
  }

  if (analytics && analytics.length) {
    groups.push({ id: GROUP_EVENT_ANALYTIC, content: 'Analytics', className: 'edxr-vis-group' })
  }

  if (periods && periods.length) {
    groups.push({ id: GROUP_PERIOD, content: 'Periods', className: 'edxr-vis-group' })
  }

  return groups
}
