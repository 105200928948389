import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useFormDisable } from './form'
import { ButtonSecondary } from '../legacy/button'
import { ButtonLight, ButtonPrimary } from '../component/button'

const InputSubmit = ({ text, disabled, small }) => {
  return <ButtonPrimary text={text || 'submit'} disabled={disabled} small={small} submit={true} />
}

export const ButtonCancel = ({ small, onCancel }) => {
  const formDisabled = useFormDisable()
  return <ButtonLight text="cancel" disabled={formDisabled} small={small} onClick={onCancel} />
}

export const ButtonBack = ({ small, onBack }) => {
  const formDisabled = useFormDisable()
  return <ButtonLight text="back" disabled={formDisabled} small={small} onClick={onBack} />
}

export const FormSubmit = ({ text, disable, small }) => {
  const { formState } = useFormContext()

  const formDisabled = useFormDisable()
  const hasErrors = useMemo(() => Object.keys(formState.errors).length > 0, [formState])
  const isDirty = useMemo(() => formState.isDirty, [formState])

  return <InputSubmit text={text} disabled={!isDirty || hasErrors || formDisabled || disable} small={small} />
}

export const FormSubmitCancel = ({ text, disable, small, onCancel }) => {
  return (
    <div>
      <ButtonCancel onCancel={onCancel} small={small} />
      <FormSubmit text={text} disable={disable} small={small} />
    </div>
  )
}

export const FormSubmitBack = ({ children, text, disable }) => {
  const history = useHistory()

  const onBack = () => history.goBack()

  return (
    <div>
      <ButtonBack onBack={onBack} />
      <FormSubmit text={text} disable={disable} />
      {children}
    </div>
  )
}

export function FormReset() {
  const { reset } = useFormContext()

  const handleClear = () => reset()

  return <ButtonSecondary text="clear" type="reset" onClick={handleClear} />
}
