import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormRequired, useFormShouldDirty } from './hooks/input-hooks'

const useStateTimeout = (initial, timeout) => {
  const [state, updateState] = useState(initial)

  return [
    state,
    value => {
      updateState(value)
      setTimeout(() => {
        updateState(initial)
      }, timeout)
    }
  ]
}

export const FormURL = ({ label, placeholder, subtext, name, required, readOnly, defaultValue, shouldDirty, autoFocus, hidden }) => {
  const { register, watch } = useFormContext()

  let formDisabled = useFormDisable()

  let watchValue = watch(name)

  useFormRequired(name, required)
  useFormShouldDirty(name, defaultValue, shouldDirty)

  const [copying, updateCopying] = useStateTimeout(false, 1000)

  const handleCopy = () => {
    navigator.clipboard.writeText(watchValue)
    updateCopying(true)
  }

  const handleOpen = () => window.open(watchValue)

  return (
    <React.Fragment>
      <FormatLabel name={name} label={label} subtext={subtext} hasValue={!!watchValue} required={required} hidden={hidden}>
        <div className="input-group">
          <input
            className="form-control"
            placeholder={placeholder || label}
            autoFocus={autoFocus}
            type="text"
            {...register(name, { required })}
            disabled={formDisabled}
            readOnly={readOnly}
            hidden={hidden}
          />
          <div className="btn-group-append" onClick={handleCopy}>
            <span className={`${'input-group-text'} ${copying && 'bg-info text-white'}`} style={{ cursor: 'pointer' }}>
              {copying ? 'Copying' : 'Copy'}
            </span>
          </div>
          <div className="btn-group-append" onClick={handleOpen}>
            <span className="input-group-text" style={{ cursor: 'pointer' }}>
              Open
            </span>
          </div>
        </div>
      </FormatLabel>
    </React.Fragment>
  )
}
