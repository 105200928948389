import React from 'react'
import { Route } from 'react-router-dom'
import { AuthStatusDetails } from './content/auth-status-details'
import { AuthStatusList } from './content/auth-status-list'
import { AuthStatusLogin } from './content/auth-status-login'
import { AuthStatusOrganization } from './content/auth-status-organization'

export const LayoutAuthStatus = () => {
  return (
    <React.Fragment>
      <Route path="/admin/auth-status/list" component={AuthStatusList} />
      <Route path="/admin/auth-status/list/:refreshToken/details" component={AuthStatusDetails} />
      <Route path="/admin/auth-status/list/:organizationID/organization" component={AuthStatusOrganization} />
      <Route path="/admin/auth-status/list/:loginID/login" component={AuthStatusLogin} />
    </React.Fragment>
  )
}
