import React from 'react'
import { Menu, MenuDelete, MenuEdit, MenuRecycle } from '../../../../../../shared/menu/menu'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnDateTime, ColumnMenu, TableBuilder } from '../../../../../../shared/table-builder/table-builder'
import { IconList } from '../../../../../../shared/icon/icon'
import { useApiListChunkLogError } from '../../../../../../api-new/state/content'

export function LogErrorList() {
  const apiListChunkLogError = useApiListChunkLogError()

  const handleReady = async () => {
    try {
      await apiListChunkLogError.get(1000)
    } catch (error) {}
  }

  const handleDelete = async item => {
    try {
      debugger
      await apiListChunkLogError.delete(item.id)
      await apiListChunkLogError.remove(item)
    } catch (error) {}
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuRecycle to="/admin/dashboard/log-error/list/recycle" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="errors">
        <CardFullHeightScrollY title="errors" HeaderMenu={HeaderMenu}>
          <TableBuilder apiList={apiListChunkLogError} onReady={handleReady}>
            <ColumnDateTime field="createdAt" header="Occured" sortable filter sort="desc" />
            <Column field="sessionID" sortable filter />
            <Column field="organization" sortable filter />
            <Column field="email" sortable filter />
            <Column field="message" sortable filter />
            <Column field="method" sortable filter />
            <Column field="statusCode" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuEdit to={`/admin/dashboard/log-error/list/edit/${row.id}`} show />
                  <MenuDelete onClick={() => handleDelete(row)} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
