import React from 'react'
import { Route } from 'react-router-dom'
import { RoleSysPermissionList } from './page/role-sys-permission-list'
import { RoleSysPermissionAdd } from './page/role-sys-permission-add'
import { RoleSysPermissionEdit } from './page/role-sys-permission-edit'
import { Delete } from '../../../../shared/page/page-delete'
import { useApiFormRoleSysPermission } from '../../../../api-new/state/content'
import { RoleSysPermissionListRole } from './page/role-sys-permission-list-role'
import { RoleAccessListIdentifier } from './page/role-sys-permission-list-identifier'

export const LayoutRoleSysPermission = () => {
  return (
    <React.Fragment>
      <Route path="/root/role-sys-permission/list" component={RoleSysPermissionList} />
      <Route path="/root/role-sys-permission/list/add" component={RoleSysPermissionAdd} />
      <Route path="/root/role-sys-permission/list/edit/:roleSysPermissionID" component={RoleSysPermissionEdit} />

      <Route path="/root/role-sys-permission/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormRoleSysPermission} titleParam="role" successPath="/root/role-sys-permission/list" />
      </Route>

      <Route path="/root/role-sys-permission/list/role/:roleSys" component={RoleSysPermissionListRole} />
      <Route path="/root/role-sys-permission/list/identifier/:identifier" component={RoleAccessListIdentifier} />
    </React.Fragment>
  )
}
