import { useEffect, useState } from 'react'
import { Filter } from '../../component/filter'

export const TableFilter = ({ gridRef }) => {
  const [filter, updateFilter] = useState('')

  const handleChange = value => updateFilter(value)

  useEffect(
    () => {
      if (gridRef.current.api) {
        gridRef.current.api.setQuickFilter(filter)
      }
    },
    [gridRef, filter]
  )

  return <Filter value={filter} onChange={handleChange} />
}
