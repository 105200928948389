export const SerializeTMP = nodes => {
  if (!nodes) {
    return ''
  }

  let results = nodes.map(node => {
    switch (node.type) {
      case 'paragraph':
        if (node.align) {
          return insertAlign(node)
        } else {
          return insertValueAfter(node, '\r\n')
        }
      case 'list-bulleted':
        return `${children(node.children)}`
      case 'list-numbered':
        return `${children(node.children, 1)}`
      default:
        return 'DEFAULT'
    }
  })

  return results.reduce((result, value) => (result += value), '')
}

const insertValueAfter = (node, value) => {
  return `${children(node.children)}${value}`
}

const insertAlign = node => {
  return `<align=${node.align}>${children(node.children)}</align>`
}

const children = (items, num) => {
  const result = items.reduce((result, item) => {
    let value = item.text

    if (item.type === 'list-item') {
      if (num) {
        value = `${num++}.<space=2em>${children(item.children)}<space=2em>`
      } else {
        value = `•<space=2em>${children(item.children)}<space=2em>`
      }
    }

    if (item.underline) {
      value = `<underline>${value}</underline>`
    }

    if (item.strikethrough) {
      value = `<strikethrough>${value}</strikethrough>`
    }

    if (item.bold) {
      value = `<b>${value}</b>`
    }

    if (item.italic) {
      value = `<i>${value}</i>`
    }

    if (item.indent) {
      value = `<indent='${item.indent * 5}%'>${value}</indent>`
    }

    return (result += value)
  }, '')

  return result
}
