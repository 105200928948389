/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Children, cloneElement, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  IconAdd,
  IconCsv,
  IconDelete,
  IconDetails,
  IconEdit,
  IconExcel,
  IconExport,
  IconMenu,
  IconPurge,
  IconRestore,
  IconSettings,
  IconRecycle,
  IconActivityLog
} from '../icon/icon'
import { ButtonPrimary } from '../component/button'
import { Floating } from '../component/floating'
import { Link } from '../../../node_modules/react-router-dom/cjs/react-router-dom.min'

export const MenuItemHeader = ({ text, show }) =>
  <React.Fragment>
    {text}
  </React.Fragment>

export const MenuItemSeperator = ({ show }) => <React.Fragment />

////////////////////////////////////////////

const MenuItemContent = ({ Icon, text }) =>
  <span className="text-nowrap">
    {Icon && <Icon />} {text}
  </span>

export const MenuItem = ({ Icon, text, show, disabled, pin, to, onClick }) => <MenuItemContent Icon={Icon} text={text} />
// MenuItem.defaultProps = { show: false, pin: false }

export const MenuAdd = ({ text }) => <MenuItemContent Icon={IconAdd} text={text || 'add'} />
MenuAdd.defaultProps = { show: false, pin: true }

export const MenuEdit = ({ text }) => <MenuItemContent Icon={IconEdit} text={text || 'edit'} />
MenuEdit.defaultProps = { show: false, pin: true }

export const MenuDetails = ({ text }) => <MenuItemContent Icon={IconDetails} text={text || 'details'} />
MenuDetails.defaultProps = { show: false, pin: true }

export const MenuDelete = ({ text }) => <MenuItemContent Icon={IconDelete} text={text || 'delete'} />
MenuDelete.defaultProps = { show: false, pin: false }

export const MenuRemove = ({ text }) => <MenuItemContent Icon={IconDelete} text={text || 'remove'} />
MenuRemove.defaultProps = { show: false, pin: false }

export const MenuRecycle = ({ text }) => <MenuItemContent Icon={IconRecycle} text={text || 'recycle bin'} />
MenuRecycle.defaultProps = { show: false, pin: false }

export const MenuRestore = ({ text }) => <MenuItemContent Icon={IconRestore} text={text || 'restore'} />
MenuRestore.defaultProps = { show: false, pin: true }

export const MenuPurge = ({ text }) => <MenuItemContent Icon={IconPurge} text={text || 'purge'} />
MenuPurge.defaultProps = { show: false, pin: false }

export const MenuExport = ({ text }) => <MenuItemContent Icon={IconExport} text={text || 'export'} />
MenuExport.defaultProps = { show: false, pin: true }

export const MenuExportCsv = ({ text }) => <MenuItemContent Icon={IconCsv} text={text || 'export csv'} />
MenuExportCsv.defaultProps = { show: false, pin: false }

export const MenuExportExcel = ({ text }) => <MenuItemContent Icon={IconExcel} text={text || 'export excel'} />
MenuExportExcel.defaultProps = { show: false, pin: false }

export const MenuTableSettingToggle = ({ text }) => <MenuItemContent Icon={IconSettings} text={text || 'Table Settings'} />
MenuTableSettingToggle.defaultProps = { show: false, pin: false }

export const MenuActivityLog = ({ text }) => <MenuItemContent Icon={IconActivityLog} text={text || 'ActivityLog'} />
MenuActivityLog.defaultProps = { show: false, pin: false }

export const MenuLink = ({ Icon, text, to, disabled }) => {
  const Content = ({ text }) =>
    <React.Fragment>
      {Icon && <Icon className="pr-1" />}
      {text}
    </React.Fragment>

  return (
    <React.Fragment>
      {disabled
        ? <div className="dropdown-item text-muted" style={{ cursor: 'not-allowed' }}>
            <Content text={text} />
          </div>
        : <Link className="dropdown-item" style={{ cursor: 'pointer' }} to={to}>
            <Content text={text} />
          </Link>}
    </React.Fragment>
  )
}

////////////////////////////////////////////

export const Menu = ({ children, small, placement }) => {
  let childArray = useMemo(() => Children.toArray(children).filter(i => i.props.show), [children])

  let childArrayPin = useMemo(() => childArray.filter(i => i.props.pin), [childArray])
  let childArrayDropdown = useMemo(() => childArray.filter(i => !i.props.pin), [childArray])

  return (
    <React.Fragment>
      {childArray.length === 1 && <MenuButton child={childArray[0]} small={small} />}
      {childArray.length > 1 &&
        <div className={`d-flex align-items-center float-end ${small && 'm-1'}`}>
          {childArrayPin.length > 0 && <MenuListButton childArray={childArrayPin} small={small} />}
          {childArrayDropdown.length > 0 && <MenuListDropdown childArray={childArrayDropdown} small={small} placement={placement} />}
        </div>}
    </React.Fragment>
  )
}

////////////////////////////////////////////////////

const MenuButton = ({ child, small }) => {
  const history = useHistory()

  const handleClick = e => {
    e.stopPropagation()
    if (child.props.to) {
      history.push(child.props.to)
    } else {
      child.props.onClick && child.props.onClick(e)
    }
  }

  return (
    <ButtonPrimary disabled={child.props.disabled} small={small} onClick={handleClick}>
      {cloneElement(child, {})}
    </ButtonPrimary>
  )
}

const MenuListButton = ({ childArray, small }) => {
  return (
    <React.Fragment>
      {childArray.length > 0 &&
        <React.Fragment>
          {Children.map(childArray, (child, i) =>
            <MenuButton key={i} child={child} small={small} to={child.props.to} onClick={child.props.onClick} />
          )}
        </React.Fragment>}
    </React.Fragment>
  )
}

////////////////////////////////////////////////////

const MenuListDropdown = ({ childArray, small, placement }) => {
  const [visible, updateVisible] = useState(false)

  const history = useHistory()

  const handleItemClick = (e, child) => {
    e.stopPropagation()

    if (child.props.to) {
      history.push(child.props.to)
    } else {
      child.props.onClick && child.props.onClick(e)
    }
  }

  const handleOpenChange = value => updateVisible(value)

  const content = (
    <div>
      <ul className="list-group border text-capitalize">
        {Children.map(childArray, (child, i) => {
          if (child.type.name === MenuItemHeader.name) {
            return <MenuListItemHeader child={child} small={small} />
          } else if (child.type.name === MenuItemSeperator.name) {
            return <MenuListItemSeperator child={child} />
          } else {
            return <MenuListItemValue child={child} small={small} onClick={handleItemClick} />
          }
        })}
      </ul>
    </div>
  )

  return (
    <Floating content={content} isOpen={visible} placement={placement} onOpenChange={handleOpenChange}>
      <div className="d-flex h-100">
        <div className={`btn border  ${small && 'btn-sm'} ms-1`}>
          <IconMenu size="lg" />
        </div>
      </div>
    </Floating>
  )
}

const MenuListItem = ({ children, disabled, onClick }) => {
  const handleClick = e => {
    if (!disabled) {
      onClick && onClick(e)
    }
  }

  return (
    <li className={`list-group-item border-0 ${disabled ? 'text-secondary' : 'edxr-menu edxr-pointer '}`} onClick={handleClick}>
      {children}
    </li>
  )
}

const MenuListItemHeader = ({ child, small }) => {
  return (
    <MenuListItem disabled>
      {small
        ? <small>
            {cloneElement(child, {})}
          </small>
        : <span>
            {cloneElement(child, {})}
          </span>}
    </MenuListItem>
  )
}

const MenuListItemSeperator = () => {
  return (
    <MenuListItem>
      <div className="border" />
    </MenuListItem>
  )
}

const MenuListItemValue = ({ child, small, onClick }) => {
  const handleClick = e => onClick && onClick(e, child)

  return (
    <MenuListItem disabled={child.props.disabled} onClick={handleClick}>
      {small
        ? <small>
            {cloneElement(child, {})}
          </small>
        : <span>
            {cloneElement(child, {})}
          </span>}
    </MenuListItem>
  )
}
