import React, { useMemo } from 'react'
import { IconBreadcrumbSeperator, IconCategory, IconCourse, IconTemplate } from '../../../../../../shared/icon/icon'
import {
  DATATYPE_CATEGORY,
  DATATYPE_COURSE,
  DATATYPE_COURSE_TEMPLATE,
  DATATYPE_NODE_LIBRARY,
  DATATYPE_NODE_LIBRARY_CATEGORY,
  DATATYPE_STREAMING_VIDEO,
  getDatatype
} from '../shared/metadata'

export const FilterList = ({ RootIcon, nodes, nodeMap, filter, onClick }) => {
  let items = useMemo(
    () => {
      let filterLower = filter.toLowerCase()

      let matchedNodeTag = nodes.filter(node => {
        if (node.tags) {
          return node.tags.some(i => i.includes(filterLower))
        }

        return false
      })

      let matchedNodeName = nodes.filter(i => i.name.toLowerCase().includes(filterLower))

      let matchedNodes = [...matchedNodeName, ...matchedNodeTag]

      matchedNodes.forEach(item => {
        let pathItems = ancestorList(nodeMap, item.id)
        item.pathItems = pathItems
      })

      return matchedNodes.filter(i => i.pathItems.length > 0)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, nodeMap]
  )

  return (
    <React.Fragment>
      <ul className="list-group">
        {items.map((item, i) =>
          <li key={i} className="list-group-item" onClick={() => onClick(item.id)}>
            <span>
              <RootIcon />
            </span>

            {item.pathItems.map((pathItem, j) => <FilterListItem key={j} pathItem={pathItem} onClick={onClick} />)}
          </li>
        )}
      </ul>
    </React.Fragment>
  )
}

const FilterListItem = ({ pathItem, onClick }) => {
  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
    onClick && onClick(pathItem.id)
  }

  return (
    <React.Fragment>
      <IconBreadcrumbSeperator size="xs" />
      {pathItem.datatype === DATATYPE_CATEGORY && <IconCategory />}
      {pathItem.datatype === DATATYPE_COURSE && <IconCourse />}
      {pathItem.datatype === DATATYPE_STREAMING_VIDEO && <IconCourse />}
      {pathItem.datatype === DATATYPE_COURSE_TEMPLATE && <IconTemplate />}
      {pathItem.datatype === DATATYPE_NODE_LIBRARY_CATEGORY && <IconCategory />}
      {pathItem.datatype === DATATYPE_NODE_LIBRARY && <IconCourse />}
      <span className="edxr-pointer text-decoration-underline mx-1" onClick={handleClick}>
        {pathItem.name}
      </span>
    </React.Fragment>
  )
}

const ancestorList = (nodeMap, nodeID, items) => {
  if (!items) {
    items = []
  }

  let node = nodeMap[nodeID]

  //check for deleted ancestors
  if (!node) {
    return []
  }

  // debugger
  let datatype = getDatatype(node)
  items.unshift({ id: node.id, name: node.name, datatype: datatype })

  if (node.parentID) {
    return ancestorList(nodeMap, node.parentID, items)
  } else {
    return items
  }
}
