import React, { useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { BreadcrumbView } from '../../../context/breadcrumb.context'
import { useBuildFrom, useSeriesGet } from '../state'
import { Header } from './header'
import { AuthContext } from '../../../context/auth.context'
import { CardFullHeightScrollY } from '../../../shared/card/card-full-height-scroll-y'
import { Card } from '../../../shared/card/card'
import { useLoad } from '../../../shared/hooks/load'
import { IconHeatmap } from '../../../shared/icon/icon'

export function Display() {
  const { seriesID, sessionID } = useParams()

  const [buildFormActions] = useBuildFrom()
  const [seriesGetActions, seriesGetState] = useSeriesGet()

  const [isMoble, updateIsMoble] = useState(false)
  const [token, updateToken] = useState()

  const [stage, updateStage] = useState('initializing')
  const [buildProgress, updateBuildProgress] = useState('0%')

  const [debug, updateDebug] = useState(false)

  const authContext = useContext(AuthContext)

  const src = useMemo(
    () => {
      if (token) {
        let hold = `${process.env.REACT_APP_API_HEATMAP}/viewer-frame-hitsjson/display.html?zip=${process.env
          .REACT_APP_API_HEATMAP}/api/v1/build/${token.token}/download.zip${debug ? '&Debug=true' : ''}`
        return hold
      } else {
        return ''
      }
    },
    [token, debug]
  )

  useLoad(() => {
    seriesGetActions.get(sessionID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLoad(async () => {
    let isMoble = navigator.userAgent.match(/iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i)

    let test = !!isMoble

    updateIsMoble(test)

    if (test) {
      return
    }

    let token = await buildFormActions.get(`${seriesID}/token`)

    updateToken(token)

    let build = await buildFormActions.get(`${token.token}/build`)

    if (!build) {
      const wait = token => {
        return new Promise(resolve => {
          const interval = setInterval(async () => {
            let buildProgress = await buildFormActions.get(`${token}/progress`)
            if (buildProgress) {
              if (buildProgress.complete) {
                updateBuildProgress('100%')
                clearInterval(interval)
                resolve()
              } else {
                updateStage('building')
                let precent = parseInt(buildProgress.value.replace(/ProgressReport:/, '').replace(/% <RaycastsProcessing>.*/, ''))

                if (precent) {
                  updateBuildProgress(`${precent}%`)
                }
              }
            }
          }, 1000)
        })
      }

      await buildFormActions.get(`${token.token}/generate`)
      await wait(token.token)
    }

    updateStage('ready')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const styleProgress = {
    width: buildProgress
  }

  const handleToggleDebug = () => {
    updateDebug(!debug)
  }

  const HeaderMenu = () => <Header token={token} seriesID={seriesID} toggleDebug={handleToggleDebug} />

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconHeatmap} text="heatmap">
        <CardFullHeightScrollY
          className="h-100"
          title={seriesGetState.item.displayName}
          HeaderMenu={authContext.state.isSysAdmin && HeaderMenu}>
          <div className="h-100">
            {isMoble && <div>Not available on phone devices, try again on a desktop computer</div>}

            {!isMoble &&
              <div className="h-100">
                {stage === 'initializing' &&
                  <Card title="Initializing Heatmap">
                    <div className="progress m-3">
                      <div className="progress-bar bg-primary text-white" style={styleProgress}>
                        Initializing....
                      </div>
                    </div>
                  </Card>}

                {stage === 'building' &&
                  <Card title="Building Heatmap">
                    <div className="progress m-3">
                      <div className="progress-bar bg-primary text-white" style={styleProgress}>
                        {buildProgress && buildProgress}
                      </div>
                    </div>
                  </Card>}

                {stage === 'ready' &&
                  <iframe style={{ border: 'none', overflow: 'hidden', width: '100%', height: '100%' }} title="heatmap" src={src} />}
              </div>}
          </div>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
