import { useMemo, useReducer } from 'react'
import { deleteAsync, getAsync, getBlobAsync, postAsync, putAsync } from '../shared/api.service'
import { useNotificationContext } from '../context/notification/context'

export const types = {
  INIT: 'INIT',
  ERROR: 'ERROR',
  SUBMIT: 'SUBMIT',
  GET_SUCCESS: 'GET_SUCCESS',
  POST_SUCCESS: 'POST_SUCCESS',
  PUT_SUCCESS: 'PUT_SUCCESS',
  DELETE_SUCCESS: 'DELETE_SUCCESS'
}

export const initialState = {
  form: {},
  loading: false,
  submitting: false,
  error: undefined
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.INIT:
      return { ...initialState }
    case types.ERROR:
      return { ...state, loading: false, submitting: false, error: action.payload }
    case types.SUBMIT:
      return { ...state, loading: false, submitting: true, error: undefined }
    case types.GET_SUCCESS:
      return { ...state, form: action.payload || state.form, loading: false, submitting: false, error: undefined }
    case types.POST_SUCCESS:
      return { ...state, form: action.payload || state.form, loading: false, submitting: false, error: undefined }
    case types.PUT_SUCCESS:
      return { ...state, form: action.payload || state.form, loading: false, submitting: false, error: undefined }
    case types.DELETE_SUCCESS:
      return { ...state, loading: false, submitting: false, error: undefined }
    default:
      return state
  }
}

export const useApiForm = (server, endpoint) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const notificationContext = useNotificationContext()

  const actions = useMemo(() => {
    return {
      init: () => initAction(dispatch)(),
      get: arg => getAction(server, endpoint, dispatch, notificationContext)(arg),
      getBlob: arg => getActionBlob(server, endpoint, dispatch, notificationContext)(arg),
      post: data => postAction(server, endpoint, dispatch, notificationContext)(data),
      postArg: (arg, data) => postArgAction(server, endpoint, arg, dispatch, notificationContext)(data),
      put: (arg, data) => putAction(server, endpoint, dispatch, notificationContext)(arg, data),
      delete: arg => deleteAction(server, endpoint, dispatch, notificationContext)(arg)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(
    () => {
      return { ...state, ...actions }
    },
    [state, actions]
  )
}

const initAction = dispatch => () => {
  dispatch({ type: types.INIT })
}

const getAction = (server, endpoint, dispatch, notificationContext) => async arg => {
  try {
    if (arg) {
      endpoint = `${endpoint}/${arg}`
    }

    dispatch({ type: types.SUBMIT })
    const result = await getAsync(server, endpoint)
    dispatch({ type: types.GET_SUCCESS, payload: result.data })
    return result.data
  } catch (exception) {
    // notificationContext.modal.error(exception.error)
    dispatch({ type: types.ERROR, payload: exception.error || '' })
  }
}

const getActionBlob = (server, endpoint, dispatch, notificationContext) => async arg => {
  try {
    if (arg) {
      endpoint = `${endpoint}/${arg}`
    }

    dispatch({ type: types.SUBMIT })
    const result = await getBlobAsync(server, endpoint)
    dispatch({ type: types.GET_SUCCESS, payload: result.data })
    return result.data
  } catch (exception) {
    // notificationContext.modal.error(exception.error)
    dispatch({ type: types.ERROR, payload: exception.error || '' })
  }
}

const postAction = (server, endpoint, dispatch, notificationContext) => async data => {
  try {
    dispatch({ type: types.SUBMIT })
    const result = await postAsync(server, endpoint, data)
    dispatch({ type: types.POST_SUCCESS, payload: result.data })
    return result.data
  } catch (exception) {
    // notificationContext.modal.error(exception.error)
    dispatch({ type: types.ERROR, payload: exception.error || '' })
  }
}

const postArgAction = (server, endpoint, arg, dispatch, notificationContext) => async data => {
  try {
    dispatch({ type: types.SUBMIT })
    const result = await postAsync(server, endpoint + arg, data)
    dispatch({ type: types.POST_SUCCESS, payload: result.data })
    return result.data
  } catch (exception) {
    // notificationContext.modal.error(exception.error)
    dispatch({ type: types.ERROR, payload: exception.error || '' })
  }
}

const putAction = (server, endpoint, dispatch, notificationContext) => async (arg, data) => {
  try {
    endpoint = `${endpoint}/${arg}`

    dispatch({ type: types.SUBMIT })
    const result = await putAsync(server, endpoint, data)
    dispatch({ type: types.PUT_SUCCESS, payload: result.data })
    return result.data
  } catch (exception) {
    // notificationContext.modal.error(exception.error)
    dispatch({ type: types.ERROR, payload: exception.error || '' })
  }
}

const deleteAction = (server, endpoint, dispatch, notificationContext) => async arg => {
  try {
    endpoint = `${endpoint}/${arg}`
    dispatch({ type: types.SUBMIT })
    const result = await deleteAsync(server, endpoint)
    dispatch({ type: types.DELETE_SUCCESS })

    if (notificationContext) {
      notificationContext.toast.notify('delete success', 3000)
    }
    return result.data
  } catch (exception) {
    // notificationContext.modal.error(exception.error)
    dispatch({ type: types.ERROR, payload: exception.error || '' })
  }
}
