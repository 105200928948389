import { Card } from '../../../../../../shared/card/card'
import moment from 'moment'

export const Display = ({ children, title }) => {
  return (
    <Card title={title}>
      {children}
    </Card>
  )
}

export const Text = ({ label, value }) => {
  return (
    <div className="p-2">
      <label className="form-label text-capitalize">
        {label}
      </label>
      <div className="form-control">
        {value}
      </div>
    </div>
  )
}

export const TextArea = ({ label, value }) => {
  return (
    <div className="p-2">
      <label className="form-label text-capitalize">
        {label}
      </label>
      <textarea className="form-control" value={value} rows="10" readOnly />
    </div>
  )
}

export const DateTime = ({ label, value }) => {
  return (
    <div className="p-2">
      <label className="form-label text-capitalize">
        {label}
      </label>
      <div className="form-control">
        {moment(value).format('MM/DD/YYYY hh:mm:ss a')}
      </div>
    </div>
  )
}
