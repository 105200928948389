import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { useChunkBuildForm, useFileBuildForm } from '../../../../../../api/state'
import { useParamsInt } from '../../../../../../shared/hooks/location'
import { StorageChunk } from '../shared/storage-chunk/storage-chunk'
import { IconEdit } from '../../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useApiFormAppVersion } from '../../../../../../api-new/state/config'

export const AppVersionUpload = () => {
  const { appVersionID } = useParamsInt()

  const apiFormAppVersion = useApiFormAppVersion()

  const handlePath = () => apiFormAppVersion.get(appVersionID)

  const handleComplete = async details => {
    apiFormAppVersion.form.storageKey = details.storageKey
    await apiFormAppVersion.put(apiFormAppVersion.form.id, apiFormAppVersion.form)
    await apiFormAppVersion.get(appVersionID)
  }

  const handleDelete = async () => {
    if (apiFormAppVersion.form.storageKey) {
      apiFormAppVersion.form.storageKey = null
      await apiFormAppVersion.put(apiFormAppVersion.form.id, apiFormAppVersion.form)
      await apiFormAppVersion.get(appVersionID)
    }
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="upload" onPath={handlePath}>
        <CardFullHeightScrollY title="Build Upload">
          <StorageChunk
            useChunkApiForm={useChunkBuildForm}
            useFileApiForm={useFileBuildForm}
            storageKey={apiFormAppVersion.form.storageKey}
            storeKey={apiFormAppVersion.form.storeKey}
            onComplete={handleComplete}
            onDelete={handleDelete}
          />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
