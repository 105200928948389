import React, { createContext, useContext, useReducer } from 'react'

const SUBSCRIBE = 'SUBSCRIBE'
const UNSUBSCRIBE = 'UNSUBSCRIBE'

const AnalyticContext = createContext()

export const useAnalyticContext = () => useContext(AnalyticContext)

export const AnalyticProvider = ({ children }) => {
  const [subscriptions, dispatch] = useReducer((state, action) => {
    let newState
    let arr

    switch (action.type) {
      case SUBSCRIBE:
        newState = { ...state }
        arr = newState[action.id] || []
        arr.push(action.fn)
        newState[action.id] = arr
        return newState
      case UNSUBSCRIBE:
        newState = { ...state }
        arr = newState[action.id] || []
        arr = arr.filter(i => i !== action.fn)
        newState[action.id] = arr
        return newState
      default:
        return state
    }
  }, {})

  const update = id => subscriptions[id] && subscriptions[id].forEach(fn => fn())

  const subscribe = (id, fn) => dispatch({ type: SUBSCRIBE, id: id, fn: fn })
  const unsubscribe = (id, fn) => dispatch({ type: UNSUBSCRIBE, id: id, fn: fn })

  return (
    <AnalyticContext.Provider value={{ update, subscribe, unsubscribe }}>
      {children}
    </AnalyticContext.Provider>
  )
}
