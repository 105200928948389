import React, { useMemo } from 'react'
import { useAddUserContext } from '../context'
import { IconContent } from '../../../../../../../shared/icon/icon'
import { SelectList } from '../select-list/select-list'
import { IconButtonClick } from '../../../../../../../shared/icon/icon-button-click'
import { useButtonCount } from './hooks'

export const SelectContentDropdown = ({ selectedContents, readonlyContents, disabled, onClick }) => {
  const count = useButtonCount(selectedContents, readonlyContents)
  const text = useMemo(() => (count ? `contents (${count})` : 'contents'), [count])
  return (
    <button className="dropdown-item" disabled={disabled} onClick={onClick}>
      <span className="me-1">
        <IconContent />
      </span>
      <span>
        {text}
      </span>
    </button>
  )
}

export const SelectContentButton = ({ selectedContents, readonlyContents, small, disabled, onClick }) => {
  const count = useButtonCount(selectedContents, readonlyContents)
  const text = useMemo(() => (count ? `contents (${count})` : 'contents'), [count])
  return <IconButtonClick Icon={IconContent} text={text} disabled={disabled} small={small} onClick={onClick} />
}

export const SelectContentModal = ({ target, selectedContents, readonlyContents, onUpdate, onHide }) => {
  const addUserContext = useAddUserContext()

  const handleClose = () => onHide()

  const onAdd = item => {
    let newList = [...selectedContents, item]
    onUpdate(newList)
  }

  const onRemove = item => {
    let newList = selectedContents.filter(i => i.id !== item.id)
    onUpdate(newList)
  }

  return (
    <React.Fragment>
      <SelectList
        header="Contents"
        selectedItems={selectedContents}
        readonlyItems={readonlyContents}
        items={addUserContext.allContents}
        modalTarget={target}
        fnListFormat={item => item.path}
        fnListFilter={(filter, items) => items.filter(i => i.path.toLowerCase().includes(filter))}
        onAdd={onAdd}
        onRemove={onRemove}
        onClose={handleClose}
      />
    </React.Fragment>
  )
}
