import React from 'react'
import { useHistory } from 'react-router'
import { useBuildFrom, useExportGet } from '../state'
import { IconClickTimes } from '../../../shared/icon/icon-click'

export function Header({ token, seriesID, toggleDebug }) {
  const history = useHistory()

  const [exportGetActions] = useExportGet()
  const [buildFormActions] = useBuildFrom()

  const handleExportSceneSeries = async () => {
    if (window.confirm('DOWNLOAD SCENE/SERIES DATA?')) {
      let result = await exportGetActions.blob(`scene-series/${seriesID}/series-id`)
      var bb = new Blob([result], { type: 'application/zip' })
      var a = document.createElement('a')
      a.download = 'scene-series.zip'
      a.href = window.URL.createObjectURL(bb)
      a.click()
    }
  }

  const handleExportBuild = async () => {
    if (window.confirm('DOWNLOAD BUILD DATA?')) {
      let result = await exportGetActions.blob(`build/${seriesID}/series-id`)
      var bb = new Blob([result], { type: 'application/zip' })
      var a = document.createElement('a')
      a.download = 'build.zip'
      a.href = window.URL.createObjectURL(bb)
      a.click()
    }
  }

  const handleDeleteBuild = () => {
    if (window.confirm('DELETE BUILD?')) {
      buildFormActions.delete(`${token.token}/build`)
      window.location.reload()
    }
  }

  return (
    <React.Fragment>
      <button className="btn btn-primary btn-sm mx-1" onClick={toggleDebug}>
        toggle debug
      </button>
      <button className="btn btn-primary btn-sm mx-1" onClick={handleExportSceneSeries}>
        export scene/series
      </button>
      <button className="btn btn-primary btn-sm mx-1" onClick={handleExportBuild}>
        export build
      </button>
      <button className="btn btn-primary btn-sm mx-1" onClick={handleDeleteBuild}>
        delete build
      </button>
      <IconClickTimes className="ml-2" onClick={() => history.goBack()} />
    </React.Fragment>
  )
}
