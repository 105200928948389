import React from 'react'
import { Card } from '../../../../shared/card/card'

export const Assignments = ({ assignments }) => {
  return (
    <React.Fragment>
      {assignments.map((assignment, i) =>
        <Card key={i} title="assignment">
          <div className="container">
            <div className="row border-bottom">
              <div className="col-3 p-2">Step</div>
              <div className="col-9 p-2">
                {assignment.stepTitle}
              </div>
            </div>
            <div className="row border-bottom">
              <div className="col-3 p-2">Objective</div>
              <div className="col-9 p-2">
                {assignment.objectiveTitle}
              </div>
            </div>
          </div>
        </Card>
      )}
    </React.Fragment>
  )
}
