import React, { useMemo, useRef, useState } from 'react'
import { VisTimeline } from './vis-timeline'
import { mapPeriod } from './periods'
import { GROUP_EVENT_ANALYTIC, GROUP_EVENT_TOPIC, GROUP_PERIOD, getGroups } from './groups'
import { IconBigger, IconCalender, IconCalenders, IconDown, IconSmaller, IconUp } from '../../../../../../../shared/icon/icon'
import { EventDetails, mapEventAnalytic, mapEventTopic } from './events'
import { Card } from '../../../../../../../shared/card/card'
import { Menu, MenuItem } from '../../../../../../../shared/menu/menu'

export const VisTimelineEventPeriod = ({ topics, analytics, periods, onSelectPeriod }) => {
  const refVis = useRef()

  const groupTopics = useMemo(() => mapEventTopic(topics, GROUP_EVENT_TOPIC), [topics])
  const groupAnalytics = useMemo(() => mapEventAnalytic(analytics, GROUP_EVENT_ANALYTIC), [analytics])
  const groupPeriods = useMemo(() => mapPeriod(periods, GROUP_PERIOD), [periods])

  const [chartHeight, updateChartHeight] = useState(300)
  const handleChartBigger = () => updateChartHeight(() => (chartHeight < 1000 ? chartHeight + 100 : 1000))
  const handleChartSmaller = () => updateChartHeight(() => (chartHeight > 0 ? chartHeight - 100 : 0))

  const items = useMemo(
    () => {
      return [...groupTopics, ...groupAnalytics, ...groupPeriods]
    },
    [groupTopics, groupAnalytics, groupPeriods]
  )

  const groups = useMemo(
    () => {
      const groups = getGroups(groupTopics, groupAnalytics, groupPeriods)
      return groups
    },
    [groupTopics, groupAnalytics, groupPeriods]
  )

  const handleAllEvents = () => {
    const sorted = [...groupTopics, ...groupAnalytics].sort((a, b) => a.start < b.start)
    const first = sorted.first()
    const last = sorted.last()
    if (first && last) {
      refVis.current.setRange(first.start, last.start)
    }
  }

  const handleAllPeriods = () => {
    let first = groupPeriods.sort((a, b) => (a.start < b.start ? -1 : 1)).first()
    let last = groupPeriods.sort((a, b) => (a.end > b.end ? -1 : 1)).first()
    if (first && last) {
      refVis.current.setRange(first.start, last.end)
    }
  }

  const handleNow = () => refVis.current.setNow()
  const handleZoomIn = () => refVis.current.zoomIn()
  const handleZoomOut = () => refVis.current.zoomOut()

  const [selectedEventTopicID, updateSelectedEventTopicID] = useState()
  const [selectedEventAnalyticID, updateSelectedEventAnalyticID] = useState()

  const handleSelect = selectedItems => {
    if (selectedItems.length) {
      const [type, id] = selectedItems[0].split('-')

      switch (type) {
        case 'period':
          onSelectPeriod && onSelectPeriod(parseInt(id))
          updateSelectedEventTopicID(null)
          updateSelectedEventAnalyticID(null)
          break
        case 'topic':
          onSelectPeriod && onSelectPeriod(null)
          updateSelectedEventTopicID(parseInt(id))
          updateSelectedEventAnalyticID(null)
          break
        case 'analytic':
          onSelectPeriod && onSelectPeriod(null)
          updateSelectedEventTopicID(null)
          updateSelectedEventAnalyticID(parseInt(id))
          break
        default:
          onSelectPeriod && onSelectPeriod(null)
          updateSelectedEventTopicID(null)
          updateSelectedEventAnalyticID(null)
          break
      }
    } else {
      onSelectPeriod && onSelectPeriod(null)
      updateSelectedEventTopicID(null)
      updateSelectedEventAnalyticID(null)
    }
  }

  const ChartMenu = () =>
    <Menu small>
      <MenuItem Icon={IconCalenders} text="All Events" onClick={handleAllEvents} show pin />
      <MenuItem Icon={IconCalenders} text="All Periods" onClick={handleAllPeriods} show pin />
      <MenuItem Icon={IconCalender} text="Now" onClick={handleNow} show pin />
      <MenuItem Icon={IconBigger} text="zoom in" onClick={handleZoomIn} show />
      <MenuItem Icon={IconSmaller} text="zoom out" onClick={handleZoomOut} show />
      <MenuItem Icon={IconDown} text="expand" onClick={handleChartBigger} show />
      <MenuItem Icon={IconUp} text="collapse" onClick={handleChartSmaller} show />
    </Menu>

  return (
    <React.Fragment>
      <Card title="timeline" HeaderMenu={ChartMenu}>
        <VisTimeline ref={refVis} items={items} groups={groups} height={chartHeight} onSelect={handleSelect} />
        {selectedEventTopicID && <EventDetails eventID={selectedEventTopicID} events={topics} />}
        {selectedEventAnalyticID && <EventDetails eventID={selectedEventAnalyticID} events={analytics} />}
      </Card>
    </React.Fragment>
  )
}
