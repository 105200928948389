import React, {createContext, useReducer} from 'react'

export const PassaveContext = createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return [...state, action.payload]
    case 'remove':
      return state.filter(i => i.id !== action.payload.id)
    default:
      return state
  }
}

export const PassaveProvider = ({children}) => {
  const [active, dispatchActive] = useReducer(reducer, [])
  const [errors, dispatchErrors] = useReducer(reducer, [])

  const addActive = name => {
    let connection = {
      id: Date.now(),
      name: name
    }

    dispatchActive({type: 'add', payload: connection})
    return connection
  }

  const removeActive = connection => {
    dispatchActive({type: 'remove', payload: connection})
  }

  const addError = (connection, message) => {
    dispatchActive({type: 'remove', payload: connection})
    dispatchErrors({type: 'add', payload: {id: connection.id, name: connection.name, message: message}})
  }

  const removeError = connection => {
    dispatchErrors({type: 'remove', payload: connection})
  }

  return (
    <PassaveContext.Provider
      value={{
        addActive: addActive,
        removeActive: removeActive,
        addError: addError,
        removeError: removeError,
        active: active,
        errors: errors
      }}
    >
      {children}
    </PassaveContext.Provider>
  )
}
