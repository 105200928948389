import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Column, ColumnDateTime, ColumnMenu, TableBuilder } from '../../../../../../shared/table-builder/table-builder'
import { Menu, MenuPurge, MenuRestore } from '../../../../../../shared/menu/menu'
import { IconList } from '../../../../../../shared/icon/icon'
import { useApiListLogError } from '../../../../../../api-new/state/content'

export function LogErrorRecycle() {
  const apiListLogError = useApiListLogError()

  const handleReady = async () => await apiListLogError.get('deleted')

  const handleRestore = async item => {
    await apiListLogError.put(`${item.id}/restore`)
    await apiListLogError.get('deleted')
  }

  const handlePurge = async item => {
    await apiListLogError.delete(`${item.id}/purge`)
    await apiListLogError.get('deleted')
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text="recycle bin">
        <CardFullHeightScrollY title="recycle bin">
          <TableBuilder apiList={apiListLogError} onReady={handleReady}>
            <ColumnDateTime field="createdAt" header="Occured" sortable filter />
            <Column field="message" sortable filter />
            <Column field="method" sortable filter />
            <Column field="query" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  <MenuRestore onClick={() => handleRestore(row)} show />
                  <MenuPurge onClick={() => handlePurge(row)} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
