import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconDashboard } from '../../../../../shared/icon/icon'

import { LogEventChart } from './chart/log-event-bar'
import { LogErrorBar } from './chart/log-error-bar'
import { Grid, GridItem2 } from '../../../../../shared/chart/grid'

export const AdminDashboard = () => {
  return (
    <BreadcrumbView Icon={IconDashboard} text="DASHBOARD">
      <Grid>
        <GridItem2>
          <LogEventChart />
        </GridItem2>
        <GridItem2>
          <LogErrorBar />
        </GridItem2>
      </Grid>
    </BreadcrumbView>
  )
}
