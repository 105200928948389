import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormCheckbox, FormSubmitBack, FormText } from '../../../../../shared/form/form'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Card } from '../../../../../shared/card/card'
import { IconEdit } from '../../../../../shared/icon/icon'
import { useApiFormRoleSysPermission, useApiListRoleSys, useApiListRoleSysScope } from '../../../../../api-new/state/content'

export const RoleSysPermissionEdit = () => {
  const { roleSysPermissionID } = useParamsInt()

  const apiListRoleSys = useApiListRoleSys()
  const apiListRoleSysScope = useApiListRoleSysScope()
  const apiFormRoleSysPermission = useApiFormRoleSysPermission()

  const handlePath = () => {
    apiListRoleSys.get()
    apiListRoleSysScope.get()
    apiFormRoleSysPermission.get(roleSysPermissionID)
  }

  const handleSubmit = data => apiFormRoleSysPermission.put(data.id, data)

  const title = useMemo(() => `${apiFormRoleSysPermission.form.roleName} - ${apiFormRoleSysPermission.form.identifier}`, [
    apiFormRoleSysPermission.form
  ])

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={title} onPath={handlePath}>
        <Card title="edit role permission">
          <Form state={apiFormRoleSysPermission.form} onSubmit={handleSubmit}>
            <FormText label="role" name="roleName" readOnly />
            <FormText label="scope" name="identifier" readOnly />

            <Card>
              <FormCheckbox label="Create" name="accessCreate" />
              <FormCheckbox label="Read" name="accessRead" />
              <FormCheckbox label="Update" name="accessUpdate" />
              <FormCheckbox label="Delete" name="accessDelete" />
            </Card>

            <FormSubmitBack text="update role permission" />
          </Form>
        </Card>
      </BreadcrumbView>
    </React.Fragment>
  )
}
