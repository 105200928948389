import { useLocation } from 'react-router-dom'
import { useAuthContext } from '../../context/auth.context'

export const useLogout = () => {
  const { pathname } = useLocation()

  const authContext = useAuthContext()

  return async () => {
    await authContext.actions.logout()

    let url = '/auth/email'

    if (pathname) {
      url += `?redirect=${pathname}`
    }
    let signoutUrl = authContext.actions.getSignoutURL()

    if (signoutUrl) {
      authContext.actions.removeSignoutURL()

      const complete = () =>
        setTimeout(() => {
          document.body.removeChild(iframe)
          window.location.replace(url)
        }, 1000)

      let iframe = document.createElement('iframe')
      iframe.setAttribute('id', 'signoutID')
      iframe.setAttribute('style', 'display:none;')
      iframe.setAttribute('src', signoutUrl)
      iframe.onload = complete
      iframe.onerror = complete
      document.body.appendChild(iframe)
    } else {
      window.location.replace(url)
    }
  }
}
