import { useEffect, useMemo, useState } from 'react'
import { useApiFormDashboardUser } from '../../../../../../api-new/state/dashboard'
import { useApiListDashboardUser } from '../../../../../../api-new/state/dashboard'
import { DashboardHeader, SelectRangeInterval, Widget } from '../../../../../../shared/chart/widget'
import { ChartBar } from '../../../../../../shared/chart/chart'
import { chartColorMain } from '../../../../../../shared/chart/chart-color'
import { IconUser } from '../../../../../../shared/icon/icon'
import moment from 'moment'
import { useLoad } from '../../../../../../shared/hooks/load'

export const DashboardUser = () => {
  const apiListDashboardUser = useApiListDashboardUser()
  const apiFormDashboardUser = useApiFormDashboardUser()

  const [count, updateCount] = useState(0)
  const [start, updateStart] = useState(null)
  const [end, updateEnd] = useState(null)
  const [interval, updateInterval] = useState('month1')

  useEffect(() => {
    ;(async () => {
      const result = await apiFormDashboardUser.get('organization/details')
      let start = moment(result.min).format('YYYY-MM-DD')
      let end = moment().format('YYYY-MM-DD')
      updateCount(result.count)
      updateStart(start)
      updateEnd(end)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLoad(() => start && end && interval && apiListDashboardUser.get(`organization?start=${start}&end=${end}&interval=${interval}`), [
    start,
    end,
    interval
  ])

  const chartData = useMemo(
    () => {
      if (!apiListDashboardUser.items.length) {
        return { labels: [], data: undefined, value: 0 }
      }

      const labels = apiListDashboardUser.items.map(i => i.date)
      const data = apiListDashboardUser.items.map(i => i.count)

      const dataSet = {
        data: data,
        borderColor: chartColorMain,
        backgroundColor: chartColorMain,
        pointBackgroundColor: chartColorMain,
        pointBorderColor: chartColorMain,
        pointRadius: 1,
        borderWidth: 1,
        tension: 1.0,
        fill: true
      }

      return { labels: labels, data: dataSet }
    },
    [apiListDashboardUser.items]
  )

  return (
    <Widget
      header={<DashboardHeader Icon={IconUser} text="USERS" value={count} />}
      body={<ChartBar labels={chartData.labels} data={chartData.data} displayY />}
      footer={
        <SelectRangeInterval
          start={start}
          end={end}
          intervals={apiFormDashboardUser.form.intervals}
          interval={interval}
          minDate={apiFormDashboardUser.form.min}
          maxDate={apiFormDashboardUser.form.max}
          onChangeStart={updateStart}
          onChangeEnd={updateEnd}
          onChangeInterval={updateInterval}
        />
      }
    />
  )
}
