import { useMemo } from 'react'

export const ACTION_ADD = 'ACTION_ADD'
export const ACTION_REMOVE_ID = 'ACTION_REMOVE_ID'
export const ACTION_REMOVE_ALL = 'ACTION_REMOVE_ALL'

export const itemType = {
  NOTIFY: 'NOTIFY',
  WARNING: 'WARNING',
  ERROR: 'ERROR'
}

export const useItemTypeTitle = (item, custom) => {
  return useMemo(
    () => {
      switch (item.type) {
        case itemType.NOTIFY:
          return 'Notify'
        case itemType.WARNING:
          return 'Warning'
        case itemType.ERROR:
          return 'Error'
        default:
          return custom
      }
    },
    [item.type, custom]
  )
}

export const useItemTypeHeaderColor = (item, custom) => {
  return useMemo(
    () => {
      switch (item.type) {
        case itemType.NOTIFY:
          return 'bg-primary'
        case itemType.WARNING:
          return 'bg-primary'
        case itemType.ERROR:
          return 'bg-danger'
        default:
          return custom
      }
    },
    [item.type, custom]
  )
}

export const useItemTypeTextColor = (item, custom) => {
  return useMemo(
    () => {
      switch (item.type) {
        case itemType.NOTIFY:
          return 'text-primary'
        case itemType.WARNING:
          return 'text-primary'
        case itemType.ERROR:
          return 'text-danger'
        default:
          return custom
      }
    },
    [item.type, custom]
  )
}

export const useItemTypeAlertColor = (item, custom) => {
  return useMemo(
    () => {
      switch (item.type) {
        case itemType.NOTIFY:
          return 'alert-info'
        case itemType.WARNING:
          return 'alert-warning'
        case itemType.ERROR:
          return 'alert-danger'
        default:
          return custom
      }
    },
    [item.type, custom]
  )
}
