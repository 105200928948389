import React from 'react'

export const FileDragDrop = ({ children, onHover, onDrop }) => {
  const handleDragEnter = e => {
    e.preventDefault()
    onHover && onHover(true)
  }

  const handleDragLeave = e => {
    e.preventDefault()
    onHover && onHover(false)
  }

  const handleDrop = e => {
    e.preventDefault()
    onHover && onHover(false)
    if (e.dataTransfer && e.dataTransfer.files.length) {
      onDrop && onDrop(e.dataTransfer.files[0])
    }
  }

  const handleDragOver = e => {
    e.preventDefault()
  }

  return (
    <div
      className="edxr-pointer"
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onDragOver={handleDragOver}>
      <span style={{ zIndex: 10 }}>
        {children}
      </span>
    </div>
  )
}
