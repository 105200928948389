import React from 'react'
import { Route } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { PermissionNodeUser } from './permission-node-user'
import { PermissionNodeGroup } from './permission-node-group'

export const PermissionNode = ({ url, nodeID }) => {
  return (
    <React.Fragment>
      <Route path={`${url}/user-permission`}>
        <PermissionNodeUser nodeID={nodeID} />
      </Route>
      <Route path={`${url}/group-permission`}>
        <PermissionNodeGroup nodeID={nodeID} />
      </Route>
    </React.Fragment>
  )
}
