import React, { useMemo, useState } from 'react'
import { MouseClickAway } from './mouse-click-away'
import { IconTimes, IconPlus } from '../../icon/icon'

export const SearchList = ({
  dropLeft,
  items,
  selectedItem,
  DisplayItemSelected,
  DisplayItemList,
  selectText,
  actionText,
  nullText,
  disabled,
  fnFilter,
  fnSort,
  fnSelectedItem,
  onAdd,
  onSelect
}) => {
  const [showFilter, updateShowFilter] = useState(false)
  const [filterValue, updateFilterValue] = useState(undefined)

  const listItems = useMemo(() => items || [], [items])

  const filteredItems = useMemo(
    () => {
      if (!fnFilter) {
        return listItems
      }

      if (!filterValue || filterValue.length <= 2) {
        return listItems
      }

      return fnFilter(listItems, filterValue)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listItems, filterValue]
  )

  const sortItems = useMemo(
    () => {
      if (!fnSort) {
        return filteredItems
      }

      return fnSort(filteredItems)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredItems]
  )

  const handleFilterChange = e => updateFilterValue(e.target.value)

  const handleNull = () => {
    onSelect(null)
    updateShowFilter(false)
  }

  const handleSelect = item => {
    updateShowFilter(false)
    onSelect && onSelect(item)
  }

  const handleFilterShow = () => {
    if (disabled) {
      return
    }

    updateFilterValue(undefined)
    updateShowFilter(true)
  }

  const handleSelectedAdd = () => {
    if (selectedItem) {
      onAdd && onAdd(selectedItem)
    }
  }

  const handleFilterClear = () => {
    if (filterValue) {
      updateFilterValue('')
    } else {
      updateShowFilter(false)
    }
  }

  const handleMouseClickAway = () => updateShowFilter(false)

  return (
    <React.Fragment>
      <MouseClickAway onClick={handleMouseClickAway}>
        {!showFilter &&
          <div>
            <div className="input-group">
              <div className={`form-control ${disabled && 'bg-light'}`} onClick={handleFilterShow}>
                <div className="no-wrap text-truncate">
                  <DisplayItemSelected selectText={selectText} item={selectedItem} />
                </div>
              </div>
              {onAdd &&
                <div className="input-group-append" style={{ cursor: 'pointer' }}>
                  <span className="input-group-text" onClick={handleSelectedAdd}>
                    <IconPlus />
                    <span className="text-capitalize">
                      {actionText}
                    </span>
                  </span>
                </div>}
            </div>
          </div>}

        {showFilter &&
          <div style={{ direction: dropLeft ? 'rtl' : 'ltr' }}>
            <div className="dropdown">
              <div className="input-group" style={{ direction: 'ltr' }}>
                <input className="form-control" value={filterValue} placeholder="filter" autoFocus onChange={handleFilterChange} />
                <div className="input-group-append">
                  <span className="input-group-text" onClick={handleFilterClear}>
                    <IconTimes />
                    {filterValue ? <span>Clear</span> : <span>Close</span>}
                  </span>
                </div>
              </div>

              <div
                className={`dropdown-menu ${showFilter && 'show'}`}
                style={{ maxHeight: '50vh', maxWidth: '50vw', overflow: 'auto', direction: 'ltr' }}>
                {nullText &&
                  <div className="dropdown-item text-capitalize" onClick={handleNull}>
                    {nullText}
                  </div>}

                {sortItems &&
                  <React.Fragment>
                    {sortItems.length
                      ? sortItems.map((item, i) =>
                          <div
                            key={i}
                            style={{ cursor: 'pointer' }}
                            className={`dropdown-item ${fnSelectedItem && fnSelectedItem(item) && 'bg-primary text-light'}`}
                            onClick={() => handleSelect(item)}>
                            <DisplayItemList item={item} selectedItem={selectedItem} />
                          </div>
                        )
                      : <div className="dropdown-item">No Results</div>}
                  </React.Fragment>}
              </div>
            </div>
          </div>}
      </MouseClickAway>
    </React.Fragment>
  )
}
