import React from 'react'
import { Route } from 'react-router-dom'
import { ContactUsComplete } from './contact-us-complete'
import { ContactUsRequest } from './contact-us-request'

export const LayoutContactUs = () => {
  return (
    <React.Fragment>
      <Route path="/public/contact-us/request" component={ContactUsRequest} />
      <Route path="/public/contact-us/complete" component={ContactUsComplete} />
    </React.Fragment>
  )
}
