import React, { useCallback, useMemo, useState } from 'react'
import { IconClose } from '../icon/icon'

const useCallbackDefault = (fn, defaultValue) => {
  return useCallback(
    item => {
      if (fn) {
        return fn(item)
      } else {
        return defaultValue
      }
    },
    [fn, defaultValue]
  )
}

export const SelectTag = ({ selectText, data, fnLabel, fnActive, fnReadOnly, onAdd, onRemove }) => {
  const [search, updateSearch] = useState('')

  const label = useCallbackDefault(fnLabel, '')
  const active = useCallbackDefault(fnActive, false)
  const readOnly = useCallbackDefault(fnReadOnly, false)

  const items = useMemo(() => data || [], [data])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeList = useMemo(() => items.filter(item => active(item)), [items])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeListReadOnly = useMemo(() => activeList.filter(item => readOnly(item)), [activeList])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeListEditable = useMemo(() => activeList.filter(item => !readOnly(item)), [activeList])

  const dropdownList = useMemo(
    () => {
      if (!items) {
        return []
      }

      return items.filter(item => !active(item))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, search]
  )

  const handleAdd = item => {
    onAdd && onAdd(item)
  }

  const handleRemove = item => {
    onRemove && onRemove(item)
    updateSearch('')
  }

  const disabled = useMemo(() => dropdownList.length === 0, [dropdownList.length])

  return (
    <div className="input-group">
      <button
        className="btn btn-outline-secondary dropdown-toggle text-capitalize"
        disabled={disabled}
        type="button"
        data-bs-toggle="dropdown">
        {selectText || 'select'}
      </button>

      <div className="form-control d-flex p-1">
        {activeListReadOnly.map((item, i) => <ActiveItemReadOnly key={i} item={item} label={label} />)}
        {activeListEditable.map((item, i) => <ActiveItemEditable key={i} item={item} label={label} onRemove={handleRemove} />)}
      </div>

      <ul className="dropdown-menu">
        {dropdownList.map((item, i) =>
          <li key={i} style={{ cursor: 'pointer' }} onClick={() => handleAdd(item)}>
            <span className="dropdown-item">
              {item.roleName}
            </span>
          </li>
        )}
      </ul>
    </div>
  )
}

const ActiveItem = ({ children, disabled }) => {
  return (
    <div className={`rounded me-1 ${disabled ? 'bg-secondary' : 'bg-primary'} text-light`}>
      {children}
    </div>
  )
}

const ActiveItemReadOnly = ({ item, label, onClick }) => {
  return (
    <ActiveItem disabled onClick={onClick}>
      <small className="mx-2">
        {label(item)}
      </small>
    </ActiveItem>
  )
}

const ActiveItemEditable = ({ item, label, onClick, onRemove }) => {
  const handleRemove = e => {
    e.stopPropagation()
    onRemove && onRemove(item)
  }

  return (
    <ActiveItem onClick={onClick}>
      <small className="my-1 mx-2">
        <span className="me-1">
          {label(item)}
        </span>
        <span style={{ cursor: 'pointer' }} className="" onClick={handleRemove}>
          <IconClose />
        </span>
      </small>
    </ActiveItem>
  )
}
