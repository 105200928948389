import React, { useMemo } from 'react'
import { useAuthContext } from '../../context/auth.context'
import { useRoleContext } from '../role.context'
import { ROLE_SYS } from '../role-const'

export const RoleSys = ({ children, type, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  const authContext = useAuthContext()
  const roleContext = useRoleContext()

  let display = useMemo(
    () => {
      if (authContext.state.isSysRoot) {
        return true
      }

      if (!roleContext.sysRoles[type]) {
        return false
      }

      if (accessRead) return roleContext.sysRoles[type].accessRead
      else if (accessCreate) return roleContext.sysRoles[type].accessCreate
      else if (accessUpdate) return roleContext.sysRoles[type].accessUpdate
      else if (accessDelete) return roleContext.sysRoles[type].accessDelete
      else return false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext.state, roleContext.sysRoles]
  )

  return (
    <React.Fragment>
      {display && children}
    </React.Fragment>
  )
}

export const RoleSysDashboard = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleSys
      type={ROLE_SYS.SYS_DASHBOARD}
      accessRead={accessRead}
      accessCreate={accessCreate}
      accessUpdate={accessUpdate}
      accessDelete={accessDelete}>
      {children}
    </RoleSys>
  )
}

export const RoleSysApp = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleSys
      type={ROLE_SYS.SYS_APP}
      accessRead={accessRead}
      accessCreate={accessCreate}
      accessUpdate={accessUpdate}
      accessDelete={accessDelete}>
      {children}
    </RoleSys>
  )
}

export const RoleSysOrganization = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleSys
      type={ROLE_SYS.SYS_ORGANIZATION}
      accessRead={accessRead}
      accessCreate={accessCreate}
      accessUpdate={accessUpdate}
      accessDelete={accessDelete}>
      {children}
    </RoleSys>
  )
}

export const RoleSysLogin = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleSys
      type={ROLE_SYS.SYS_LOGIN}
      accessRead={accessRead}
      accessCreate={accessCreate}
      accessUpdate={accessUpdate}
      accessDelete={accessDelete}>
      {children}
    </RoleSys>
  )
}

export const RoleSysAuthStatus = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleSys
      type={ROLE_SYS.SYS_AUTH_STATUS}
      accessRead={accessRead}
      accessCreate={accessCreate}
      accessUpdate={accessUpdate}
      accessDelete={accessDelete}>
      {children}
    </RoleSys>
  )
}

export const RoleSysProvider = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleSys
      type={ROLE_SYS.SYS_PROVIDER}
      accessRead={accessRead}
      accessCreate={accessCreate}
      accessUpdate={accessUpdate}
      accessDelete={accessDelete}>
      {children}
    </RoleSys>
  )
}

export const RoleSysSendgridEvent = ({ children, accessRead, accessCreate, accessUpdate, accessDelete }) => {
  return (
    <RoleSys
      type={ROLE_SYS.SYS_SENDGRID_EVENT}
      accessRead={accessRead}
      accessCreate={accessCreate}
      accessUpdate={accessUpdate}
      accessDelete={accessDelete}>
      {children}
    </RoleSys>
  )
}
