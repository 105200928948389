import React from 'react'

export const FormError = ({ hasError, error }) => {
  return (
    <React.Fragment>
      <div>
        <small className={hasError ? 'text-danger' : 'text-muted'}>
          {error}
        </small>
      </div>
    </React.Fragment>
  )
}

export const FormErrorRequired = ({ hasError }) => {
  return (
    <React.Fragment>
      <div>
        <small className={hasError ? 'text-danger' : 'text-muted'}>required</small>
      </div>
    </React.Fragment>
  )
}
