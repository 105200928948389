import React from 'react'
import { Route } from 'react-router-dom'
import { AdminNode } from './content/admin-node'

export const LayoutAdminNode = () => {
  return (
    <React.Fragment>
      <Route path="/admin-node/root" component={AdminNode} />
    </React.Fragment>
  )
}
