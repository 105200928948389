import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconList } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { Column, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import { useApiListRoleSys } from '../../../../../api-new/state/content'

export const RoleSysListLogins = () => {
  const { roleSys } = useParams()

  const apiListRoleSys = useApiListRoleSys()

  const title = useMemo(() => `Role ${roleSys}`, [roleSys])

  const handleReady = () => apiListRoleSys.get(`${roleSys}/role`)

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconList} text={roleSys}>
        <CardFullHeightScrollY title={title}>
          <TableBuilder apiList={apiListRoleSys} onReady={handleReady}>
            <Column field="roleName" sortable filter />
            <Column field="email" sortable filter />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
