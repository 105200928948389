import React, { useContext, useMemo } from 'react'
import { AuthContext } from './auth.context'

export const DisplayUnauth = ({ children }) => {
  const authContext = useContext(AuthContext)

  const isAuth = useMemo(() => !!authContext.state.accessToken, [authContext.state.accessToken])

  return (
    <React.Fragment>
      {!isAuth && children}
    </React.Fragment>
  )
}
