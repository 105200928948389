import { useMemo, useState } from 'react'
import { ModalDialog, useModalActions } from '../../../../../../shared/component/modal-dialog'
import { ButtonPrimary } from '../../../../../../shared/legacy/button'
import { IconCheck, IconCopy, IconDeepLinkOpen } from '../../../../../../shared/icon/icon'

export const CourseDeepLink = ({ targetID, nodeID }) => {
  const modalActions = useModalActions(targetID)

  const url = useMemo(() => `edxr://content?id=${nodeID}`, [nodeID])

  const handleOpen = () => {
    window.open(url)
    modalActions.hide()
  }

  const [state, updateState] = useState(false)

  const handleCopy = () => {
    window.navigator.clipboard.writeText(url)

    updateState(true)

    setTimeout(() => {
      updateState(false)
    }, 2000)
  }

  return (
    <ModalDialog target={targetID} header="Deep Link">
      <div>
        <div className="mb-2">
          <input className="form-control" value={url} readOnly />
        </div>
        <ButtonPrimary Icon={IconDeepLinkOpen} text="Open" onClick={handleOpen} />
        <ButtonPrimary Icon={state ? IconCheck : IconCopy} text="Copy" onClick={handleCopy} />
      </div>
    </ModalDialog>
  )
}
