import React from 'react'
import { Route } from 'react-router-dom'
import { TradeShowRegister } from './trade-show-register'
import { CaptchaProvider } from '../shared/legacy/captcha'

export function RouteExternal() {
  return (
    <React.Fragment>
      <CaptchaProvider>
        <Route path="/external/tradeshow/:identifier" component={TradeShowRegister} />
        <Route path="/external/tradeshow-get/:identifier" component={TradeShowRegister} />
      </CaptchaProvider>
    </React.Fragment>
  )
}
