import { useSlate } from 'slate-react'
import { Editor, Transforms, Element } from 'slate'
import { Button } from './button'

const LIST_TYPES = ['list-numbered', 'list-bulleted']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right']

export const ButtonBlockAlign = ({ Icon, format }) => {
  const editor = useSlate()

  const isActive = isBlockActive(editor, format, 'align')

  const handleClick = event => {
    event.preventDefault()
    toggleBlock(editor, format)
  }

  return <Button Icon={Icon} isActive={isActive} onClick={handleClick} />
}

export const ButtonBlockList = ({ Icon, format }) => {
  const editor = useSlate()

  const isActive = isBlockActive(editor, format, 'type')

  const handleClick = event => {
    event.preventDefault()
    toggleBlock(editor, format)
  }

  return <Button Icon={Icon} isActive={isActive} onClick={handleClick} />
}

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => !Editor.isEditor(n) && Element.isElement(n) && n[blockType] === format
    })
  )

  return !!match
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && LIST_TYPES.includes(n.type) && !TEXT_ALIGN_TYPES.includes(format),
    split: true
  })
  let newProperties = {}
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format
    }
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}
