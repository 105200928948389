import React from 'react'
import { useHistory } from 'react-router-dom'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormText, FormSubmitBack } from '../../../../../../shared/form/form'
import { IconEdit } from '../../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { useRoleSysApp } from '../../../../../../role/hook/role-sys'
import { useApiFormApp } from '../../../../../../api-new/state/config'

export const AppAdd = () => {
  const history = useHistory()

  const apiFormApp = useApiFormApp()

  const roleSysApp = useRoleSysApp()

  const handleSubmit = async data => {
    let app = await apiFormApp.post(data)
    if (roleSysApp.accessUpdate) history.push(`/admin/app/edit/${app.id}`)
    else history.push(`/admin/app`)
  }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="add">
        <CardFullHeightScrollY title="add app">
          <Form onSubmit={handleSubmit}>
            <FormText label="app name" name="name" required autoFocus />
            <FormSubmitBack text="add app" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
