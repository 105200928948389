import React, { useEffect, useMemo } from 'react'
import { useGetUniqueRowValues } from './hooks'
import { useAddUserContext } from '../context'
import { useImportContext } from './context'
import { Card } from '../../../../../../../shared/card/card'

export const StepTemplate = () => {
  const addUserContext = useAddUserContext()
  const importContext = useImportContext()

  const uniqueTemplateNames = useGetUniqueRowValues(importContext.rows, importContext.indexes.template)

  const handleUpdate = (index, templateName, template) => {
    let newValues = { ...importContext.templateValues }

    if (template) newValues[index] = { templateName, template }
    else delete newValues[index]

    importContext.updateTemplateValues(newValues)
  }

  useEffect(
    () => {
      if (Object.keys(importContext.templateValues).length) {
        return
      }

      let values = {}

      uniqueTemplateNames.forEach((templateName, index) => {
        let templateOptions = addUserContext.allTemplates.filter(template => template.name.toLowerCase().includes(templateName))

        if (templateOptions.length === 1) {
          let template = templateOptions[0]
          values[index] = { templateName, template }
        }
      })

      importContext.updateTemplateValues(values)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addUserContext.allTemplates, uniqueTemplateNames]
  )

  return (
    <React.Fragment>
      <Card title="map templates">
        {uniqueTemplateNames.map((templateName, i) =>
          <div key={i} className="m-2">
            <SelectTemplate index={i} label={templateName} templateName={templateName} onUpdate={handleUpdate} />
          </div>
        )}
      </Card>
    </React.Fragment>
  )
}

const SelectTemplate = ({ index, label, templateName, onUpdate }) => {
  const addUserContext = useAddUserContext()
  const importContext = useImportContext()

  const displayTemplates = useMemo(
    () => addUserContext.allTemplates.filter(template => template.name.toLowerCase().includes(templateName)),
    [addUserContext.allTemplates, templateName]
  )

  const value = useMemo(
    () => {
      let item = importContext.templateValues[index]
      if (item) {
        return item.template.id
      }
      return -1
    },
    [index, importContext.templateValues]
  )

  useEffect(
    () => {
      let warning = value === -1 && displayTemplates.length !== 0
      importContext.templateWarnings[index] = warning
      importContext.updateTemplateWarnings({ ...importContext.templateWarnings })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  const showWarning = useMemo(() => !!importContext.templateWarnings[index], [importContext.templateWarnings, index])

  const handleChange = e => {
    let id = parseInt(e.target.value)
    let template = addUserContext.allTemplates.find(i => i.id === id)
    onUpdate && onUpdate(index, templateName, template)
  }

  return (
    <div>
      <label className="form-label">
        {label}
      </label>
      <select className={`form-control ${showWarning && 'border-danger'}`} value={value} onChange={handleChange}>
        <option value={-1}>select</option>
        {displayTemplates.map((template, i) =>
          <option key={i} value={template.id}>
            {template.name}
          </option>
        )}
      </select>
    </div>
  )
}
